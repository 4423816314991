<template>
  <div class="tw-w-full">
    <div class="tw-flex">
      <input
        v-model="code"
        class="tw-input tw-border tw-w-full tw-rounded-r-none focus:tw-outline-none"
        :class="{ 'tw-border-error': error, 'tw-border-gray-300': !error }"
        placeholder="Scan affiliate code"
        @keyup.enter="onCodeInput"
      />
      <button
        class="tw-btn tw-btn-primary tw-rounded-l-none"
        @click="onCodeInput()"
      >
        <p v-if="!loading" class="tw-font-normal tw-text-white tw-mb-0">
          Apply
        </p>
        <LoadingIcon
          v-else
          class="tw-w-4 tw-h-4 tw-animate-spin dark:tw-text-gray-200 tw-fill-white"
        />
      </button>
    </div>

    <p class="tw-text-error tw-text-sm tw-mb-0" v-if="error">
      {{ error }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { LoadingIcon } from '@/components/icons'
import { toast, type ToastOptions } from 'vue3-toastify'
import { httpsCallable } from 'firebase/functions'
import { useSale } from '@/stores'
import functions from '@/config/firebase/functions'
import { ref, computed } from 'vue'

const options = {
  autoClose: 3000,
  type: toast.TYPE.WARNING,
  transition: toast.TRANSITIONS.SLIDE,
  theme: toast.THEME.LIGHT,
  position: toast.POSITION.BOTTOM_LEFT,
} as ToastOptions

const sale = useSale()

const code = ref<string>('')
const loading = ref(false)
const error = ref('')

const customer = computed(() => sale.customer)

const onCodeInput = () => {
  loading.value = true
  const createAffiliate = httpsCallable(functions, 'CreateAffiliate')
  createAffiliate({ uid: customer.value.userId, code: code.value })
    .then(async result => {
      const data = result.data as Array<string>
      if (data.length) {
        error.value = data[0]
        toast(data[0], options)
      } else {
        toast('Affiliate created successfully.', {
          ...options,
          type: toast.TYPE.SUCCESS,
        })
      }
    })
    .then(() => (loading.value = false))
}
</script>
