import database, { transform } from '@/config/firebase/realtime-database'
import { EventStatus, useEvents, type ExtendedEvent } from '../events'
import {
  ref,
  set,
  update,
  serverTimestamp,
  remove as removeNode,
} from 'firebase/database'
import { patchTimeStamp } from '@/utilities/common'
import { PromotionTypes, type Promotion } from '../promotion/types'
import { reactive, computed, ref as vRef } from 'vue'
import { SaleStatus, generateOrder } from './order'
import db from '@/config/firebase/database'
import { useRegister } from '../register'
import {
  KingAgreementCategories,
  ProductTypes,
  useInventory,
} from '../inventory'
import { defineStore } from 'pinia'
import { useStaff } from '../staff'
import moment from 'moment'
import debug from 'debug'

const logger = debug('sale')

import {
  cloneDeep,
  each,
  filter,
  findIndex,
  map,
  reduce,
  round,
  has,
  forEach,
  isArray,
  isEmpty,
  assign,
  find,
} from 'lodash'

import { usePromotion } from '../promotion/promotion'

import {
  collection,
  doc,
  Timestamp,
  runTransaction,
  serverTimestamp as serverTimestampFirestore,
  updateDoc,
} from 'firebase/firestore'

import {
  DiscountType,
  type SaleProductType,
  type AppliedPromotionType,
  type Customer,
  type GentlemanAgreementSaleProduct,
  type SaleDiscount,
  type SaleProduct,
  type ServiceSaleProduct,
  type UpgradeSaleProduct,
  type NoComplimentaryType,
} from './types'

import {
  calculatePoints,
  calculateServiceCharge,
  calculateVAT,
  getSubTotalAmount,
  getTotalPayableAmount,
  priceToConsider,
  sanitizeProduct,
} from '@/utilities/sale'

import Product from '../inventory/product'

import {
  createGentlemanAgreements,
  createGiftCheque,
  createKingsAgreements,
  createLedgerEntries,
  createLockerBox,
  createStoreCredit,
  createTickets,
  getRedeemedPoints,
  updateAgreements,
  updatePromotions,
} from './helper'

import {
  feeder,
  filterPromotionApplicableProducts,
} from '@/utilities/promotion'

export const useSale = defineStore('sale', () => {
  const order = reactive({
    ...generateOrder(),
  })

  const selectedAgreementId = vRef<string>('')
  const appliedAgreements = reactive<Array<any>>([])

  const customer = computed<Customer>(() => {
    return {
      userTags: order.userTags,
      userName: order.userName,
      userId: order.userId,
      userPhone: order.userPhone,
      userEmail: order.userEmail,
      userPersona: order.userPersona,
      memberGroup: order.memberGroup,
      customerAlert: order.customerAlert,
      onAccount: order.onAccount,
      affiliateCodes: order.affiliateCodes,
      userLoyalty: order.userLoyalty,
    }
  })

  const saleDiscount = computed<SaleDiscount>(() => {
    return {
      ...order.discount,
      amount:
        order.discount.type === DiscountType.Percentage
          ? round((subTotal.value / 100) * order.discount.percentage)
          : order.discount.amount,
      percentage:
        order.discount.type === DiscountType.Money
          ? (order.discount.amount / subTotal.value) * 100
          : order.discount.percentage,
    } as SaleDiscount
  })

  const promotionDiscount = computed(() => {
    const productPromotion = reduce(
      order.products,
      (total, product) => {
        return isEmpty(product.promotion)
          ? total
          : total + product.promotion?.discountByPromotion
      },
      0,
    )

    return productPromotion
  })

  const products = computed<Array<SaleProductType>>(() => {
    return order.products
  })

  const subTotal = computed(() => {
    const reducer = (acc: number, current: SaleProductType) =>
      acc + getSubTotalAmount(current)
    return round(reduce(order.products, reducer, 0))
  })

  const totalServiceCharge = computed(() => {
    const reducer = (acc: number, current: SaleProductType) => {
      if (current.type === ProductTypes.GentlemanAgreement) {
        const _current = current as GentlemanAgreementSaleProduct
        if (!_current.service || !_current.agreementCount) return 0

        return (
          acc +
          calculateServiceCharge(_current.service) * _current.agreementCount
        )
      }
      return acc + calculateServiceCharge(current) * current.quantity
    }
    return reduce(order.products, reducer, 0)
  })

  const totalServiceChargePaying = computed<number>(() => {
    return (
      totalServiceCharge.value -
      (totalServiceCharge.value / 100) * reduceChargesByPercentage.value
    )
  })

  const totalVAT = computed<number>(() => {
    const reducer = (acc: number, current: SaleProductType) => {
      if (current.type === ProductTypes.GentlemanAgreement) {
        const _current = current as GentlemanAgreementSaleProduct
        if (!_current.service || !_current.agreementCount) return 0

        return acc + calculateVAT(_current.service) * _current.agreementCount
      }
      return acc + calculateVAT(current) * current.quantity
    }

    return reduce(order.products, reducer, 0)
  })

  const totalVATPaying = computed<number>(() => {
    return (
      totalVAT.value - (totalVAT.value / 100) * reduceChargesByPercentage.value
    )
  })

  const total = computed<number>(() => {
    return round(subTotal.value + totalVAT.value + totalServiceCharge.value)
  })

  const totalPayableAmount = computed<number>(() => {
    const voidChargesByPercentage = saleDiscount.value.percentage || 0

    const amount = reduce(
      order.products,
      (acc, current) =>
        acc + getTotalPayableAmount(current, voidChargesByPercentage),
      0,
    )

    const voidAmount = saleDiscount.value.amount || 0

    // TODO removed -3 rounding from here
    return round(amount - voidAmount)
  })

  const totalReportingValue = computed<number>(() => {
    const exclude = [
      ProductTypes.GentlemanAgreement,
      ProductTypes.KingsAgreement,
      ProductTypes.GiftCheque,
      ProductTypes.LockerBox,
    ]

    const voidChargesByPercentage = saleDiscount.value.percentage || 0

    const reducer = (acc: number, current: SaleProductType) =>
      acc + getTotalPayableAmount(current, voidChargesByPercentage)
    const products = filter(order.products, p => !exclude.includes(p.type))
    const amount = reduce(products, reducer, 0)

    const voidAmount = saleDiscount.value.amount || 0

    return round(amount - voidAmount)
  })

  /**
   * Returns the count value times two plus one.
   *
   * @returns {number}
   */
  const totalDiscountByPromotion = computed<number>(() => {
    const productPromotionAmount = reduce(
      order.products,
      (sum, product) => sum + (product.promotion?.discountByPromotion || 0),
      0,
    )
    const salePromotionAmount = saleDiscount.value.promotion?._id
      ? saleDiscount.value.amount
      : 0
    return round(productPromotionAmount + salePromotionAmount, -3)
  })

  /**
   * @description Overall discount applied to sale considering
   * the sale discount, auto and manual promotion discounts.
   * useful for receipt and reporting data.
   *
   * @returns {number}
   */
  const totalDiscount = computed<number>(() => {
    return saleDiscount.value.amount + totalDiscountByPromotion.value
  })

  /**
   * @description Overall sale discount applied considering
   * the sale discount and sale promotion
   *
   * @returns {number}
   */
  const totalSaleDiscount = computed<number>(() => {
    return saleDiscount.value.amount
  })

  const totalPointsEarned = computed<number>(() => {
    return reduce(
      order.products,
      (sum, product) => {
        if (!product.points) return sum
        return sum + product.points
      },
      0,
    )
  })

  const appliedPromotion = computed(() => {
    return order.promotion
  })

  const appliedPromotionIds = computed(() => {
    return map(order.promotion, p => p._id)
  })

  const agreementTotal = computed(() => {
    return reduce(
      appliedAgreements,
      (total, current: any) => {
        return total + current.agreementTotal
      },
      0,
    )
  })

  const totalExcludingVAT = computed(() => {
    return reduce(
      order.products,
      (acc, current) => {
        return (acc += priceToConsider(current) * current.quantity)
      },
      0,
    )
  })

  const reduceChargesByPercentage = computed<number>(() => {
    return saleDiscount.value.percentage || 0
  })

  const setTable = (id: string, name: string) => {
    order.tableId = id
    order.tableName = name
    onTableChange(id, name).then(() => {
      logger('Event document updated with table')
    })
    sync()
    return { tableId: id, tableName: name }
  }

  const onTableChange = async (tableId: string, tableName: string) => {
    if (order.eventItem?.type === 'reservation') {
      order.customBill = ''
      order.products.forEach(p => {
        if (p.type === ProductTypes.Service) {
          p.salesPerson = {
            staffId: tableId,
            staffName: tableName,
            image: '',
          }
        }
      })

      order.tableName = tableName
      order.staffName = tableName
    }

    const register = useRegister()
    const eventRef = doc(
      db,
      `locations/${register.location}/events/${order.eventItem._id}`,
    )

    const services = map([...(order.eventItem.services || [])], (s: any) => ({
      ...s,
      staffName: tableId,
      staff: tableName,
    }))

    await updateDoc(eventRef, {
      services,
      tableName,
      staffName: tableName,
    })
  }

  const setOrderNote = (note: string) => {
    order.orderNote = note
    sync()
  }

  const activateAgreement = (id: string) => {
    selectedAgreementId.value = id
  }

  const assignCustomer = async (customer: any) => {
    order.userTags = customer.userTags ?? []
    order.userName = customer.fullName ?? ''
    order.userId = customer._id
    order.userPhone = customer.phone ?? ''
    order.userEmail = customer.email ?? ''
    order.userPersona = customer.persona ?? []
    order.memberGroup = customer.membership?.level ?? ''
    order.customerAlert = customer.customerAlert ?? ''
    order.onAccount = customer.onAccount ?? ''
    order.affiliateCodes = customer.affiliateCodes ?? []

    order.userLoyalty = {
      groomPoints: customer?.membership?.groomPoints || 0,
      networkPoints: customer?.membership?.networkPoints || 0,
      stylePoints: customer?.membership?.stylePoints || 0,
    }

    if (order.products.length) {
      const promotion = usePromotion()
      await promotion.recomputePromotion()
    }

    await sync()
  }

  const removeCustomer = async () => {
    order.userId = ''
    order.userTags = []
    order.userName = ''
    order.userPhone = ''
    order.userEmail = ''
    order.userPersona = []
    order.memberGroup = ''
    order.customerAlert = ''
    order.onAccount = false
    order.userLoyalty = {
      groomPoints: 0,
      networkPoints: 0,
      stylePoints: 0,
    }

    const promotion = usePromotion()
    await promotion.recomputePromotion()
    await sync()
  }

  const setGuestCount = async (count: number) => {
    order.amountOfGuest = count
    await sync()
  }

  const setCustomBill = (customBillName: string) => {
    order.customBill = customBillName
    return customBillName
  }

  const setCustomerAlert = async (alert: string) => {
    const ref = doc(db, 'users', order.userId)
    await updateDoc(ref, { customerAlert: alert })
    order.customerAlert = alert
    await sync()
  }

  const sync = async (
    options: { shouldClearState: boolean } = { shouldClearState: false },
  ) => {
    const register = useRegister()

    const _update = {
      ...order,
      totalOrderValue: total.value,
      totalReportingValue: totalReportingValue.value,
      totalPayableAmount: totalPayableAmount.value,
      totalWithoutVat: round(totalExcludingVAT.value),
      totalServiceCharge: round(totalServiceChargePaying.value),
      totalVAT: round(totalVATPaying.value),
      totalDiscount: round(totalDiscount.value),
      totalPoints: totalPointsEarned.value,
      registerId: register.registerId,
      updatedAt: serverTimestamp(),
    }

    if (_update.orderId) {
      logger(`order ${order.orderId} being updated`)
      await update(
        ref(database, `${register.location}/${_update.orderId}`),
        transform(_update),
      )
    } else {
      logger(`order update skipped`)
    }

    if (options.shouldClearState) {
      clear()
    }
  }

  const updateProperty = async (
    index: number,
    updates: { [key: string]: any },
  ) => {
    const next = { ...order.products[index], ...updates }
    order.products.splice(index, 1, next)
    await sync()
  }

  const updateStatus = async (status: string) => {
    const register = useRegister()
    const orderId = order.orderId

    logger(`order ${orderId} status being updated`)

    const _update = {
      status,
      updatedAt: serverTimestamp(),
    }

    if (orderId) {
      await update(
        ref(database, `${register.location}/${orderId}`),
        transform(_update),
      )
    }
  }

  const open = async (data: any) => {
    const { customer, ...tableInfo } = data
    const register = useRegister()
    const staff = useStaff()

    const createdAtTimestamp = serverTimestamp()

    const payload = {
      ...generateOrder(),
      registerId: register.registerId,
      createdAt: createdAtTimestamp,
      updatedAt: createdAtTimestamp,
      orderId: moment().unix(),
      staffId: staff.active._id,
      staffName: staff.active.fullName,
      isCustomBill: !!data?.customBill,
      active: true,
      status: SaleStatus.OPEN,
      ...tableInfo,
      ...customer,
    }

    await set(
      ref(database, `${register.location}/${payload.orderId}`),
      transform(payload),
    )

    return payload
  }

  const add = async (product: SaleProductType) => {
    product.internalId = Date.now().toString()

    const staff = useStaff()
    if (!product.salesPerson) {
      product.salesPerson = {
        staffId: staff.active._id,
        staffName: staff.active.fullName,
        image: staff.active.image,
      }
    }

    if ([ProductTypes.Service, ProductTypes.Upgrade].includes(product.type)) {
      const agreementIndex = findIndex(
        order.products,
        o => o.internalId === selectedAgreementId.value,
      )

      if (agreementIndex !== -1) {
        const _product = {
          ...order.products[agreementIndex],
        } as ServiceSaleProduct
        _product.service = product
        _product.price = product.price

        order.products.splice(agreementIndex, 1, _product)
        selectedAgreementId.value = ''
      } else {
        order.products.push(product)
      }
    } else {
      order.products.push(product)
    }

    if (
      [
        ProductTypes.GentlemanAgreement,
        ProductTypes.InitiateAgreement,
      ].includes(product.type)
    ) {
      selectedAgreementId.value = product.internalId
    }

    const promotion = usePromotion()
    await promotion.recomputePromotion()
    await sync()
  }

  const remove = async (removable: any) => {
    const _remove = (product: SaleProduct) => {
      const index = findIndex(
        order.products,
        p => p.internalId === product.internalId,
      )

      if (index === -1) {
        logger(`Could not find item in sale`)
        return
      }
      order.products.splice(index, 1)
    }

    try {
      if (isArray(removable)) {
        each(removable, _remove)
      } else {
        _remove(removable)
      }

      const promotion = usePromotion()
      await promotion.recomputePromotion()
      await sync()
    } catch (error) {
      console.error(error)
    }
  }

  const createOrderProductsSummary = () => {
    const _order = cloneDeep(order)

    const summary = (product: any) => {
      /**
       * price: Single item price
       * totalEx: quantity * price OR manualPrice
       * totalDiscount: TotalEx * (discount OR discountByPromotion)/100
       * totalServiceCharge: (TotalEx - TotalDiscount) * serviceCharge->amount/100
       * totalVAT: (TotalEx - TotalDiscount + TotalServiceCharge) * VAT->amount/100
       * total: totalEx + TotalServiceCharge + TotalVAT - TotalDiscount
       *
       * For agreements sold we will need to store the same information as above inside
       * the product object. Now all prices are 0 in agreement objects in the products array.
       *
       */

      if (product.type === ProductTypes.GentlemanAgreement) {
        if (product.service) {
          product.price = product.service.price
          product.totalEx = round(
            product.service.price * product.agreementCount,
          )
        } else {
          logger(`agreement linked service not found`)
          throw new Error(
            'Agreement has no service selected, could not proceed with order',
          )
        }
      } else {
        product.price = product.manualPrice
          ? product.manualPrice
          : product.price
        product.totalEx = round(product.price * product.quantity)
      }

      if (product.discount) {
        product.totalDiscount = round(
          (product.totalEx / 100) * product.discount,
        )
      } else if (product.promotion?.discountByPromotion) {
        product.totalDiscount = round(
          product.totalEx * (product.promotion.discountByPromotion / 100),
        )
      } else {
        product.totalDiscount = 0
      }

      if (has(product.serviceCharge, 'amount')) {
        product.totalServiceCharge = round(
          ((product.totalEx - product.totalDiscount) *
            product.serviceCharge.amount) /
            100,
        )
      } else {
        product.totalServiceCharge = 0
      }

      if (has(product.VAT, 'amount')) {
        product.totalVAT = round(
          ((product.totalEx -
            product.totalDiscount +
            product.totalServiceCharge) *
            product.VAT.amount) /
            100,
        )
      } else {
        product.totalVAT = 0
      }

      product.total = round(
        product.totalEx +
          product.totalServiceCharge +
          product.totalVAT -
          product.totalDiscount,
      )
      return product
    }

    _order.products = map(order.products, product => {
      if (product.type === ProductTypes.Upgrade) {
        const P = product as UpgradeSaleProduct
        if (P.complimentaryDrink) {
          const drink = { ...P.complimentaryDrink, price: 0 }
          P.complimentaryDrink = summary(drink)
        }

        return summary(P)
      }

      if (product.type === ProductTypes.Service) {
        const P = product as ServiceSaleProduct
        if (P.complimentaryFood) {
          const food = { ...P.complimentaryFood, price: 0 }
          P.complimentaryFood = summary(food)
        }

        if (P.complimentaryDrink) {
          const drink = { ...P.complimentaryDrink, price: 0 }
          P.complimentaryDrink = summary(drink)
        }

        return summary(P)
      }

      return summary(product)
    })

    return _order
  }

  const complete = async (payload: {
    payments: Array<any>
    voucher?: any
    discard?: boolean
  }) => {
    try {
      await sync()

      const { discard = false } = payload
      const register = useRegister()
      const orderRef = doc(
        collection(db, `locations/${register.location}/orders`),
      )

      const orderId = await runTransaction(db, async transaction => {
        const locationRef = doc(db, `locations/${register.location}`)
        const locationDoc = await transaction.get(locationRef)
        const locationData = locationDoc.data() as {
          code: string
          OrderIdSequence: number
        }

        const _OrderIdSequence = locationData?.OrderIdSequence || 0
        const orderId = `${locationData.code}-${_OrderIdSequence + 1}`

        let orderData: any = createOrderProductsSummary()

        orderData.products = orderData.products.map((p: any) => {
          if (p?.preparationFinish && p?.preparationStart) {
            p.preparationTime = p.preparationFinish - p.preparationStart
          }
          return patchTimeStamp(
            p,
            'orderedAt',
            'orderTime',
            'preparationStart',
            'preparationFinish',
          )
        })

        orderData = patchTimeStamp(
          orderData,
          'createdAt',
          'updatedAt',
          'eventItem.createdAt',
          'eventItem.updatedAt',
          'eventItem.startDate',
          'eventItem.endDate',
          'eventItem.reminderDate',
        )

        orderData.redeemedPoints = getRedeemedPoints(payload.payments)
        delete orderData.userLoyalty

        const { products, ...partialOrderData } = orderData

        logger({
          ...partialOrderData,
          orderId,
          paid: discard ? false : true,
          status: discard ? SaleStatus.DISCARDED : SaleStatus.CLOSED,
          closedAt: serverTimestampFirestore(),
          agreementTotal: agreementTotal.value,
        })

        await transaction.set(orderRef, {
          ...partialOrderData,
          orderId,
          paid: discard ? false : true,
          status: discard ? SaleStatus.DISCARDED : SaleStatus.CLOSED,
          closedAt: serverTimestampFirestore(),
          agreementTotal: agreementTotal.value,
        })

        await Promise.all(
          map(products, async product => {
            const _ref = doc(
              collection(
                db,
                `locations/${register.location}/orders/${orderRef.id}/items`,
              ),
            )
            const sanitized = sanitizeProduct(product, { orderId: orderRef.id })
            if (isArray(sanitized)) {
              await Promise.all(
                sanitized.map(s => {
                  const itemRef = doc(
                    collection(
                      db,
                      `locations/${register.location}/orders/${orderRef.id}/items`,
                    ),
                  )
                  return transaction.set(itemRef, s)
                }),
              )
            } else {
              await transaction.set(_ref, sanitized)
            }
          }),
        )

        if (!discard) {
          const actions = [
            createLedgerEntries,
            updateAgreements,
            updatePromotions,
            createGentlemanAgreements,
            createKingsAgreements,
            createGiftCheque,
            createLockerBox,
            createTickets,
            createStoreCredit,
          ]

          await Promise.all(
            actions.map(async action => {
              await action({
                transaction,
                _id: orderRef.id,
                orderId,
                registerId: register.registerId,
                registerName: register.outlet,
                location: register.location,
                customer: customer.value,
                order: order,
                payments: payload.payments,
                voucher: payload.voucher,
                saleDiscount: saleDiscount.value,
              })
            }),
          )
        } else {
          console.log(`Order:: skipped actions for discarded order`)
        }

        const OrderIdSequence = _OrderIdSequence + 1
        transaction.update(locationRef, { OrderIdSequence })

        logger(`Order ${orderRef.id} has been processed`)

        return orderId
      })

      if (register.location && order.orderId) {
        const _node = ref(database, `${register.location}/${order.orderId}`)
        await removeNode(_node)
      } else {
        logger(
          `Skipping remove node with location ${register.location} & orderId ${order.orderId}`,
        )
      }

      await clear()

      return orderId
    } catch (e) {
      logger(e)
    }
  }

  const openParked = async () => {
    const register = useRegister()
    order.parkedSale = false
    order.active = true
    order.createdAt = Timestamp.fromDate(new Date())
    order.openRegisterEntryId = register.registerId
    return await sync()
  }

  const discard = async () => {
    await complete({
      payments: [],
      discard: true,
    })
    return true
  }

  const park = async () => {
    order.parkedSale = true
    order.active = false
    order.status = SaleStatus.PARKED
    order.openRegisterEntryId = ''
    await sync()
    return { parkedSale: true }
  }

  const clear = () => {
    assign(order, { ...generateOrder() })
  }

  /**
   * @param discount { type, percentage, amount }
   * @description Manual discount being applied through
   * the discount dialog in the Order
   */
  const applyDiscount = async (discount: SaleDiscount) => {
    order.discount = discount
    await sync()
  }

  const resetDiscount = async (manual = false) => {
    order.discount = {
      type: 'percentage',
      percentage: 0,
      amount: 0,
      promotion: null,
      manual: manual,
    }

    await sync()
  }

  const applySalePromotion = async (
    promotion: Promotion,
    manual: boolean = true,
  ) => {
    await resetDiscount()
    const payload = {
      amount:
        promotion.discountType === DiscountType.Money
          ? promotion.discountValue
          : (subTotal.value / 100) * promotion.discountValue,
      percentage:
        promotion.discountType === DiscountType.Money
          ? round((promotion.discountValue / subTotal.value) * 100)
          : promotion.discountValue,
      type: promotion.discountType,
      promotion: { ...promotion },
      manual,
    }
    order.discount = payload
  }

  const applyPromotion = async (
    promotion: Promotion,
    manual: boolean = true,
  ) => {
    if (promotion.type === PromotionTypes.Sale) {
      await applySalePromotion(promotion, manual)
    } else {
      const applicableProducts = filterPromotionApplicableProducts(
        promotion,
        order.products,
      )

      if (applicableProducts.length) {
        each(applicableProducts, product => {
          const index = findIndex(
            order.products,
            i => i.internalId === product.internalId,
          )
          if (index !== -1) {
            const _product = { ...order.products[index] }
            _product.manualPromotion = manual

            const _discountAmount = feeder(
              _product.price,
              promotion.discountType,
              promotion.discountValue,
            )

            if (promotion.discountType === DiscountType.Money) {
              _product.promotion = {
                ...promotion,
                discountByPromotion: _discountAmount,
                manual,
              }
              _product.manualPrice = _product.price - _discountAmount
              _product.discount = 0
              _product.priceAfterDiscount =
                _product.price - promotion.discountValue
              _product.points = calculatePoints(_product)
            } else if (promotion.discountType === DiscountType.Percentage) {
              _product.promotion = {
                ...promotion,
                discountByPromotion: _discountAmount,
                manual,
              }
              _product.manualPrice = 0
              _product.discount = promotion.discountValue
              _product.priceAfterDiscount = _product.price - _discountAmount
              _product.points = calculatePoints(_product)
            } else if (promotion.discountType === DiscountType.FixedPrice) {
              const _priceToConsider = priceToConsider(_product)
              if (_priceToConsider > _discountAmount) {
                _product.promotion = {
                  ...promotion,
                  discountByPromotion: _priceToConsider - _discountAmount,
                  manual,
                }
                _product.manualPrice = promotion.discountValue
                _product.discount = 0
                _product.priceAfterDiscount = promotion.discountValue
                _product.points = calculatePoints(_product)
              }
            }

            order.products.splice(index, 1, _product)
          } else {
            logger(
              `product lookup failed for ${product.id} with internal id ${product.internalId} to apply promotion`,
            )
          }
        })
      }
    }

    await sync()
  }

  const removeSalePromotion = (manual: boolean = true) => {
    order.discount = {
      type: DiscountType.Percentage,
      percentage: 0,
      amount: 0,
      promotion: null,
      manual,
    }
  }

  const removePromotion = async (
    promotion: Promotion,
    manual: boolean = false,
  ) => {
    if (promotion.type === PromotionTypes.Sale) {
      await removeSalePromotion(manual)
    } else {
      const index: number = findIndex(
        order.promotion,
        p => p._id === promotion._id,
      )

      if (index === -1) {
        logger(
          `Trying to revert manual promotion which is not applied on order.`,
        )
        return
      }

      const _promotion = order.promotion[index]

      order.products.forEach(product => {
        if (product.promotion?._id === _promotion._id) {
          product.promotion = {} as AppliedPromotionType
          product.discount = 0
          product.manualPrice = 0
          product.priceAfterDiscount = 0
          product.manualPromotion = manual
          product.points = calculatePoints(product)
        }
      })
    }

    await sync()
  }

  const removePromotionFromProduct = async (product: SaleProductType) => {
    logger(`Removing promotion from ${product.id}`)

    const productIndex = findIndex(
      order.products,
      p => p.internalId === product.internalId,
    )

    if (productIndex === -1) {
      logger(
        `Trying to revert promotion on non-existing item ${product.id} (${product.internalId})`,
      )
      return
    }

    const _product = { ...order.products[productIndex] }

    _product.promotion = {} as AppliedPromotionType
    _product.manualPromotion = true
    _product.discount = 0
    _product.priceAfterDiscount = 0
    _product.manualPrice = 0
    _product.points = calculatePoints(_product)

    order.products.splice(productIndex, 1, _product)

    const promotions = usePromotion()
    await promotions.recomputePromotion()
    await sync()
  }

  /**
   * @description Agreement can be added or removed, Find out whether the agreement
   * is added or removed. For added case, apply agreement of all applicable items and
   * preserver it's copy in  If agreement is being removed, restore the order
   * items from previously saved
   *
   */
  const applyAgreement = (agreement: any) => {
    removeSalePromotion(true)

    const _applied = {
      agreement,
      agreementTotal: 0,
      old: [] as Array<SaleProductType>,
    }

    const excludeProducts = reduce(
      appliedAgreements,
      (items, applied) => {
        items = [...items, ...map(applied.old, 'internalId')]
        return items
      },
      [] as Array<string>,
    )

    let appliedCounter = 0

    const cleanPromotion = (_product: SaleProductType) => {
      _product.promotion = {} as AppliedPromotionType
      _product.manualPromotion = true
      _product.discount = 0
      _product.priceAfterDiscount = 0
      _product.manualPrice = 0
      _product.points = calculatePoints(_product)
    }

    each(order.products, (product, index) => {
      if (excludeProducts.includes(product.internalId)) {
        logger(
          `${product.id} (${product.internalId}) has already applied a promotion`,
        )
        return
      }
      if (product.type === ProductTypes.Service) {
        const _product = { ...product }

        switch (agreement.type) {
          case ProductTypes.Service:
          case ProductTypes.InitiateAgreement:
            if (map(agreement.services, s => s.id).includes(product.id)) {
              cleanPromotion(_product)
              const P = _product as ServiceSaleProduct
              // TODO remove this rounding post release v1.0.8 as permanent fix has been
              // applied in the agreement creation action
              _applied.agreementTotal +=
                round(agreement.pricePerService) * product.quantity
              _applied.old.push({ ...P })

              /**
               * @see https://barbaard.atlassian.net/jira/software/projects/BPS/boards/3?selectedIssue=BPS-467
               * As pricePerService is including the VAT (8%) & Service Charge 5% we need to remove it from product price and add
               * pricePerService as paid amount so balance the calculation
               */

              P.manualPrice = agreement.pricePerService / 1.05 / 1.08
              P.discount = 0
              P.payedByAgreement = true
              P.payedByAgreementId = agreement._id

              order.products.splice(index, 1, P)
            }
            break
          case ProductTypes.KingsAgreement:
          case ProductTypes.EmperorsAgreement:
          case ProductTypes.NobelAgreement:
            const P = _product as ServiceSaleProduct
            if (
              agreement.type === ProductTypes.KingsAgreement &&
              !KingAgreementCategories.includes(P.serviceCategory || '')
            ) {
              break
            }

            cleanPromotion(P)
            //_applied.agreementTotal += product.price * product.quantity
            P.payedByAgreement = true
            P.payedByAgreementId = agreement._id

            // _applied.agreementTotal += getTotalPayableAmount(product)
            _applied.old.push({ ...P })

            P.discount = 100
            P.priceAfterDiscount = 0
            order.products.splice(index, 1, P)
            break
          case ProductTypes.MerchantsAgreement:
          case ProductTypes.GrandMerchantsAgreement:
            if (appliedCounter < 2) {
              cleanPromotion(_product)
              const P = _product as ServiceSaleProduct
              _applied.agreementTotal +=
                agreement.pricePerService * product.quantity
              _applied.old.push({ ...P })
              /**
               * @see https://barbaard.atlassian.net/jira/software/projects/BPS/boards/3?selectedIssue=BPS-467
               * As pricePerService is including the VAT (8%) & Service Charge 5% we need to remove it from product price and add
               * pricePerService as paid amount so balance the calculation
               */
              P.manualPrice = agreement.pricePerService / 1.05 / 1.08
              P.discount = 0
              P.payedByAgreement = true
              P.payedByAgreementId = agreement._id
              order.products.splice(index, 1, P)
              appliedCounter++
            }
            break
        }
      }

      if (product.type === ProductTypes.Upgrade) {
        const _product = { ...product }
        const P = _product as UpgradeSaleProduct

        switch (agreement.type) {
          case 'upgrades':
            logger('Upgrade agreement')
            if (map(agreement.upgrades, s => s.id).includes(P.id)) {
              cleanPromotion(_product)
              _applied.agreementTotal +=
                round(agreement.pricePerService) * P.quantity
              _applied.old.push({ ...P })

              /**
               * @see https://barbaard.atlassian.net/jira/software/projects/BPS/boards/3?selectedIssue=BPS-467
               * As pricePerService is including the VAT (8%) we need to remove it from product price and add
               * pricePerService as paid amount so balance the calculation
               */
              P.manualPrice = agreement.pricePerService / 1.05 / 1.08
              P.discount = 0
              P.payedByAgreement = true
              P.payedByAgreementId = agreement._id

              order.products.splice(index, 1, P)
            }
            break
        }
      }
    })

    appliedAgreements.push(_applied)
  }

  const revertAgreement = async (
    agreement: any,
    shouldUpdateOrder: boolean = true,
  ) => {
    const index = findIndex(
      appliedAgreements,
      a => a.agreement._id === agreement._id,
    )

    if (index === -1) {
      logger(
        `Trying to revert promotion, ${agreement._id} not found in applied agreements`,
      )
    }

    logger(
      `Agreement ${appliedAgreements[index].old.length} items needs reversal`,
    )

    each(appliedAgreements[index].old, old => {
      const index = findIndex(order.products, p => p.id === old.id)

      if (index !== -1) {
        order.products.splice(index, 1, { ...old })
      } else {
        logger(`Agreement ${old.id} could not be restored`)
      }
    })

    appliedAgreements.splice(index, 1)

    if (shouldUpdateOrder) {
      await sync()
    }
  }

  const revertAllAppliedAgreements = async () => {
    const allAgreements = appliedAgreements.map(data => data.agreement)
    allAgreements.forEach(agreement => revertAgreement(agreement, false))
    await sync()
  }

  const flushAppliedAgreements = () => {
    appliedAgreements.splice(0, appliedAgreements.length)
  }

  const fromSale = async (sale: any) => {
    assign(order, sale)
  }

  const markAllItemsPrinted = async () => {
    const course = !has(order, 'course') ? 1 : order.course + 1
    order.course = course
    order.products = map(order.products, p => ({ ...p, isPrinted: true }))
    await sync()
  }

  const fromAppointment = async (
    customer: any,
    event: ExtendedEvent,
    status = EventStatus.ArrivedOnTime,
  ) => {
    assign(order, { ...generateOrder() })
    const inventory = useInventory()
    const register = useRegister()
    const staff = useStaff()

    if (!inventory.services.length) {
      await inventory.setServices()
    }

    const guest = {
      userId: customer._id,
      userName: customer.fullName,
      userTags: customer.tags || [],
      userPhone: customer?.phone,
      userEmail: customer?.email,
      memberGroup: customer.membership?.level ?? '',
    }

    const products: Array<any> = []
    let department = 'bar'

    if (event?.type !== 'reservation') {
      department = 'barbershop'
      if (event.services && event.services.length) {
        event.services.forEach((service: any) => {
          const _service = new Product(service, ProductTypes.Service)

          const inventoryService = inventory.services.find(
            s => s.id === service.id,
          )

          const item: any = {
            id: service.id,
            name: _service.title,
            type: ProductTypes.Service,
            category: ProductTypes.Service,
            price: _service.price,
            itemNote: '',
            originalPrice: _service.price,
            manualPrice: 0,
            promotion: {},
            quantity: 1,
            discount: 0,
            VAT: _service.VAT,
            serviceCharge: _service.serviceCharge,
            salesPerson: {
              staffName: service?.staff ? service.staff : staff.active.fullName,
              staffId: service?.staffId ? service.staffId : staff.active._id,
            },
            ...(inventoryService?.data.complimentary
              ? {
                  complimentaries: {
                    drink: 'drink-complimentary',
                    food: 'snack-complimentary',
                  },
                  complimentaryDrink: {} as NoComplimentaryType,
                  complimentaryFood: {} as NoComplimentaryType,
                }
              : {}),
          }

          products.push(item)
        })
      }

      if (event.upgrades && event.upgrades.length) {
        event.upgrades.forEach(
          (upgrade: {
            id: string
            name: string
            price: number
            staff?: string
            staffId?: string
            linkedDrink?: string
          }) => {
            const _upgrade = new Product(
              {
                _id: upgrade.id,
                name: upgrade.name,
                price: upgrade.price,
              },
              ProductTypes.Upgrade,
            )

            const item: Record<string, any> = {
              id: upgrade.id,
              name: _upgrade.title,
              price: _upgrade.price,
              itemNote: '',
              type: ProductTypes.Upgrade,
              category: ProductTypes.Upgrade,
              originalPrice: upgrade.price,
              manualPrice: 0,
              manualPromotion: false,
              promotion: {},
              quantity: 1,
              salesPerson: {
                staffName: upgrade?.staff
                  ? upgrade.staff
                  : staff.active.fullName,
                staffId: upgrade?.staffId ? upgrade.staffId : staff.active._id,
              },
              VAT: _upgrade.VAT,
              serviceCharge: _upgrade.serviceCharge,
            }

            if (upgrade?.linkedDrink) {
              item.complimentaries = {
                drink: 'drink-gentlemanup',
              }
            }

            products.push(item)
          },
        )
      }
    }

    const data = {
      ...guest,
      products,
      customBill: event.staffName,
      amountOfGuest: 1,
      department: department,
      salesCategory: department,
      eventItem: event,
    }

    const _order = await open(data)

    const _event = useEvents()
    _event.setEventStatus({
      _id: event._id,
      location: register.location,
      userId: event.userId as string,
      status,
    })
    return { orderId: _order.orderId }
  }

  const voidQuantityFromSale = (products: Array<SaleProductType>) => {
    forEach(products, product => {
      const index = findIndex(
        order.products,
        p => p.internalId === product.internalId,
      )
      if (index !== -1) {
        const _product = { ...order.products[index] }
        if (_product.quantity === product.quantity) {
          // remove the product completely
          order.products.splice(index, 1)
        } else {
          _product.quantity = _product.quantity - product.quantity
          // deduct the quantity moved to new sale
          order.products.splice(index, 1, _product)
        }
      } else {
        logger(
          `could not find ${product.id} (${product.internalId}) in existing sale for split`,
        )
      }
    })
  }

  const splitSale = async (data: any) => {
    const register = useRegister()
    const staff = useStaff()

    const createdAtTimestamp = serverTimestamp()

    const { products, ...orderData } = data

    const payload = {
      ...generateOrder(),
      registerId: register.registerId,
      createdAt: createdAtTimestamp,
      updatedAt: createdAtTimestamp,
      orderId: moment().unix(),
      staffId: staff.active._id,
      staffName: staff.active.fullName,
      active: true,
      status: SaleStatus.OPEN,
      promotionDiscount: 0,
      promotion: [],

      discount: {
        type: 'percentage',
        percentage: 0,
        amount: 0,
      },

      paid: false,
      products,
      ...orderData,
    }

    if (data?.customBill) {
      payload.isCustomBill = true
    } else {
      payload.tableId = ''
      payload.tableName = ''
    }

    await set(
      ref(database, `${register.location}/${payload.orderId}`),
      transform(payload),
    )

    voidQuantityFromSale(products)

    await sync()

    return payload
  }

  return {
    /**
     * @description state properties
     */
    order,
    selectedAgreementId,
    appliedAgreements,

    /**
     * @description getter properties
     */
    customer,
    saleDiscount,
    promotionDiscount,
    products,
    subTotal,
    totalServiceCharge,
    totalServiceChargePaying,
    totalVAT,
    totalVATPaying,
    total,
    totalPayableAmount,
    totalReportingValue,
    totalDiscountByPromotion,
    totalDiscount,
    totalSaleDiscount,
    totalPointsEarned,
    appliedPromotion,
    appliedPromotionIds,
    agreementTotal,
    totalExcludingVAT,
    reduceChargesByPercentage,

    /**
     * @description actions
     */
    setTable,
    onTableChange,
    setOrderNote,
    activateAgreement,
    assignCustomer,
    removeCustomer,
    setGuestCount,
    setCustomBill,
    setCustomerAlert,
    update,
    updateProperty,
    updateStatus,
    open,
    add,
    remove,
    createOrderProductsSummary,
    complete,
    openParked,
    discard,
    park,
    clear,
    applyDiscount,
    resetDiscount,
    applySalePromotion,
    applyPromotion,
    removeSalePromotion,
    removePromotion,
    removePromotionFromProduct,
    applyAgreement,
    revertAgreement,
    revertAllAppliedAgreements,
    flushAppliedAgreements,
    fromSale,
    markAllItemsPrinted,
    fromAppointment,
    voidQuantityFromSale,
    splitSale,
  }
})
