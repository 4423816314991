<template>
  <div
    :ref="`sale-item-${item.internalId}`"
    tabIndex="{0}"
    class="tw-collapse tw-rounded-lg tw-border-b tw-border-gray-300"
    :class="{
      'tw-collapse-open': visible,
      'tw-collapse-close': !visible,
    }"
  >
    <div class="tw-collapse-title tw-py-2 tw-px-2">
      <ItemCardContent
        :item="item"
        :index="index"
        :details-visible="visible"
        @expand="onExpand"
        @assign="onStaffMemberChange"
      />
    </div>

    <div class="tw-collapse-content tw-flex tw-flex-col tw-gap-2">
      <div class="tw-flex tw-gap-2">
        <div role="group" class="tw-flex-1">
          <p class="tw-mb-0 tw-text-sm">Months</p>
          <input
            type="number"
            :value="item.months"
            class="tw-input tw-input-sm tw-border tw-border-gray-300 tw-rounded-lg tw-w-full focus:tw-outline-none"
            @change="
              update(
                'months',
                parseInt(($event.target as HTMLInputElement).value),
              )
            "
          />
        </div>
        <div role="group" class="tw-flex-1">
          <p class="tw-mb-0 tw-text-sm">No.</p>
          <input
            type="text"
            class="tw-input tw-input-sm tw-border tw-border-gray-300 tw-rounded-lg tw-w-full focus:tw-outline-none"
            :value="item.lockerNumber"
            @change="
              update('lockerNumber', ($event.target as HTMLInputElement).value)
            "
          />
        </div>
        <div role="group" class="tw-flex-1">
          <p class="tw-mb-0 tw-text-sm">Guests</p>
          <input
            type="number"
            class="tw-input tw-input-sm tw-border tw-border-gray-300 tw-rounded-lg tw-w-full focus:tw-outline-none"
            :value="item.freeGuests"
            @change="
              update(
                'freeGuests',
                parseInt(($event.target as HTMLInputElement).value),
              )
            "
          />
        </div>
        <div role="group" class="tw-flex-1">
          <p class="tw-mb-0 tw-text-sm">Discount (%)</p>
          <NumberInput
            min="0"
            max="100"
            :value="item.discount"
            @input="value => applyDiscount(value)"
            class="tw-input tw-input-sm tw-border tw-border-gray-300 tw-rounded-lg tw-w-full focus:tw-outline-none"
          />
        </div>
      </div>

      <div v-if="item.promotion?._id" class="tw-flex tw-justify-between">
        <p class="tw-text-sm tw-mb-0">
          <b>Promotion:</b> {{ item.promotion.name }}
        </p>

        <TrashIcon
          class="tw-w-4 tw-h-4 tw-cursor-pointer hover:tw-opacity-80"
          @click="onPromotionRemove"
        />
      </div>

      <p class="tw-text-sm" v-if="tax">
        {{ tax }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ItemCardContent from './ItemCardContent.vue'
import { TrashIcon } from '@/components/icons'
import { ref, type PropType } from 'vue'
import { useComposableSaleItem } from '@/composables/sale-item'
import type { LockerBoxSaleProduct } from '@/stores/sale'
import NumberInput from '../common/NumberInput.vue'

const props = defineProps({
  item: {
    type: Object as PropType<LockerBoxSaleProduct>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
})
const visible = ref<boolean>(false)
const {
  tax,
  onPromotionRemove,
  applyDiscount,
  update,
  onStaffMemberChange,
  scrollToSelf,
} = useComposableSaleItem(props.item, props.index)

const onExpand = () => {
  visible.value = !visible.value

  if (visible.value) {
    scrollToSelf()
  }
}
</script>
