<template>
  <div class="item-price">
    <div class="item">
      <div>{{ item.name }}</div>
      <div class="small m-0 gift-fill">
        {{ item.quantity || 0 }} x ₫{{
          formatNumber(round(item.price))
        }}
      </div>
      <div v-if="item.promotion?._id" class="small m-0 gift-fill">
        {{ item.promotion.name }}
      </div>
      <div class="staff" v-if="item.itemNote">
        Note: {{ item.itemNote }}
      </div>
    </div>
    <div class="price">
      <div>
        <strong>₫{{ formatNumber(subTotal) }}</strong>
      </div>
      <div>
        <small v-if="item.discount || item.manualPrice">
          <s class="font-weight-normal">
            ₫
            {{ formatNumber(round(item.price * item.quantity)) }}
          </s>
        </small>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useComposableSaleItem } from '@/composables/sale-item'
import { formatNumber } from '@/utilities/common'
import { round } from 'lodash'
import { defineProps } from 'vue'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
})

const { subTotal } = useComposableSaleItem(props.item, props.index)
</script>
