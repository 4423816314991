import { defineStore } from 'pinia'
import { useRegister } from '@/stores/register'
import {
  Timestamp,
  collection,
  doc,
  getDocs,
  increment,
  query,
  setDoc,
  where,
} from 'firebase/firestore'
import database from '@/config/firebase/database'
import {
  type EventStatus as EventStatusType,
  type Event,
  EventStatus,
} from './types'
import { assign, filter, findIndex, map } from 'lodash'
import { useSales } from '@/stores/sales'
import { reactive, computed } from 'vue'
import debug from 'debug'

const logger = debug('events')

export type ExtendedEvent = Event & { _id: string }

export type SelectedEvent = {
  event: ExtendedEvent
  config: any
}

export const useEvents = defineStore('events', () => {
  const sales = useSales()
  const register = useRegister()

  const events = reactive<Array<ExtendedEvent>>([])
  const event = reactive<SelectedEvent>({} as SelectedEvent)

  const nextEvents = computed(() => {
    const eventIds = map(
      filter(sales.orders, o => o?.eventItem?._id),
      o => o.eventItem._id,
    )
    return filter(
      events,
      e => !eventIds.includes(e._id) && e.status === EventStatus.Approved,
    )
  })

  const activeEvents = computed(() => {
    const eventIds = map(
      filter(sales.orders, o => o?.eventItem?._id),
      o => o.eventItem._id,
    )
    return filter(events, e => eventIds.includes(e._id))
  })

  const completedEvents = computed(() => {
    const eventIds = map(
      filter(sales.orders, o => o?.eventItem?._id),
      o => o.eventItem._id,
    )
    const status = [EventStatus.Cancelled, EventStatus.NoShow]
    return filter(
      events,
      e =>
        status.includes(e.status as string) ||
        (!eventIds.includes(e._id) && e.status === EventStatus.ArrivedOnTime),
    )
  })

  const setEvents = async () => {
    const fromTime = new Date()
    const toTime = new Date()
    fromTime.setHours(0, 0, 0, 0)
    toTime.setHours(23, 59, 59, 999)

    const q = query(
      collection(database, `locations/${register.location}/events`),
      where('startDate', '>', Timestamp.fromDate(fromTime)),
      where('startDate', '<', Timestamp.fromDate(toTime)),
    )
    const snapshot = await getDocs(q)
    const _events: Array<ExtendedEvent> = []
    snapshot.forEach(doc => {
      const data = doc.data()
      if (data?.type === 'appointment' || data?.type === 'reservation') {
        _events.push({
          _id: doc.id,
          ...(data as Event),
        })
      }
    })

    events.splice(0, events.length, ..._events)
  }

  const selectEvent = (_event: SelectedEvent) => {
    assign(event, _event)
  }

  const setEventStatus = async (payload: {
    _id: string
    status: string
    location: string
    userId: string
  }) => {
    const { _id, status, location } = payload
    const docRef = doc(database, `locations/${location}/events/${_id}`)
    await setDoc(docRef, { status }, { merge: true })

    const index = findIndex(events, event => event._id === _id)
    if (index !== -1) {
      const _status = status as EventStatusType
      events.splice(index, 1, { ...events[index], status: _status })
    } else {
      logger(`could not update ${_id} event status in local state`)
    }
    incrementAppointmentCounter(payload)
    return true
  }

  const setFoodandDrink = async (payload: {
    _id: string
    location: string
    food: string
    drink: string
  }) => {
    const docRef = doc(
      database,
      `locations/${payload.location}/events/${payload._id}`,
    )
    await setDoc(
      docRef,
      { food: payload.food, drink: payload.drink },
      { merge: true },
    )
  }

  const incrementAppointmentCounter = async (payload: {
    _id: string
    status: string
    location: string
    userId: string
  }) => {
    const metadata: any = {}
    if (payload.status === 'arrived-on-time') {
      metadata[`appointments_on_time`] = increment(1)
    } else if (payload.status === 'arrived-late') {
      metadata[`appointments_late`] = increment(1)
    } else {
      metadata[`total_no_shows`] = increment(1)
    }
    const docRef = doc(database, `users/${payload.userId}`)
    await setDoc(docRef, { metadata }, { merge: true })
  }

  return {
    events,
    event,
    nextEvents,
    activeEvents,
    completedEvents,
    setEvents,
    selectEvent,
    incrementAppointmentCounter,
    setFoodandDrink,
    setEventStatus,
  }
})
