<template>
  <div
    v-if="visible || alert"
    class="tw-alert tw-alert-warning tw-flex tw-justify-between tw-shadow-lg tw-py-3 tw-rounded-lg tw-col-span-1 md:tw-col-span-5"
  >
    <div class="tw-flex tw-items-center tw-w-full">
      <WarningIcon class="tw-w-4 tw-h-4 tw-mr-2" />
      <input
        ref="customer-alert"
        v-if="updating"
        v-model="text"
        class="tw-input tw-input-sm tw-border tw-w-full focus:tw-outline-none"
        placeholder="Customer note"
      />
      <div v-else>{{ text }}</div>
    </div>
    <button
      class="tw-btn tw-btn-sm tw-btn-link tw-text-white tw-font-normal"
      @click="onEditCustomerAlert"
    >
      <LoadingIcon
        v-if="loading"
        class="tw-w-4 tw-h-4 tw-animate-spin dark:tw-text-gray-200 tw-fill-white"
      />
      <CheckIcon v-else-if="updating" class="tw-w-4 tw-h-4" />
      <PenIcon v-else class="tw-w-4 tw-h-4" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import {
  LoadingIcon,
  WarningIcon,
  PenIcon,
  CheckIcon,
} from '@/components/icons'
import { useSale } from '@/stores'
import { ref, onMounted, watch, useTemplateRef } from 'vue'

const sale = useSale()
const props = defineProps({
  alert: {
    type: String,
    default: '',
  },
  visible: Boolean
})

const loading = ref(false)
const updating = ref(false)
const text = ref('')
const inputWrapper = useTemplateRef('customer-alert')

onMounted(() => {
  if (props.alert) {
    text.value = props.alert
  }
})

const onEditCustomerAlert = async () => {
  if (updating.value) {
    await onSaveCustomerAlert()
    updating.value = false
  } else {
    updating.value = true
    focusAlertInput()
  }
}

const focusAlertInput = () => {
  setTimeout(() => {
    if (inputWrapper.value) {
      inputWrapper.value.focus()
    }
  }, 300)
}

const onSaveCustomerAlert = async () => {
  loading.value = true
  await sale.setCustomerAlert(text.value)
  loading.value = false
  return
}

watch(
  () => props.visible,
  newValue => {
    if (newValue) {
      updating.value = true
      focusAlertInput()
    }
  },
)

watch(
  () => props.alert,
  newValue => {
    text.value = newValue
  },
)
</script>
