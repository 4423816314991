<template>
  <Modal
    :visible="visible"
    :title="title"
    @close="() => emit('update:visible', false)"
  >
    <div class="tw-p-6 tw-border-b tw-border-gray-300 tw-relative">
      <slot name="content" />
    </div>

    <div class="tw-p-6 tw-flex tw-justify-end tw-gap-4">
      <button
        class="tw-btn tw-btn-secondary tw-text-white tw-font-normal"
        @click="() => emit('update:visible', false)"
      >
        Cancel
      </button>
      <button
        class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
        @click="() => emit('confirm')"
      >
        Continue
      </button>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from '@/components/common/Modal.vue'

defineProps({
  title: String,
  visible: Boolean,
})

const emit = defineEmits(['confirm', 'update:visible'])
</script>
<style lang="scss" scoped>
.spinning {
  margin-left: 3px;
  margin-right: 1px;
}

*::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #f7f7f7;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 3px;
}

.loading {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
