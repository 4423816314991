<template>
  <div class="tw-w-full">
    <div class="tw-flex">
      <input
        v-model="voucher"
        class="tw-input tw-border tw-w-full tw-rounded-r-none focus:tw-outline-none"
        :class="{
          'tw-border-error': error,
          'tw-border-gray-300': !error,
        }"
        placeholder="Enter gift voucher code"
        @input="onVoucherInput(($event.target as HTMLInputElement).value)"
      />
      <button
        class="tw-btn tw-btn-primary tw-rounded-l-none"
        @click="addVoucher"
      >
        <p v-if="!loading" class="tw-font-normal tw-text-white tw-mb-0">
          Apply
        </p>
        <LoadingIcon
          v-else
          class="tw-w-4 tw-h-4 tw-animate-spin dark:tw-text-gray-200 tw-fill-white"
        />
      </button>
    </div>

    <p class="tw-text-error tw-text-sm tw-mb-0" v-if="error">
      {{ error }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { LoadingIcon } from '@/components/icons'
import { collection, getDocs, query, where, limit } from 'firebase/firestore'
import database from '@/config/firebase/database'
import { isEmpty } from 'lodash'
import { ref, reactive } from 'vue'
import debug from 'debug'

const logger = debug('voucher-component')

const emit = defineEmits(['apply'])

const voucher = ref('')
const loading = ref(false)
const error = ref('')
let voucherDocument = reactive({})

const onVoucherInput = (value: string) => {
  if (value) {
    error.value = ''
    return
  }
  error.value = 'Gift cheque number is required'
}

const addVoucher = async () => {
  if (!voucher.value) {
    error.value = 'Gift cheque number is required'
    return
  }
  try {
    loading.value = true
    const voucherQuery = query(
      collection(database, `giftcheques`),
      where('code', '==', `${voucher.value}`),
      limit(1),
    )
    const snapshot = await getDocs(voucherQuery)
    if (snapshot.size !== 1) {
      logger(`${voucher.value} is not found`)
      error.value = 'Gift code invalid'
      return
    }
    snapshot.forEach(v => {
      const voucher: any = { _id: v.id, ...v.data() }
      if (voucher.active) {
        voucherDocument = voucher
      }
    })
    if (!isEmpty(voucherDocument)) {
      emit('apply', voucherDocument)
    } else {
      logger(`${voucher.value} is empty or invalid`)
      error.value = 'Gift code invalid or expired'
      return
    }
  } catch (e: any) {
    logger(`error ${e.message} while applying voucher ${voucher.value}`)
    error.value = 'Error applying Gift code, try again!'
  } finally {
    loading.value = false
    setTimeout(() => {
      error.value = ''
    }, 3000)
  }
}
</script>
