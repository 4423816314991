import { defineStore } from 'pinia'
import {
  each,
  filter,
  findIndex,
  has,
  isEmpty,
  map,
  orderBy,
  remove,
  sortBy,
  uniqBy,
  values,
} from 'lodash'
import { collection, getDocs, query, where } from 'firebase/firestore'
import database from '@/config/firebase/database'
import Product, { type InventoryProduct, type Ticket } from './product'
import { useRegister } from '../register'
import { useApp } from '../app'
import { useSale } from '@/stores/sale/sale'
import { reactive, computed, ref } from 'vue'
import { determinePoints } from '@/utilities/common'
import {
  ComplimentaryTypes,
  ProductStatus,
  ProductTypes,
  type BrandType,
} from './types'

export const useInventory = defineStore('inventory', () => {
  const products = reactive<Array<Product>>([])
  const productVariants = reactive<Array<Product>>([])

  const FnBProducts = reactive<Array<Product>>([])

  const complimentary = reactive<Array<Product>>([])
  const complimentaryUpgrades = reactive<Array<InventoryProduct>>([])

  const services = reactive<Array<Product>>([])
  const upgrades = reactive<Array<Product>>([])
  const selectedProduct = reactive<Product>({} as Product)
  const tickets = reactive<Array<Ticket>>([])

  const _activeProductBrand = ref<BrandType>({} as BrandType)
  const _activeFnBBrand = ref<BrandType>({} as BrandType)

  const productBrands = reactive<Array<BrandType>>([])
  const fnbBrands = reactive<Array<BrandType>>([])

  const activeProductBrand = computed<BrandType | boolean>(() => {
    if (isEmpty(_activeProductBrand.value)) return false
    return _activeProductBrand.value
  })

  const activeFnBBrand = computed<BrandType | boolean>(() => {
    if (isEmpty(_activeFnBBrand.value)) return false
    return _activeFnBBrand.value
  })

  const getProductsByFnBBrand = computed(() => {
    return (payload?: number) => {
      return FnBProducts.filter(product => {
        if (payload && payload === 1) {
          return true
        }
        return product.category.id == payload
      })
    }
  })

  const availableFnBBrands = computed<Array<BrandType>>(() => {
    const filter = (brand: any) => {
      return (
        !brand.title.includes('.') &&
        brand.title.split('-')[1] !== 'complimentary'
      )
    }
    return fnbBrands.filter(filter)
  })

  const FoodComplimentary = computed(() => {
    return complimentary.filter(
      item => item.meta.complimentary.type === ComplimentaryTypes.Food,
    )
  })

  const DrinkComplimentary = computed(() => {
    return complimentary.filter(
      item => item.meta.complimentary.type === ComplimentaryTypes.Drink,
    )
  })

  const hydrate = async () => {
    Promise.all([setProducts(), setFnBProducts(), setServices(), setUpgrades()])
  }

  const setProducts = async () => {
    const _products: Array<Product> = []
    const _variants: Array<Product> = []

    const categories: Array<BrandType> = []
    const q = query(collection(database, `posItems/products/items`))
    const snapshot = await getDocs(q)

    snapshot.forEach(doc => {
      const data = doc.data()

      if (
        has(data, 'status') &&
        data.status.toLowerCase() === ProductStatus.inactive
      ) {
        return
      }

      data.points = data?.importPrice
        ? determinePoints(Number(data.price) - Number(data.importPrice))
        : 0

      // TODO length check patch added as products coming with -1, -2 parentIds
      // remove it after test

      if (data?.parentId && data.parentId.length > 5) {
        _variants.push(new Product(data, ProductTypes.ProductVariant))
      } else {
        const _product = new Product(data, ProductTypes.Product)
        _products.push(_product)
        categories.push(_product.category)
      }
    })

    each(_products, product => {
      const __variants = filter(_variants, v => v.data.parentId === product.id)
      if (__variants.length) {
        product.variants = __variants
      }
    })

    const uniqueCategories = orderBy(
      uniqBy(categories, 'id'),
      ['title'],
      ['asc'],
    )
    remove(uniqueCategories, c => c.id === 1)

    products.splice(0, products.length, ..._products)
    productVariants.splice(0, productVariants.length, ..._variants)
    productBrands.splice(0, productBrands.length, ...uniqueCategories)
  }

  const setFnBProducts = async () => {
    const register = useRegister()
    const app = useApp()

    const products: Array<Product> = []
    const _complimentary: Array<Product> = []
    const variants: Array<Product> = []
    const upgradeComplimentary: Array<Product> = []

    const categories: { [key: string]: any } = {}

    const items = await getDocs(
      query(collection(database, `posItems/f&b/items`)),
    )
    const details = await getDocs(
      query(
        collection(database, `posItems/f&b/details`),
        where('active', '==', true),
        where('locations', 'array-contains', register.location),
      ),
    )
    const groups = await getDocs(
      query(
        collection(database, `posItems/f&b/groups`),
        where('active', '==', true),
      ),
    )

    groups.forEach(group => {
      const product = new Product(
        { _id: group.id, ...group.data() },
        ProductTypes.FnB,
      )
      products.push(product)
    })

    const categoryDocs: { [key: string]: any } = {}
    const _categoryDocs = await getDocs(
      query(collection(database, `posItems/f&b/categories`)),
    )
    _categoryDocs.forEach(
      item => (categoryDocs[item.id] = { _id: item.id, ...item.data() }),
    )

    const _items: { [key: string]: any } = {}
    items.forEach(item => (_items[item.id] = item.data()))

    const _taxes: { [key: string]: any } = {}

    app.tax.forEach(tax => (_taxes[tax._id] = tax))

    details.forEach(detail => {
      const _detail = detail.data()

      const meta: { [key: string]: any } = { type: ProductTypes.FnB }

      meta['tax'] =
        _detail?.taxId && has(_taxes, _detail.taxId)
          ? _taxes[_detail.taxId]
          : (meta['tax'] = {
              amount: 8,
              label: '8%',
            })

      if (_detail?.complimentary) {
        meta['complimentary'] = {
          complimentary: _detail.complimentary,
          type: _detail?.type || false,
          locations: _detail?.locations || [],
        }

        const data = {
          _id: detail.id,
          ..._items[_detail.itemId],
          ..._detail,
        }

        if (_detail.categoryId && categoryDocs[_detail.categoryId]) {
          data['category'] = categoryDocs[_detail?.categoryId]
        }

        const product = new Product(
          data,
          ProductTypes.FnB,
          meta,
          register.location,
        )

        _complimentary.push(product)
      } else {
        if (_detail?.parentId && _detail.parentId !== '""') {
          const { BOMPrice = 0, CategoryName = '' } =
            _items[_detail.itemId] || {}

          const data = {
            _id: detail.id,
            ..._items[_detail.itemId],
            BOMPrice,
            CategoryName,
            ..._detail,
          }

          if (_detail.categoryId && categoryDocs[_detail.categoryId]) {
            data['category'] = categoryDocs[_detail?.categoryId]
          }

          const product = new Product(
            data,
            ProductTypes.FnBVariant,
            meta,
            register.location,
          )

          variants.push(product)
          categories[product.category.id] = product.category
        } else {
          const data = {
            _id: detail.id,
            ..._items[_detail.itemId],
            ..._detail,
          }

          if (_detail.categoryId && categoryDocs[_detail.categoryId]) {
            data['category'] = categoryDocs[_detail?.categoryId]
          }

          const product = new Product(
            {
              _id: detail.id,
              ..._items[_detail.itemId],
              ..._detail,
            },
            ProductTypes.FnB,
            meta,
            register.location,
          )
          products.push(product)
          categories[product.category.id] = product.category
        }
      }

      if (_detail.upgrade) {
        const data = {
          _id: detail.id,
          ..._items[_detail.itemId],
          ..._detail,
        }

        if (_detail.categoryId && categoryDocs[_detail.categoryId]) {
          data['category'] = categoryDocs[_detail?.categoryId]
        }

        const product = new Product(
          data,
          ProductTypes.FnB,
          meta,
          register.location,
        )

        upgradeComplimentary.push(product)
      }
    })

    each(products, product => {
      const _variants = filter(variants, v => v.data.parentId === product.id)
      if (_variants.length) {
        product.variants = _variants
      }
    })

    const _categories = orderBy(
      filter(uniqBy(values(categories), 'id'), c => !c.title.includes('.')),
      ['title'],
      ['asc'],
    )
    remove(_categories, c => c.id === 2)

    FnBProducts.splice(0, FnBProducts.length, ...products)
    complimentary.splice(0, complimentary.length, ..._complimentary)
    fnbBrands.splice(0, fnbBrands.length, ..._categories)
    complimentaryUpgrades.splice(
      0,
      complimentaryUpgrades.length,
      ...upgradeComplimentary,
    )
  }

  const setServices = async () => {
    const _services: Array<Product> = []
    const q = query(
      collection(database, `services`),
      where('active', '==', true),
    )
    const snapshot = await getDocs(q)

    snapshot.forEach(doc => {
      const service = new Product(
        { _id: doc.id, ...doc.data() },
        ProductTypes.Service,
      )
      _services.push(service)
    })

    services.splice(
      0,
      services.length,
      ...sortBy(_services, ['title'], ['asc']),
    )
  }

  const setUpgrades = async () => {
    const q = query(collection(database, `upgrades`))
    const snapshot = await getDocs(q)

    const _upgrades: Array<Product> = []
    snapshot.forEach(doc => {
      const upgrade = new Product(
        { _id: doc.id, ...doc.data() },
        ProductTypes.Upgrade,
      )
      _upgrades.push(upgrade)
    })

    upgrades.splice(0, upgrades.length, ..._upgrades)
  }

  const updateTicket = async (id: string, update: Partial<Ticket>) => {
    const index = findIndex(tickets, t => t._id === id)

    if (index !== -1) {
      const ticket = { ...tickets[index], ...update }
      if (ticket?.limit && ticket.sold === ticket.limit) {
        ticket.soldOut = true
        const sale = useSale()
        const tickets = filter(
          sale.products,
          p => p.category === ProductTypes.Ticket,
        )
        sale.remove(tickets)
      }
      tickets.splice(index, 1, ticket)
    }
  }

  const setTickets = async () => {
    const now = new Date()
    const register = useRegister()
    const q = query(
      collection(database, `locations/${register.location}/tickets`),
      where('endDate', '>=', now),
    )

    const snapshot = await getDocs(q)

    const _tickets: Array<Ticket> = []
    snapshot.forEach(doc => {
      const ticket: Ticket = { ...doc.data(), _id: doc.id, sold: 0 } as Ticket

      if (ticket.startDate.seconds * 1000 > Date.now()) {
        return
      }

      _tickets.push(ticket)
    })

    const all = await Promise.allSettled(
      map(_tickets, async ticket => {
        if (ticket.limit) {
          const sold = await getDocs(
            query(
              collection(
                database,
                `locations/${register.location}/tickets/${ticket._id}/issued`,
              ),
            ),
          )

          if (sold.size === ticket.limit) {
            ticket.soldOut = true
          }

          ticket.sold = sold.size

          return ticket
        }

        return ticket
      }),
    )

    tickets.splice(0, tickets.length, ...map(all, 'value'))
  }

  const setActiveBrand = (type: 'product' | 'fnb', brand: BrandType) => {
    if (type === 'product') {
      _activeProductBrand.value = brand
    } else if (type === 'fnb') {
      _activeFnBBrand.value = brand
    }
  }

  return {
    products,
    productVariants,
    FnBProducts,
    complimentary,
    services,
    upgrades,
    complimentaryUpgrades,
    selectedProduct,
    tickets,
    availableFnBBrands,
    getProductsByFnBBrand,
    productBrands,
    fnbBrands,
    FoodComplimentary,
    DrinkComplimentary,

    activeProductBrand,
    activeFnBBrand,

    hydrate,
    setProducts,
    setFnBProducts,
    setServices,
    setUpgrades,
    updateTicket,
    setTickets,
    setActiveBrand,
  }
})
