<template>
  <div>
    <div class="tw-flex">
      <div
        class="tw-flex tw-w-full tw-flex-grow-1 tw-gap-2 tw-mr-2"
        @click="emit('expand')"
        role="button"
      >
        <CaretDownIcon
          class="tw-text-primary tw-w-5 tw-h-5 tw-transition tw-duration-100 tw-shrink-0"
          :class="{
            '-tw-rotate-90': !detailsVisible,
          }"
        />

        <div class="tw-flex tw-flex-col tw-w-full tw-gap-2">
          <p class="tw-mb-0 tw-font-medium" v-html="item.name"></p>
          <p class="tw-mb-0 tw-text-sm">
            {{ item.quantity || 0 }} x ₫{{ formatNumber(round(item.price)) }}
          </p>

          <div v-if="promotion" class="tw-flex tw-items-center tw-gap-2">
            <GiftIcon class="tw-text-success tw-w-3 tw-h-3 tw-shrink-0" />

            <p class="tw-mb-0 tw-text-xs">
              {{ (promotion as AppliedPromotionType).name }}
            </p>

            <span @click.stop="onPromotionRemove">
              <TrashIcon
                class="tw-text-error tw-w-4 tw-h-4 tw-shrink-0 relative z-10 hover:tw-opacity-80"
              />
            </span>
          </div>
        </div>

        <div class="tw-text-right">
          <p class="tw-font-bold tw-mb-0">₫{{ formatNumber(total) }}</p>
          <p
            v-if="item.discount || item.manualPrice"
            class="tw-mb-0 tw-line-through"
          >
            ₫ {{ formatNumber(originalTotal) }}
          </p>
        </div>
      </div>

      <div class="tw-flex tw-flex-col tw-items-center tw-gap-1">
        <Staff
          :id="item.id"
          :staff="item.salesPerson"
          @assign="onStaffMemberChange"
        />

        <div
          v-if="item.points"
          class="tw-flex tw-items-center tw-justify-center tw-bg-amber-500 tw-px-1 tw-py-0.5 tw-rounded-full"
        >
          <div>
            <StarFillIcon class="tw-h-2.5 tw-w-2.5 tw-text-amber-300" />
          </div>
          <div class="tw-text-xs tw-text-white tw-mx-1 tw-font-bold">
            {{ item.points }}
          </div>
        </div>

        <TrashIcon
          class="tw-text-error tw-w-5 tw-h-5 tw-shrink-0 hover:tw-opacity-80"
          @click="sale.remove(item)"
        />
      </div>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  TrashIcon,
  GiftIcon,
  CaretDownIcon,
  StarFillIcon,
} from '@/components/icons'
import Staff from './Staff.vue'
import { useSale } from '@/stores/sale/sale'
import { formatNumber } from '@/utilities/common'
import { useComposableSaleItem } from '@/composables/sale-item'
import { computed, type PropType, type ComputedRef } from 'vue'
import { getOriginalTotal, getTotalPayableAmount } from '@/utilities/sale'
import type { AppliedPromotionType, SaleProductType } from '@/stores/sale'
import { round } from 'lodash'

const props = defineProps({
  item: {
    type: Object as PropType<SaleProductType>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  detailsVisible: Boolean,
})

const emit = defineEmits(['expand'])

const { onStaffMemberChange, onPromotionRemove } = useComposableSaleItem(
  props.item,
  props.index,
)

const total: ComputedRef<number> = computed(() =>
  getTotalPayableAmount(props.item),
)

const originalTotal: ComputedRef<number> = computed(() =>
  getOriginalTotal(props.item),
)

const promotion: ComputedRef<boolean | AppliedPromotionType> = computed(() =>
  props.item.promotion?._id ? props.item.promotion : false,
)

const sale = useSale()
</script>
