<template>
  <div
    :ref="`sale-item-${item.internalId}`"
    tabIndex="{0}"
    class="tw-collapse tw-rounded-lg tw-border-b tw-border-gray-300"
    :class="{ 'tw-collapse-open': visible, 'tw-collapse-close': !visible }"
  >
    <div class="tw-collapse-title tw-px-2">
      <ItemCardContent
        :item="item"
        :index="index"
        :details-visible="visible"
        @expand="onExpand"
        @assign="onStaffMemberChange"
        @removePromotion="onPromotionRemove"
      >
        <Complimentary
          v-if="hasComplimentary && !visible"
          :item="item"
          :index="index"
          :auto-hide="true"
        />
      </ItemCardContent>
    </div>
    <div class="tw-collapse-content tw-flex tw-flex-col tw-gap-2">
      <Complimentary v-if="hasComplimentary" :item="item" :index="index" />

      <div class="tw-flex tw-gap-2">
        <div
          v-if="item.type !== ProductTypes.Service"
          class="tw-flex tw-flex-col tw-w-1/3"
        >
          <p class="tw-mb-1 tw-text-sm">Quantity</p>
          <div class="tw-flex tw-rounded-lg tw-h-8">
            <span
              class="tw-px-3 tw-inline-flex tw-items-center tw-min-w-fit tw-rounded-s-md tw-border-e-0 tw-border tw-border-gray-200"
              :class="{
                'tw-bg-gray-50 tw-text-gray-700 tw-cursor-not-allowed tw-pointer-events-none':
                  item.quantity === 1,
                'tw-bg-gray-700 tw-text-white tw-cursor-pointer tw-pointer-events-auto':
                  item.quantity > 1,
              }"
              @click="update('quantity', item.quantity - 1 || 1, 1)"
            >
              <MinusIcon class="tw-w-3 tw-h-3" />
            </span>
            <NumberInput
              min="1"
              :value="item.quantity"
              placeholder="1"
              class="tw-py-2 tw-w-10 tw-border-y tw-border-gray-200 tw-text-center tw-focus:z-10 tw-disabled:opacity-50 tw-disabled:pointer-events-none tw-dark:bg-slate-900 tw-dark:border-gray-700 tw-dark:text-gray-400 focus:tw-outline-none hidden-number-input"
              @input="value => update('quantity', value)"
            />
            <span
              @click="update('quantity', item.quantity + 1, 1)"
              class="tw-px-3 tw-inline-flex tw-items-center tw-min-w-fit tw-rounded-e-md tw-border-s-0 tw-border tw-border-gray-200 tw-bg-gray-700 tw-text-white tw-cursor-pointer"
            >
              <PlusIcon class="tw-w-3 tw-h-3" />
            </span>
          </div>
        </div>

        <div role="group" class="tw-w-1/3">
          <p class="tw-mb-1 tw-text-sm">Price</p>

          <NumberInput
            min="0"
            :disabled="!!item.discount"
            :value="!!item.discount ? 0 : item.manualPrice"
            :placeholder="formatNumber(round(item.price))"
            @input="value => applyManualPrice(value)"
            class="tw-input tw-input-sm tw-border tw-border-gray-300 tw-w-full"
          />
        </div>

        <div role="group" class="tw-w-1/3">
          <p class="tw-mb-1 tw-text-sm">Discount(%)</p>
          <NumberInput
            min="0"
            max="100"
            class="tw-input tw-input-sm tw-border tw-border-gray-300 tw-rounded-lg tw-w-full focus:tw-outline-none"
            :disabled="!!item.manualPrice"
            :value="item.discount"
            placeholder="Discount"
            @input="
              v => {
                applyDiscount(v, shouldComputePoints)
              }
            "
          />
        </div>
      </div>

      <div role="group">
        <p class="tw-mb-1 tw-text-sm">Note</p>
        <input
          type="text"
          class="tw-input tw-input-sm tw-border tw-border-gray-300 tw-rounded-lg tw-w-full focus:tw-outline-none"
          :value="item.itemNote"
          placeholder="Item Note"
          @change="
            update('itemNote', ($event.target as HTMLInputElement).value)
          "
        />
      </div>

      <div v-if="item.promotion?._id" class="tw-flex tw-justify-between">
        <p class="tw-text-sm tw-mb-0">
          <b>Promotion:</b> {{ item.promotion.name }}
        </p>

        <TrashIcon
          class="tw-w-4 tw-h-4 tw-cursor-pointer hover:tw-opacity-80"
          @click="onPromotionRemove"
        />
      </div>

      <p class="tw-text-xs tw-font-bold tw-text-gray-500" v-if="tax">
        {{ tax }}
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, type PropType } from 'vue'
import { determinePoints, formatNumber } from '@/utilities/common'
import { useComposableSaleItem } from '@/composables/sale-item'
import { round } from 'lodash'
import { MinusIcon, PlusIcon, TrashIcon } from '../icons'
import ItemCardContent from './ItemCardContent.vue'
import Complimentary from './Complimentary.vue'
import {
  type FnBSaleProduct,
  type SaleProduct,
  type SaleProductType,
  type ServiceSaleProduct,
  type UpgradeSaleProduct,
} from '@/stores/sale'
import { ProductTypes } from '@/stores/inventory'
import NumberInput from '../common/NumberInput.vue'

const props = defineProps({
  item: {
    type: Object as PropType<SaleProductType>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
})

const {
  tax,
  onStaffMemberChange,
  applyDiscount,
  onPromotionRemove,
  update,
  updateProperty,
  scrollToSelf,
} = useComposableSaleItem(props.item, props.index)

const visible = ref<boolean>(false)

const shouldComputePoints = computed<boolean>(() => {
  return [ProductTypes.Product, ProductTypes.ProductVariant].includes(
    props.item.type,
  )
})

const hasComplimentary = computed(() => {
  const P = props.item as ServiceSaleProduct | UpgradeSaleProduct
  if (P.complimentaries) return true

  return false
})

const onExpand = () => {
  visible.value = !visible.value

  if (visible.value) {
    scrollToSelf()
  }
}

const applyManualPrice = (price: number) => {
  const common = {
    manualPromotion: true,
    priceAfterDiscount: 0,
  }

  if (!price) {
    const points = shouldComputePoints.value
      ? determinePoints(
          props.item.price - (props.item as SaleProduct | FnBSaleProduct).cost,
        )
      : props.item.points

    updateProperty({ ...common, manualPrice: 0, points })
    return
  }

  const points = shouldComputePoints.value
    ? determinePoints(price - (props.item as SaleProduct | FnBSaleProduct).cost)
    : props.item.points

  updateProperty({ ...common, manualPrice: price, points })
}
</script>
