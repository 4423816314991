<template>
  <div
    :ref="`sale-item-${item.internalId}`"
    tabIndex="{0}"
    class="tw-collapse tw-rounded-lg"
    :class="{
      'tw-collapse-open': visible,
      'tw-collapse-close': !visible,
      'tw-border tw-border-error': !isEmpty(errors),
      'tw-border-b tw-border-gray-300': isEmpty(errors),
    }"
  >
    <div class="tw-collapse-title tw-py-2 tw-px-2">
      <ItemCardContent
        :item="item"
        :index="index"
        :details-visible="visible"
        @expand="onExpand"
        @assign="onStaffMemberChange"
        @removePromotion="onPromotionRemove"
      />
    </div>

    <div class="tw-collapse-content">
      <div role="group">
        <p class="tw-mb-0 tw-text-sm">Amount</p>
        <NumberInput
            min="0"
            class="tw-input tw-input-sm tw-border tw-border-gray-300 tw-w-full"
            :value="item.price"
            placeholder="Enter Amount"
            @input="v => update('price', v)"
          />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ItemCardContent from './ItemCardContent.vue'
import { isEmpty } from 'lodash'
import { useComposableSaleItem } from '@/composables/sale-item'
import { reactive, ref, defineProps, type PropType } from 'vue'
import type { SaleProductType } from '@/stores/sale'
import NumberInput from '../common/NumberInput.vue'

const props = defineProps({
  item: {
    type: Object as PropType<SaleProductType>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
})

const { update, scrollToSelf, onStaffMemberChange, onPromotionRemove } =
  useComposableSaleItem(props.item, props.index)

let errors = reactive<{ [property: string]: string }>({})
const visible = ref<boolean>(false)

const validator = () => {
  if (!isEmpty(errors)) return false

  if (!props.item.price) {
    errors = {
      code: 'Amount required',
    }
    return false
  }
  errors = {}
  return true
}

defineExpose({
  validator,
})

const onExpand = () => {
  visible.value = !visible.value

  if (visible.value) {
    scrollToSelf()
  }
}
</script>
