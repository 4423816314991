import { defineStore } from 'pinia'
import { useRegister } from '@/stores/register'
import { ref, onValue } from 'firebase/database'
import database from '@/config/firebase/realtime-database'
import { computed, reactive } from 'vue'
import debug from 'debug'
import type { OrderType } from '../sales'

const logger = debug('sales')

export const useSales = defineStore('sales', () => {
  const orders = reactive<Array<OrderType>>([])

  const activeOrders = computed<Array<OrderType>>(() => {
    const register = useRegister()
    return orders.filter(order => {
      return order.registerId == register.registerId
    })
  })

  const hydrate = async () => {
    const register = useRegister()
    const location = register.location
    const ordersRef = ref(database, location)

    if (window.__orderListener__) {
      logger('detaching existing listerner')
      window.__orderListener__()
    }

    window.__orderListener__ = onValue(ordersRef, snapshot => {
      const _orders = snapshot.val()
      orders.splice(0, orders.length, ..._orders)
    })
  }

  const setSales = (_orders: Array<OrderType>) => {
    orders.splice(0, orders.length, ..._orders)
  }

  return {
    orders,
    hydrate,
    activeOrders,
    setSales,
  }
})
