<template>
  <div
    class="tw-border tw-border-gray-300 tw-flex tw-justify-between tw-items-center tw-rounded-lg tw-px-3 tw-py-2"
    :class="{
      'tw-bg-warning tw-bg-opacity-50': shouldHighlight,
      'tw-bg-white': !shouldHighlight,
    }"
  >
    <div class="tw-mr-2">
      <div
        class="tw-h-10 tw-w-10 tw-bg-primary tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-shrink-0"
      >
        {{ getInitials(customer?.userName) }}
      </div>
    </div>
    <div class="tw-flex tw-flex-col flex-1">
      <div class="tw-flex tw-items-center tw-gap-2">
        <StarFillIcon v-if="shouldHighlight" class="tw-w-4 tw-h-4" />
        <p class="tw-mb-0">{{ customer.userName }}</p>
      </div>
      <div class="tw-mb-0 tw-flex tw-gap-1 tw-flex-wrap tw-whitespace-nowrap">
        <div
          v-if="customer.memberGroup"
          class="tw-badge tw-badge-primary tw-text-white tw-text-xs"
        >
          {{ formatMembershipTier(customer.memberGroup) }}
        </div>
        <div
          v-for="(tag, index) in personaTags"
          :key="`guest-persona-${index}`"
          class="tw-badge tw-badge-secondary tw-text-white tw-text-xs"
        >
          {{ tag.name }}
        </div>
        <div
          v-for="(tag, index) in customer?.userTags"
          :key="`guest-tag-${index}`"
          class="tw-badge"
        >
          {{ tag }}
        </div>
      </div>
    </div>
    <div class="tw-flex tw-items-center tw-gap-0.5">
      <ClipBoardIcon
        class="tw-text-secondary tw-w-3.5 tw-h-3.5 tw-cursor-pointer hover:tw-opacity-80"
        @click="emit('create-alert')"
      />
      <TrashIcon
        class="tw-text-error tw-w-4 tw-h-4 tw-cursor-pointer hover:tw-opacity-80"
        @click="emit('remove')"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { TrashIcon, StarFillIcon, ClipBoardIcon } from '@/components/icons'
import { formatMembershipTier, getInitials } from '@/utilities/common'
import { PersonaTraits, type TraitType } from '@/utilities/constants'
import { filter, some } from 'lodash'
import { computed } from 'vue'

const props = defineProps({
  customer: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['remove', 'create-alert'])

const personaTags = computed(() => {
  const persona = props.customer.value?.userPersona || []
  return filter(PersonaTraits, (t: TraitType) => persona.includes(t.key))
})

const shouldHighlight = computed<boolean>(() => {
  return some(personaTags.value, (t: TraitType) => !!t?.highlight)
})
</script>
