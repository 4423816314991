<template>
  <svg
    v-bind="$attrs"
    viewBox="0 0 20 20"
    fill="none"
    @click="$emit('click', $event)"
  >
    <g clip-path="url(#clip0_1_19)">
      <path
        d="M17.5 7.5V3.01758C17.5 2.68594 17.3684 2.36836 17.134 2.13359L15.366 0.366016C15.1316 0.131641 14.8137 0 14.482 0H3.75C3.05977 0 2.5 0.559766 2.5 1.25V7.5C1.11914 7.5 0 8.61914 0 10V14.375C0 14.7203 0.279687 15 0.625 15H2.5V18.75C2.5 19.4402 3.05977 20 3.75 20H16.25C16.9402 20 17.5 19.4402 17.5 18.75V15H19.375C19.7203 15 20 14.7203 20 14.375V10C20 8.61914 18.8809 7.5 17.5 7.5ZM15 17.5H5V13.75H15V17.5ZM15 8.75H5V2.5H12.5V4.375C12.5 4.72031 12.7797 5 13.125 5H15V8.75ZM16.875 11.5625C16.3574 11.5625 15.9375 11.1426 15.9375 10.625C15.9375 10.107 16.3574 9.6875 16.875 9.6875C17.3926 9.6875 17.8125 10.107 17.8125 10.625C17.8125 11.1426 17.3926 11.5625 16.875 11.5625Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_19">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
