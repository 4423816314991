<template>
  <div class="tw-container tw-max-w-screen-xl tw-pt-10 tw-px-4 sm:tw-px-12">
    <h2
      class="tw-text-3xl tw-text-secondary tw-font-bold tw-border-b tw-border-primary tw-py-2 tw-mb-4 tw-w-max"
    >
      New Order
    </h2>

    <div
      class="tw-grid tw-grid-cols-1 md:tw-grid-cols-5 tw-gap-4 tw-items-start"
    >
      <div
        class="md:tw-col-span-3 tw-w-full tw-card tw-border-gray-300 tw-shadow-md tw-bg-white"
      >
        <div class="tw-card-body tw-p-4 tw-flex tw-flex-col tw-gap-4">
          <p
            class="tw-font-bold tw-shrink-0 tw-mb-0 tw-border-b tw-border-primary tw-w-max"
          >
            Add custom bill
          </p>
          <input
            v-model="info.customBill"
            class="tw-input tw-border tw-border-gray-300 tw-w-full"
            type="text"
            placeholder="Add Custom Bill Details"
          />

          <div v-if="!info.customBill" class="tw-flex tw-flex-col tw-gap-4">
            <p class="tw-text-error tw-mb-0" v-if="error.table">
              {{ error.table }}
            </p>

            <div class="tw-grid tw-grid-cols-4 tw-gap-4">
              <div v-for="table in sortedTables" :key="table._id">
                <div
                  v-if="occupiedTableIds.includes(table._id)"
                  class="tw-font-bold tw-p-4 tw-text-center tw-border tw-border-gray-300 tw-bg-gray-300 tw-rounded-lg tw-text-secondary"
                >
                  {{ table.name }}
                </div>
                <div
                  v-else
                  :class="{
                    'tw-cursor-pointer tw-text-primary hover:tw-bg-primary hover:tw-bg-opacity-10':
                      info.tableId !== table._id,
                    'tw-bg-primary tw-text-white tw-pointer-events-none':
                      info.tableId === table._id,
                  }"
                  class="tw-font-bold tw-p-4 tw-text-center tw-border tw-border-primary tw-rounded-lg"
                  @click="() => setTable(table)"
                >
                  {{ table.name }}
                </div>
              </div>
            </div>

            <div class="tw-flex tw-justify-end tw-gap-4">
              <label className="tw-label tw-cursor-pointer">
                <input
                  v-model="sortTableByAsc"
                  type="radio"
                  name="is-cash"
                  className="tw-radio tw-border-gray-700 tw-mr-1"
                  v-bind:value="false"
                />
                <span className="tw-label-text tw-text-gray-600">DESC</span>
              </label>
              <label className="tw-label tw-cursor-pointer">
                <input
                  v-model="sortTableByAsc"
                  type="radio"
                  name="is-cash"
                  className="tw-radio tw-border-gray-700 tw-mr-1"
                  v-bind:value="true"
                />
                <span className="tw-label-text tw-text-gray-600">ASC</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="md:tw-col-span-2 tw-w-full tw-card tw-border tw-border-gray-300 tw-shadow-md tw-bg-white"
      >
        <div class="tw-card-body tw-p-4 tw-flex tw-items-center tw-gap-2">
          <button
            v-if="!info.customer.userId"
            class="tw-btn tw-btn-warning tw-btn-block tw-text-white tw-font-normal"
            @click="(isModalShown = true)"
          >
            Add new guest
          </button>

          <add-new-guest
            v-model:visible="isModalShown"
            @created="onCustomerAdded"
          />

          <search-and-assign-guest
            v-if="!info.customer.userId"
            class="tw-w-full"
            @selected="onCustomerAdded"
          />
          <assigned-guest-card
            v-if="info.customer.userId"
            :customer="info.customer"
            @remove="info.customer = {}"
            class="tw-rounded-xl tw-w-full"
          />

          <div class="tw-flex tw-items-center tw-gap-4 tw-w-full">
            <input
              v-model="info.amountOfGuest"
              class="tw-input tw-border tw-border-gray-300 tw-w-full"
              type="text"
              placeholder="Enter amount of guests"
            />
            <p class="tw-font-bold tw-border-b tw-border-primary tw-mb-0">
              Guests
            </p>
          </div>

          <p class="tw-text-error tw-text-sm" v-if="error.amountOfGuest">
            {{ error.amountOfGuest }}
          </p>

          <inputpad
            @store-value="setGuestAmount"
            @set-backspace="(PressedBackspace = false)"
            :keybordValue="keyValue"
            :isBackspacePressed="PressedBackspace"
          />

          <textarea
            v-model="info.orderNote"
            id="order-note"
            name="order-note"
            rows="3"
            placeholder="Note about order...."
            class="tw-textarea tw-border tw-border-gray-300 tw-w-full"
            style="resize: none"
          >
          </textarea>

          <select
            v-model="info.salesCategory"
            class="tw-select tw-w-full tw-border tw-border-gray-300"
          >
            <option
              v-for="category in salesCategories"
              :key="category._id"
              :value="category._id"
            >
              {{ category.name }}
            </option>

            <option value="new">Create new sales category</option>
          </select>

          <div class="tw-flex tw-w-full" v-if="showCreateSalesCategoryView">
            <input
              v-model="salesCategory"
              type="text"
              placeholder="Type sales category name"
              class="tw-input tw-input-border tw-border-gray-300 tw-w-full"
            />
          </div>

          <button
            class="tw-btn tw-btn-primary tw-text-white tw-font-normal tw-btn-block"
            @click="startOrder"
          >
            Start Order
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Inputpad from '@/components/common/Inputpad.vue'
import AssignedGuestCard from '@/components/customer/AssignedGuestCard.vue'
import SearchAndAssignGuest from '@/components/customer/SearchAndAssignGuest.vue'
import AddNewGuest from '@/components/modal/AddNewGuest.vue'
import { orderBy } from 'lodash'
import { useTables } from '@/stores/table'
import { useSale } from '@/stores/sale/sale'
import { useRegister } from '@/stores/register'
import { NavbarTypes, useApp } from '@/stores/app'
import { ref, reactive, computed, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import debug from 'debug'

const logger = debug('sale-component')

const router = useRouter()
const appStore = useApp()
const saleStore = useSale()
const tablesStore = useTables()
const registerStore = useRegister()

const keyValue = ref('')
const PressedBackspace = ref(false)

const info = reactive({
  tableId: '',
  tableName: '',
  orderNote: '',
  amountOfGuest: 1,
  customBill: '',
  salesCategory: '',
  customer: {}
})

const error = reactive({
  table: '',
  amountOfGuest: '',
})

const sortTableByAsc = ref(true)
const isModalShown = ref(false)
const showCreateSalesCategoryView = ref(false)
const salesCategory = ref('')
const tables = computed(() => tablesStore.tables)
const occupiedTableIds = computed(() => tablesStore.occupiedTableIds)

const salesCategories = computed(() => registerStore.salesCategories)

const sortedTables = computed(() => {
  return orderBy(tables.value, 'name', sortTableByAsc.value ? 'asc' : 'desc')
})

const onCustomerAdded = (data: any): void => {
  isModalShown.value = false

  const customer: Record<string, any> = {
    userTags: data.userTags ?? [],
    userName: data.fullName ?? '',
    userId: data._id,
    userPhone: data.phone ?? '',
    userEmail: data.email ?? '',
    userPersona: data.persona ?? [],
    memberGroup: data.membership?.level ?? '',
    customerAlert: data.customerAlert ?? '',
    onAccount: data.onAccount ?? '',
    affiliateCodes: data.affiliateCodes ?? [],
    userLoyalty: {
      groomPoints: data?.membership?.groomPoints || 0,
      networkPoints: data?.membership?.networkPoints || 0,
      stylePoints: data?.membership?.stylePoints || 0,
    }
  }

  info.customer = customer
}

const setGuestAmount = (value: string) => {
  info.amountOfGuest = parseInt(value)
}

const setTable = (table: any) => {
  info.tableId = table._id
  info.tableName = table.name
  error.table = ''
}

const addSalesCategory = async () => {
  if (!salesCategory.value) {
    return
  }
  const category = await registerStore.createSalesCategory(salesCategory.value)
  info.salesCategory = category._id
  showCreateSalesCategoryView.value = false
}

const startOrder = async () => {
  try {
    if (!info.customBill && !info.tableId) {
      error.table = 'Please Select Table Or Add Custom Bill To Proceed Further'
      return
    }

    if (!info.amountOfGuest) {
      error.amountOfGuest = 'Guest amount is required to start order'
      return
    }

    if (info.amountOfGuest > 20) {
      error.amountOfGuest = 'More then 20 guest in single table is not allowed'
      return
    }

    if (showCreateSalesCategoryView.value && salesCategory.value) {
      await addSalesCategory()
    }

    const payload = {
      ...info,
      department: 'bar',
    }

    const order = await saleStore.open(payload)
    router.replace({
      name: 'sale',
      params: { orderId: order.orderId },
    })
  } catch (e) {
    logger(e)
  }
}

onMounted(() => {
  info.salesCategory = salesCategories.value[0]?._id
  tablesStore.setTablesForCurrentLocation()
  saleStore.clear()

  appStore.setNavbarOption(NavbarTypes.BackButtonOptionNavabar)
})

watch(
  () => info.salesCategory,
  newValue => {
    if (newValue === 'new') {
      showCreateSalesCategoryView.value = true
    }
  },
)
</script>
