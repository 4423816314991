import { defineStore } from 'pinia'
import { assign, orderBy } from 'lodash'
import { useRegister } from './register'
import database from '@/config/firebase/database'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { type StaffUser } from './authentication'
import { reactive } from 'vue'
import debug from 'debug'

const logger = debug('staff')

export const useStaff = defineStore(
  'staff',
  () => {
    const register = useRegister()

    const staff = reactive<Array<StaffUser>>([])
    const active = reactive<StaffUser>({} as StaffUser)

    const hydrate = async () => {
      if (!location) {
        logger('location is not found, could not load staff members')
      }

      const q = query(
        collection(database, `staff`),
        where('active', '==', true),
        where('locations', 'array-contains', register.location),
      )

      const snapshot = await getDocs(q)

      const _staff: Array<StaffUser> = []

      snapshot.forEach(doc => {
        _staff.push({
          _id: doc.id,
          ...doc.data(),
        } as StaffUser)
      })

      logger(`${_staff.length} staff members loaded`)

      staff.splice(0, staff.length, ...orderBy(_staff, 'name', 'asc'))
    }

    const fetchAllStaffMembers = async () => {
      const q = query(
        collection(database, `staff`),
        where('active', '==', true),
      )

      const snapshot = await getDocs(q)

      const staff: Array<StaffUser> = []

      snapshot.forEach(doc => {
        staff.push({
          _id: doc.id,
          ...doc.data(),
        } as StaffUser)
      })
      return orderBy(staff, 'name', 'asc')
    }

    const setActive = (member: StaffUser) => {
      assign(active, member)
    }

    return {
      staff,
      active,
      hydrate,
      fetchAllStaffMembers,
      setActive,
    }
  },
  {
    persist: true,
  },
)
