export const cssText = `
@page {
  size: auto;   /* auto is the initial value */
  /* this affects the margin in the printer settings */
  margin: 0mm 0mm 0mm 0mm;
}

@media print {
  body {
    zoom: 100%;
    margin: 0 auto;
  }
  .print-bill {
    height: auto;
    width: 95%;
    padding: 0px;
    font-size: 10pt;
  }
}
body{
  margin: 0;
  margin: 0 auto;
}
.print-bill {
  height: auto;
  width: 95%;
  padding: 0px;
  margin: 0 auto;
  font-size: 10pt;
}
.logo {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.logo > img {
  width: 50%;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: dotted 1px;
}

.address-time {
  display: flex;
  justify-content: space-between;
}

.address-time > .address {
  text-align: left;
}

.address-time > .time {
  text-align: right;
}

.barbaard-club {
  text-align: center;
}

.barbaard-club > .title {
  font-weight: bold;
  font-size: 10pt;
}

.barbaard-club > .description {
  margin-top: 18px;
  margin-bottom: 18px;
}

.item-price-head {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.item-price-head > .item {
  text-align: left;
}

.item-price-head > .item > .normal-item {
  font-weight: normal;
}

.item-price-head > .price {
  text-align: right;
}

.item-price-head > .normal-price {
  text-align: right;
  font-weight: normal;
}

.item-price {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.item-price > .item {
  text-align: left;
}

.item-price > .price {
  text-align: right;
}

.item-price-foot {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 9pt;
}

.item-price-foot.major {
  font-size: 12pt;
}

.item-price-foot.normal {
  font-weight: normal;
}

.item-price-foot > .item {
  text-align: left;
}

.item-price-foot > .price {
  text-align: right;
}

.payment {
  margin-bottom: 5px;
}

.download {
  text-align: center;
  font-weight: bold;
}

.barcode {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.barcode > img {
  width: 25%;
}

.store {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.store > .appstore {
  display: flex;
  justify-content: space-around;
  margin-top: 4px;
  width: 50%;
}

.store > .playstore {
  display: flex;
  justify-content: space-around;
  width: 50%;
}

.store > img {
  width: 80%;
}
`
