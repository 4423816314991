<template>
  <div class="tw-grid tw-grid-cols-1 tw-gap-4 md:tw-grid-cols-2">
    <AgreementCard @click="emit('back')">
      <div
        class="tw-card-body tw-p-2 tw-flex tw-items-center tw-justify-center"
      >
        <BackArrowIcon
          class="tw-text-primary hover:tw-text-secondary tw-w-8 tw-h-8"
        />
      </div>
    </AgreementCard>
    <AgreementCard
      @click="sellAgreement"
      title="Gentleman's Agreement"
      :points="FixedPoints.Gentleman"
    />
    <AgreementCard
      @click="sellInitiateAgreement"
      title="Initiate Agreement"
      :points="FixedPoints.Initiate"
    />
    <AgreementCard
      @click="sellKingsAgreement"
      title="King's Agreement"
      :points="FixedPoints.King"
    />
    <AgreementCard
      @click="sellNobelAgreement"
      title="Nobel's Agreement"
      :points="FixedPoints.King"
    />
    <AgreementCard
      @click="sellEmperorsAgreement"
      title="Emperor's Agreement"
      :points="FixedPoints.Emperor"
    />
  </div>
</template>

<script lang="ts" setup>
import { useSale } from '@/stores/sale/sale'
import { generateSaleItem } from '@/utilities/sale'
import BackArrowIcon from '@/components/icons/BackArrowIcon.vue'
import AgreementCard from './components/AgreementCard.vue'
import { FixedPoints } from '@/utilities/constants'
import { defineEmits } from 'vue'
import { ProductTypes } from '@/stores/inventory'

const sale = useSale()

const emit = defineEmits(['back', 'showServices'])

const onAgreementAddedToSale = () => {
  emit('showServices')
}

const sellInitiateAgreement = () => {
  const agreement = generateSaleItem({
    id: 'Agreement',
    name: `Initiate Agreement`,
    price: 0,
    type: ProductTypes.InitiateAgreement,
    category: ProductTypes.InitiateAgreement,
    agreementCount: 1,
    agreementComp: 1,
    VAT: { amount: 8, label: '8%' },
    serviceCharge: { amount: 5, label: '5%' },
    points: FixedPoints.Initiate,
  })
  sale.add(agreement)
  onAgreementAddedToSale()
}

const sellAgreement = () => {
  const agreement = generateSaleItem({
    id: 'Agreement',
    name: `Gentleman's Agreement`,
    price: 0,
    type: ProductTypes.GentlemanAgreement,
    category: ProductTypes.GentlemanAgreement,
    agreementCount: 10,
    agreementComp: 2,
    VAT: { amount: 8, label: '8%' },
    serviceCharge: { amount: 5, label: '5%' },
    points: FixedPoints.Gentleman,
  })
  sale.add(agreement)
  onAgreementAddedToSale()
}

const sellKingsAgreement = () => {
  const price = 39000000

  const agreement = generateSaleItem({
    id: 'Kings',
    name: `King's Agreement`,
    price: price,
    pricePerMonth: price / 12,
    netPricePerMonth: price / 12,
    type: ProductTypes.KingsAgreement,
    category: ProductTypes.KingsAgreement,
    months: 12,
    VAT: { amount: 8, label: '8%' },
    points: FixedPoints.King,
  })

  sale.add(agreement)
}

const sellNobelAgreement = () => {
  const price = 29000000

  const agreement = generateSaleItem({
    id: 'Nobel',
    name: `Nobel's Agreement`,
    price: price,
    pricePerMonth: price / 12,
    netPricePerMonth: price / 12,
    type: ProductTypes.NobelAgreement,
    category: ProductTypes.NobelAgreement,
    months: 12,
    VAT: { amount: 8, label: '8%' },
    points: FixedPoints.Nobel,
  })

  sale.add(agreement)
}

const sellEmperorsAgreement = () => {
  const price = 49000000
  const basePrice = price / 1.08

  const agreement = generateSaleItem({
    id: 'EmperorsAgreement',
    name: `Emperor's Agreement`,
    price: price,
    pricePerMonth: price / 12,
    netPricePerMonth: basePrice / 12,
    type: ProductTypes.EmperorsAgreement,
    category: ProductTypes.EmperorsAgreement,
    months: 12,
    VAT: { amount: 8, label: '8%' },
    points: FixedPoints.Emperor,
  })

  sale.add(agreement)
}
</script>
