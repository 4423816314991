<template>
  <div
    class="tw-flex tw-flex-col tw-cursor-pointer hover:tw-bg-gray-100"
    :class="{ 'tw-pointer-events-none': applied || !applicable, 'tw-pointer-events-none tw-opacity-50': !agreement.applicable }"
    @click="onAgreementClick"
  >
    <div
      class="tw-p-2 tw-border tw-border-gray-300 tw-rounded-lg"
    >
      <div class="tw-flex tw-items-center tw-p-2">
        <CheckCircleFillIcon
          v-if="applied"
          class="tw-h-4 tw-w-4 tw-text-success tw-shrink-0"
        />
        <CheckCircleIcon
          v-else
          class="tw-h-4 tw-w-4 tw-text-secondary tw-shrink-0"
        />

        <div class="tw-ml-4">
          <b> {{ title }}</b>
          <slot name="content" />

          <slot name="footer" />
        </div>
      </div>
    </div>

    <Confirm v-model:visible="showConfirmDialog" @confirm="onApplyAgreementConfirm">
      <template #content>
        This order already has a promotion discount applied. Applying the
        agreement will remove any additional discounts on services covered by
        the agreement including any sale discount. Do you wish to proceed?
      </template>
    </Confirm>
  </div>
</template>

<script lang="ts" setup>
import { CheckCircleFillIcon, CheckCircleIcon } from '@/components/icons'
import Confirm from '@/components/modal/Confirm.vue'
import { map, filter, some, difference, reduce } from 'lodash'
import { useSale } from '@/stores'
import { computed, ref } from 'vue'
import { ProductTypes } from '@/stores/inventory'
const sale = useSale()

const props = defineProps({
  agreement: {
    type: Object,
    required: true,
  },
})

const showConfirmDialog = ref(false)
const confirmed = ref(false)

const applied = computed(() => {
  return map(sale.appliedAgreements, a => a.agreement._id).includes(
    props.agreement._id,
  )
})

const canApply = computed<boolean>(() => {
  const getApplicableServices = () => {
    switch (props.agreement.type) {
      case ProductTypes.Upgrade:
        return filter(
          sale.products,
          upgrade => upgrade.type === ProductTypes.Upgrade,
        )
      default:
        return filter(sale.products, product =>
          some(props.agreement.services, s => s.id === product.id),
        )
    }
  }

  const applicableItems = getApplicableServices()

  return !some(applicableItems, i => !!i.promotion?._id)
})

const title = computed(() => {
  switch (props.agreement.type) {
    case 'service':
      return props.agreement.serviceName
    case ProductTypes.InitiateAgreement:
      return props.agreement.serviceName
    case ProductTypes.NobelAgreement:
      return `Nobel's Agreement`
    case ProductTypes.KingsAgreement:
      return `King's Agreement`
    case ProductTypes.EmperorsAgreement:
      return `Emperor's Agreement`
    case ProductTypes.MerchantsAgreement:
      return `Merchant's Agreement`
    case ProductTypes.GrandMerchantsAgreement:
      return `Grand Merchant's Agreement`
    case 'upgrades':
      return map(props.agreement.upgrades, 'name').join(' & ')
    default:
      return 'Unknown Agreement'
  }
})

const applicable = computed(() => {
  if (
    [
      ProductTypes.MerchantsAgreement,
      ProductTypes.GrandMerchantsAgreement,
    ].includes(props.agreement.type)
  ) {
    /**
     * Sale must have two services with no other agreements applied to
     * apply merchant agreement
     *
     */
    const exclude = reduce(
      sale.appliedAgreements,
      (items, applied) => {
        items = [...items, ...map(applied.old, 'id')]
        return items
      },
      [] as Array<string>,
    )
    const services = map(
      filter(sale.order.products, p => p.type === ProductTypes.Service),
      'id',
    )
    return difference(services, exclude).length >= 2
  }
  return true
})

const handleAgreement = () => {
  const callable = applied.value ? sale.revertAgreement : sale.applyAgreement
  callable(props.agreement)
}

const onApplyAgreementConfirm = () => {
  showConfirmDialog.value = false
  confirmed.value = true
}

const onAgreementClick = () => {
  if (applied.value) {
    return
  }

  if (!canApply.value && confirmed.value) {
    confirmed.value = false
    handleAgreement()
  } else if (!canApply.value && !confirmed.value) {
    showConfirmDialog.value = true
    return
  } else {
    handleAgreement()
  }
}
</script>
