import { filter, reduce, round } from 'lodash'
import { defineStore } from 'pinia'
import { getTotalPayableAmount } from '@/utilities/sale'
import { useSale } from './sale'
import { computed } from 'vue'
import { ProductTypes } from '../inventory'
import {
  getDoc,
  doc,
  query,
  collection,
  where,
  limit,
  getDocs,
} from 'firebase/firestore'
import { User, LocationOrder, LocationOrderItemUnit, business } from 'core'
import database from '@/config/firebase/database'

export const useLoyalty = defineStore('loyalty', () => {
  const sale = useSale()

  const hasLoyaltyPoints = computed<boolean>(() => {
    const { userLoyalty: l } = sale.customer
    return !!(l.groomPoints || l.networkPoints || l.stylePoints)
  })

  const claimableGroomingPoints = computed<number>(() => {
    const { userLoyalty } = sale.customer

    return totalPayableGroomingAmount.value < userLoyalty.groomPoints
      ? totalPayableGroomingAmount.value
      : userLoyalty.groomPoints
  })

  const claimableNetworkingPoints = computed<number>(() => {
    const { userLoyalty } = sale.customer

    return totalPayableNetworkingAmount.value < userLoyalty.networkPoints
      ? totalPayableNetworkingAmount.value
      : userLoyalty.networkPoints
  })

  const claimableStylingPoints = computed<number>(() => {
    const { userLoyalty } = sale.customer

    return totalPayableStyleAmount.value < userLoyalty.stylePoints
      ? totalPayableStyleAmount.value
      : userLoyalty.stylePoints
  })

  const totalPayableGroomingAmount = computed<number>(() => {
    const sale = useSale()

    const groomingTypes = [
      ProductTypes.Service,
      ProductTypes.GentlemanAgreement,
      ProductTypes.KingsAgreement,
      ProductTypes.EmperorsAgreement,
    ]

    const amount = reduce(
      filter(sale.products, p => {
        return groomingTypes.includes(p.type)
      }),
      (acc, current) => acc + getTotalPayableAmount(current),
      0,
    )

    return round(amount)
  })

  const totalPayableNetworkingAmount = computed<number>(() => {
    const amount = reduce(
      filter(sale.products, p => {
        return p.type === ProductTypes.FnB || p.type === ProductTypes.FnBVariant
      }),
      (acc, current) => acc + getTotalPayableAmount(current),
      0,
    )

    return round(amount)
  })

  const totalPayableStyleAmount = computed<number>(() => {
    const amount = reduce(
      filter(sale.products, p => {
        return (
          p.type === ProductTypes.Product ||
          p.type === ProductTypes.ProductVariant
        )
      }),
      (acc, current) => acc + getTotalPayableAmount(current),
      0,
    )

    return round(amount)
  })

  const calculateLoyaltyPointsEarned = async (
    userId: string,
    orderId: string,
    locationId: string,
  ) => {
    const loyalty = {
      points: 0,
      membership: {},
    }
    try {
      const customer = await getDoc(doc(database, `users/${userId}`))
      if (!customer.exists()) {
        return loyalty
      }

      const orderQuery = query(
        collection(database, `locations/${locationId}/orders`),
        where('orderId', '==', orderId),
        limit(1),
      )
      const snapshot = await getDocs(orderQuery)

      if (snapshot.empty) return loyalty
      const order = snapshot.docs[0]

      const itemsSnapshot = await getDocs(
        collection(
          database,
          `locations/${locationId}/orders/${order.id}/items`,
        ),
      )
      const _items: LocationOrderItemUnit[] = []
      itemsSnapshot.forEach(doc => {
        _items.push(
          LocationOrderItemUnit.from({
            ...doc.data(),
            meta: {
              id: doc.id,
              location: locationId,
              order: orderId,
            },
          }),
        )
      })

      const _u = User.from({ id: customer.id, ...customer.data() })
      const _o = LocationOrder.from({
        ...order.data(),
        meta: {
          id: order.id,
          location: locationId,
        },
      })

      const result = business.loyalty.earnPts(_u as User, _o, _items)

      if (result.isSuccess) {
        loyalty.points = result.order.pointProps.earnedPoints?.tier || 0
        loyalty.membership = result.user.membership?.toIndexable() || {}
        return loyalty
      } else {
        return loyalty
      }
    } catch (e) {
      console.log('Loyalty:: error calculating points', e)
      return loyalty
    }
  }

  return {
    hasLoyaltyPoints,
    claimableGroomingPoints,
    claimableNetworkingPoints,
    claimableStylingPoints,
    totalPayableGroomingAmount,
    totalPayableNetworkingAmount,
    totalPayableStyleAmount,

    calculateLoyaltyPointsEarned,
  }
})
