<template>
  <div
    class="tw-relative tw-bg-primary tw-w-full tw-flex tw-items-start tw-py-3 tw-px-4 tw-transition tw-duration-100 tw-items-center"
  >
    <div class="tw-container tw-flex tw-flex-col tw-gap-4">
      <div
        class="tw-bg-primary tw-z-10 tw-w-full tw-flex tw-gap-4 tw-transition tw-duration-100 tw-origin-top tw-flex-row tw-items-center"
      >
        <div class="tw-flex tw-gap-4 tw-flex-row tw-items-center">
          <!-- todo: collapse stopped working -->
          <slot>
            <router-link
              v-for="route in routes"
              class="tw-text-white tw-underline-offset-8 tw-decoration-warning hover:tw-opacity-80 hover:tw-text-gray-100 hover:tw-no-underline"
              exact-active-class="tw-underline tw-pointer-events-none"
              :key="route.title"
              :to="route.to"
              @click="(isMenuExpanded = false)"
            >
              {{ route.title }}
            </router-link>
          </slot>
        </div>

        <div class="tw-flex tw-items-center tw-text-white tw-ml-auto">
          <Avatar
            class="tw-mr-4"
            :name="staff.active.fullName"
            :image="staff.active.image"
            @click="(isStaffModalShown = true)"
          />
          <CircledUserIcon class="tw-w-8 tw-h-8 tw-mr-2" />
          <div class="tw-flex tw-flex-col tw-text-white">
            <p class="tw-font-bold tw-mb-0">{{ staff.active.fullName }}</p>
            <p
              class="tw-text-sm tw-mb-0 tw-cursor-pointer hover:opacity-80"
              @click="(isUserModalShown = true)"
            >
              Switch Account
            </p>
          </div>
        </div>
      </div>
    </div>

    <Modal
      :visible="isUserModalShown"
      title="Switch User"
      max-width-class="tw-max-w-screen-sm"
      @close="() => (isUserModalShown = false)"
    >
      <div
        class="tw-flex tw-justify-between tw-px-6 tw-py-4 tw-cursor-pointer hover:tw-bg-gray-100"
        @click="userLogout"
      >
        <p class="tw-font-medium tw-mb-0">Logout</p>
        <PowerIcon class="tw-w-4 tw-h-4" />
      </div>
    </Modal>

    <Modal
      :visible="isStaffModalShown"
      max-width-class="tw-max-w-screen-sm"
      title="Switch General Sales Person"
      @close="() => (isStaffModalShown = false)"
    >
      <div class="tw-max-h-96 tw-overflow-y-scroll">
        <div
          v-for="staffMember in staff.staff"
          :key="staffMember._id"
          class="tw-flex tw-items-center tw-px-6 tw-py-4 tw-gap-4 tw-cursor-pointer hover:tw-bg-gray-100"
          :class="{
            'tw-bg-primary tw-text-white tw-pointer-events-none':
              staff.active._id == staffMember._id,
          }"
          @click="() => changeActiveStaffMember(staffMember)"
        >
          <Avatar
            :name="staffMember.fullName"
            :image="staffMember.image"
            :variant="
              staff.active._id != staffMember._id ? 'primary' : undefined
            "
          />
          <div class="tw-flex tw-flex-col">
            <p class="tw-font-medium tw-mb-0">
              {{ staffMember.fullName }}
            </p>
            <p class="tw-text-sm tw-mb-0">{{ staffMember.email }}</p>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script lang="ts" setup>
import { CircledUserIcon, PowerIcon } from '@/components/icons'
import Modal from '../common/Modal.vue'
import Avatar from '../common/Avatar.vue'
import { useAuthentication, type StaffUser } from '../../stores/authentication'
import { useStaff } from '../../stores/staff'
import { useRegister } from '../../stores/register'
import { defineProps, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const authentication = useAuthentication()
const register = useRegister()
const staff = useStaff()

const router = useRouter()
const route = useRoute()

defineProps({
  routes: {
    type: Array<{
      title: string
      to: string
      name: string
      class: string
    }>,
    required: true,
    default: () => [],
  },
})

const isUserModalShown = ref<boolean>(false)
const isStaffModalShown = ref<boolean>(false)
const isMenuExpanded = ref<boolean>(false)

const userLogout = async () => {
  isUserModalShown.value = false
  await authentication.logout()
  register.reset()
  router.push('/auth/login')
}

const changeActiveStaffMember = (staffMember: StaffUser) => {
  staff.setActive(staffMember)
  isStaffModalShown.value = false
}

watch(route, () => {
  isMenuExpanded.value = false
})
</script>

<style></style>
