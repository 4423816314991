<template>
  <div
    class="tw-modal"
    :class="{
      'tw-visible tw-pointer-events-auto tw-opacity-100': visible,
    }"
    @click.self="$emit('update:visible', false)"
  >
    <div
      class="tw-modal-box tw-p-0 tw-bg-white"
      :class="{
        [props.maxWidthClass]: true,
      }"
    >
      <slot name="header">
        <div
          class="tw-flex tw-justify-between tw-items-center tw-w-full tw-p-4 tw-border-b tw-border-gray-300"
        >
          <h6 class="tw-mb-0 tw-ml-2">{{ title }}</h6>
          <div
            class="tw-flex tw-justify-center tw-items-center tw-w-10 tw-h-10 tw-rounded-full tw-transition-all hover:tw-bg-gray-200 hover:tw-rotate-180"
          >
            <CloseIcon
              class="tw-w-5 tw-h-5"
              @click="() => emit('close', false)"
            />
          </div>
        </div>
      </slot>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue'
import { CloseIcon } from '../icons'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  maxWidthClass: {
    type: String,
    default: 'tw-max-w-screen-lg',
  },
  title: String,
})

const emit = defineEmits(['close', 'update:visible'])
</script>
