<template>
  <div>
    <div
      class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-lg tw-border tw-border-gray-300 pointer tw-mb-4 hover:tw-bg-primary hover:tw-bg-opacity-10"
      @click="emit('back')"
    >
      <BackArrowIcon class="tw-w-4 tw-h-4 tw-mr-2" />
      <p class="tw-mb-0">Back</p>
    </div>

    <div
      v-for="(service, index) in services"
      :key="index"
      class="tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-2 tw-border-b tw-border-x tw-border-gray-300 pointer first:tw-border-t first:tw-rounded-t-lg last:tw-rounded-b-lg hover:tw-bg-primary hover:tw-bg-opacity-10"
      @click="addService(service)"
    >
      <div class="tw-flex tw-flex-1 tw-flex-col tw-gap-1">
        <h6 class="tw-mb-0">{{ service.title }}</h6>
        <small v-if="service.price" class="tw-mb-0">
          Price: ₫ {{ formatNumber(round(service.price)) }}
        </small>
      </div>
      <div>
        <Points
          v-if="service.data.points"
          :points="service.data.points"
          class="tw-rounded-lg tw-ml-4"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatNumber } from '@/utilities/common'
import { round } from 'lodash'
import {
  ProductTypes,
  useInventory,
  type InventoryProduct,
} from '@/stores/inventory'
import { useSale } from '@/stores/sale/sale'
import { generateSaleItem } from '@/utilities/sale'
import BackArrowIcon from '@/components/icons/BackArrowIcon.vue'
import Points from '../sale/components/Points.vue'
import { defineEmits, computed } from 'vue'
import type { NoComplimentaryType } from '@/stores/sale'

const emit = defineEmits(['back'])

const inventory = useInventory()
const sale = useSale()

const services = computed<Array<InventoryProduct>>(() => {
  return inventory.services.filter(s => s.data.type === 'appointment')
})

const addService = (data: InventoryProduct) => {
  const optionalComplimentaries = data.data.complimentary
    ? {
        complimentaries: {
          drink: 'drink-complimentary',
          food: 'snack-complimentary',
        },
        complimentaryDrink: {} as NoComplimentaryType,
        complimentaryFood: {} as NoComplimentaryType,
      }
    : {}

  const item = generateSaleItem({
    id: data.id,
    type: ProductTypes.Service,
    name: data.title,
    category: ProductTypes.Service,
    serviceCategory: data.data.category,
    price: data.price,
    VAT: data.VAT,
    serviceCharge: data.serviceCharge,
    points: data.data.points ? data.data.points : 0,
    ...optionalComplimentaries,
  })
  sale.add(item)
}
</script>
