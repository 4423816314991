<template>
  <div>
    <div
      class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-lg tw-border tw-border-gray-300 cursor-pointer tw-mb-4 hover:tw-bg-primary hover:tw-bg-opacity-10"
      @click="emit('back')"
    >
      <BackArrowIcon class="tw-w-4 tw-h-4 tw-mr-2" />
      <p class="tw-mb-0">Back</p>
    </div>

    <div
      v-for="(upgrade, index) in upgrades"
      :key="index"
      class="tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-2 tw-border-b tw-border-x tw-border-gray-300 pointer first:tw-border-t first:tw-rounded-t-lg last:tw-rounded-b-lg hover:tw-bg-primary hover:tw-bg-opacity-10"
      @click="saveUpgrade(upgrade)"
    >
      <div class="tw-flex tw-flex-1 tw-flex-col tw-gap-1">
        <h6 class="tw-mb-0">{{ upgrade.title }}</h6>
        <small v-if="upgrade.price" class="tw-mb-0">
          Price: ₫ {{ formatNumber(round(upgrade.price)) }}
        </small>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatNumber } from '@/utilities/common'
import {
  ProductTypes,
  useInventory,
  type InventoryProduct,
} from '@/stores/inventory'
import { useSale } from '@/stores/sale/sale'
import { round } from 'lodash'
import { generateSaleItem } from '@/utilities/sale'
import BackArrowIcon from '@/components/icons/BackArrowIcon.vue'
import { defineEmits, computed } from 'vue'

const emit = defineEmits(['back'])

const inventory = useInventory()
const sale = useSale()

const upgrades = computed<Array<InventoryProduct>>(() => {
  return inventory.upgrades.sort((a: InventoryProduct, b: InventoryProduct) => {
    return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
  })
})

const saveUpgrade = (upgrade: InventoryProduct) => {
  const item = generateSaleItem({
    id: upgrade.id,
    type: ProductTypes.Upgrade,
    category: ProductTypes.Upgrade,
    name: upgrade.title,
    price: upgrade.price,
    VAT: upgrade.VAT,
    serviceCharge: upgrade.serviceCharge,
    points: 0,
  })

  // Signature Drink Upgrade
  if (upgrade.data?.linkedDrink) {
    item.complimentaries = {
      drink: 'drink-gentlemanup',
    }
  }

  sale.add(item)
}
</script>
