<template>
  <div
    class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-lg tw-border tw-border-gray-300 cursor-pointer tw-mb-4 hover:tw-bg-primary hover:tw-bg-opacity-10"
    @click="emit('back')"
  >
    <BackArrowIcon class="tw-w-4 tw-h-4 tw-mr-2" />
    <p class="tw-mb-0">Back</p>
  </div>

  <div
    v-for="(ticket, index) in tickets"
    :key="index"
    class="tw-p-4 tw-my-1 tw-border tw-border-x tw-border-gray-300 tw-rounded"
    :class="{
      'tw-cursor-not-allowed tw-bg-gray-200': ticket.soldOut,
      'tw-cursor-pointer hover:tw-bg-primary hover:tw-bg-opacity-10':
        !ticket.soldOut,
    }"
    @click="saveTicket(ticket)"
  >
    <div class="tw-flex tw-flex-1 tw-items-center">
      <div>
        <CouponIcon
          class="tw-w-10 tw-h-10 tw-mr-2 tw-opacity-80 tw-rotate-90"
        />
      </div>
      <div class="tw-flex-1">
        <h6>{{ ticket.name }}</h6>
        <div class="tw-flex tw-justify-between">
          <div>
            <small> ₫ {{ formatNumber(round(ticket.price)) }}</small>
          </div>
          <div v-if="ticket.limit">
            <div class="tw-badge tw-badge-secondary">
              <small class="tw-font-semibold">
                <template v-if="ticket.soldOut"> Sold Out </template>
                <template v-else>
                  {{ ticket.sold }} / {{ ticket.limit }} Sold
                </template>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatNumber } from '@/utilities/common'
import { useInventory } from '@/stores/inventory/inventory'
import { useSale } from '@/stores/sale/sale'
import { type Ticket } from '@/stores/inventory/product'
import { round } from 'lodash'
import { generateSaleItem } from '@/utilities/sale'
import BackArrowIcon from '@/components/icons/BackArrowIcon.vue'
import CouponIcon from '@/components/icons/CouponIcon.vue'
import { defineEmits, computed, onMounted } from 'vue'
import { ProductTypes } from '@/stores/inventory'

const emit = defineEmits(['back'])

const inventory = useInventory()
const sale = useSale()

const tickets = computed(() => inventory.tickets)

const saveTicket = (ticket: Ticket) => {
  if (ticket.soldOut) return
  const item = generateSaleItem({
    id: ticket._id,
    type: ProductTypes.Ticket,
    category: ProductTypes.Ticket,
    name: ticket.name,
    price: ticket.price / 1.08,
    points: 0,
    VAT: {
      label: '8%',
      amount: 8,
    },
  })

  sale.add(item)
}

onMounted(async () => {
  await inventory.setTickets()
})
</script>
