<template>
  <div class="tw-flex tw-gap-4 tw-flex-row tw-items-center">
    <button
      class="tw-btn tw-btn-sm tw-btn-outline tw-border-white tw-text-white hover:tw-bg-white hover:tw-text-black"
      @click="onBack"
    >
      <ArrowRightIcon class="tw-w-4 tw-h-4 tw-translate tw-rotate-180" />
    </button>
    <SwitchTable />
    <ParkSale />
    <DiscardSale />
    <div
      v-if="shouldShowSendToBar"
      class="tw-btn tw-btn-sm tw-flex tw-items-center tw-text-white hover:tw-opacity-80"
      @click="sendToBar"
    >
      <PrintIcon class="tw-h-4 tw-w-4 tw-text-white tw-mr-2" />
      <span>Send to bar</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { some } from 'lodash'
import { useSale } from '@/stores/sale/sale'
import { ArrowRightIcon, PrintIcon } from '@/components/icons'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import DiscardSale from './actions/DiscardSale.vue'
import ParkSale from './actions/ParkSale.vue'
import SwitchTable from './actions/SwitchTable.vue'

const router = useRouter()
const sale = useSale()

const shouldShowSendToBar = computed<boolean>(() => {
  return some(sale.products, p => !p.isPrinted)
})

const sendToBar = async () => {
  await sale.markAllItemsPrinted()
  router.back()
}

const onBack = async () => {
  await sale.markAllItemsPrinted()
  if (!window.history.state.back) {
    router.replace({ name: 'home' })
    return
  }
  router.back()
}
</script>
