<template>
  <div
    :ref="`sale-item-${item.internalId}`"
    tabIndex="{0}"
    class="tw-collapse tw-rounded-lg"
    :class="{
      'tw-collapse-open': visible,
      'tw-collapse-close': !visible,
      'tw-border tw-border-error': !isEmpty(errors),
      'tw-border-b tw-border-gray-300': isEmpty(errors),
    }"
  >
    <div class="tw-collapse-title tw-py-2 tw-px-2">
      <ItemCardContent
        :item="item"
        :index="index"
        :details-visible="visible"
        @expand="onExpand"
        @assign="onStaffMemberChange"
        @removePromotion="onPromotionRemove"
      />
    </div>

    <div class="tw-collapse-content tw-flex tw-gap-4">
      <div role="group" class="tw-flex tw-flex-col">
        <p class="tw-mb-0 tw-text-sm">Value</p>
        <NumberInput
          :value="item.price"
          @input="value => update('price', value)"
          placeholder="Enter Amount"
          class="tw-input tw-input-sm tw-border tw-border-gray-300 tw-w-full"
        />
      </div>
      <div role="group" class="tw-flex tw-flex-col">
        <p class="tw-mb-0 tw-text-sm">Code</p>
        <input
          type="text"
          class="tw-input tw-input-sm tw-border tw-rounded-lg tw-w-full focus:tw-outline-none"
          :class="{
            'tw-border-gray-300': !errors.code,
            'tw-border-error': errors.code,
          }"
          :value="item.code"
          @change="onItemCodeInput(($event.target as HTMLInputElement).value)"
        />
        <p
          v-if="!isEmpty(errors) && errors.code"
          class="tw-mb-0 tw-text-xs tw-text-error"
        >
          {{ errors.code }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ItemCardContent from './ItemCardContent.vue'
import db from '@/config/firebase/database'
import { collection, getDocs, query, where, limit } from 'firebase/firestore'
import { reactive, ref, type PropType, defineProps } from 'vue'
import { useComposableSaleItem } from '@/composables/sale-item'
import { isEmpty } from 'lodash'
import type { GiftChequeSaleProduct } from '@/stores/sale'
import NumberInput from '../common/NumberInput.vue'

const props = defineProps({
  item: {
    type: Object as PropType<GiftChequeSaleProduct>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
})

let errors = reactive<{ [property: string]: string }>({})
const visible = ref<boolean>(false)

const { update, scrollToSelf, onStaffMemberChange, onPromotionRemove } =
  useComposableSaleItem(props.item, props.index)

const onItemCodeInput = (code: string) => {
  update('code', code)
  validator()

  if (code) checkVoucher(code)
}

const validator = () => {
  if (!isEmpty(errors.value)) return false

  if (!props.item.code) {
    errors = {
      code: 'Gift Cheque code required',
    }
    return false
  }
  errors = {}
  return true
}

const onExpand = () => {
  visible.value = !visible.value

  if (visible.value) {
    scrollToSelf()
  }
}

const checkVoucher = async (val: string) => {
  const voucherQuery = query(
    collection(db, `giftcheques`),
    where('code', '==', `${val}`),
    limit(1),
  )
  const snapshot = await getDocs(voucherQuery)

  if (snapshot.size !== 1) {
    errors = {}
    return
  }

  snapshot.forEach(v => {
    const data = v.data()
    if (data.orderId) {
      errors = {
        code: 'Code already in use. Please change to a different one',
      }
      return
    }

    errors = {}
  })
}

defineExpose({
  validator,
})
</script>
