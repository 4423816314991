<template>
  <Modal
    :visible="visible"
    max-width-class="tw-max-w-screen-sm"
    title="Add Note"
    @close="() => emit('update:visible', false)"
  >
    <div class="tw-p-6">
      <textarea
        id="addNote"
        class="tw-textarea tw-border tw-border-gray-300 tw-w-full"
        rows="4"
        placeholder="Add order note"
        :value="order.orderNote"
        @change="e => sale.setOrderNote((e.target as HTMLInputElement).value)"
        style="resize: none"
      >
      </textarea>
    </div>

    <div class="tw-px-6 tw-pb-6 tw-flex tw-justify-end">
      <button
        class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
        @click="() => emit('update:visible', false)"
      >
        Ok
      </button>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from '@/components/common/Modal.vue'
import { useSale } from '@/stores/sale/sale'
import { computed } from 'vue'
const sale = useSale()

defineProps({
  id: String,
  title: String,
  visible: Boolean,
})

const emit = defineEmits(['update:visible'])

const order = computed(() => sale.order)
</script>
