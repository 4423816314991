import firebase from './firebase'
import {
  getAuth,
  setPersistence,
  // connectAuthEmulator,
  browserLocalPersistence,
} from 'firebase/auth'

const auth = getAuth(firebase)

// if (process.env.NODE_ENV === 'development') {
//   connectAuthEmulator(auth, 'http://localhost:9099')
// }

setPersistence(auth, browserLocalPersistence)

export default auth
