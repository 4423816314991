export enum NavbarTypes {
  MultipleOptionNavbar = 'MultipleOptionNavbar',
  BackButtonOptionNavabar = 'BackButtonOptionNavabar',
  DefaultNavbar = 'DefaultNavbar',
  NoOptionNavbar = 'NoOptionNavbar',
}

export type SaleCategory = {
  _id: string
  name: string
}

export type Tax = {
  _id: string
  amount: number
  label: string
}

export type Location = {
  _id: string
  prefix: string
  outlets: string[]
  salesCategories: Array<SaleCategory>
  onlineBooking: boolean
  OrderIdSequence: number
  connectaIdMom: string
  addressFirst: string
  posAreas: string[]
  active: boolean
  pos: boolean
  revenueShare: number
  connectaIdHob: string
  connectaBranchCodeHob: string
  phone: string
  connectaBranchCodeMom: string
  addressDistrict: string
  addressCity: string
  name: string
  marketman: string
  code: string
  addressSecond: string
}

export type PaymentMethod = {
  _id: string
  active: boolean
  exclude?: boolean
  key: string
  locationAccounts: Record<string, number>
  locations: Array<string>
  name: string
}
