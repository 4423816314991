import type { Promotion } from '@/stores/promotion'
import type { ProductTypes } from '../inventory'

export enum DiscountType {
  Money = 'money',
  Percentage = 'percentage',
  FixedPrice = 'fixed-price',
}

export enum SaleProductStatus {
  queued = 'queued',
  preparing = 'preparing',
  prepared = 'prepared',
}

export type SaleDiscount = {
  type: string
  percentage: number
  amount: number
  promotion: Promotion | null
  manual: boolean
}

export type Customer = {
  userTags: Array<string>
  userName: string
  userId: string
  userPhone?: string
  userEmail?: string
  userPersona?: Array<string>
  memberGroup?: string
  onAccount?: boolean
  customerAlert?: string
  affiliateCodes: Array<string>
  userLoyalty: {
    groomPoints: number
    networkPoints: number
    stylePoints: number
  }
}

export type SaleTax = {
  label: string
  amount: number
}

export type SalesPerson = {
  staffId: string
  staffName: string
  image?: string
}

export type BrandType = {
  id: string
  name: string
}

export type ComplimentaryType = {
  drink: string
  food: string
}

export type NoComplimentaryType = {
  id?: string
  text: string
  value: number
}

export type AppliedPromotionType = {
  _id: string
  name: string
  discountByPromotion: number
  manual?: boolean
}

export type BaseSaleProduct = {
  id: string
  internalId: string
  name: string
  discount: number
  quantity: number
  type: ProductTypes

  price: number
  originalPrice: number
  priceAfterDiscount: number
  manualPrice: number

  category: string
  itemNote: string
  serviceCharge: SaleTax
  VAT: SaleTax
  salesPerson: SalesPerson

  points: number
  isPrinted: boolean
  status: SaleProductStatus
  orderTime: any
  preparationStart: any
  preparationFinish: any
  preparationTime?: number
  preparedByName?: string
  preparedById?: string

  promotion: AppliedPromotionType
  manualPromotion: boolean

  cost?: number

  // Queue specific properties
  course: number
}

export type SaleProduct = BaseSaleProduct & {
  brand: BrandType
  cost: number
  brandName?: string
}

export type FnBSaleProduct = BaseSaleProduct & {
  brand: BrandType
  storeCategory: BrandType
  storeBrand: BrandType
  categoryName: string
  cost: number
}

export type GentlemanAgreementSaleProduct = BaseSaleProduct & {
  agreementCount: number
  agreementComp: number
  service?: BaseSaleProduct
}

export type GiftChequeSaleProduct = BaseSaleProduct & {
  code: string
}

export type KingsAgreementSaleProduct = BaseSaleProduct & {
  months: number
  pricePerMonth: number
  netPricePerMonth: number
}

export type LockerBoxSaleProduct = BaseSaleProduct & {
  months: number
  lockerNumber: number
  freeGuests: number
  pricePerMonth: number
  netPricePerMonth: number
}

export type PayableByAgreeemnt = {
  payedByAgreement?: boolean
  payedByAgreementId?: string
}

export type ServiceSaleProduct = BaseSaleProduct &
  PayableByAgreeemnt & {
    complimentaries: ComplimentaryType
    complimentaryDrink?: BaseSaleProduct | NoComplimentaryType
    complimentaryFood?: BaseSaleProduct | NoComplimentaryType
    serviceCategory?: string
    service?: BaseSaleProduct
  }

export type TicketSaleProduct = BaseSaleProduct & {
  complimentaries: ComplimentaryType
  complimentaryDrink?: BaseSaleProduct | NoComplimentaryType
  complimentaryFood?: BaseSaleProduct | NoComplimentaryType
}

export type UpgradeSaleProduct = BaseSaleProduct &
  PayableByAgreeemnt & {
    complimentaries: Pick<ComplimentaryType, 'drink'>
    complimentaryDrink?: BaseSaleProduct | NoComplimentaryType
  }

export type SaleProductType =
  | BaseSaleProduct
  | SaleProduct
  | GentlemanAgreementSaleProduct
  | FnBSaleProduct
  | GiftChequeSaleProduct
  | KingsAgreementSaleProduct
  | LockerBoxSaleProduct
  | ServiceSaleProduct
  | UpgradeSaleProduct
  | TicketSaleProduct
