<template>
  <svg
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xml:space="preserve"
    v-bind="$attrs"
    @click="$emit('click', $event)"
  >
    <g>
      <path
        d="M486.4,76.8h-25.6h-51.2H102.4H51.2H25.6C11.43,76.8,0,88.25,0,102.4V128v49.62v27.18h22.85c25.5,0,49.15,17.43,53.25,42.6
          c5.22,32.08-19.4,59.8-50.5,59.8H0v27.2V384v25.6c0,14.15,11.43,25.6,25.6,25.6h25.6h51.2h307.2h51.2h25.6
          c14.15,0,25.6-11.45,25.6-25.6V384v-49.6v-27.2c0,0-11.45,0-25.6,0c-31.12,0-55.75-27.73-50.52-59.8
          c4.1-25.17,27.75-42.6,53.25-42.6H512v-27.18V128v-25.6C512,88.25,500.55,76.8,486.4,76.8z M230.4,384h-51.2v-51.2h51.2V384z
          M230.4,281.6h-51.2v-51.2h51.2V281.6z M230.4,179.2h-51.2V128h51.2V179.2z"
      />
    </g>
  </svg>
</template>
