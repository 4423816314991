import database from '@/config/firebase/realtime-database'
import { useRegister } from '@/stores/register'
import { useSales } from '@/stores/sales'
import { ref, onValue } from 'firebase/database'
import { values } from 'lodash'
import { REGISTER_OPENED } from '@/events/events'
import debug from 'debug'

const logger = debug('order-plugin')

export const Order = {
  install() {
    const fn = () => {
      logger('init')

      const register = useRegister()
      const sales = useSales()

      if (!register.location) return

      if (window.__orderListener__) {
        logger('detaching existing listerner')
        window.__orderListener__()
      }

      const ordersRef = ref(database, register.location)
      window.__orderListener__ = onValue(
        ordersRef,
        snapshot => {
          const data = snapshot.val()
          sales.setSales(values(data))
        },
        error => {
          logger('listner stopped', error)
        },
      )
    }

    window.addEventListener(REGISTER_OPENED, fn)
    window.addEventListener('orders', fn)
  },
}
