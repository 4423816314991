<template>
  <div
    v-if="shouldShow || hideTimeInControl"
    class="tw-card tw-rounded-md tw-shadow-md tw-border tw-border-[#dfdfdf] tw-h-full tw-min-h-[15rem]"
  >
    <div
      class="tw-flex tw-items-center tw-justify-between tw-bg-primary tw-rounded-t-md tw-p-2 tw-shadow-md tw-text-white tw-border-gray-400 tw-border-b-2"
    >
      <div>
        <div class="tw-font-semibold">{{ table }}</div>
        <div class="tw-text-sm">{{ sale?.userName || 'Guest' }}</div>
      </div>
      <div
        v-if="timer"
        class="tw-badge tw-badge-outline tw-rounded-md tw-font-semibold tw-p-4"
      >
        {{ timer }}
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-1 tw-gap-2">
      <div v-for="(value, index) in courses" :key="`course-${index}`">
        <div v-if="value.separator">
          <div
            v-if="index > 0"
            class="tw-relative tw-border-b tw-border-gray-300 tw-my-2"
          >
            <div
              class="tw-text-center tw-absolute tw-leading-4 -tw-top-2 tw-bg-white tw-left-[50%] tw-text-gray-300 tw-px-1 tw-text-xs tw-rounded-full"
            >
              {{ value.course }}
            </div>
          </div>
        </div>
        <div v-else class="tw-p-2">
          <div class="tw-my-1">
            <Queued
              v-if="value.status === 'queued'"
              v-bind="value"
              @start="startPreparation"
            />
            <Preparing
              v-if="value.status === 'preparing'"
              v-bind="value"
              @complete="completePreparation"
            />
            <Completed v-if="value.status === 'prepared'" v-bind="value" />
          </div>
        </div>
      </div>
    </div>

    <button
      v-if="hideTimeInControl"
      class="tw-btn tw-block tw-btn-primary tw-text-white tw-flex tw-justify-center tw-align-center tw-m-2"
      @click="() => $emit('print')"
    >
      <PrintIcon class="tw-w-4 tw-h-4" /> Print Completed Items
    </button>

    <div
      class="tw-w-full tw-bg-gray-200 tw-h-1.5 tw-rounded-b-md"
      v-if="hideTimer"
    >
      <div
        class="tw-bg-primary tw-h-1.5 tw-rounded-bl-md tw-rounded-r-md tw-transition-all tw-ease-in tw-duration-300"
        :style="{ width: `${hideTimer * 3.33}%` }"
      ></div>
    </div>

    <SalesPersonModal
      title="Select staff member"
      v-model:visible="shouldShowAssignedPerson"
      @save="onAssignedPersonSelected"
      :filter="staff => staff.type === 'bartender'"
    />
  </div>
</template>

<script lang="ts" setup>
import { forOwn, groupBy, maxBy, orderBy, some } from 'lodash'
import { usePreparationQueue } from '@/stores/queue'
import SalesPersonModal from '@/components/modal/SalesPerson.vue'
import type { SaleProduct } from '@/stores/sale/types'
import Completed from './Completed.vue'
import Preparing from './Preparing.vue'
import Queued from './Queued.vue'
import { PrintIcon } from '@/components/icons'
import { ref, reactive, computed, onMounted, watch, onBeforeUnmount } from 'vue'
import { ProductTypes } from '@/stores/inventory'

const preparationQueue = usePreparationQueue()

const props = defineProps({
  sale: {
    type: Object,
    required: true,
  },
  course: Number,
})
defineEmits(['print'])

const itemToStart = ref('')
const shouldShowAssignedPerson = ref(false)
const countDown = reactive({
  min: 0,
  sec: 0,
})

const internval = ref(0)
const hideTimer = ref(0)
const hideTimeInControl = ref<boolean>(false)

const shouldShow = computed(() => {
  return some(products.value, i => !i.preparationFinish)
})

const products = computed(() => {
  return props.sale.products.filter(
    (p: SaleProduct) =>
      (p.type === ProductTypes.FnB || p.type === ProductTypes.FnBVariant) &&
      p.isPrinted,
  )
})

const table = computed(() => {
  return props.sale.isCustomBill ? props.sale.customBill : props.sale.tableName
})

const timer = computed(() => {
  const { min, sec } = countDown
  return !min && !sec
    ? ''
    : `${String(min).padStart(2, '0')} : ${String(sec).padStart(2, '0')}`
})

const courses = computed(() => {
  const items = []
  const groups = groupBy(orderBy(products.value, 'course'), 'course') as {
    [key: string]: Array<SaleProduct>
  }
  forOwn(groups, (grouped, course) => {
    items.push({ separator: true, course }, ...grouped)
  })

  return items
})

const startPreparation = (internalId: string) => {
  itemToStart.value = internalId
  shouldShowAssignedPerson.value = true
}

const completePreparation = (internalId: string) => {
  preparationQueue.finish({
    internalId,
    order: props.sale,
  })
}

const onAssignedPersonSelected = (person: {
  staffId: string
  staffName: string
}) => {
  preparationQueue.start({
    internalId: itemToStart.value,
    order: props.sale,
    staffId: person.staffId,
    staffName: person.staffName,
  })
  itemToStart.value = ''
}

const reEvaluateCountdown = () => {
  const product = maxBy(products.value, 'preparationStart')
  if (!product) return

  const now: any = new Date()

  const maxProductTime: any = new Date(product.orderTime)
  const timeDifference = now - maxProductTime

  const secondsInADay = 60 * 60 * 1000 * 24,
    secondsInAHour = 60 * 60 * 1000

  countDown.min = Math.floor(
    (((timeDifference % secondsInADay) % secondsInAHour) / (60 * 1000)) * 1,
  )
  countDown.sec = Math.floor(
    ((((timeDifference % secondsInADay) % secondsInAHour) % (60 * 1000)) /
      1000) *
      1,
  )
}

onMounted(() => {
  internval.value = setInterval(function () {
    reEvaluateCountdown()
  }, 1000)
})

watch(shouldShow, (show, old) => {
  if (!old && show) {
    hideTimeInControl.value = false
  } else if (!show && old) {
    hideTimeInControl.value = true
    const internalInterval = setInterval(() => {
      if (hideTimer.value < 30) {
        hideTimer.value++
      } else {
        clearInterval(internalInterval)
        hideTimeInControl.value = false
      }
    }, 1000)
  }
})

onBeforeUnmount(() => {
  if (internval.value) {
    clearInterval(internval.value)
  }
})
</script>
