<template>
  <div
    class="tw-btn tw-btn-sm tw-text-white hover:tw-opacity-80"
    @click="(isDiscardModalShown = true)"
  >
    Discard Sale
  </div>

  <Teleport to="#outside-portal">
    <Modal
      v-model:visible="isDiscardModalShown"
      max-width-class="tw-max-w-screen-sm"
      title="Are you sure to discard the sale?"
      @close="(isDiscardModalShown = false)"
    >
      <div class="tw-p-6 tw-flex tw-justify-end tw-gap-4">
        <button
          class="tw-btn tw-btn-secondary tw-text-white tw-font-normal"
          @click="(isDiscardModalShown = false)"
        >
          Cancel
        </button>
        <button
          class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
          @click="discard"
        >
          Yes
          <LoadingIcon
            v-if="isDiscarding"
            class="tw-w-4 tw-h-4 tw-animate-spin dark:tw-text-gray-200 tw-fill-white tw-ml-2"
          />
        </button>
      </div>
    </Modal>
  </Teleport>
</template>

<script lang="ts" setup>
import { toast, type ToastOptions } from 'vue3-toastify'
import { useSale } from '@/stores/sale/sale'
import { LoadingIcon } from '@/components/icons'
import Modal from '@/components/common/Modal.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const sale = useSale()

const isDiscardModalShown = ref<boolean>(false)
const isDiscarding = ref<boolean>(false)

const discard = async () => {
  try {
    isDiscarding.value = true
    await sale.discard()
    router.replace({ name: 'home' })
  } catch (e) {
    console.error(e)
    toast('Failed to update the order Data!', {
      type: toast.TYPE.ERROR,
      transition: toast.TRANSITIONS.SLIDE,
      theme: toast.THEME.LIGHT,
      position: toast.POSITION.BOTTOM_LEFT,
    } as ToastOptions)
  }
}
</script>
