<template>
  <div class="tw-flex tw-flex-col tw-gap-4">
    <AssignedGuestCard
      v-if="sale.customer.userId"
      :customer="sale.customer"
      @remove="sale.removeCustomer"
      @create-alert="emit('create-alert')"
    />
    <SearchAndAssignGuest v-else @selected="setGuestToTheSale" />

    <div class="tw-flex tw-gap-4">
      <div className="tw-dropdown tw-w-full">
        <label
          tabIndex="{0}"
          className="tw-btn tw-btn-primary tw-text-white tw-font-normal tw-btn-block tw-mb-0"
          @click="(isOptionShown = true)"
        >
          {{ sale.order.amountOfGuest }} guests

          <CaretDownIcon class="tw-h-4 tw-w-4 tw-ml-1" />
        </label>
        <ul
          v-if="isOptionShown"
          tabIndex="{0}"
          className="tw-z-50 tw-dropdown-content tw-menu tw-flex-nowrap tw-shadow tw-rounded-lg tw-w-full tw-max-h-96 tw-overflow-auto tw-bg-white"
        >
          <li
            v-for="(guest, index) in 20"
            :key="index"
            @click="onSetGuestCount(guest)"
          >
            <a>{{ guest }}</a>
          </li>
        </ul>
      </div>

      <button
        v-if="!sale.customer.userId"
        class="tw-btn tw-btn-warning tw-text-white tw-font-normal"
        @click="(isModalShown = true)"
      >
        Add new guest
      </button>

      <AddNewGuest v-model:visible="isModalShown" @created="onNewGuestAdded" />
    </div>

    <div
      v-if="sale.order.orderNote"
      class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-lg tw-shadow-sm tw-bg-warning"
    >
      <WarningIcon class="tw-h-4 tw-w-4 tw-mr-2" />
      <p class="tw-mb-0 tw-whitespace-pre-line">
        {{ sale.order.orderNote }}
      </p>
    </div>

    <div
      class="tw-border tw-border-gray-300 tw-rounded-lg tw-shadow-sm tw-bg-white tw-flex tw-flex-col tw-gap-4 tw-pb-4"
      :style="{ height: sale.order.orderNote ? '62vh' : '68vh' }"
    >
      <div
        v-if="sale.products.length"
        id="ordersOpenSales"
        class="tw-overflow-y tw-overflow-x-hidden tw-flex-1"
      >
        <div
          id="ordersOpenSales"
          class="tw-overflow-y tw-overflow-x-hidden tw-flex-1"
        >
          <component
            v-for="(item, index) in sale.products"
            :is="_Component(item.type)"
            :ref="el => (el ? (itemRefs[index] = el) : null)"
            :key="item.internalId"
            :index="index"
            :item="item"
            class="my-1"
          />
        </div>
      </div>
      <p
        v-else
        class="tw-border-b tw-border-gray-300 tw-m-auto tw-pt-3 tw-text-center tw-font-bold"
      >
        Add Something to your order
      </p>

      <template v-if="sale.saleDiscount?.amount">
        <hr class="tw-border-t tw-border-gray-300 tw-border-b-none tw-my-0" />

        <div class="tw-flex tw-items-center tw-px-4 tw-gap-2 tw-font-medium">
          <p class="tw-mb-0 tw-text-sm">
            {{ sale.saleDiscount.promotion?.name || 'Special Discount' }}
          </p>
          <p class="tw-flex-1 tw-mb-0 tw-text-right tw-text-sm">
            - {{ formatNumber(sale.saleDiscount.amount) }} ₫
            <span v-if="sale.saleDiscount.type == 'percentage'">
              ({{ sale.saleDiscount.percentage }})%
            </span>
          </p>

          <TrashIcon
            class="tw-w-4 tw-h-4 tw-cursor-pointer tw-text-error"
            @click="sale.resetDiscount"
          />
        </div>
      </template>

      <div
        v-if="sale.totalPointsEarned"
        class="tw-flex tw-items-center tw-h-8 tw-px-4 tw-bg-sky-500"
      >
        <div
          class="tw-h-5 tw-w-5 tw-pl-1 tw-pt-1 tw-bg-amber-500 tw-pl-1 tw-pt-1 tw-rounded-full"
        >
          <StarFillIcon class="tw-h-3 tw-w-3 tw-text-amber-300" />
        </div>
        <div class="tw-mx-2 tw-text-sm tw-text-white">
          <span class="tw-font-bold">
            {{ sale.totalPointsEarned }}
          </span>
          Coins earned with this sale!
        </div>
      </div>

      <div class="tw-flex tw-flex-col tw-gap-4 tw-px-4 lg:tw-flex-row">
        <div class="tw-w-full">
          <button
            class="tw-btn tw-btn-sm tw-btn-secondary tw-btn-block tw-text-white tw-font-normal"
            @click="(isDiscountModalShown = true)"
          >
            <PercentageIcon class="tw-h-4 tw-w-4" />
          </button>
          <Discount v-model:visible="isDiscountModalShown" />
        </div>

        <div class="tw-w-full">
          <button
            class="tw-btn tw-btn-sm tw-btn-secondary tw-btn-block tw-text-white tw-font-normal"
            @click="(isPromotionModalShown = true)"
          >
            <GiftIcon class="tw-h-4 tw-w-4" />
          </button>
          <Promotion v-model:visible="isPromotionModalShown" />
        </div>

        <div class="tw-w-full">
          <button
            class="tw-btn tw-btn-sm tw-btn-secondary tw-btn-block tw-text-white tw-font-normal"
            @click="(isAffiliateModalShown = true)"
          >
            <CouponIcon class="tw-h-5 tw-w-7" fill="white" />
          </button>
          <Affiliate v-model:visible="isAffiliateModalShown" />
        </div>

        <div class="tw-w-full">
          <button
            class="tw-btn tw-btn-sm tw-btn-secondary tw-btn-block tw-text-white tw-font-normal tw-relative"
            @click="(isNoteModalShown = true)"
          >
            <ClipBoardIcon class="tw-h-5 tw-w-5" />
            <div
              v-if="sale.order.orderNote"
              class="tw-bg-success tw-rounded-full tw-w-4 tw-h-4 tw-absolute -tw-top-1 -tw-right-1"
            ></div>
          </button>
          <AddNote v-model:visible="isNoteModalShown" />
        </div>

        <div class="tw-w-full">
          <button
            class="tw-btn tw-btn-sm tw-btn-secondary tw-btn-block tw-text-white tw-font-normal tw-relative"
            @click="(isSplitSaleModalShown = true)"
          >
            <CutIcon class="tw-h-5 tw-w-5" />
          </button>
          <SplitSale
            v-model:visible="isSplitSaleModalShown"
            @split="onOrderSplit"
          />
        </div>
      </div>

      <p v-if="error" class="tw-text-error tw-mb-0 tw-px-4">
        {{ error }}
      </p>

      <div
        class="tw-grid tw-grid-flow-row tw-items-center lg:tw-grid-flow-col tw-gap-4 tw-px-4 tw-max-w-full"
      >
        <button
          class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
          @click="print"
        >
          <PrintIcon class="tw-h-5 tw-w-5 tw-text-white" />
        </button>
        <button
          class="tw-btn tw-btn-primary tw-text-white tw-font-normal tw-btn-block"
          @click="redirectToPayment"
        >
          Confirm Sale
        </button>

        <h1 class="tw-text-lg tw-font-bold">
          ₫
          {{
            sale.totalPayableAmount ? formatNumber(sale.totalPayableAmount) : 0
          }}
        </h1>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSale } from '@/stores/sale/sale'
import { useEvents } from '@/stores/events'
import { has } from 'lodash'
import { ref, defineProps, defineEmits } from 'vue'
import { useRouter } from 'vue-router'
import { toast } from 'vue3-toastify'
import { useRegister } from '@/stores/register'
import { useUtilities } from '@/composables/utilities'
import {
  CouponIcon,
  CaretDownIcon,
  CutIcon,
  PrintIcon,
  ClipBoardIcon,
  GiftIcon,
  PercentageIcon,
  StarFillIcon,
  WarningIcon,
  TrashIcon,
} from '../icons'
import SplitSale from '@/components/modal/SplitSale.vue'
import AddNote from '@/components/modal/AddNote.vue'
import AddNewGuest from '@/components/modal/AddNewGuest.vue'
import Discount from '@/components/modal/Discount.vue'
import Promotion from '@/components/modal/Promotion.vue'
import Affiliate from '@/components/modal/Affiliate.vue'
import AssignedGuestCard from '@/components/customer/AssignedGuestCard.vue'
import SearchAndAssignGuest from '../customer/SearchAndAssignGuest.vue'
import GentlemanAgreementCard from './GentlemanAgreementCard.vue'
import KingsAgreementCard from './KingsAgreementCard.vue'
import LockerBoxCard from './LockerBoxCard.vue'
import GiftChequeCard from './GiftChequeCard.vue'
import TicketCard from './TicketCard.vue'
import StoreCreditCard from './StoreCreditCard.vue'
import SaleItemCard from './SaleItemCard.vue'
import type { ServiceSaleProduct } from '@/stores/sale'
import { ProductTypes } from '@/stores/inventory'

const toastOptions = {
  transition: toast.TRANSITIONS.SLIDE,
  theme: toast.THEME.LIGHT,
  position: toast.POSITION.BOTTOM_LEFT,
}

defineProps({
  mode: Object,
})
const emit = defineEmits(['print', 'create-alert'])

const router = useRouter()
const sale = useSale()
const events = useEvents()
const register = useRegister()
const { formatNumber, isEmpty } = useUtilities()

const error = ref<string>('')
const isModalShown = ref<boolean>(false)
const isOptionShown = ref<boolean>(false)
const isSplitSaleModalShown = ref<boolean>(false)
const isDiscountModalShown = ref<boolean>(false)
const isPromotionModalShown = ref<boolean>(false)
const isNoteModalShown = ref<boolean>(false)
const isAffiliateModalShown = ref<boolean>(false)

const itemRefs = ref<Array<any>>([])

const _Component = (type: string) => {
  switch (type) {
    case ProductTypes.GentlemanAgreement:
    case ProductTypes.InitiateAgreement:
      return GentlemanAgreementCard
    case ProductTypes.KingsAgreement:
    case ProductTypes.EmperorsAgreement:
    case ProductTypes.NobelAgreement:
      return KingsAgreementCard
    case ProductTypes.LockerBox:
      return LockerBoxCard
    case ProductTypes.GiftCheque:
      return GiftChequeCard
    case ProductTypes.Ticket:
      return TicketCard
    case ProductTypes.StoreCredit:
      return StoreCreditCard
    default:
      return SaleItemCard
  }
}

const onOrderSplit = (orderId: string) => {
  router.replace({
    name: 'sale',
    params: { orderId },
  })
}

const redirectToPayment = async (): Promise<void> => {
  let shouldProceed = true

  itemRefs.value.forEach(ref => {
    if (has(ref, 'validator')) {
      shouldProceed = ref.validator()
    }
  })

  if (!shouldProceed) {
    return
  }

  let valid = true
  sale.products.forEach(p => {
    if (p.category === ProductTypes.Service) {
      const P = p as ServiceSaleProduct
      if (P.complimentaries) {
        if (!P?.complimentaryDrink?.id || !P?.complimentaryFood?.id) {
          valid = false
        }
      }
    }
  })

  if (!valid) {
    toast('You have to select complimentary drink and food!', {
      ...toastOptions,
      type: toast.TYPE.WARNING,
    })
    return
  }

  if (!sale.customer.userId && !isEmpty(sale.order.manualPromotion)) {
    toast('Customer should exist if you want to apply promotion', {
      ...toastOptions,
      type: toast.TYPE.WARNING,
    })
    return
  }

  if (!isEmpty(sale.order.eventItem)) {
    events.setFoodandDrink({
      location: register.location,
      _id: sale.order.eventItem._id,
      food: sale.order.eventItem.food?.toString() || '',
      drink: sale.order.eventItem.drink?.toString() || '',
    })
  }

  await sale.updateStatus('cheque-requested')

  toast('Cheque has been Requested', {
    ...toastOptions,
    type: toast.TYPE.SUCCESS,
  })

  router.push({
    name: 'payment',
    params: { orderId: sale.order.orderId },
  })
}

const print = () => {
  error.value = ''
  emit('print')
}

const onNewGuestAdded = (data: any): void => {
  setGuestToTheSale({ ...data, phone: data.phoneNumber })
}

const setGuestToTheSale = (user: any) => {
  sale.assignCustomer(user)
}

const onSetGuestCount = (count: number) => {
  isOptionShown.value = false
  sale.setGuestCount(count)

  setTimeout(() => {
    isOptionShown.value = true
  }, 300)
}
</script>
