import { has, isEmpty, round } from 'lodash'
import {
  SaleProductStatus,
  type SaleProductType,
  type BaseSaleProduct,
  type FnBSaleProduct,
  type GentlemanAgreementSaleProduct,
  type GiftChequeSaleProduct,
  type KingsAgreementSaleProduct,
  type LockerBoxSaleProduct,
  type SaleProduct,
  type ServiceSaleProduct,
} from '@/stores/sale/types'
import { serverTimestamp } from 'firebase/database'
import { determinePoints } from './common'
import { ProductTypes } from '@/stores/inventory'

export const getOriginalTotal = (product: SaleProductType): number => {
  let _product = null
  if (product.type === ProductTypes.GentlemanAgreement) {
    _product = product as GentlemanAgreementSaleProduct
    if (!_product.service) {
      return 0
    }

    const _vat = calculateVAT(_product.service, true)
    const _serviceCharge = calculateServiceCharge(_product.service, true)
    return round(
      (_product.service.price + _vat + _serviceCharge) *
        _product.agreementCount,
    )
  }

  const total =
    (product.price +
      calculateVAT(product, true) +
      calculateServiceCharge(product, true)) *
    product.quantity

  return round(total)
}

export const priceToConsider = (product: SaleProductType): number => {
  if (product.discount) {
    return product.priceAfterDiscount
  } else if (product.manualPrice) {
    return product.manualPrice
  } else {
    return product.price
  }
}

export const calculateVAT = (
  product: SaleProductType,
  original = false,
): number => {
  const serviceCharge = calculateServiceCharge(product, original)
  const _price = original ? product.price : priceToConsider(product)
  return product?.VAT?.amount
    ? ((_price + serviceCharge) / 100) * product.VAT.amount
    : 0
}

export const calculateServiceCharge = (
  product: SaleProductType,
  original = false,
): number => {
  return product?.serviceCharge?.amount
    ? ((original ? product.price : priceToConsider(product)) / 100) *
        product.serviceCharge.amount
    : 0
}

export const calculatePoints = (product: SaleProductType): number => {
  if ([ProductTypes.FnB, ProductTypes.FnBVariant].includes(product.type)) {
    const _product = product as FnBSaleProduct

    if (_product.cost) {
      return determinePoints(priceToConsider(product) - _product.cost)
    }
  }
  return product?.points || 0
}

export const getTotalPayableAmount = (
  product: SaleProductType,
  voidChargesByPercentage?: number,
): number => {
  let amount = 0,
    _vat = 0,
    _serviceCharge = 0

  let _product = null

  switch (product.category) {
    case ProductTypes.GentlemanAgreement:
      _product = product as GentlemanAgreementSaleProduct

      if (_product.service?.id) {
        _vat = calculateVAT(_product.service)
        _serviceCharge = calculateServiceCharge(_product.service)

        if (voidChargesByPercentage) {
          _vat = _vat - (_vat / 100) * voidChargesByPercentage
          _serviceCharge =
            _serviceCharge - (_serviceCharge / 100) * voidChargesByPercentage
        }
        amount =
          (_product.service.price + _vat + _serviceCharge) *
          _product.agreementCount
      }

      if (_product.discount) {
        amount = amount - (amount / 100) * product.discount
      }

      break
    case ProductTypes.KingsAgreement:
      _product = product as KingsAgreementSaleProduct
      _vat = calculateVAT(_product)
      _serviceCharge = calculateServiceCharge(_product)

      if (voidChargesByPercentage) {
        _vat = _vat - (_vat / 100) * voidChargesByPercentage
        _serviceCharge =
          _serviceCharge - (_serviceCharge / 100) * voidChargesByPercentage
      }

      amount = priceToConsider(_product) + _vat + _serviceCharge
      break
    case ProductTypes.GiftCheque:
      _product = product as GiftChequeSaleProduct
      amount = _product.price
      break
    case ProductTypes.LockerBox:
      _product = product as LockerBoxSaleProduct
      _vat = calculateVAT(_product)
      amount = priceToConsider(_product) + _vat

      if (voidChargesByPercentage) {
        _vat = _vat - (_vat / 100) * voidChargesByPercentage
        _serviceCharge =
          _serviceCharge - (_serviceCharge / 100) * voidChargesByPercentage
      }
      break
    default:
      _vat = calculateVAT(product)
      _serviceCharge = calculateServiceCharge(product)

      if (voidChargesByPercentage) {
        _vat = _vat - (_vat / 100) * voidChargesByPercentage
        _serviceCharge =
          _serviceCharge - (_serviceCharge / 100) * voidChargesByPercentage
      }
      amount =
        (priceToConsider(product) + _vat + _serviceCharge) * product.quantity
      break
  }
  return round(amount)
}

export const getSubTotalAmount = (product: SaleProductType): number => {
  let amount = 0
  let _product = null

  switch (product.type) {
    case ProductTypes.GentlemanAgreement:
      _product = product as GentlemanAgreementSaleProduct
      if (_product.service?.id) {
        amount = _product.service.price * _product.agreementCount
      }

      if (product.discount) {
        amount = amount - (amount / 100) * product.discount
      }

      break
    case ProductTypes.KingsAgreement:
      _product = product as KingsAgreementSaleProduct
      amount = priceToConsider(_product)
      break
    case ProductTypes.GiftCheque:
      _product = product as GiftChequeSaleProduct
      amount = _product.price
      break
    case ProductTypes.LockerBox:
      _product = product as LockerBoxSaleProduct
      amount = priceToConsider(_product)
      break
    default:
      amount = priceToConsider(product) * product.quantity
      break
  }
  return round(amount)
}

/**
 * @description Pre-processing function for product item in sale
 * before copying it to Firestore for consistency for document
 */
export const sanitizeProduct: any = (
  product: SaleProductType,
  additionalProperties = {},
) => {
  let _product = null
  const associatedProducts = []

  if (product.type === ProductTypes.Service) {
    _product = product as ServiceSaleProduct
    if (_product.complimentaryFood?.id) {
      const complimentaryFood = _product.complimentaryFood as BaseSaleProduct

      if (complimentaryFood.id != '1') {
        complimentaryFood.salesPerson = { ...product.salesPerson }

        associatedProducts.push(
          sanitizeProduct(
            complimentaryFood as SaleProductType,
            additionalProperties,
          ),
        )
        // TODO type problem
        _product.complimentaryFood = complimentaryFood.id
      }
    } else {
      _product.complimentaryFood = 0
    }
  }

  if ([ProductTypes.Upgrade, ProductTypes.Service].includes(product.type)) {
    _product = product as ServiceSaleProduct
    if (_product.complimentaryDrink?.id) {
      const complimentaryDrink = _product.complimentaryDrink as BaseSaleProduct

      if (complimentaryDrink.id != '1') {
        complimentaryDrink.salesPerson = { ...product.salesPerson }
        associatedProducts.push(
          sanitizeProduct(
            complimentaryDrink as SaleProductType,
            additionalProperties,
          ),
        )
        // TODO type problem
        _product.complimentaryDrink = complimentaryDrink.id
      } else {
        _product.complimentaryDrink = 0
      }
    }
  }

  if (
    [ProductTypes.Product, ProductTypes.ProductVariant].includes(product.type)
  ) {
    _product = product as SaleProduct
    // @ts-ignore
    _product.brand = _product.brandName
    _product.category = ProductTypes.Product

    delete _product.brandName
  }

  if ([ProductTypes.FnB, ProductTypes.FnBVariant].includes(product.type)) {
    _product = product as FnBSaleProduct
    _product.category = ProductTypes.FnB
    delete _product.brand
  }

  if (product.type === ProductTypes.Complimentary) {
    delete product.brand
  }

  if (
    [
      ProductTypes.FnB,
      ProductTypes.FnBVariant,
      ProductTypes.Complimentary,
      ProductTypes.ProductVariant,
      ProductTypes.Product,
    ].includes(product.category)
  ) {
    delete product.storeBrand
    delete product.storeCategory
  }

  if (product.type === ProductTypes.ProductVariant) {
    product.category = ProductTypes.Product
  }

  product.staffId = product?.salesPerson?.staffId || ''
  product.staffName = product?.salesPerson?.staffName || ''
  delete product.salesPerson

  if (has(product, 'VAT')) {
    product.vat = product.VAT
    delete product.VAT
  }

  delete product.complimentaries
  delete product.component

  if (isEmpty(associatedProducts)) {
    return { ...product, ...additionalProperties }
  } else {
    return [...associatedProducts, { ...product, ...additionalProperties }]
  }
}

export const generateSaleItem = (
  product: Partial<SaleProductType>,
): SaleProductType => {
  return {
    quantity: 1,
    itemNote: '',
    originalPrice: product.price,
    manualPrice: 0,
    discount: 0,
    priceAfterDiscount: 0,
    VAT: product.VAT || false,
    serviceCharge: product.serviceCharge || false,
    manualPromotion: false,
    promotion: {},
    isPrinted: false,
    status: SaleProductStatus.queued,
    orderTime: serverTimestamp(),
    preparationStart: 0,
    preparationFinish: 0,
    course: 0,
    preparedById: '',
    preparedByName: '',
    ...product,
  } as SaleProductType
}
