import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  signOut,
  type User,
} from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import { defineStore } from 'pinia'
import auth from '@/config/firebase/auth'
import { has, isEmpty } from 'lodash'
import database from '@/config/firebase/database'
import { reactive, computed } from 'vue'
import { type StaffUser } from './types'
import { useStaff } from '../staff'

export const useAuthentication = defineStore(
  'authentication',
  () => {
    const state = reactive<{ user: StaffUser }>({
      user: {} as StaffUser,
    })

    const isAuthenticated = computed(() => {
      return !isEmpty(state.user)
    })

    const user = computed<StaffUser | null>(() =>
      isAuthenticated.value ? state.user : null,
    )

    const setUserFromAuthentication = async (user: User) => {
      const staff = useStaff()
      const snapshot = await getDoc(doc(database, `admin/${user.uid}`))

      if (snapshot.exists()) {
        const _user = snapshot.data() as Omit<StaffUser, '_id'>

        if (!has(_user, 'roles')) {
          throw Error("You don't have role assigned")
        }

        if (!_user?.active) {
          throw Error('Your account is not activated')
        }

        const allowed = ['pos.admin', 'pos.staff']

        if (_user && allowed.some(role => _user.roles.includes(role))) {
          state.user = { _id: snapshot.id, ..._user }

          if (isEmpty(staff.active)) {
            staff.setActive({ _id: snapshot.id, ..._user })
          }
        } else {
          throw "You don't have admin or staff role to access"
        }
      }
    }

    const authenticate = async (email: string, password: string) => {
      try {
        await setPersistence(auth, browserLocalPersistence)
        const { user: __user } = await signInWithEmailAndPassword(
          auth,
          email,
          password,
        )

        await setUserFromAuthentication(__user)
      } catch (e) {
        console.error(e)
        throw 'Could not login your account, Try again or contact administrator.'
      }
    }

    const verifyPin = async (staffId: string, code: string) => {
      const snapshot = await getDoc(doc(database, `staff/${staffId}`))
      const staff: any = snapshot.data()
      return staff.pinCode?.toString() === code
    }

    const reset = () => {
      state.user = {} as StaffUser
    }

    const logout = async () => {
      await signOut(auth)
      reset()
    }

    return {
      user,
      isAuthenticated,
      authenticate,
      setUserFromAuthentication,
      verifyPin,
      logout,
      reset,
    }
  },
  {
    persist: true,
  },
)
