<template>
  <input
    type="text"
    :value="formattedValue"
    @input="onInput"
    @blur="onBlur"
    class="number-input"
    v-bind="$attrs"
  />
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'

type Props = {
  value: number | null
  min?: number | string
  max?: number | string
  allowDecimal?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['update:value', 'blur', 'input'])

const min = computed(() => (props.min !== undefined ? Number(props.min) : 0))
const max = computed(() =>
  props.max !== undefined ? Number(props.max) : Infinity,
)

const formattedValue = computed(() => value.value.toLocaleString())

const value = ref(props.value ?? 0)

watch(
  () => props.value,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      value.value = newValue ?? 0
    }
  },
)

const onBlur = (event: Event) => {
  event.stopPropagation()

  emit('update:value', value.value)
  emit('blur', event)
}

const onInput = (event: Event) => {
  event.stopPropagation()

  const input = ((event.target as HTMLInputElement).value as string).replace(
    /[,.]/g,
    '',
  )

  const regxp = props.allowDecimal ? /^\d+(\.\d+)?$/ : /^\d+$/

  if (regxp.test(input)) {
    const _value = parseFloat(input) || min.value

    if (_value < min.value) value.value = min.value
    if (_value > max.value) value.value = max.value
    if (_value <= max.value && _value >= min.value) value.value = _value

    emit('update:value', value.value)
    emit('input', value.value)
  } else {
    value.value = min.value
    emit('update:value', min.value)
    emit('input', min.value)
  }
}
</script>
