<template>
  <Modal
    max-width-class="tw-max-w-screen-sm"
    :visible="visible"
    title="Redeem Affiliate Code"
    @close="() => emit('update:visible', false)"
  >
    <div class="tw-flex tw-flex-col tw-p-6 tw-gap-4 tw-justify-center">
      <label class="tw-input tw-input-bordered tw-flex tw-items-center tw-gap-2 focus:tw-outline-none">
        <input
          type="text"
          class="tw-grow"
          placeholder="Scan or enter affiliate code"
          @change="onCodeInput(($event.target as HTMLInputElement).value)"
          @input="onManualCodeInput(($event.target as HTMLInputElement).value)"
        />
        <span v-if="searchLoading" class="tw-loading tw-loading-spinner tw-loading-sm"></span>
      </label>

      <div v-if="results.length" class="tw-max-h-[40vh] tw-overflow-y-scroll">
        <div
          v-for="(user, index) in results"
          :key="`referral-${index}`"
          class="tw-border tw-rounded-md tw-border-gray-300 tw-py-2 tw-px-4 tw-mb-1 tw-cursor-pointer hover:tw-bg-gray-50"
          @click="checkManualAffiliate(user._id)"
        >
          <div class="tw-font-bold">{{ user?.fullName || user?.email }}</div>
          <div class="tw-text-sm tw-text-gray-500">
            {{ user?.email }}
            <span v-if="user?.phone && user?.email">
              | {{ user?.phone }}</span
            >
          </div>
        </div>
      </div>
      
      <p class="tw-text-error tw-text-sm tw-mb-0" v-if="error">
        {{ error }}
      </p>
    </div>

    <div class="tw-px-6 tw-pb-6 tw-flex tw-justify-end">
      <button
        class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
        @click="onCodeInput(code)"
      >
        Check
        <LoadingIcon
          v-if="loading"
          class="tw-w-5 tw-h-5 tw-animate-spin dark:tw-text-gray-400 tw-fill-white"
        />
      </button>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { filter, find, has, map, orderBy, split } from 'lodash'
import Modal from '@/components/common/Modal.vue'
import { LoadingIcon } from '@/components/icons'
import { useSale } from '@/stores/sale/sale'
import { toast, type ToastOptions } from 'vue3-toastify'
import functions from '@/config/firebase/functions'
import { httpsCallable } from 'firebase/functions'
import { doc, getDoc } from 'firebase/firestore'
import database from '@/config/firebase/database'
import { searchClient } from '@algolia/client-search'
import { ref, reactive, computed } from 'vue'
import { usePromotion } from '@/stores'
import type { Promotion } from '@/stores/promotion'

const algolia = searchClient(
  import.meta.env.VITE_ALGOLIA_APP_ID,
  import.meta.env.VITE_ALGOLIA_APP_KEY,
)

const ToastConfig = {
  autoClose: 3000,
  type: toast.TYPE.WARNING,
  transition: toast.TRANSITIONS.SLIDE,
  theme: toast.THEME.LIGHT,
  position: toast.POSITION.BOTTOM_LEFT,
} as ToastOptions

const sale = useSale()
const promotions = usePromotion()

defineProps({
  visible: Boolean,
})

const emit = defineEmits(['update:visible'])

const code = ref('')
const loading = ref(false)
const searchLoading = ref(false)
const error = ref('')
const results = reactive<Array<any>>([])

const customer = computed(() => sale.customer)

const onManualCodeInput = async (code: string) => {
  if (code && code.length >= 6) {
    searchLoading.value = true
    const { hits = [] } = await algolia.searchSingleIndex({
      indexName: 'users',
      searchParams: {
        query: code,
      },
    })
    const _results = orderBy(
      map(hits, (o: any) => {
        const { path, ...data } = o
        const segments = split(path, '/')
        return {
          ...data,
          _id: segments[segments.length - 1],
        }
      }),
      [o => o.fullName],
      ['asc'],
    )

    results.splice(0, results.length, ...filter(_results, u => has(u, 'membership.level')))
    searchLoading.value = false
  }
}

const checkManualAffiliate = (userId: string) => {
  loading.value = true
  const checkAffiliate = httpsCallable(functions, 'CheckAffiliate')
  checkAffiliate({ uid: customer.value.userId, code: userId })
    .then(async result => {
      const data = result.data as Array<string>
      if (data.length) {
        error.value = data[0]
        toast(data[0], ToastConfig)
      } else {
        const _doc = await getDoc(
          doc(database, `users/${customer.value.userId}`),
        )
        const _customer = { ..._doc.data(), _id: _doc.id }
        sale.assignCustomer(_customer)

        setTimeout(() => {
          const promotion = find(promotions.promotions, (p: Promotion) => {
            return (
              p?.voucher && p.users && p.users.includes(customer.value.userId)
            )
          }) as Promotion

          if (promotion) {
            sale.applyPromotion(promotion)
          }
          emit('update:visible', false)
        }, 2000)
      }
    })
    .then(() => {
      loading.value = false
    })
}

const onCodeInput = (_code: string) => {
  if (_code.length !== 6) return

  code.value = _code
  loading.value = true
  const checkAffiliate = httpsCallable(functions, 'CheckAffiliate')
  checkAffiliate({ uid: customer.value.userId, code: code.value })
    .then(async result => {
      const data = result.data as Array<string>
      if (data.length) {
        error.value = data[0]
        toast(data[0], ToastConfig)
      } else {
        const _doc = await getDoc(
          doc(database, `users/${customer.value.userId}`),
        )
        const _customer = { ..._doc.data(), _id: _doc.id }
        sale.assignCustomer(_customer)

        setTimeout(() => {
          const promotion = find(promotions.promotions, (p: Promotion) => {
            return (
              p?.voucher && p.users && p.users.includes(customer.value.userId)
            )
          }) as Promotion

          if (promotion) {
            sale.applyPromotion(promotion)
          }
          emit('update:visible', false)
        }, 2000)
      }
    })
    .then(() => {
      loading.value = false
    })
}
</script>
