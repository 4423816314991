<template>
  <Modal
    :visible="visible"
    title="Split Sale"
    @close="emit('update:visible', false)"
  >
    <div class="tw-grid tw-grid-cols-2 tw-p-4">
      <div class="tw-p-2">
        <input
          type="text"
          class="tw-input tw-w-full tw-px-3 tw-border tw-border-gray-300 focus:tw-outline-none"
          placeholder="Custom Bill"
          v-model="data.customBill"
        />
      </div>

      <div class="tw-p-2">
        <select
          class="tw-select tw-select-bordered tw-w-full"
          @change="setTable(($event.target as HTMLInputElement).value)"
        >
          <option disabled selected>Select Table</option>
          <option
            v-for="table in tablesStore.tables"
            :key="`table-${table._id}`"
            :value="table._id"
            :disabled="tablesStore.occupiedTableIds.includes(table._id)"
          >
            {{ table.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="tw-flex tw-flex-col tw-py-6 tw-gap-4">
      <div class="tw-overflow-auto tw-px-6 tw-max-h-96">
        <table class="tw-table tw-table-compact tw-w-full">
          <thead>
            <tr>
              <th>Quantity</th>
              <th>Item</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(product, index) in products"
              :key="`split-sale-item-${index}`"
            >
              <td class="tw-w-1/4">
                {{ product.quantity }}
              </td>

              <td class="tw-w-1/2">
                {{ product.name }}
              </td>

              <td class="tw-w-1/6">
                <div class="tw-flex tw-rounded-lg tw-h-8">
                  <span
                    class="tw-px-3 tw-inline-flex tw-items-center tw-min-w-fit tw-rounded-s-md tw-border-e-0 tw-border tw-border-gray-200"
                    :class="{
                      'tw-bg-gray-50 tw-text-gray-700 tw-cursor-not-allowed tw-pointer-events-none': !find(splits, i => i.internalId === product.internalId)?.quantity,
                      'tw-bg-gray-700 tw-text-white tw-cursor-pointer tw-pointer-events-auto': find(splits, i => i.internalId === product.internalId)?.quantity,
                    }"
                    @click="decrement(product.internalId)"
                  >
                    <MinusIcon class="tw-w-3 tw-h-3" />
                  </span>
                  <NumberInput
                    min="1"
                    :value="find(splits, i => i.internalId === product.internalId)?.quantity || 0"
                    placeholder="Qty."
                    class="tw-py-2 tw-w-10 tw-border-y tw-border-gray-200 tw-text-center tw-focus:z-10 tw-disabled:opacity-50 tw-disabled:pointer-events-none tw-dark:bg-slate-900 tw-dark:border-gray-700 tw-dark:text-gray-400 focus:tw-outline-none hidden-number-input"
                    @input="(value: number) => setQuantity(product.internalId, value)"
                  />
                  <span
                    @click="increment(product.internalId)"
                    class="tw-px-3 tw-inline-flex tw-items-center tw-min-w-fit tw-rounded-e-md tw-border-e-0 tw-border tw-border-gray-200"
                    :class="{
                      'tw-bg-gray-50 tw-text-gray-700 tw-cursor-not-allowed tw-pointer-events-none': (find(splits, i => i.internalId === product.internalId)?.quantity || 0) >= product.quantity,
                      'tw-bg-gray-700 tw-text-white tw-cursor-pointer tw-pointer-events-auto': (find(splits, i => i.internalId === product.internalId)?.quantity || 0) < product.quantity
                    }"
                  >
                    <PlusIcon class="tw-w-3 tw-h-3" />
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="tw-px-6 tw-pb-6 tw-flex tw-justify-end">
      <button
        class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
        @click="splitSale"
      >
        Split
      </button>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { findIndex, isEmpty, find, forEach } from 'lodash'
import { PlusIcon, MinusIcon } from '@/components/icons'
import Modal from '@/components/common/Modal.vue'
import { toast } from 'vue3-toastify'
import { useSale } from '@/stores/sale/sale'
import { useTables, type ExtendedTable } from '@/stores/table'
import type { SaleProduct } from '@/stores/sale/types'
import { reactive, computed } from 'vue'
import NumberInput from '../common/NumberInput.vue'
import debug from 'debug'

const logger = debug('sale-split')

const sale = useSale()
const tablesStore = useTables()

defineProps({
  visible: Boolean,
})

const emit = defineEmits(['split', 'update:visible'])
const splits = reactive<Array<{ internalId: string; quantity: number }>>([])

const data = reactive<{
  table: ExtendedTable | null,
  customBill: string
}>({
  table: null,
  customBill: ''
})

const products = computed(() => sale.products)

const setTable = (id: string) => {
  const _table = find(tablesStore.tables, t => t._id === id)
  if (_table) {
    data.table = _table
  }
}

const increment = (internalId: string) => {
  const index = findIndex(splits, s => s.internalId === internalId)
  if (index === -1) {
    splits.push({
      internalId,
      quantity: 1,
    })
  } else {
    const item = splits[index]
    splits.splice(index, 1, {
      internalId: item.internalId,
      quantity: item.quantity + 1,
    })
  }
}

const decrement = (internalId: string) => {
  const index = findIndex(splits, s => s.internalId === internalId)
  if (index !== -1) {
    const item = splits[index]
    if (item.quantity === 1) {
      splits.splice(index, 1)
    } else {
      splits.splice(index, 1, {
        internalId: item.internalId,
        quantity: item.quantity - 1,
      })
    }
  }
}

const setQuantity = (internalId: string, quantity: number) => {
  const index = findIndex(splits, s => s.internalId === internalId)
  if (index !== -1) {
    const item = splits[index]
    if (item.quantity === 0) {
      splits.splice(index, 1)
    } else {
      splits.splice(index, 1, {
        internalId: item.internalId,
        quantity: quantity,
      })
    }
  }
}

const splitSale = async () => {
  if (!data.table && !data.customBill) {
    // Must select a table or make a custom bill
    toast.warning('Must enter custom bill or select table to split the order')
    return
  }

  const products = [] as Array<SaleProduct>
  forEach(splits, split => {
    const product = find(sale.products, p => p.internalId === split.internalId)
    if (product) {
      products.push({
        ...product,
        ...split,
      } as SaleProduct)
    }
  })

  const payload = {
    products,
    customBill: data.customBill,
    tableId: data.table?._id,
    tableName: data.table?.name,
    department: 'bar',
    salesCategory: 'bar',
  }

  try {
    const order = await sale.splitSale(payload)
    toast.info('Order has been splitted')

    setTimeout(() => {
      emit('split', order.orderId)
    }, 1500)
  } catch (e) {
    toast.error(
      `Order can not be splitted due to technical problem, Please try again`,
    )
    logger(e)
  } finally {
    emit('update:visible', false)
  }
}
</script>
