<template>
  <div
    :ref="`sale-item-${item.internalId}`"
    tabIndex="{0}"
    class="tw-collapse tw-rounded-lg tw-border-b tw-border-gray-300"
    :class="{
      'tw-collapse-open': visible,
      'tw-collapse-close': !visible,
      'tw-border tw-border-danger': !isEmpty(errors),
    }"
  >
    <div class="tw-collapse-title tw-py-2 tw-px-2">
      <ItemCardContent
        :item="item"
        :index="index"
        :details-visible="visible"
        @expand="onExpand"
        @assign="onStaffMemberChange"
      />
    </div>

    <div class="tw-collapse-content tw-flex tw-flex-col tw-gap-2">
      <div class="tw-flex tw-flex-col">
        <div class="tw-flex">
          <button
            v-if="!item.service || !item.service?.id"
            class="tw-btn tw-btn-primary"
            :class="{ 'tw-text-primary tw-bg-transparent': isActive }"
            :style="[
              isActive ? 'border: 1px dashed currentColor !important' : '',
            ]"
            @click="activate(!isActive)"
          >
            {{ isActive ? 'Cancel selection' : 'Select service' }}
          </button>

          <div
            v-else
            :key="item.service.id"
            class="tw-flex tw-items-center tw-justify-between"
          >
            <p class="tw-text-sm tw-mb-0">
              {{ item.service.name }}
            </p>
            <TrashIcon
              class="tw-w-4 tw-h-4 tw-cursor-pointer hover:tw-opacity-80"
              @click="removeService"
            />
          </div>
        </div>

        <div class="tw-flex tw-gap-2 tw-mt-2">
          <div role="group" class="tw-flex-1">
            <p class="tw-mb-0 tw-text-sm">Services</p>
            <NumberInput
              min="1"
              :value="item.agreementCount"
              class="tw-input tw-input-sm tw-border tw-border-gray-300 tw-rounded-lg tw-w-full focus:tw-outline-none"
              @input="value => update('agreementCount', value)"
            />
          </div>
          <div role="group" class="tw-flex-1">
            <p class="tw-mb-0 tw-text-sm">Comp.</p>
            <NumberInput
              :value="item.agreementComp"
              class="tw-input tw-input-sm tw-border tw-border-gray-300 tw-rounded-lg tw-w-full focus:tw-outline-none"
              @input="value => update('agreementComp', value)"
            />
          </div>
          <div role="group" class="tw-flex-1">
            <p class="tw-mb-0 tw-text-sm">Discount (%)</p>
            <NumberInput
              min="0"
              max="100"
              class="tw-input tw-input-sm tw-border tw-border-gray-300 tw-rounded-lg tw-w-full focus:tw-outline-none"
              :value="item.discount"
              @input="value => applyDiscount(value)"
            />
          </div>
        </div>
      </div>

      <div v-if="item.promotion?._id" class="tw-flex tw-justify-between">
        <p class="tw-text-sm tw-mb-0">
          <b>Promotion:</b> {{ item.promotion.name }}
        </p>
        <TrashIcon
          class="tw-w-4 tw-h-4 tw-cursor-pointer hover:tw-opacity-80"
          @click="onPromotionRemove"
        />
      </div>

      <p class="tw-text-sm" v-if="tax">
        {{ tax }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { isEmpty } from 'lodash'
import ItemCardContent from './ItemCardContent.vue'
import { TrashIcon } from '@/components/icons'
import { useSale } from '@/stores/sale/sale'
import { useComposableSaleItem } from '@/composables/sale-item'
import { computed, reactive, ref, type PropType, defineProps, watch } from 'vue'
import type { GentlemanAgreementSaleProduct } from '@/stores/sale'
import NumberInput from '../common/NumberInput.vue'

const props = defineProps({
  item: {
    type: Object as PropType<GentlemanAgreementSaleProduct>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
})

const sale = useSale()

const {
  update,
  scrollToSelf,
  applyDiscount,
  onStaffMemberChange,
  onPromotionRemove,
  tax,
} = useComposableSaleItem(props.item, props.index)

let errors = reactive<{ [property: string]: string }>({})
const visible = ref<boolean>(false)

const isActive = computed(
  () => sale.selectedAgreementId === props.item.internalId,
)

const validator = () => {
  if (!props.item.service) {
    errors = {
      service: 'Agreement requires service',
    }
    return false
  }
  errors = {}
  return true
}

const onExpand = () => {
  visible.value = !visible.value

  if (visible.value) {
    scrollToSelf()
  }
}

const removeService = async () => {
  await update('service', false)
  await update('price', 0)
}

const activate = (active: boolean) => {
  sale.activateAgreement(active ? props.item.internalId : '')
}

watch(
  () => sale.selectedAgreementId,
  () => {
    if (sale.selectedAgreementId === props.item.internalId) {
      visible.value = true
    }
  },
  {
    immediate: true,
  },
)

defineExpose({
  validator,
})
</script>
