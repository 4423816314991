import { type Timestamp } from 'firebase/firestore'

export enum EventNotification {
  Confirmation = 'confirmation',
  Cancelled = 'cancelled',
  Completed = 'completed',
  NoShow = 'no-show',
  Update = 'update',
}

export enum EventType {
  reservation = 'reservation',
  appointment = 'appointment',
}

export interface EventService {
  id: string
  name: string
  price: number
  staff?: string
  staffId?: string
  duration?: number
}

export enum LoyaltyMemberGroup {
  Gold = 'Gold Member',
  Black = 'Black Member',
  BlackDiamond = 'Black Diamond Member',
  ClubMember = 'Club Member',
  SilverMember = 'Silver Member',
}

export interface Event {
  booklyId: number
  chainId?: number

  completed?: boolean
  createdAt: Timestamp
  updatedAt: Timestamp
  createdFrom: string

  upgrades?: {
    id: string
    name: string
    price: number
  }[]

  drink?: number
  food?: number

  startDate: Timestamp
  endDate: Timestamp

  internalNote?: string
  notes?: string

  staffAny: boolean

  status?: EventStatus
  type?: string // TODO?

  userId?: string
  userName?: string
  userTags?: string[]

  connectaId?: string
  services?: EventService[]
  discountCode?: string

  comment?: string
  daysSinceLastAppointment?: number
  daysSinceLastReservation?: number
  notifyUser?: boolean
  notifyWhatsapp?: boolean

  source?: string
  medium?: string
  campaign?: string

  // computed/derived
  serviceName?: string
  staffName?: string
  reminderDate?: Timestamp

  orderId?: string
  billTotal?: number
  amountOfGuest?: number

  notifications?: EventNotification[]

  memberGroup?: LoyaltyMemberGroup

  firstVisit?: boolean
}

export enum EventStatus {
  Cancelled = 'cancelled',
  NoShow = 'no-show',
  Confirmed = 'confirmed',
  Approved = 'approved',
  ArrivedOnTime = 'arrived-on-time',
  ArrivedLate = 'arrived-late',
}
