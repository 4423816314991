<template>
  <Modal
    :visible="visible"
    title="Add new guest"
    @close="() => emit('update:visible', false)"
  >
    <div class="tw-p-6 tw-border-b tw-border-gray-300 tw-relative">
      <div v-if="creating" class="loading">
        <LoadingIcon
          class="tw-w-10 tw-h-10 tw-animate-spin dark:tw-text-gray-300 tw-fill-white"
        />
      </div>

      <div
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-4 tw-gap-y-5 tw-mb-4"
      >
        <div class="tw-w-full tw-flex tw-h-12">
          <div
            class="tw-bg-[#e9ecef] tw-text-[#495057] tw-px-3 tw-py-2 tw-rounded-l-lg tw-border tw-shrink-0 tw-flex tw-items-center"
            :class="{
              'tw-border-gray-300': !errors.firstName,
              'tw-border-error': !!errors.firstName,
            }"
          >
            First Name
          </div>
          <input
            v-model="guest.firstName"
            class="tw-input tw-w-full tw-px-3 tw-rounded-l-none tw-rounded-r-lg tw-border-l-0 tw-border-r tw-border-y focus:tw-outline-none"
            :class="{
              'tw-border-gray-300': !errors.firstName,
              'tw-border-error': !!errors.firstName,
            }"
            type="text"
            placeholder="Enter first name"
            required
          />
        </div>

        <div class="tw-w-full tw-flex tw-h-12">
          <div
            class="tw-bg-[#e9ecef] tw-text-[#495057] tw-px-3 tw-py-2 tw-rounded-l-lg tw-border tw-shrink-0 tw-flex tw-items-center"
            :class="{
              'tw-border-gray-300': !errors.lastName,
              'tw-border-error': !!errors.lastName,
            }"
          >
            Last Name
          </div>
          <input
            v-model="guest.lastName"
            class="tw-input tw-w-full tw-px-3 tw-rounded-l-none tw-rounded-r-lg tw-border-l-0 tw-border-r tw-border-y focus:tw-outline-none"
            :class="{
              'tw-border-gray-300': !errors.lastName,
              'tw-border-error': !!errors.lastName,
            }"
            type="text"
            placeholder="Enter last name"
            required
          />
        </div>

        <div class="tw-w-full tw-flex tw-h-12">
          <div
            class="tw-bg-[#e9ecef] tw-text-[#495057] tw-px-3 tw-py-2 tw-rounded-l-lg tw-border tw-shrink-0 tw-flex tw-items-center tw-border-gray-300"
          >
            Email
          </div>
          <input
            v-model="guest.email"
            class="tw-input tw-w-full tw-px-3 tw-rounded-l-none tw-rounded-r-lg tw-border-l-0 tw-border-r tw-border-y focus:tw-outline-none tw-border-gray-300"
            type="email"
            placeholder="Enter Email"
            required
          />
        </div>

        <div class="tw-w-full tw-flex tw-h-12">
          <div
            class="tw-bg-[#e9ecef] tw-text-[#495057] tw-px-3 tw-py-2 tw-rounded-l-lg tw-border tw-border-gray-300 tw-shrink-0 tw-flex tw-items-center"
          >
            Company
          </div>
          <input
            v-model="guest.company"
            class="tw-input tw-w-full tw-px-3 tw-rounded-l-none tw-rounded-r-lg tw-border-l-0 tw-border-r tw-border-y tw-border-gray-300 focus:tw-outline-none"
            type="text"
            placeholder="Enter company name"
            required
          />
        </div>

        <div class="tw-w-full tw-flex tw-flex-col">
          <div class="tw-w-full tw-flex tw-h-12">
            <div
              class="tw-bg-[#e9ecef] tw-text-[#495057] tw-px-3 tw-py-2 tw-rounded-l-lg tw-border tw-shrink-0 tw-flex tw-items-center"
            >
              Phone
            </div>
            <vue-tel-input
              v-model="guest.phone"
              mode="international"
              class="tw-w-full"
            ></vue-tel-input>
          </div>
        </div>

        <div class="tw-w-full tw-h-fit tw-flex">
          <div
            class="tw-bg-[#e9ecef] tw-text-[#495057] tw-px-3 tw-py-2 tw-shrink-0 tw-flex tw-items-center tw-rounded-l-lg tw-border tw-border-gray-300"
          >
            Nationality
          </div>
          <select
            class="tw-select tw-flex-shrink tw-w-full tw-px-3 tw-font-normal tw-text-base tw-rounded-l-none tw-rounded-r-lg tw-border-l-0 tw-border-r tw-border-y tw-border-gray-300 focus:tw-outline-none"
            v-model="guest.nationality"
          >
            <option disabled selected>Select your Nationality</option>
            <option
              v-for="nationality in nationalities"
              :key="nationality"
              :value="nationality"
            >
              {{ nationality }}
            </option>
          </select>
        </div>

        <div class="tw-w-full tw-flex tw-h-12">
          <div
            class="tw-bg-[#e9ecef] tw-text-[#495057] tw-px-3 tw-py-2 tw-rounded-l-lg tw-border tw-border-gray-300 tw-shrink-0 tw-flex tw-items-center"
          >
            Birthdate
          </div>
          <input
            v-model="guest.birthday"
            class="tw-input tw-w-full tw-px-3 tw-rounded-l-none tw-rounded-r-lg tw-border-l-0 tw-border-r tw-border-y tw-border-gray-300 focus:tw-outline-none"
            type="text"
            placeholder="type in YYYY-MM-DD format"
          />
        </div>

        <div class="tw-w-full tw-flex tw-h-12 tw-items-center">
          <div
            class="tw-tooltip"
            data-tip="The referral has been moved to affiliate"
          >
            <UserIcon class="tw-w-4 tw-h-4 tw-text-gray-400" />
          </div>
        </div>
      </div>

      <div class="tw-flex tw-justify-start tw-items-center tw-gap-6 tw-h-12">
        <div class="tw-flex tw-items-center">
          <input
            v-model="guest.walkIn"
            type="checkbox"
            class="tw-w-5 tw-h-5 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded"
          />
          <label
            for="default-checkbox"
            class="tw-ms-2 tw-font-medium tw-text-gray-900"
            >Walk-in</label
          >
        </div>

        <div class="tw-flex tw-items-center">
          <input
            v-model="guest.hotelGuest"
            type="checkbox"
            class="tw-w-5 tw-h-5 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded"
          />
          <label
            for="default-checkbox"
            class="tw-ms-2 tw-font-medium tw-text-gray-900"
            >Hotel Guest</label
          >
        </div>

        <div v-if="guest.hotelGuest">
          <input
            v-model="guest.hotelRoom"
            class="tw-input tw-px-3 tw-rounded-lg tw-border tw-border-gray-300 focus:tw-outline-none"
            type="text"
            placeholder="Enter room number"
          />
        </div>
      </div>
    </div>

    <div class="tw-p-6 tw-flex tw-justify-end tw-gap-4">
      <button
        class="tw-btn tw-btn-secondary tw-text-white tw-font-normal"
        @click="onCancel"
      >
        Cancel
      </button>
      <button
        class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
        @click="handleSubmit"
      >
        Submit
      </button>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import database from '@/config/firebase/database'
import { collection, serverTimestamp, addDoc } from 'firebase/firestore'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'
import { nationalities } from './constants'
import Modal from '@/components/common/Modal.vue'
import { LoadingIcon, UserIcon } from '@/components/icons'
import { toast, type ToastOptions } from 'vue3-toastify'
import { defineProps, defineEmits, reactive, ref } from 'vue'
import { searchClient } from '@algolia/client-search'

const algolia = searchClient(
  import.meta.env.VITE_ALGOLIA_APP_ID,
  import.meta.env.VITE_ALGOLIA_APP_KEY,
)

const toastOptions = {
  type: toast.TYPE.WARNING,
  transition: toast.TRANSITIONS.SLIDE,
  theme: toast.THEME.LIGHT,
  position: toast.POSITION.BOTTOM_LEFT,
}

const GuestDetails = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  birthday: '',
  company: '',
  nationality: null,
  walkIn: false,
  hotelGuest: false,
  hotelRoom: '',
}

defineProps({
  id: String,
  title: String,
  visible: Boolean,
})

const emit = defineEmits(['created', 'close', 'update:visible'])

const creating = ref(false)
let guest = reactive({ ...GuestDetails })

const errors = reactive({
  email: '',
  firstName: '',
  lastName: '',
})

const onCancel = () => emit('update:visible', false)

const handleSubmit = async () => {
  const _guest = { ...guest }
  let error = false

  if (!_guest.firstName) {
    errors.firstName = 'Guest first name is required'
    error = true
  }

  if (!_guest.lastName) {
    errors.lastName = 'Guest last name is required'
    error = true
  }

  if (error) {
    toast(
      'Please fill the required fields to create new guest!',
      toastOptions as ToastOptions,
    )
    return
  }

  creating.value = true

  if (_guest.email) {
    const { hits = [] } = await algolia.searchSingleIndex({
      indexName: 'users',
      searchParams: { query: _guest.email },
    })

    if (hits.length) {
      toast(
        'Guest with the same email is already exist',
        toastOptions as ToastOptions,
      )
      creating.value = false
      return
    }
  }

  if (_guest.phone) {
    const { hits = [] } = await algolia.searchSingleIndex({
      indexName: 'users',
      searchParams: { query: _guest.phone },
    })

    if (hits.length) {
      toast(
        'Guest with the same phone is already exist',
        toastOptions as ToastOptions,
      )
      creating.value = false
      return
    }
  }

  const { firstName, lastName, phone, birthday } = guest

  const phoneFormat = phone.replace(/\s/g, '').trim()

  const __guest = {
    ...guest,
    phone: phoneFormat,
    fullName: `${firstName} ${lastName}`,
    firstName,
    lastName,
    birthday: birthday ? new Date(birthday).toISOString().split('T')[0] : '',
    createdAt: serverTimestamp(),
    refferals: [],
    tags: ['new'],
  }

  const docRef = await addDoc(collection(database, 'users'), __guest)

  guest = { ...GuestDetails }
  emit('created', { _id: docRef.id, ...__guest })
  emit('update:visible', false)
  emit('close')

  creating.value = false
}
</script>
<style lang="scss" scoped>
.spinning {
  margin-left: 3px;
  margin-right: 1px;
}

*::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #f7f7f7;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 3px;
}

.loading {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
