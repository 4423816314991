import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useUI = defineStore('ui', () => {
  const showAppointmentPanel = ref(false)

  const appointmentPanelVisible = computed(() => showAppointmentPanel.value)

  const toggleAppointmentPanel = () => {
    showAppointmentPanel.value = !showAppointmentPanel.value
  }

  return {
    showAppointmentPanel,
    appointmentPanelVisible,
    toggleAppointmentPanel,
  }
})
