<template>
  <div class="tw-container tw-max-w-screen-xl tw-px-4 sm:tw-px-12">
    <Transactions
      v-model:visible="showDetails"
      :title="selectedTransaction.method"
      :movement="selectedTransaction.movement"
      :transactions="selectedTransaction.transactions"
    />

    <h2
      class="tw-text-3xl tw-text-secondary tw-font-bold tw-py-2 tw-mb-4 tw-w-max"
    >
      Close Register
    </h2>

    <div
      class="tw-max-w-full tw-overflow-auto tw-rounded-xl tw-border tw-border-gray-300 tw-shadow-md tw-mb-4"
    >
      <table class="tw-table tw-w-full">
        <thead>
          <tr>
            <th>Method</th>
            <th>Amount</th>
            <th>Counted</th>
            <th>Difference</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(record, index) in transactions"
            :key="'transactions' + index"
            :class="{ 'table-active': index % 2 }"
          >
            <td>
              {{ record.method }}
            </td>
            <td>
              {{ formatNumber(record.amount) }}
            </td>
            <td>
              <NumberInput
                v-model:value="record.cleared"
                class="tw-input tw-w-full tw-border tw-border-gray-300 tw-w-full"
              />
            </td>
            <td>
              <template v-if="record.exclude">
                {{ formatNumber(record.amount + record.cleared) }}
              </template>
              <template v-else>
                {{ formatNumber(record.amount - record.cleared) }}
              </template>
            </td>
            <td>
              <button
                class="tw-btn tw-btn-primary tw-btn-sm tw-font-normal tw-text-white"
                @click="
                  () => {
                    assign(selectedTransaction, record)
                    showDetails = true
                  }
                "
              >
                Show Details
              </button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Totals</td>
            <td>₫ {{ formatNumber(expectedTotal) }}</td>
            <td>₫ {{ formatNumber(countedTotal) }}</td>
            <td>₫ {{ formatNumber(difference) }}</td>
            <td>&nbsp;</td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="tw-flex tw-gap-4 tw-flex-col md:tw-flex-row">
      <div
        class="tw-w-full tw-card tw-shadow-md tw-bg-white tw-p-4 tw-border tw-border-gray-300"
      >
        <p class="tw-font-bold tw-mb-2">Outlet: {{ info.outlet }}</p>
        <p class="tw-font-bold tw-mb-2">
          Opening #: {{ registerStore.register.code }}
        </p>
        <p class="tw-font-bold tw-mb-2">
          Opening Time:
          {{
            new Date(
              registerStore.register.openedAt?.seconds * 1000,
            ).toLocaleString()
          }}
        </p>

        <div class="tw-flex tw-flex-col tw-w-full">
          <label class="tw-mb-2">Closing By</label>
          <select
            v-model="staffMember"
            class="tw-select tw-border tw-border-gray-300"
          >
            <option
              v-for="member in staffMembers"
              :key="member._id"
              :value="member._id"
            >
              {{ member.fullName }}
            </option>
          </select>
        </div>
      </div>

      <div
        class="tw-w-full tw-card tw-shadow-md tw-bg-white tw-p-4 tw-border tw-border-gray-300"
      >
        <textarea
          id="addNoteToEntireSale"
          name=""
          rows="4"
          placeholder="Add closure note…"
          v-model="closingNote"
          class="tw-textarea tw-border tw-border-gray-300 tw-mb-4"
          style="resize: none"
        >
        </textarea>
        <p v-if="error" class="tw-text-error">
          {{ error }}
        </p>
        <button
          v-if="loading"
          class="tw-btn tw-btn-secondary tw-btn-disabled tw-text-white tw-font-normal"
          disabled
        >
          Closing the register..
        </button>
        <button
          v-else
          class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
          @click="closeRegister"
        >
          Close Register
        </button>
      </div>
    </div>

    <VerifyPin
      :length="6"
      v-model:visible="authentication.showPinVerification"
      @verified="onAuthenticated"
      @reset="(authentication.authenticated = false)"
      :staffId="staffMember"
    />
  </div>
</template>

<script lang="ts" setup>
import Transactions from './Transactions.vue'
import { toast } from 'vue3-toastify'
import { useRegister } from '@/stores/register'
import { useTransactions, type SummaryTransaction } from '@/stores/transactions'
import { useStaff } from '@/stores/staff'
import { NavbarTypes, useApp } from '@/stores/app'
import VerifyPin from '@/components/modal/VerifyPin.vue'
import { ref, reactive, computed, watch, onBeforeMount, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { formatNumber } from '@/utilities/common'
import { assign, filter } from 'lodash'
import type { StaffUser } from '@/stores/authentication'
import NumberInput from '@/components/common/NumberInput.vue'

const toastOptions = {
  transition: toast.TRANSITIONS.SLIDE,
  theme: toast.THEME.LIGHT,
  position: toast.POSITION.BOTTOM_LEFT,
}

const router = useRouter()

const registerStore = useRegister()
const transactionStore = useTransactions()
const staffStore = useStaff()
const appStore = useApp()

const authentication = reactive({
  authenticated: false,
  showPinVerification: false,
})

const loading = ref<boolean>(false)
const showDetails = ref<boolean>(false)
const closingNote = ref<string>('')
const error = ref<string>('')
const selectedTransaction = reactive<SummaryTransaction>(
  {} as SummaryTransaction,
)
const staffMember = ref<string>('')
const staffMembers = reactive<Array<StaffUser>>([])
const transactions = reactive<Array<SummaryTransaction>>([])

const info = computed(() => registerStore.info)
const summary = computed(() => transactionStore.summary)

const expectedTotal = computed(() => {
  return transactions.reduce((acc, current) => acc + current.amount, 0)
})

const countedTotal = computed(() => {
  return transactions.reduce((acc, t) => acc + t.cleared, 0)
})

const difference = computed(() => {
  return transactions.reduce((acc, current) => {
    return acc + (current.exclude ? current.cleared + current.amount : current.cleared - current.amount)
  }, 0)
})

const onAuthenticated = () => {
  authentication.authenticated = true
  closeRegister()
}

const closeRegister = async () => {
  error.value = ''

  if (!staffMember.value) {
    error.value = 'Please select closing staff member'
    loading.value = false
    return
  }

  if (!authentication.authenticated) {
    authentication.showPinVerification = true
    return
  }
  loading.value = true

  const isInvalidClearedValue = transactions.reduce((acc, current) => {
    return acc && (!current.cleared || isNaN(current.cleared))
  }, false)

  if (isInvalidClearedValue) {
    error.value = 'counted value should be valid'
    loading.value = false
    return
  }

  const staff: StaffUser = staffMembers.find(
    m => m._id === staffMember.value,
  ) as StaffUser

  try {
    const payload = {
      difference: difference.value,
      closingNote: closingNote.value,
      transactions: transactions,
      closedByUserId: staff._id,
      closedByUserName: staff.fullName,
    }
    await registerStore.close(payload)
    router.replace({ name: 'register.open' })
  } catch (e: any) {
    toast(e.message, {
      ...toastOptions,
      type: toast.TYPE.ERROR,
    })
  } finally {
    loading.value = false
  }
}

onBeforeMount(async () => {
  transactionStore.fetchLedgerEntries()
  let _staffMembers = await staffStore.fetchAllStaffMembers()
  _staffMembers = filter(_staffMembers, s => s.active)
  staffMembers.splice(0, staffMembers.length, ..._staffMembers)
})

onMounted(() => {
  appStore.setNavbarOption(NavbarTypes.BackButtonOptionNavabar)
})

watch(
  summary,
  newSummary => {
    transactions.splice(0, transactions.length, ...newSummary)
  },
  { deep: true, immediate: true },
)
</script>

<style lang="scss" scoped>
table table td,
table table th {
  @apply tw-bg-transparent;
}

table tr.table-active table td,
table tr.table-active table th {
  @apply tw-border-secondary;
}

tr.table-active > td {
  @apply tw-bg-gray-200;
}

tfoot td,
thead th {
  @apply tw-normal-case tw-text-base;
}

.tw-table th:first-child {
  @apply tw-relative;
}
</style>
