<template>
  <svg
    v-bind="$attrs"
    viewBox="0 0 24 24"
    fill="currentColor"
    @click="$emit('click')"
  >
    <path d="M0 10h24v4h-24z" />
  </svg>
</template>
