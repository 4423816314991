<template>
  <div class="tw-dropdown tw-dropdown-bottom">
    <label
      tabindex="0"
      class="tw-flex tw-items-center tw-gap-2 tw-text-white tw-rounded-lg tw-cursor-pointer tw-px-2 tw-py-1 tw-transition tw-duration-100 tw-mb-0 hover:tw-bg-black hover:tw-bg-opacity-10"
    >
      <CaretDownIcon class="tw-w-4 tw-h-4" />
      <p v-if="sale.order.customBill || sale.order.tableName" class="tw-mb-0">
        {{ sale.order.customBill || sale.order.tableName }}
      </p>
      <p v-else class="tw-mb-0 lg:tw-hidden">Select Table</p>
    </label>

    <ul
      tabindex="0"
      class="tw-dropdown-content tw-menu tw-p-2 tw-shadow tw-bg-white tw-rounded-lg tw-w-max"
    >
      <li class="tw-py-2 tw-px-4">
        {{
          sale.order.customBill
            ? 'CustomBill: ' + sale.order.customBill
            : 'SelectedTable: ' + sale.order.tableName
        }}
      </li>
      <li class="tw-text-sm tw-text-gray-500 tw-px-4">Unoccupied</li>
      <li
        v-for="(_table, index) in orderBy(tables.unoccupied, 'name', 'asc')"
        :key="index"
        @click="() => setTable(_table)"
      >
        <a>
          {{ _table.name }}
        </a>
      </li>
      <li class="tw-text-sm tw-text-gray-500 tw-px-4">Occupied</li>
      <li
        v-for="(table, index) in orderBy(tables.occupied, 'name', 'asc')"
        :key="index"
        class="tw-pointer-events-none tw-text-gray-400"
      >
        <a>
          {{ table.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { orderBy, some } from 'lodash'
import { useSale } from '@/stores/sale/sale'
import { CaretDownIcon } from '@/components/icons'
import { useTables } from '@/stores'
import type { ExtendedTable } from '@/stores/table'

const sale = useSale()
const tables = useTables()

const setTable = (table: ExtendedTable) => {
  tables.setTable({
    tableId: table._id,
    tableName: table.name,
    customBill: '',
  })
}
</script>
