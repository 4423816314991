import './assets/style.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import core from '@/plugins'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import * as Sentry from '@sentry/vue'

import App from './App.vue'
import router from './router'

const app = createApp(App)

const isSentryDev = import.meta.env.VITE_SENTRY_ENVIRONMENT === 'development'

Sentry.init({
  app,
  dsn: import.meta.env.VITE_POS_SENTRY_DSN,
  environment: import.meta.env.VITE_POS_SENTRY_ENVIRONMENT,
  enabled: !isSentryDev,
  release: import.meta.env.VITE_POS_APP_VERSION,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
  replaysOnErrorSampleRate: isSentryDev ? 0 : 1,
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
app.use(router)
app.use(core)

app.mount('#app')
