<template>
  <div
    v-if="!props.image && props.name"
    v-bind="$attrs"
    class="tw-rounded-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
    :class="{
      'tw-bg-white tw-text-primary': !props.variant,
      'tw-bg-primary tw-text-white': props.variant === 'primary',
      'tw-bg-success tw-text-white': props.variant === 'success',
      'tw-h-8 tw-w-8': !props.size,
      'tw-h-10 tw-w-10': props.size === 'lg',
    }"
  >
    {{ getInitials(props.name) }}
  </div>
  <img
    v-else
    v-bind="$attrs"
    class="tw-rounded-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
    :class="{
      'tw-h-8 tw-w-8': !props.size,
      'tw-h-10 tw-w-10': props.size === 'lg',
    }"
    :src="props.image"
    :alt="props.name"
  />
</template>

<script lang="ts" setup>
import { defineProps } from 'vue'
import { getInitials } from '@/utilities/common'

const props = defineProps({
  name: {
    type: String,
  },
  variant: {
    type: String,
  },
  image: {
    type: String,
  },
  size: {
    type: String,
  },
})
</script>
