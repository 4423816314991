<template>
  <div class="wrapper tw-relative">
    <QueuedPrint ref="print" />
    <div
      v-if="isQueueEmpty"
      class="tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-center"
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-text-gray-400">
        <div>
          <EmptyIcon class="tw-w-20 tw-h-20" />
        </div>
        <div class="tw-text-lg tw-font-bold tw-mt-2">
          No Items in Queue at the moment
        </div>
        <div class="tw-text-xs tw-text-center">
          Queued items to prepare will be shown here as soon as it arrives,<br />
          I'm watching it out for you!
        </div>
      </div>
    </div>
    <div
      class="tw-p-4 tw-pb-0 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4"
    >
      <QueuedSale
        v-for="(order, index) in queuedOrders"
        :key="`queued-sale-${index}`"
        :sale="order"
        :course="order.course"
        @print="print(order)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import QueuedSale from './components/QueuedSale.vue'
import { usePreparationQueue } from '@/stores/queue'
import EmptyIcon from '@/components/icons/EmptyIcon.vue'
import QueuedPrint from '@/components/common/QueuedPrint.vue'
import { computed, onMounted, useTemplateRef } from 'vue'
const preparationQueue = usePreparationQueue()
const printRef = useTemplateRef('print')

const queuedOrders = computed(() => preparationQueue.queuedOrders)
const isQueueEmpty = computed(() => {
  return !queuedOrders.value.length
})

onMounted(() => {
  window.dispatchEvent(new Event('orders'))
})

const print = (order: any) => {
  printRef.value?.print(order)
}
</script>

<style lang="scss">
.wrapper {
  height: calc(100vh - 56px);
  overflow-y: scroll;
}

.wrapper::-webkit-scrollbar {
  width: 1em;
}

.wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
</style>
