<template>
  <div class="tw-container tw-max-w-screen-xl tw-px-4 sm:tw-px-12">
    <h2
      class="tw-text-3xl tw-text-secondary tw-font-bold tw-py-2 tw-mb-4 tw-w-max"
    >
      Cash Management
    </h2>

    <div class="tw-grid tw-grid-cols-1 tw-gap-4 tw-mb-4 md:tw-grid-cols-3">
      <div
        className="tw-card tw-p-6 tw-bg-white tw-shadow-md tw-border tw-border-[#e6e9ec]"
      >
        <div class="tw-font-bold tw-mb-1">Amount</div>
        <NumberInput
          v-model:value="amount"
          placeholder="Enter amount"
          class="tw-input tw-w-full tw-border tw-border-gray-300 tw-mb-4"
        />
        <div class="tw-font-bold tw-mb-1">Added/Removed By Staff</div>
        <select
          class="tw-select tw-flex-shrink tw-w-full tw-px-3 tw-font-normal tw-text-base tw-rounded-lg tw-border tw-border-gray-300 focus:tw-outline-none"
          @change="onStaffSelected"
        >
          <option
            v-for="s in staff"
            :key="s._id"
            :value="s._id"
            :selected="s._id === staffId"
          >
            {{ s.fullName }}
          </option>
        </select>
      </div>
      <div
        className="tw-card tw-p-6 tw-bg-white tw-shadow-md tw-border tw-border-[#e6e9ec]"
      >
        <p class="tw-font-bold">Notes</p>
        <textarea
          rows="4"
          placeholder="Type to add a cash movement note"
          v-model="notes"
          class="tw-textarea tw-border tw-border-gray-300"
          style="resize: none"
        >
        </textarea>
      </div>
      <div
        className="tw-card tw-p-6 tw-bg-white tw-shadow-md tw-border tw-border-[#e6e9ec] tw-flex tw-flex-col tw-justify-center tw-w-full"
      >
        <button
          class="tw-btn tw-btn-warning tw-text-white tw-mb-2"
          @click="cashManagement('add')"
        >
          Add Cash
        </button>

        <button
          class="tw-btn tw-btn-primary tw-text-white"
          @click="cashManagement('remove')"
        >
          Remove Cash
        </button>
      </div>
    </div>

    <div
      class="tw-max-w-full tw-overflow-auto tw-rounded-xl tw-border tw-border-gray-200 tw-shadow-md"
    >
      <table class="tw-table tw-w-full tw-shadow-md tw-rounded-xl">
        <thead>
          <tr>
            <th v-for="(field, index) in fields" :key="index">
              {{ typeof field === 'object' ? field.label : field }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="tw-border-gray-300"
            v-for="(entry, index) in registerStore.cashMovements"
            :key="index"
          >
            <td v-for="(field, index) in fields" :key="index">
              {{
                typeof field === 'object'
                  ? field.formatter
                    ? field.formatter(entry[field.key])
                    : entry[field.key]
                  : entry[field]
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <VerifyPin
      :length="6"
      v-model:visible="authentication.showPinVerification"
      @verified="onAuthenticated"
      @reset="(authentication.authenticated = false)"
      :staffId="staffId"
    />
  </div>
</template>

<script lang="ts" setup>
import { toast } from 'vue3-toastify'
import VerifyPin from '@/components/modal/VerifyPin.vue'
import { find } from 'lodash'
import {
  PaymentMethods,
  useRegister,
  type CashLedgerEntry,
} from '@/stores/register'
import NumberInput from '@/components/common/NumberInput.vue'
import { useStaff } from '@/stores/staff'
import { ref, reactive, computed, onBeforeMount, onMounted } from 'vue'
import { formatNumber } from '@/utilities/common'
import debug from 'debug'
import type { Timestamp } from 'firebase/firestore'
import { useApp } from '@/stores'
import { NavbarTypes } from '@/stores/app'

const logger = debug('component:cash-management')

const toastOptions = {
  transition: toast.TRANSITIONS.SLIDE,
  theme: toast.THEME.LIGHT,
  position: toast.POSITION.BOTTOM_LEFT,
}

const registerStore = useRegister()
const staffStore = useStaff()
const app = useApp()

const amount = ref<number>(0)
const reason = ref<string>('')
const notes = ref<string>('')
const staffId = ref<string>('')
const staffName = ref<string>('')
const cashEntryType = ref<string>('')

const fields = reactive<
  Array<{
    key: keyof CashLedgerEntry
    label: string
    formatter?: (arg: any) => string
  }>
>([
  { key: 'staffName', label: 'Staff' },
  { key: 'reason', label: 'Reason' },
  {
    key: 'amount',
    label: 'Amount',
    formatter: (value: number) => `₫ ${formatNumber(value)}`,
  },
  {
    key: 'date',
    label: 'Date',
    formatter: (value: Timestamp) => {
      const time = new Date(value.seconds).toLocaleTimeString()
      const date = new Date(value.seconds * 1000).toDateString()

      return `${date} ${time}`
    },
  },
])

const authentication = reactive({
  authenticated: false,
  showPinVerification: false,
})

const registerId = computed(() => registerStore.registerId)
const staff = computed(() => staffStore.staff)

const onStaffSelected = ($e: Event) => {
  const _value = ($e.target as HTMLSelectElement).value
  const staffMember = find(staff.value, m => m._id === _value)
  if (staffMember) {
    staffId.value = staffMember._id
    staffName.value = staffMember.fullName
  }
}

const onAuthenticated = () => {
  authentication.authenticated = true
  cashManagement(cashEntryType.value)
}

const cashManagement = (type: string) => {
  if (!amount.value || !notes.value) {
    toast(
      'Amount & Cash movement notes required to add or remove cash, Please fill all the fields!',
      {
        ...toastOptions,
        type: toast.TYPE.WARNING,
      },
    )
    return
  }

  cashEntryType.value = type

  if (!authentication.authenticated) {
    authentication.showPinVerification = true
    return
  }

  const data = {
    type: type,
    amount: type === 'add' ? amount.value : -amount.value,
    reason: notes.value,
    [type === 'add' ? 'credit' : 'debit']: amount.value,
    staffId: staffId.value,
    staffName: staffName.value,
  }

  registerStore
    .createCashEntry({
      ...data,
      registerId: registerId.value,
      registerName: registerStore.location,
      method: PaymentMethods.internalCashManagement,
    })
    .then(() => {
      toast('Cash entry has been added in register.', {
        ...toastOptions,
        type: toast.TYPE.SUCCESS,
      })
    })
    .catch(e => {
      logger(e)
      toast('Cash entry creation is failed, Please try again.', {
        ...toastOptions,
        type: toast.TYPE.ERROR,
      })
    })
    .then(() => {
      amount.value = 0
      reason.value = ''
    })
}

onBeforeMount(() => {
  registerStore.hydrateCashEntries()
})

onMounted(() => {
  app.setNavbarOption(NavbarTypes.BackButtonOptionNavabar)
})
</script>

<style lang="scss" scoped>
.equal-height {
  height: 100%;
}
</style>
