<template>
  <Modal
    :visible="visible"
    title="Apply Promotion"
    @close="() => emit('update:visible', false)"
  >
    <div class="tw-flex tw-flex-col tw-p-6 tw-gap-4">
      <div
        v-for="(promotion, index) in order.promotion"
        class="tw-w-100 tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-lg tw-flex tw-items-center"
        :key="`applied-promotion-${index}`"
      >
        <p class="tw-mb-0">{{ promotion.name }}</p>

        <CheckCircleFillIcon
          class="tw-ml-auto tw-text-success tw-h-5 tw-w-5 tw-mr-2"
        />

        <TrashIcon
          class="tw-text-error tw-h-5 tw-w-5 tw-cursor-pointer hover:tw-opacity-80"
          @click="sale.removePromotion(promotion)"
        />
      </div>

      <input
        type="search"
        class="tw-input tw-w-full tw-px-3 tw-border tw-border-gray-300 focus:tw-outline-none"
        placeholder="Search Promotion"
        @input="onSearch"
      />

      <div v-if="results.length" class="tw-w-full tw-flex tw-flex-col">
        <div
          v-for="promotion in results"
          class="tw-flex tw-items-center tw-justify-between tw-border-x tw-border-b tw-border-gray-300 tw-px-4 tw-py-2 first:tw-rounded-t-lg first:tw-border-t last:tw-rounded-b-lg"
          :key="promotion.name"
          @click="onSearchItemClick(promotion)"
        >
          <p class="tw-mb-0">{{ promotion.name }}</p>
          <p class="tw-mb-0">
            {{ promotion.discountValue }}
            {{ promotion.discountType === 'percentage' ? '%' : '₫' }}
          </p>

          <div class="tw-flex tw-gap-4">
            <button
              v-if="appliedPromotionIds.includes(promotion._id)"
              class="tw-btn tw-btn-error tw-text-white tw-font-normal"
              @click="sale.removePromotion(promotion)"
            >
              Remove
            </button>
            <button
              v-else
              class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
              @click="() => sale.applyPromotion(promotion)"
            >
              Apply
            </button>
          </div>
        </div>
      </div>

      <div class="tw-overflow-x-auto tw-max-h-[40vh]">
        <table class="tw-table tw-table-compact tw-w-full">
          <thead>
            <tr>
              <th>Name</th>
              <th>Discount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(promotion, index) in manualPromotions" :key="index">
              <td>
                <p class="tw-mb-0">
                  {{ promotion.name }}
                </p>

                <div
                  v-if="promotion.type === PromotionTypes.Sale"
                  className="tw-badge tw-badge-success"
                >
                  Sale Promotion
                </div>
              </td>

              <td>
                <p class="tw-mb-0">
                  {{ promotion.discountValue }}
                  {{ promotion.discountType === 'percentage' ? '%' : '₫' }}
                </p>
              </td>

              <td>
                <button
                  v-if="appliedPromotionIds.includes(promotion._id)"
                  class="tw-btn tw-btn-sm tw-btn-error tw-font-normal tw-text-white"
                  @click="sale.removePromotion(promotion)"
                >
                  Remove
                </button>
                <button
                  v-else
                  class="tw-btn tw-btn-sm tw-btn-primary tw-font-normal tw-text-white"
                  @click="() => sale.applyPromotion(promotion)"
                >
                  Apply
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="tw-px-6 tw-pb-6 tw-flex tw-justify-end">
      <button
        class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
        @click="() => emit('update:visible', false)"
      >
        Ok
      </button>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { debounce, map, orderBy } from 'lodash'
import Modal from '@/components/common/Modal.vue'
import { useSale } from '@/stores/sale/sale'
import { usePromotion } from '@/stores/promotion/promotion'
import { TrashIcon, CheckCircleFillIcon } from '../icons'
import { reactive, computed, ref } from 'vue'
import { PromotionTypes, type Promotion } from '@/stores/promotion/types'

defineProps({
  visible: Boolean,
})
const emit = defineEmits(['selected', 'update:visible'])

const sale = useSale()
const promotion = usePromotion()

const search = ref<string>('')
const results = reactive<Array<Promotion>>([])

const manualPromotions = computed(() => orderBy(promotion.manualPromotions, ['name'], ['asc']))
const order = computed(() => sale.order)
const appliedPromotionIds = computed(() => map(sale.order.promotion, '_id'))

const onSearchItemClick = (promotion: Promotion) => {
  sale.applyPromotion(promotion)
  search.value = ''
  results.splice(0, results.length)
}

const onSearch = debounce(async (event) => {
  const search = event.target.value
  const _results = await promotion.searchPromotion(search)
  results.splice(0, results.length, ..._results)
}, 200)
</script>
