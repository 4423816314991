<template>
  <div class="lg:tw-w-1/2 tw-text-left tw-m-auto">
    <h3
      class="tw-font-bold tw-text-gray-600 tw-border-b tw-border-primary tw-pb-2 tw-mb-6 tw-w-max"
    >
      Open Register
    </h3>
    <div
      class="tw-border tw-border-gray-300 tw-rounded-lg tw-shadow-lg tw-p-4 tw-bg-white tw-flex tw-flex-col tw-gap-4"
    >
      <div class="tw-flex tw-flex-col">
        <p
          class="tw-font-bold tw-underline tw-decoration-primary tw-underline-offset-8 tw-mb-4"
        >
          Opening Float
        </p>
        <input
          v-model.number="openingFloat"
          type="number"
          pattern="[0-9]*"
          class="tw-input tw-w-full tw-px-3 tw-border tw-border-gray-300 focus:tw-outline-none"
          placeholder="Enter Amount"
        />
      </div>

      <div class="tw-flex tw-flex-col">
        <label class="tw-mb-2">Opened By</label>
        <select v-model="staff" class="tw-select tw-border tw-border-gray-300">
          <option
            v-for="member in staffMembers"
            :key="member._id"
            :value="member._id"
          >
            {{ member.fullName }}
          </option>
        </select>
      </div>

      <div class="tw-flex tw-flex-col">
        <p
          class="tw-font-bold tw-underline tw-decoration-primary tw-underline-offset-8 tw-mb-4"
        >
          Note
        </p>
        <textarea
          id="addNote"
          class="tw-textarea tw-border tw-border-gray-300 tw-w-full"
          rows="4"
          placeholder="Add register opening note"
          v-model="openingNote"
          style="resize: none"
        >
        </textarea>
      </div>

      <button
        class="tw-w-full tw-bg-primary tw-px-4 tw-py-2 tw-text-white tw-rounded-md tw-flex tw-items-center tw-justify-center disabled:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-pointer-events-none"
        :disabled="disabled"
        @click="OpenRegister"
      >
        Open Register
      </button>

      <div class="tw-flex tw-flex-col">
        <div class="tw-flex tw-gap-4">
          <p class="tw-font-bold tw-mb-0">Outlet:</p>
          <p class="tw-text-gray-500 tw-mb-0">{{ outlet }}</p>
        </div>

        <div class="tw-flex tw-gap-4">
          <p class="tw-font-bold tw-mb-0">Opening #:</p>
          <p class="tw-text-gray-500 tw-mb-0">{{ code }}</p>
        </div>

        <div class="tw-flex tw-gap-4">
          <p class="tw-font-bold tw-mb-0">Opening Time:</p>
          <p class="tw-text-gray-500 tw-mb-0">
            {{ new Date().toLocaleDateString() }}
          </p>
        </div>
      </div>
    </div>

    <VerifyPin
      :length="6"
      v-model:visible="authentication.showPinVerification"
      @verified="onAuthenticated"
      @reset="(authentication.authenticated = false)"
      :staffId="staff"
    />
  </div>
</template>

<script lang="ts" setup>
import { toast } from 'vue3-toastify'
import { find } from 'lodash'
import { useRegister } from '@/stores/register'
import { type StaffUser } from '@/stores/authentication'
import { useStaff } from '@/stores/staff'
import { NavbarTypes, useApp } from '@/stores/app'
import VerifyPin from '@/components/modal/VerifyPin.vue'
import { REGISTER_OPENED } from '@/events/events'
import { ref, reactive, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import debug from 'debug'

const logger = debug('register-component')

const router = useRouter()

const registerStore = useRegister()
const staffStore = useStaff()
const appStore = useApp()

const openingFloat = ref(0)
const loading = ref(false)
const openingNote = ref('')
const code = ref('')
const staff = ref('')
let staffMembers = reactive<Array<StaffUser>>([])
const authentication = reactive({
  authenticated: false,
  showPinVerification: false,
})

const outlet = computed(() => registerStore.outlet)
const active = computed(() => staffStore.active)
const disabled = computed<boolean>(() => {
  return !staff.value
})

const onAuthenticated = () => {
  authentication.authenticated = true
  OpenRegister()
}

const OpenRegister = async () => {
  if (loading.value) return

  try {
    loading.value = true
    const _staff = find(staffMembers, m => m._id === staff.value)

    if (!authentication.authenticated) {
      authentication.showPinVerification = true
      return
    }

    if (_staff) {
      staffStore.setActive(_staff)
    }

    openingFloat.value ??= 0
    const cashManagement = openingFloat.value
      ? [
          {
            staffId: _staff?._id,
            staffName: _staff?.fullName,
            amount: openingFloat.value,
            [openingFloat.value > 0 ? 'credit' : 'debit']: openingFloat.value,
            reason: 'Opening Float',
            type: 'add',
          },
        ]
      : []

    await registerStore.create({
      code: code.value,
      openingNote: openingNote.value,
      cashManagement,
    })

    window.dispatchEvent(new Event(REGISTER_OPENED))
    router.push({ name: 'home' })
  } catch (e) {
    logger(e)

    toast('Failed to Create new Register, please try again', {
      type: toast.TYPE.ERROR,
      transition: toast.TRANSITIONS.SLIDE,
      theme: toast.THEME.LIGHT,
      position: toast.POSITION.BOTTOM_LEFT,
    })
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  appStore.setNavbarOption(NavbarTypes.NoOptionNavbar)
  staff.value = active.value._id
  staffMembers = await staffStore.fetchAllStaffMembers()

  code.value = await registerStore.getLatestRegisterCode()
})
</script>
