<template>
  <Modal
    :visible="visible"
    max-width-class="tw-max-w-screen-sm"
    title="Redeem Loyalty Credits"
    @close="() => emit('update:visible', false)"
  >
    <div class="tw-flex tw-flex-col tw-p-6 tw-gap-4 tw-justify-center">
      <div v-if="claimableGroomingPoints">
        <label
          class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900"
        >
          Grooming Credit
        </label>
        <input
          type="number"
          :max="claimableGroomingPoints"
          v-model.number="grooming"
          placeholder="Enter grooming credit to redeem"
          class="tw-border tw-border-gray-300 tw-text-gray-900 tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5"
        />
      </div>

      <div v-if="claimableNetworkingPoints">
        <label
          class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900"
        >
          Networking Credit
        </label>
        <input
          type="number"
          :max="claimableNetworkingPoints"
          v-model.number="networking"
          placeholder="Enter networking credit to redeem"
          class="tw-border tw-border-gray-300 tw-text-gray-900 tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5"
        />
      </div>

      <div v-if="claimableStylingPoints">
        <label
          class="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900"
        >
          Styling Credit
        </label>
        <input
          type="number"
          :max="claimableStylingPoints"
          v-model.number="styling"
          placeholder="Enter styling credit to redeem"
          class="tw-border tw-border-gray-300 tw-text-gray-900 tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5"
        />
      </div>
    </div>

    <div class="tw-px-6 tw-pb-6 tw-flex tw-justify-end">
      <button
        class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
        @click="apply"
      >
        Apply
      </button>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from '@/components/common/Modal.vue'
import { useLoyalty } from '@/stores'
import {
  ROYALTY_POINTS_GROOMING,
  ROYALTY_POINTS_NETWORKING,
  ROYALTY_POINTS_STYLING,
} from '@/utilities/constants'

import { ref, computed, onMounted, watch } from 'vue'

const loyalty = useLoyalty()

const props = defineProps({
  visible: Boolean,
})

const emit = defineEmits(['apply', 'update:visible'])

const grooming = ref(0)
const networking = ref(0)
const styling = ref(0)

const claimableNetworkingPoints = computed(
  () => loyalty.claimableNetworkingPoints,
)
const claimableGroomingPoints = computed(() => loyalty.claimableGroomingPoints)
const claimableStylingPoints = computed(() => loyalty.claimableStylingPoints)

const apply = () => {
  const applicable = []

  if (grooming.value)
    applicable.push({ method: ROYALTY_POINTS_GROOMING, amount: grooming.value })
  if (networking.value)
    applicable.push({
      method: ROYALTY_POINTS_NETWORKING,
      amount: networking.value,
    })
  if (styling.value)
    applicable.push({ method: ROYALTY_POINTS_STYLING, amount: styling.value })

  emit('apply', applicable)
  emit('update:visible', false)
}

const reset = () => {
  grooming.value = claimableGroomingPoints.value
  networking.value = claimableNetworkingPoints.value
  styling.value = claimableStylingPoints.value
}

onMounted(() => {
  reset()
})

watch(
  () => props.visible,
  () => {
    reset()
  },
)
</script>
