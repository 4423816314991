<template>
  <div class="tw-container tw-max-w-screen-xl tw-pt-10 tw-px-4 sm:tw-px-12">
    <div
      class="tw-grid tw-grid-cols-1 md:tw-grid-cols-5 tw-gap-4 tw-justify-between"
    >
      <CustomerAlert
        :alert="saleStore.customer.customerAlert"
        :visible="showAlertView"
      />
      <div class="md:tw-col-span-3">
        <product-search class="tw-mb-4" />

        <InventoryTabs :tabs="tabs" @change="onTabChanged" ref="inventory">
          <template #fnb>
            <FnBProducts
              v-if="inventoryStore.activeFnBBrand"
              @close="
                () => inventoryStore.setActiveBrand('fnb', {} as BrandType)
              "
            />
            <div
              class="tw-grid tw-grid-cols-1 tw-gap-4 md:tw-grid-cols-3"
              v-else
            >
              <div
                class="tw-font-bold tw-flex tw-items-center tw-justify-center tw-text-center tw-border tw-rounded-lg tw-shadow-sm tw-cursor-pointer tw-h-40 hover:tw-border-primary hover:tw-bg-primary hover:tw-bg-opacity-10 hover:tw-text-primary"
                @click="
                  inventoryStore.setActiveBrand('fnb', {
                    id: 1,
                    title: 'All F&B Products',
                  })
                "
              >
                All F&B Products
              </div>
              <div
                v-for="(brand, index) in inventoryStore.fnbBrands"
                :key="index"
              >
                <div
                  class="tw-font-bold tw-flex tw-items-center tw-justify-center tw-text-center tw-border tw-rounded-lg tw-shadow-sm tw-cursor-pointer tw-h-40 hover:tw-border-primary hover:tw-bg-primary hover:tw-bg-opacity-10 hover:tw-text-primary"
                  @click="() => inventoryStore.setActiveBrand('fnb', brand)"
                >
                  <span>
                    <strong>{{ brand.title }}</strong>
                  </span>
                </div>
              </div>
            </div>
          </template>

          <template #products>
            <Products
              v-if="inventoryStore.activeProductBrand"
              @close="inventoryStore.setActiveBrand('product', {} as BrandType)"
            />
            <template v-else>
              <div class="tw-grid tw-grid-cols-1 tw-gap-4 md:tw-grid-cols-3">
                <div
                  class="tw-font-bold tw-flex tw-items-center tw-justify-center tw-text-center tw-border tw-rounded-lg tw-shadow-sm tw-cursor-pointer tw-h-40 hover:tw-border-primary hover:tw-bg-primary hover:tw-bg-opacity-10 hover:tw-text-primary"
                  @click="
                    inventoryStore.setActiveBrand('product', {
                      id: 1,
                      title: 'All Products',
                    })
                  "
                >
                  All Products
                </div>
                <div
                  v-for="(brand, index) in inventoryStore.productBrands"
                  :key="index"
                >
                  <div
                    class="tw-font-bold tw-flex tw-items-center tw-justify-center tw-text-center tw-border tw-rounded-lg tw-shadow-sm tw-cursor-pointer tw-h-40 hover:tw-border-primary hover:tw-bg-primary hover:tw-bg-opacity-10 hover:tw-text-primary"
                    @click="
                      () => inventoryStore.setActiveBrand('product', brand)
                    "
                  >
                    {{ brand.title }}
                  </div>
                </div>
              </div>
            </template>
          </template>

          <template #services>
            <div class="tw-grid tw-grid-cols-1 tw-gap-4 md:tw-grid-cols-2">
              <template v-if="serviceTab === ServiceTab.Main">
                <SaleCard
                  v-if="serviceTab === ServiceTab.Main"
                  :class="{
                    'text-dark bg-warning border-0':
                      !!saleStore.selectedAgreementId,
                  }"
                  @click="(serviceTab = ServiceTab.Services)"
                  title="Services"
                />

                <SaleCard
                  :class="{
                    'text-dark bg-warning border-0':
                      !!saleStore.selectedAgreementId,
                  }"
                  @click="(serviceTab = ServiceTab.Upgrades)"
                  title="Upgrades"
                />

                <SaleCard
                  :disabled="!isUserAddedToSale"
                  @click="
                    isUserAddedToSale
                      ? (serviceTab = ServiceTab.Agreements)
                      : null
                  "
                >
                  <strong>Agreements</strong>
                  <small v-if="!isUserAddedToSale">Add a guest first</small>
                </SaleCard>
                <SaleCard
                  :disabled="!isUserAddedToSale"
                  @click="isUserAddedToSale ? sellLockerbox() : null"
                >
                  <strong>Lockerbox</strong>
                  <small v-if="!isUserAddedToSale">Add a guest first</small>

                  <template #footer>
                    <Points :points="FixedPoints.LockerBox" />
                  </template>
                </SaleCard>
                <SaleCard @click="sellGiftCheque">
                  <strong>Gift Cheque</strong>
                </SaleCard>
                <SaleCard @click="(serviceTab = ServiceTab.Tickets)">
                  <strong>Tickets</strong>
                </SaleCard>
                <SaleCard
                  :disabled="!isUserAddedToSale"
                  @click="isUserAddedToSale ? sellStoreCredit() : null"
                >
                  <strong>Store Credit</strong>
                  <small v-if="!isUserAddedToSale">Add a guest first</small>
                </SaleCard>
              </template>
              <div class="tw-col-span-2">
                <Services
                  v-if="serviceTab === ServiceTab.Services"
                  @back="(serviceTab = ServiceTab.Main)"
                />
                <Upgrades
                  v-if="serviceTab === ServiceTab.Upgrades"
                  @back="(serviceTab = ServiceTab.Main)"
                />
                <Agreements
                  v-if="serviceTab === ServiceTab.Agreements"
                  @back="(serviceTab = ServiceTab.Main)"
                  @show-services="onAgreementAddedToSale"
                />
                <Tickets
                  v-if="serviceTab === ServiceTab.Tickets"
                  @back="(serviceTab = ServiceTab.Main)"
                />
              </div>
            </div>
          </template>
        </InventoryTabs>
      </div>
      <div class="md:tw-col-span-2">
        <SaleOverview
          @print="printTheSale"
          @create-alert="showAlertView = true"
          :mode="mode"
        />
      </div>
    </div>
    <Print
      ref="bill"
      :order="order"
      :summary="{
        totalPayableAmount,
        subTotal,
        totalServiceChargePaying,
        totalVATPaying,
        totalDiscount,
        totalSaleDiscount,
      }"
      :payments="[]"
    />
  </div>
</template>

<script lang="ts" setup>
import SaleOverview from '@/components/sale/SaleOverview.vue'
import Products from '@/views/inventory/Products.vue'
import FnBProducts from '@/views/inventory/FnBProducts.vue'
import Services from '@/views/inventory/Services.vue'
import Upgrades from '@/views/inventory/Upgrades.vue'
import Tickets from '@/views/inventory/Tickets.vue'
import Agreements from '@/views/inventory/Agreements.vue'
import SaleCard from './components/SaleCard.vue'
import CustomerAlert from './components/CustomerAlert.vue'
import InventoryTabs from './components/InventoryTabs.vue'
import Print from '@/components/common/Print.vue'
import ProductSearch from '@/components/ProductSearch.vue'
import db, { transform } from '@/config/firebase/realtime-database'
import { ref as dbRef, onValue } from 'firebase/database'
import { useApp, useSale, useTables, useRegister, useInventory } from '@/stores'
import { generateSaleItem } from '@/utilities/sale'
import { toast, type ToastOptions } from 'vue3-toastify'
import { generateOrder } from '@/stores/sale/order'
import { debounce } from 'lodash'
import { FixedPoints } from '../../utilities/constants'
import { reactive, computed, useTemplateRef, watch, onMounted, ref } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { NavbarTypes } from '@/stores/app'
import { ProductTypes, type BrandType } from '@/stores/inventory'
import Points from './components/Points.vue'

enum Tab {
  fnb = 'fnb',
  products = 'products',
  services = 'services',
}

enum ServiceTab {
  Main = 'main',
  Services = 'services',
  Upgrades = 'upgrades',
  Agreements = 'agreements',
  Tickets = 'tickets',
}

const tabs: Array<{
  name: string
  key: Tab
  active?: boolean
}> = [
  {
    name: 'F & B',
    key: Tab.fnb,
    active: true,
  },
  {
    name: 'Products',
    key: Tab.products,
  },
  {
    name: 'Services',
    key: Tab.services,
  },
]

const route = useRoute()
const router = useRouter()
const billRef = useTemplateRef('bill')
const inventoryRef = useTemplateRef('inventory')

const appStore = useApp()
const saleStore = useSale()
const tablesStore = useTables()
const registerStore = useRegister()
const inventoryStore = useInventory()

let listener = reactive<{ callback: () => void }>({ callback: () => {} })

const serviceTab = ref<ServiceTab>(ServiceTab.Main)
const showAlertView = ref<boolean>(false)

const mode = reactive({
  king: false,
  service: false,
  lockerbox: false,
  giftcheque: false,
})

const selectedAgreementId = computed(() => saleStore.selectedAgreementId)
const order = computed(() => saleStore.order)
const totalPayableAmount = computed(() => saleStore.totalPayableAmount)
const subTotal = computed(() => saleStore.subTotal)
const totalServiceChargePaying = computed(
  () => saleStore.totalServiceChargePaying,
)
const totalVATPaying = computed(() => saleStore.totalVATPaying)
const totalDiscount = computed(() => saleStore.totalDiscount)
const totalSaleDiscount = computed(() => saleStore.totalSaleDiscount)
const location = computed(() => registerStore.location)

const isUserAddedToSale = computed(() => {
  return !!saleStore.customer.userId
})

watch(selectedAgreementId, value => {
  if (value) serviceTab.value = ServiceTab.Services
})

const onTabChanged = (tab: string) => {
  if (tab === Tab.services) {
  }
}

onMounted(() => {
  window.dispatchEvent(new Event('orders'))
  tablesStore.setTablesForCurrentLocation()
})

onBeforeRouteLeave(() => {
  listener.callback()
})

const hydrate = async () => {
  appStore.setNavbarOption(NavbarTypes.MultipleOptionNavbar)

  const ordersRef = dbRef(db, `${location.value}/${route.params.orderId}`)

  const _onValue = debounce(async (snapshot: any) => {
    const order = snapshot.val()
    if (!order) {
      const redirectAfter = 2000
      await new Promise(resolve => {
        toast(
          'The order has been marked as complete or removed from active orders.',
          {
            autoClose: redirectAfter,
            type: toast.TYPE.WARNING,
            transition: toast.TRANSITIONS.SLIDE,
            theme: toast.THEME.LIGHT,
            position: toast.POSITION.BOTTOM_LEFT,
          } as ToastOptions,
        )

        setTimeout(resolve, redirectAfter)
      })

      router.push({ name: 'home' })

      return
    } else {
      saleStore.fromSale({ ...generateOrder(), ...order })
      if (order.status === 'cheque-confirmed') {
        router.push({
          name: 'payment',
          params: { orderId: order.value.orderId },
        })
        return
      }
    }
  }, 500)

  const callback = onValue(ordersRef, _onValue)
  listener = { callback }
}

const printTheSale = () => {
  if (billRef.value) {
    billRef.value.setOrderId('Ongoing Sale')
    billRef.value.lock()
    billRef.value.print()
  }
}

const sellLockerbox = () => {
  const lockerbox = generateSaleItem({
    id: 'LockerBox',
    name: 'Locker Box',
    price: 42000000,
    type: ProductTypes.LockerBox,
    category: ProductTypes.LockerBox,
    VAT: { amount: 8, label: '8%' },
    months: 12,
    lockerNumber: 1,
    freeGuests: 3,
    points: FixedPoints.LockerBox,
  })

  saleStore.add(lockerbox)
}

const sellStoreCredit = () => {
  const lockerbox = generateSaleItem({
    id: 'Store Credit',
    name: 'Store Credit',
    price: 0,
    type: ProductTypes.StoreCredit,
    category: ProductTypes.StoreCredit,
  })

  saleStore.add(lockerbox)
}

const sellGiftCheque = () => {
  const giftcheque = generateSaleItem({
    id: 'GiftCheque',
    name: 'Gift Cheque',
    type: ProductTypes.GiftCheque,
    category: ProductTypes.GiftCheque,
    price: 100000,
    code: '',
  })

  saleStore.add(giftcheque)
}

const onAgreementAddedToSale = () => {
  if (inventoryRef.value) {
    inventoryRef.value.changeTab(Tab.services)
    serviceTab.value = ServiceTab.Services
  }
}

watch(() => route, hydrate, {
  immediate: true,
})
</script>

<style scoped>
.box4after {
  transform: rotateY(180deg);
}

.text-rotate {
  transform: rotateY(180deg);
}

.box4before {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
</style>
