<template>
  <div>
    <template v-if="staff">
      <Avatar
        :name="staff.staffName"
        :image="staff.image"
        @click="(show = true)"
        variant="success"
      />
    </template>

    <div
      v-else
      class="tw-h-8 tw-w-8 tw-bg-secondary tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
      @click="(show = true)"
    >
      <UserIcon class="tw-text-white tw-h-4 tw-w-4" />
    </div>
    <sales-person
      v-model:visible="show"
      @save="person => $emit('assign', person)"
    />
  </div>
</template>

<script lang="ts" setup>
import SalesPerson from '@/components/modal/SalesPerson.vue'
import { UserIcon } from '@/components/icons'
import Avatar from '@/components/common/Avatar.vue'
import { defineProps, ref } from 'vue'

defineProps({
  staff: Object,
})

const show = ref<boolean>(false)
</script>
