import { type Timestamp } from 'firebase/firestore'

export enum DiscountTypes {
  Money = 'money',
  Percentage = 'percentage',
  FixedPrice = 'fixed-price',
}

export enum PromotionTypes {
  Sale = 'sale',
  ProductCategory = 'productCategory',
  ProductBrand = 'productBrand',
  Product = 'product',
  Service = 'service',
  FnB = 'fnb',
  FnBCategory = 'fnbCategory',
  Agreement = 'agreement',
  Upgrade = 'upgrade',
  AllItem = 'all-items',
}

export type Promotion = {
  _id: string
  name: string
  active: boolean
  voucher?: boolean
  autoApply?: boolean
  customerGroup?: Array<string>
  customerId?: Array<string>
  days?: Array<string>
  discountType: string
  discountValue: number
  product?: Array<string>
  productBrand?: Array<string>
  productCategory?: Array<string>
  fnb?: Array<string>
  fnbCategory?: Array<string>
  service?: Array<string>
  services?: Array<string>
  upgrades?: Array<string>
  memberGroups?: Array<string>
  userId?: Array<string>
  users?: Array<string>
  userTags?: Array<string>
  minAmount: number
  redemptionLimit?: number
  redemptionCount?: number

  timeFilter: boolean
  timeFrom: Timestamp
  timeTill: Timestamp

  type: string

  startDate: Timestamp
  endDate: Timestamp
}
