<template>
  <div class="lg:tw-w-1/2 tw-text-left tw-m-auto">
    <h3
      class="tw-font-bold tw-text-gray-600 tw-border-b tw-border-primary tw-pb-2 tw-mb-6 tw-w-max"
    >
      Open Register
    </h3>
    <div
      class="tw-p-4 tw-bg-white tw-rounded-lg tw-border tw-border-gray-300 tw-shadow-lg tw-flex tw-flex-col tw-gap-4"
    >
      <p v-if="error" class="tw-text-error tw-mb-0">
        {{ error }}
      </p>

      <div class="tw-flex tw-flex-col tw-gap-4 lg:tw-flex-row">
        <div class="tw-flex tw-flex-col tw-w-full">
          <label class="tw-mb-2">Location:</label>
          <select
            v-model="location"
            class="tw-select tw-border tw-border-gray-300"
          >
            <option
              v-for="(alocation, index) in allowedLocations"
              :key="index"
              :value="alocation._id"
            >
              {{ alocation.name }}
            </option>
          </select>
        </div>

        <div class="tw-flex tw-flex-col tw-w-full">
          <label class="tw-mb-2">Register:</label>
          <select
            v-model="outlet"
            class="tw-select tw-border tw-border-gray-300"
          >
            <option
              v-for="(optionOutlet, index) in outlets"
              :key="index"
              :value="optionOutlet.value"
            >
              {{ optionOutlet.text }}
            </option>
          </select>
        </div>
      </div>

      <div class="tw-flex tw-flex-col tw-w-full">
        <label class="tw-mb-2">Opened By</label>
        <select v-model="staff" class="tw-select tw-border tw-border-gray-300">
          <option
            v-for="member in allowedStaffMembers"
            :key="member._id"
            :value="member._id"
          >
            {{ member.fullName }}
          </option>
        </select>
      </div>

      <div class="tw-flex tw-justify-end">
        <button
          class="tw-bg-primary tw-px-4 tw-py-2 tw-text-white tw-rounded-md tw-flex tw-items-center tw-justify-center sm:tw-w-max disabled:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-pointer-events-none"
          :disabled="disabled"
          @click="createNewRegister"
        >
          <p v-if="!loading" class="tw-mb-0">Open register</p>
          <LoadingIcon
            v-else
            class="tw-w-4 tw-h-4 tw-animate-spin dark:tw-text-gray-200 tw-fill-white"
          />
        </button>
      </div>
    </div>
    <VerifyPin
      :length="6"
      v-model:visible="showPinVerification"
      @verified="onAuthenticated"
      @reset="(authentication.authenticated = false)"
      :staffId="staff"
    />
  </div>
</template>

<script lang="ts" setup>
import { capitalize, find, has } from 'lodash'
import { LoadingIcon } from '@/components/icons'
import { useAuthentication, type StaffUser } from '@/stores/authentication'
import { NavbarTypes, useApp, type Location } from '@/stores/app'
import { useRegister } from '@/stores/register'
import { useStaff } from '@/stores/staff'
import VerifyPin from '@/components/modal/VerifyPin.vue'
import { REGISTER_OPENED } from '@/events/events'
import { ref, reactive, computed, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import debug from 'debug'

const logger = debug('register-component')

const router = useRouter()

const authenticationStore = useAuthentication()
const appStore = useApp()
const registerStore = useRegister()
const staffStore = useStaff()

const authentication = reactive({
  authenticated: false,
  showPinVerification: false,
})

const showPinVerification = ref<boolean>(false)

const error = ref('')
const loading = ref(false)
const location = ref<string>('')
const outlet = ref('')
const outlets = reactive<Array<{ text: string; value: string }>>([])
const staff = ref('')
const staffMembers = reactive<Array<StaffUser>>([])

const user = computed(() => authenticationStore.user)
const locations = computed(() => appStore.locations)

const allowedLocations = computed((): Array<Location> => {
  return appStore.locations.filter((location: any) =>
    user?.value?.locations.includes(location._id),
  )
})

const allowedStaffMembers = computed<Array<StaffUser>>(() => {
  return staffMembers.filter(
    (m: StaffUser) =>
      has(m, 'locations') && m.locations.includes(location.value),
  )
})

const disabled = computed<boolean>(() => {
  return loading.value || !location.value || !outlet.value || !staff.value
})

const onAuthenticated = () => {
  authentication.authenticated = true
  createNewRegister()
}

const createNewRegister = async () => {
  if (loading.value) return

  if (!authentication.authenticated) {
    logger('not authenticated')
    showPinVerification.value = true
    return
  }
  
  if (!location.value || !outlet.value || !staff.value) {
    error.value = 'Please fill all fields!'
    return
  } else {
    error.value = ''
  }

  try {
    loading.value = true

    const hasOpenedExisting = await registerStore.initRegister(
      location.value,
      outlet.value,
      outlets,
    )

    if (hasOpenedExisting) {
      window.dispatchEvent(new Event(REGISTER_OPENED))
    }

    router.push(
      hasOpenedExisting
        ? { name: 'home' }
        : {
            name: 'register.create',
          },
    )
  
  } catch (e) {
    logger(e)
  } finally {
    loading.value = false
  }
}

const setLocationInfo = (_location: Location) => {
  //TODO: reference not found for locationId
  location.value = _location._id
  outlet.value = _location.outlets[0]

  const _outlets = _location.outlets.map((value: string) => ({
    text: capitalize(value),
    value,
  }))
  outlets.splice(0, outlets.length, ..._outlets)
}

watch(location, id => {
  const l = find(locations.value, location => location._id === id)

  if (!l) {
    logger(`Location: no location found with id ${id}`)
    return
  }

  outlet.value = l.outlets[0]

  outlets.splice(
    0,
    outlets.length,
    ...l.outlets.map((value: string) => ({
      text: capitalize(value),
      value,
    })),
  )

  setLocationInfo(l)
})

watch(locations, () => {
  if (!location.value && locations.value.length > 0) {
    location.value = locations.value[0]._id
  }
})

onMounted(async () => {
  appStore.setNavbarOption(NavbarTypes.NoOptionNavbar)

  const _staffMembers = await staffStore.fetchAllStaffMembers()
  staffMembers.splice(0, staffMembers.length, ..._staffMembers)

  location.value = allowedLocations.value[0]?._id
  outlet.value = allowedLocations.value[0]?.outlets[0]

  const _outlets = allowedLocations.value[0].outlets.map((value: string) => ({
    text: capitalize(value),
    value,
  }))

  outlets.splice(0, outlets.length, ..._outlets)
})
</script>
