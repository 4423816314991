<template>
  <div class="tw-grid tw-grid-cols-2 tw-gap-4 lg:tw-grid-cols-4">
    <div
      class="tw-card tw-shadow-md tw-cursor-pointer tw-border tw-border-gray-300 tw-transition tw-duration-100 hover:tw-border-primary"
    >
      <div
        class="tw-card-body tw-p-2 tw-flex tw-items-center tw-justify-center"
        @click="emit('close')"
      >
        <BackArrowIcon
          class="tw-text-primary hover:tw-text-secondary tw-w-8 tw-h-8"
        />
      </div>
    </div>
    <div
      v-for="(product, index) in items"
      :key="index"
      class="tw-card tw-shadow-md tw-cursor-pointer tw-border tw-border-gray-300 tw-transition tw-duration-100 hover:tw-border-primary"
      @click="saveItem(product)"
    >
      <figure class="tw-h-32 tw-mb-0" v-if="product.image">
        <img
          class="tw-w-full tw-object-cover tw-object-center tw-h-full"
          :src="product.image"
          :alt="product.id"
        />
      </figure>
      <div
        class="tw-h-32 tw-mb-0 tw-flex tw-items-center tw-justify-center"
        v-else
      >
        <BarbaardIcon class="tw-h-16 tw-w-16 tw-opacity-50" />
      </div>
      <div class="tw-card-body tw-p-2">
        <h6 class="line-clamp-2 tw-mb-0" v-html="product.title" />
        <small class="tw-mb-0" v-if="product.price">
          Price: ₫ {{ formatNumber(product.price) }}
        </small>
      </div>
    </div>
    <ProductVariants
      v-if="selected.product"
      :visible="!!selected.product"
      :product="selected.product"
      @update:visible="onModalChange"
      @selected="(product: InventoryProduct) => saveItem(product)"
    />
  </div>
</template>

<script lang="ts" setup>
import ProductVariants from '@/components/modal/ProductVariants.vue'
import { formatNumber } from '@/utilities/common'
import { useInventory } from '@/stores/inventory/inventory'
import { useSale } from '@/stores/sale/sale'
import { generateSaleItem } from '@/utilities/sale'
import BackArrowIcon from '@/components/icons/BackArrowIcon.vue'
import { type SaleProduct } from '../../stores/sale/types'
import { reactive, computed, defineEmits } from 'vue'
import {
  ProductTypes,
  type BrandType,
  type InventoryProduct,
} from '@/stores/inventory'
import BarbaardIcon from '@/components/icons/BarbaardIcon.vue'

const emit = defineEmits(['close'])

const sale = useSale()
const inventory = useInventory()

const selected = reactive<{
  product: InventoryProduct | null
}>({
  product: null,
})

const items = computed(() => {
  const brand = inventory.activeFnBBrand as BrandType
  return inventory.FnBProducts.filter((product: InventoryProduct) => {
    if (brand.id === 1) return true
    return product.category.id == brand.id
  })
})

const onModalChange = (val: any) => {
  if (val) return

  selected.product = null
}

const saveItem = (product: InventoryProduct) => {
  if (product.type === ProductTypes.FnB && product.variants.length) {
    selected.product = product
    return
  }
  selected.product = null

  const item = generateSaleItem({
    type: ProductTypes.FnB,
    category: ProductTypes.FnB,
    brand: product.category,
    id: product.id,
    name: product.title,
    price: product.price,
    originalPrice: product.price,
    storeCategory: product.data?.category?._id,
    storeBrand: product.storeBrand,
    VAT: product.VAT,
    serviceCharge: product.serviceCharge,
    categoryName: product.data.categoryName,
    cost: product.data?.BOMPrice || 0,
    points: 0,
    course: sale.order.course + 1,
  })
  sale.add(item as SaleProduct)
}
</script>
