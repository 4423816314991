<template>
  <!-- Note: Deprecated, Check references and remove if not needed -->
  <Modal
    max-width-class="tw-max-w-screen-sm"
    :visible="visible"
    :title="title"
    @close="() => emit('update:visible', false)"
  >
    <div class="tw-flex tw-flex-col tw-max-h-96 tw-overflow-y-scroll">
      <div
        v-for="(s, index) in staff"
        :key="index"
        class="tw-border-b tw-border-gray-300 tw-flex tw-items-center tw-cursor-pointer tw-px-6 tw-py-2 hover:tw-bg-primary hover:tw-bg-opacity-10"
        @click="save(s)"
      >
        <Avatar
          :name="s.fullName"
          :image="s.image"
          variant="success"
          class="tw-mr-4"
        />
        <p class="tw-mb-0">{{ s.fullName }}</p>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from '@/components/common/Modal.vue'
import Avatar from '@/components/common/Avatar.vue'
import { useStaff } from '@/stores/staff'
import { filter as _filter, orderBy } from 'lodash'
import { reactive, computed } from 'vue'
import type { StaffUser } from '@/stores/authentication'

const staffStore = useStaff()

const props = defineProps({
  visible: Boolean,
  title: {
    type: String,
    default: 'Assign Sales Person',
  },
  filter: {
    type: Function,
    default: <T,>(arg: T): T => arg,
  },
})

const emit = defineEmits(['save', 'update:visible'])

const person = reactive({
  staffId: '',
  staffName: '',
  image: '',
})

const staff = computed<Array<StaffUser>>(() => {
  return orderBy(
    _filter(staffStore.staff, props.filter),
    ['fullName'],
    ['asc'],
  ) as Array<StaffUser>
})

const save = (staff: StaffUser) => {
  person.staffId = staff._id
  person.staffName = staff.fullName
  person.image = staff.image
  emit('save', person)
  emit('update:visible', false)
}
</script>
