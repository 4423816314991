import { writeBatch } from 'firebase/firestore'
import {
  each,
  filter,
  forOwn,
  groupBy,
  has,
  intersection,
  isArray,
  map,
  orderBy,
  reduce,
  words,
} from 'lodash'
import { defineStore } from 'pinia'
import { useSale } from '@/stores/sale/sale'
import {
  feeder,
  filterPromotionApplicableProducts,
  reducer,
} from '@/utilities/promotion'
import database from '@/config/firebase/database'
import { collection, doc } from 'firebase/firestore'
import { produce } from './producer'
import { getTotalPayableAmount } from '@/utilities/sale'
import type { SaleProductType, AppliedPromotionType } from '../sale/types'
import { reactive, computed } from 'vue'
import { PromotionTypes, type Promotion } from './types'
import debug from 'debug'

const logger = debug('promotions')

export const usePromotion = defineStore('promotion', () => {
  const sale = useSale()

  const promotions = reactive<Array<Promotion>>([])

  const autoPromotions = computed<Array<Promotion>>(() => {
    return promotions.filter(
      (p: Promotion) => has(p, 'autoApply') && p.autoApply,
    )
  })

  const affiliatePromotions = computed<Array<Promotion>>(() => {
    return autoPromotions.value.filter(
      p => !!p?.voucher && p.users?.includes(sale.customer.userId),
    )
  })

  const manualPromotions = computed<Array<Promotion>>(() => {
    return promotions.filter(
      (p: Promotion) => has(p, 'autoApply') && !p.autoApply,
    )
  })

  const autoProductPromotionGroups = computed(() => {
    return groupBy(
      filter(
        autoPromotions.value,
        (p: Promotion) => p.type !== PromotionTypes.Sale,
      ),
      'type',
    )
  })

  const autoSalePromotions = computed<Array<Promotion>>(() => {
    return autoPromotions.value.filter(
      (p: Promotion) => p.type === PromotionTypes.Sale,
    )
  })

  const productPromotions = computed<Array<Promotion>>(() => {
    return promotions.filter((p: Promotion) => p.type !== PromotionTypes.Sale)
  })

  const salePromotions = computed(() => {
    return promotions.filter((p: Promotion) => p.type === PromotionTypes.Sale)
  })

  const searchPromotion = (search: string) => {
    if (!search) {
      return []
    }

    const result = promotions.filter(p => {
      return !!intersection(
        words(search.toLowerCase()),
        words(p.name.toLowerCase()),
      ).length
    })

    return orderBy(result, ['name'], ['asc'])
  }

  const recomputePromotion = async () => {
    if (affiliatePromotions.value.length) {
      await sale.applyPromotion(affiliatePromotions.value[0], false)
      return
    }

    const productDecisions = await computeAutoPromotions()
    const saleDecision: any = await computeAutoSalePromotion()
    removeAllAutoAppliedPromotions()

    forOwn(productDecisions, async (_computed, type) => {
      if (_computed) {
        logger(`applying ${type} auto promotion to the products`)
        await sale.applyPromotion(_computed.promotion, false)
      }
    })

    if (saleDecision && saleDecision.discount) {
      await sale.applyPromotion(saleDecision.promotion, false)
    }
  }

  const __populatePromotions = async () => {
    const promotions = produce()
    const batch = writeBatch(database)
    promotions.forEach(promotion => {
      batch.set(doc(collection(database, `promotions`)), promotion)
    })

    await batch.commit()
    logger(`Created ${promotions.length} promotions`)
  }

  const computeAutoPromotions = async () => {
    const groups = autoProductPromotionGroups.value
    const customer = sale.customer
    const products = sale.products

    const _assign = (promotion: Array<Promotion> | Promotion) => {
      if (isArray(promotion)) {
        const intermediateResults: { [key: string]: any } = {}
        each(promotion, (p: Promotion, index: number) => {
          const applicableProducts = filterPromotionApplicableProducts(
            p,
            products,
          )
          const amount = reduce(
            applicableProducts,
            (total, item) => {
              return total + getTotalPayableAmount(item)
            },
            0,
          )
          intermediateResults[index] = {}
          intermediateResults[index].promotion = p
          intermediateResults[index].products = applicableProducts
          intermediateResults[index].discount = feeder(
            amount,
            p.discountType,
            p.discountValue,
          )
        })

        return reducer(intermediateResults)
      } else {
        const applicableProducts = filterPromotionApplicableProducts(
          promotion,
          products,
        )
        const amount = reduce(
          applicableProducts,
          (total, item) => {
            return total + getTotalPayableAmount(item)
          },
          0,
        )

        return {
          promotion,
          discount: feeder(
            amount,
            promotion.discountType,
            promotion.discountValue,
          ),
          products: applicableProducts,
        }
      }
    }

    const computed: { [key: string]: any } = {}

    forOwn(groups, (promotions: Array<Promotion>, type) => {
      const intermediateResults: { [key: string]: any } = {}

      if (customer.userId) {
        // determine the value of discount for specific user & product | f&b
        const userProductPromotion: Array<Promotion> = promotions.filter(p => {
          return (
            p?.users && isArray(p.users) && p.users.includes(customer?.userId)
          )
        })

        if (userProductPromotion.length) {
          intermediateResults['customer'] = _assign(userProductPromotion)
        }

        // determine the value of discount for specific user tags & product | f&b
        const tagSalePromotion = promotions.filter(p => {
          return (
            p.userTags &&
            isArray(p.userTags) &&
            p.userTags?.filter(i => customer?.userTags?.includes(i)).length
          )
        })

        if (tagSalePromotion.length) {
          intermediateResults['tag'] = _assign(tagSalePromotion)
        }

        // determine the value of discount for user groups
        const groupSalePromotion = promotions.filter(p => {
          return (
            p.memberGroups &&
            isArray(p.memberGroups) &&
            customer?.memberGroup &&
            p.memberGroups.includes(customer?.memberGroup)
          )
        })
        if (groupSalePromotion.length) {
          intermediateResults['group'] = _assign(groupSalePromotion)
        }
      }

      // determine the value of discount for normal product & f&b promotion
      const normalSalePromotion = filter(promotions, p => {
        return (
          (!p?.users || !p?.users?.length) &&
          (!p?.userTags || !p?.userTags?.length) &&
          (!p?.memberGroups || !p?.memberGroups?.length)
        )
      })
      if (normalSalePromotion.length) {
        intermediateResults['normal'] = _assign(normalSalePromotion)
      }

      computed[type] = reducer(intermediateResults)
    })

    return computed
  }

  const computeAutoSalePromotion = async () => {
    const customer = sale.customer
    const amount = sale.subTotal

    if (!amount) {
      logger(`order amount ${amount} is not valid for promotion`)
      return {
        promotion: {},
        discount: 0,
      }
    }

    if (sale.order.discount.manual) {
      logger(`Skipping auto sale promotion, manual override`)
      return {
        promotion: {},
        discount: 0,
      }
    }

    const estimates: { [key: string]: any } = {}

    const _assign = (promotion: Array<Promotion> | Promotion) => {
      if (isArray(promotion)) {
        const intermediateResults: { [key: string]: any } = {}
        each(promotion, (p, index) => {
          intermediateResults[index] = {}
          intermediateResults[index].promotion = p
          intermediateResults[index].discount = feeder(
            amount,
            p.discountType,
            p.discountValue,
          )
        })

        return reducer(intermediateResults)
      } else {
        return {
          promotion,
          discount: feeder(
            amount,
            promotion.discountType,
            promotion.discountValue,
          ),
        }
      }
    }

    if (customer.userId) {
      // determine the value of discount for specific user
      const userSalePromotion = autoSalePromotions.value.filter(p => {
        return (
          has(p, 'users') &&
          isArray(p.users) &&
          p.users.includes(customer.userId)
        )
      })
      if (userSalePromotion.length) {
        estimates['customer'] = _assign(userSalePromotion)
      }

      // determine the value of discount for specific user tags & product | fandb
      const tagSalePromotion = autoSalePromotions.value.filter(p => {
        return (
          has(p, 'userTags') &&
          isArray(p.userTags) &&
          p.userTags.filter((i: string) => customer.userTags.includes(i)).length
        )
      })
      if (tagSalePromotion.length) {
        estimates['tag'] = _assign(tagSalePromotion)
      }

      // determine the value of discount for specific user group
      const groupSalePromotion = autoSalePromotions.value.filter(p => {
        return (
          has(p, 'memberGroups') &&
          isArray(p.memberGroups) &&
          customer.memberGroup &&
          p.memberGroups.includes(customer.memberGroup)
        )
      })
      if (groupSalePromotion.length) {
        estimates['group'] = _assign(groupSalePromotion)
      }
    }

    // determine normal sale discount
    const normalSalePromotion = filter(autoSalePromotions.value, p => {
      return (
        (!p?.users || !p?.users?.length) &&
        (!p?.userTags || !p?.userTags?.length) &&
        (!p?.memberGroups || !p?.memberGroups?.length)
      )
    })
    if (normalSalePromotion.length) {
      estimates['normal'] = _assign(normalSalePromotion)
    }

    return reducer(estimates)
  }

  const removeAllAutoAppliedPromotions = (manual: boolean = false) => {
    const autoAppliedPromotionIds = map(
      filter(sale.order.promotion, i => !i.manual),
      i => i._id,
    )

    // TODO cross check and verify if promotion object has id or _id property
    sale.order.products.forEach(
      (
        product: SaleProductType,
        index: number,
        products: Array<SaleProductType>,
      ) => {
        if (
          product.promotion?.hasOwnProperty('_id') &&
          autoAppliedPromotionIds.includes(product.promotion._id)
        ) {
          const _product = { ...product }
          _product.promotion = {} as AppliedPromotionType
          _product.manualPromotion = manual
          _product.priceAfterDiscount = 0
          _product.discount = 0
          _product.manualPrice = 0

          products[index] = _product
        }
      },
    )
  }

  const hydrate = (_promotions: Array<Promotion>) => {
    promotions.splice(0, promotions.length, ..._promotions)
  }
  return {
    promotions,
    autoPromotions,
    affiliatePromotions,
    manualPromotions,
    autoProductPromotionGroups,
    autoSalePromotions,
    productPromotions,
    salePromotions,
    searchPromotion,
    recomputePromotion,
    __populatePromotions,
    computeAutoPromotions,
    computeAutoSalePromotion,
    removeAllAutoAppliedPromotions,
    hydrate,
  }
})
