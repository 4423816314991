<template>
  <div class="tw-relative tw-w-full">
    <input
      ref="search"
      id="search"
      class="tw-input tw-border tw-border-gray-300 tw-w-full"
      type="search"
      :placeholder="props.placeholder"
      @input="onSearch"
    />
    <div
      v-if="results.length"
      class="tw-absolute tw-top-12 tw-left-0 tw-h-max tw-max-h-96 tw-overflow-auto tw-rounded-xl tw-bg-white tw-w-full tw-z-10 tw-shadow-sm"
    >
      <div
        v-for="(user, index) in results"
        class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-border-b tw-border-gray-300 tw-cursor-pointer last:tw-border-none hover:tw-bg-gray-300"
        :key="index"
        @click="selected(user)"
      >
        <div
          class="tw-h-10 tw-w-10 tw-bg-primary tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center tw-shrink-0"
        >
          {{
            getInitials(
              user.fullName
                ? user.fullName
                : `${user.firstName} ${user.lastName}`,
            )
          }}
        </div>
        <div class="tw-flex tw-flex-col tw-px-2">
          <span v-if="user.fullName" class="tw-text-nowrap">
            {{ user.fullName }}
            {{ user.hotelRoom ? ` - ${user.hotelRoom}` : '' }}
          </span>
          <span v-else class="tw-text-nowrap">
            {{ user.firstName }} {{ user.lastName }}
            {{ user.hotelRoom ? ` - ${user.hotelRoom}` : '' }}</span
          >
          <small class="tw-text-gray-500 tw-text-nowrap">{{
            user.email
          }}</small>
          <small class="tw-text-gray-500">{{ user.phone }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import database from '@/config/firebase/database'
import { doc, getDoc } from 'firebase/firestore'
import { debounce, map, orderBy, split } from 'lodash'
import { searchClient } from '@algolia/client-search'
import { defineProps, defineEmits, reactive, useTemplateRef } from 'vue'
import { getInitials } from '@/utilities/common'

type AlgoliaUserType = {
  _id: string
  firstName?: string
  lastName?: string
  fullName: string
  email: string
  phone: string
  hotelRoom: string
}

const algolia = searchClient(
  import.meta.env.VITE_ALGOLIA_APP_ID,
  import.meta.env.VITE_ALGOLIA_APP_KEY,
)

const results = reactive<Array<AlgoliaUserType>>([])
const _searchRef = useTemplateRef('search')

const props = defineProps({
  placeholder: {
    type: String,
    default: 'Search & add guest',
  },
})
const emit = defineEmits(['selected'])

const selected = async (user: AlgoliaUserType) => {
  const _doc = await getDoc(doc(database, `users/${user._id}`))
  const result = { ...user, ..._doc.data(), _id: _doc.id }
  emit('selected', result)
  if (_searchRef.value) {
    _searchRef.value.value = ''
  }
}

const onSearch = debounce(async function (event) {
  const search = event.target.value
  if (search.length >= 2) {
    const { hits = [] } = await algolia.searchSingleIndex({
      indexName: 'users',
      searchParams: { query: search },
    })

    const nextResults = orderBy(
      map(hits, o => {
        const { path, ...data } = o
        const segments = split(path, '/')
        return {
          ...data,
          _id: segments[segments.length - 1],
        }
      }),
      [o => o.fullName],
      ['asc'],
    )

    results.splice(0, results.length, ...nextResults)
  } else {
    results.splice(0, results.length)
  }
})
</script>

<style lang="scss" scoped>
.list-group {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1;
  max-height: 450px;
  overflow-y: auto;

  .list-item {
    cursor: pointer;
  }
}
</style>
