<template>
  <div class="tw-container tw-max-w-screen-xl tw-pt-10 tw-px-4 sm:tw-px-12">
    <div class="open-table">
      <h2
        class="tw-text-secondary tw-border-b tw-border-primary tw-py-1 tw-font-bold tw-w-max tw-mb-4"
      >
        Payment received
      </h2>
      <div class="tw-flex">
        <div
          class="tw-mx-0 tw-p-2 tw-border tw-border-gray-300 tw-rounded-lg tw-shadow-lg tw-bg-white tw-w-full sm:tw-w-5/12 xl:tw-w-4/12"
        >
          <div class="tw-py-2 tw-px-2 tw-h-[68vh] tw-overflow-y-auto">
            <SaleItem
              v-for="(item, index) in order.products"
              :key="index"
              :index="index"
              :item="item"
            />
            <div
              class="tw-flex tw-justify-between tw-border-b tw-border-gray-300 tw-py-3"
            >
              <h6 class="tw-mb-0 tw-font-normal tw-mr-2">SubTotal</h6>
              <h6 class="tw-mb-0 tw-font-normal">
                ₫{{ formatNumber(order.totalOrderValue) }}
              </h6>
            </div>

            <div
              class="tw-flex tw-justify-between tw-border-b tw-border-gray-300 tw-py-3"
            >
              <h5 class="tw-mb-0 tw-font-bold tw-mr-2">Sale Total</h5>
              <h6 class="tw-mb-0 tw-font-bold">
                ₫{{ formatNumber(order.totalOrderValue) }}
              </h6>
            </div>

            <div
              v-for="(payment, index) in payments"
              :key="`sale-paid-${index}`"
              class="tw-flex tw-justify-between tw-border-b tw-border-gray-300 tw-py-3"
            >
              <h6 class="tw-mb-0 tw-font-normal tw-mr-2">
                {{ payment.method }}
              </h6>
              <h6 class="tw-mb-0 tw-font-normal">
                ₫{{ formatNumber(payment.amount) }}
              </h6>
            </div>

            <div
              class="tw-flex tw-justify-between tw-border-b tw-border-gray-300 tw-py-3"
            >
              <h5 class="tw-mb-0 tw-font-bold tw-mr-2">To Pay</h5>
              <h6 class="tw-mb-0 tw-font-bold">₫0</h6>
            </div>
          </div>
        </div>
        <div
          class="tw-mt-4 tw-p-0 tw-w-full sm:tw-w-7/12 xl:tw-w-8/12 lg:tw-px-3 lg:tw-mt-0"
        >
          <div
            class="tw-border tw-border-gray-300 tw-shadow-lg tw-rounded-lg tw-px-3 tw-py-5 tw-bg-white"
          >
            <h5 class="tw-mb-0 tw-font-bold tw-text-center tw-mb-8">
              <span class="tw-text-primary">{{
                payment.customer.fullName
              }}</span>
              is a
            </h5>
            <div class="tw-grid tw-grid-cols-2 tw-gap-4">
              <button
                v-for="trait in PersonaTraits"
                :key="trait.key"
                class="tw-btn tw-btn-block tw-text-white tw-font-normal"
                :class="{
                  'tw-btn-primary': userPersona.includes(trait.key),
                  'tw-btn-secondary': !userPersona.includes(trait.key),
                }"
                @click="addTraitToPersona(trait)"
              >
                {{ trait.name }}
              </button>
            </div>
          </div>
          <div class="tw-mt-4">
            <p class="tw-text-error tw-mb-0" v-if="error">
              {{ error }}
            </p>

            <button
              class="tw-btn tw-btn-primary tw-btn-block tw-text-white tw-font-normal"
              @click="completePersona"
            >
              Complete sale
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { indexOf } from 'lodash'
import { PersonaTraits, type TraitType } from '@/utilities/constants'
import SaleItem from './components/SaleItem.vue'
import { usePayment } from '@/stores/payment'
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { formatNumber } from '@/utilities/common'

const router = useRouter()
const payment = usePayment()

const error = ref('')

const emit = defineEmits(['setOption'])

const order = computed(() => payment.order)
const payments = computed(() => payment.payments)

const userPersona = computed(() => payment.customer.persona || [])

const completePersona = () => {
  router.push({ name: 'home' })
}

const addTraitToPersona = async (trait: TraitType) => {
  const index = indexOf(userPersona.value, trait.key)
  const _persona = [...userPersona.value]

  if (index === -1) {
    _persona.push(trait.key)
  } else {
    _persona.splice(index, 1)
  }

  payment.setPaymentPersona({
    orderId: payment.orderId,
    customer: {
      ...payment.customer,
      persona: _persona,
    },
    order: order.value,
    payments: payments.value,
  })

  await payment.updatePersona(_persona, payment.customer.userId)
}

onMounted(() => {
  emit('setOption', 'backButtonOptionNavabar')
})
</script>
