import { defineStore } from 'pinia'
import { each, find, keys, values } from 'lodash'
import { useRegister } from '@/stores/register'
import {
  collection,
  getDocs,
  query,
  documentId,
  where,
} from 'firebase/firestore'
import database from '@/config/firebase/database'
import { reactive, computed, ref } from 'vue'
import {
  NavbarTypes,
  type Location,
  type PaymentMethod,
  type Tax,
} from './types'
import debug from 'debug'

const logger = debug('app')

export const useApp = defineStore('app', () => {
  const tax = reactive<Array<Tax>>([])
  const locations = reactive<Array<Location>>([])
  const payments = reactive<Array<PaymentMethod>>([])
  const nav = ref(NavbarTypes.NoOptionNavbar)

  const location = computed(() => {
    const register = useRegister()
    return find(locations, l => l._id === register.location)
  })

  const setNavbarOption = (navigation: NavbarTypes) => {
    nav.value = navigation
  }

  const hydrateTaxClasses = async () => {
    const snapshot = await getDocs(query(collection(database, 'tax')))

    const slabs = [] as Array<any>
    snapshot.forEach(doc => {
      slabs.push({
        _id: doc.id,
        ...doc.data(),
      } as Tax)
    })

    tax.splice(0, tax.length, ...slabs)
    logger(`${slabs.length} tax classes loaded`)
  }

  const hydratePaymentMethods = async () => {
    const snapshot = await getDocs(query(collection(database, 'payments')))

    const methods = [] as Array<PaymentMethod>
    snapshot.forEach(doc => {
      methods.push({
        _id: doc.id,
        ...doc.data(),
      } as PaymentMethod)
    })

    payments.splice(0, payments.length, ...methods)
    logger(`${methods.length} payment methods loaded`)
  }

  const hydrateLocations = async () => {
    const IGNORE_LOCATION_ID = 'barbaard'

    const snapshot = await getDocs(
      query(
        collection(database, 'locations'),
        where(documentId(), '!=', IGNORE_LOCATION_ID),
      ),
    )

    const _locations: { [key: string]: any } = {}

    snapshot.forEach(doc => {
      const data = doc.data()
      _locations[doc.id] = {
        _id: doc.id,
        prefix: `${data.code}-${new Date().getFullYear().toString().slice(-2)}`,
        outlets: [],
        salesCategories: [],
        ...doc.data(),
      }
    })

    await Promise.all(
      keys(_locations).map(async location => {
        const outletSnapshot = await getDocs(
          query(collection(database, `locations/${location}/registers`)),
        )
        outletSnapshot.forEach(o => _locations[location].outlets.push(o.id))

        const salesCategoriesSnapshot = await getDocs(
          query(collection(database, `locations/${location}/salesCategories`)),
        )
        salesCategoriesSnapshot.forEach(o =>
          _locations[location].salesCategories.push({
            _id: o.id,
            name: o.data()?.name,
          }),
        )
      }),
    )

    locations.splice(0, locations.length, ...values(_locations))
    logger(`${locations.length} locations loaded`)
  }

  /**
   * @description Action returns location document which is currently open.
   * @returns {Location} location
   */
  const getLocation = async () => {
    const register = useRegister()
    return find(locations, location => location._id === register.location)
  }

  return {
    nav,
    tax,
    locations,
    payments,
    location,

    setNavbarOption,
    hydrateTaxClasses,
    hydratePaymentMethods,
    hydrateLocations,
    getLocation,
  }
})
