import { isEmpty, round } from 'lodash'
import { formatNumber } from '@/utilities/common'

export const useUtilities = () => {
  return {
    formatNumber,
    round,
    isEmpty,
  }
}
