import { defineStore } from 'pinia'
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore'
import database from '@/config/firebase/database'
import { generateOrder } from '@/stores/sale/order'
import { assign, reduce } from 'lodash'
import { useSale } from '@/stores/sale'
import { reactive, computed } from 'vue'
import type { Order } from '@/stores/sales'

type PersonaType = {
  orderId: string
  customer: any
  order: ReturnType<typeof generateOrder>
  payments: Array<any>
}

export type ReceiptParams = {
  order: Order
  orderId: string
  totalPayableAmount: number
  subTotal: number
  totalServiceChargePaying: number
  totalVATPaying: number
  totalDiscount: number
  totalSaleDiscount: number
}

export const usePayment = defineStore('payment', () => {
  const persona = reactive({
    orderId: '',
    customer: {} as any,
    order: {} as ReturnType<typeof generateOrder>,
    payments: [] as Array<any>,
  })

  const orderId = computed(() => {
    return persona.orderId
  })

  const customer = computed(() => {
    return persona.customer
  })

  const order = computed(() => {
    return persona.order
  })

  const payments = computed(() => {
    return persona.payments
  })

  const setPaymentPersona = (payload: PersonaType) => {
    assign(persona, payload)
  }

  const updatePersona = async (persona: Array<string>, userId: string) => {
    const docRef = doc(database, `users/${userId}`)
    setDoc(docRef, { persona }, { merge: true })
  }

  /**
   * @returns Promise<number> Returns total store credit for current sale customer
   */

  const fetchStoreCredit = async (): Promise<number> => {
    const sale = useSale()

    if (!sale.customer.userId) {
      return 0
    }

    const q = query(
      collection(database, `storecredit`),
      where('active', '==', true),
      where('userId', '==', sale.customer.userId),
    )

    const snapshot = await getDocs(q)

    const credits: Array<any> = []

    snapshot.forEach(doc => {
      credits.push({
        _id: doc.id,
        ...doc.data(),
      })
    })

    return reduce(credits, (total, credit) => total + credit.storeCredit, 0)
  }

  return {
    persona,
    fetchStoreCredit,
    orderId,
    customer,
    order,
    payments,
    setPaymentPersona,
    updatePersona,
  }
})
