<template>
  <div class="tw-container tw-max-w-screen-xl tw-pt-10 tw-px-4 sm:tw-px-12">
    <div class="open-table">
      <h2
        class="tw-text-secondary tw-border-b tw-border-primary tw-py-1 tw-font-bold tw-w-max tw-mb-4"
      >
        Make Payment
      </h2>
      <div class="tw-flex tw-flex-col tw-gap-4 sm:tw-flex-row">
        <div
          class="tw-border tw-border-gray-300 tw-rounded-lg tw-shadow-lg tw-bg-white tw-w-full sm:tw-w-5/12 xl:tw-w-4/12"
        >
          <div class="tw-overflow-y-auto sm:tw-h-[60vh]">
            <SaleItem
              v-for="(item, index) in saleStore.products"
              :key="index"
              :item="item"
              :index="index"
            />

            <div class="tw-px-4 tw-border-b tw-border-gray-300">
              <div class="tw-flex tw-justify-between tw-py-3">
                <h6 class="tw-mb-0 tw-font-bold tw-mr-2">SubTotal</h6>
                <h6 class="tw-mb-0 tw-font-bold">
                  ₫{{ formatNumber(saleStore.subTotal) }}
                </h6>
              </div>
            </div>

            <div
              class="tw-px-4 tw-border-b tw-border-gray-300"
              v-if="saleStore.order.discount?.amount"
            >
              <div class="tw-flex tw-justify-between tw-py-3">
                <h6 class="tw-mb-0 tw-font-bold tw-mr-2">
                  {{
                    saleStore.order.discount.promotion?.name ||
                    'Sale Discount'
                  }}
                </h6>
                <h6 class="tw-mb-0 tw-font-bold">
                  ₫{{ formatNumber(saleStore.order.discount?.amount) }}
                </h6>
              </div>
            </div>

            <div class="tw-px-4 tw-border-b tw-border-gray-300">
              <div class="tw-flex tw-justify-between tw-py-3">
                <h6 class="tw-mb-0 tw-font-bold tw-mr-2">Sale Total</h6>
                <h6 class="tw-mb-0 tw-font-bold">
                  ₫{{ formatNumber(saleStore.totalPayableAmount) }}
                </h6>
              </div>
            </div>

            <div
              class="tw-px-4 tw-border-b tw-border-gray-300"
              v-for="(payment, index) in payments"
              :key="`sale-paid-${index}`"
            >
              <div class="tw-flex tw-justify-between tw-py-3">
                <h6 class="tw-mb-0 tw-font-bold tw-mr-2">
                  {{ payment.method }}
                </h6>
                <h6 class="tw-mb-0 tw-font-bold">
                  ₫{{ formatNumber(payment.amount) }}
                </h6>
              </div>
            </div>

            <div class="tw-px-4 tw-border-b tw-border-gray-300">
              <div class="tw-flex tw-justify-between tw-py-3">
                <h6 class="tw-mb-0 tw-font-bold tw-mr-2">To Pay</h6>
                <h6 class="tw-mb-0 tw-font-bold">
                  ₫{{ completed ? 0 : formatNumber(remaining) }}
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div class="tw-w-full sm:tw-w-7/12 xl:tw-w-8/12">
          <div
            class="tw-border tw-border-gray-300 tw-shadow-lg tw-rounded-lg tw-p-5 tw-bg-white tw-flex tw-flex-col tw-gap-4"
          >
            <div class="tw-flex tw-flex-col tw-gap-4 xl:tw-flex-row">
              <div class="tw-w-full xl:tw-w-3/12">
                <p
                  class="tw-border-b tw-border-primary tw-font-bold tw-w-max tw-mb-0"
                >
                  Amount To Pay
                </p>
              </div>
              <div class="tw-text-right tw-w-full xl:tw-w-9/12">
                <div class="tw-w-full tw-flex tw-mb-2" v-if="!completed">
                  <NumberInput
                    v-model:value="partialAmount"
                    class="tw-input tw-text-right tw-w-full tw-px-3 tw-rounded-r-none tw-rounded-l-lg tw-border-r-0 tw-border-l tw-border-y tw-border-gray-300 focus:tw-outline-none"
                  />
                  <div
                    class="tw-font-bold tw-w-10 tw-bg-[#e9ecef] tw-text-[#495057] tw-px-3 tw-py-2 tw-rounded-r-lg tw-border tw-border-gray-300 tw-shrink-0 tw-flex tw-items-center"
                  >
                    ₫
                  </div>
                </div>
                <p
                  v-if="amountLeft < partialAmount"
                  class="tw-font-bold tw-text-right tw-mb-2"
                >
                  Return {{ formatNumber(amountToReturn) }} vnd to guest
                </p>

                <div
                  v-if="amountLeft > 0"
                  class="tw-grid tw-grid-cols-2 tw-gap-2 md:tw-grid-cols-4"
                >
                  <button
                    v-for="amount in helperAmounts"
                    :key="amount"
                    class="tw-btn tw-btn-sm tw-bg-[#e9ecef] tw-text-black hover:tw-text-white"
                    @click="(partialAmount = amount)"
                  >
                    {{ formatNumber(amount) }}
                  </button>
                </div>
              </div>
            </div>

            <div class="tw-flex tw-gap-2">
              <Voucher @apply="applyVoucher" />
              <Affiliate />
            </div>

            <AffiliateCodes />

            <div
              v-if="payments.length"
              class="tw-flex tw-flex-col tw-flex-wrap tw-gap-4 sm:tw-flex-row"
            >
              <div
                class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-w-full md:tw-flex-row"
              >
                <p
                  class="tw-w-max tw-font-bold tw-mb-0 tw-border-b tw-border-primary"
                >
                  Selected Payment Method:
                </p>

                <button
                  v-if="!completed"
                  class="tw-btn tw-btn-primary tw-text-white tw-font-normal tw-shadow-lg tw-ml-auto"
                  @click="resetPaymentMethod"
                >
                  change
                </button>
              </div>

              <PaymentCard
                v-for="(payment, index) in payments"
                :key="`applied-payment-${index}`"
                :amount="payment.amount"
                :method="payment.method"
              >
                <template
                  v-if="payment.method === PaymentMethods.storeCreditAgreement"
                  #content
                >
                  <p
                    class="tw-text-xs tw-opacity-80 tw-mb-1"
                    v-for="(info, index) in agreementPaymentInfo"
                    :key="`applied-agreement-${index}`"
                  >
                    {{ info }}
                  </p>
                </template>
              </PaymentCard>
            </div>

            <div
              v-if="shouldShowComplete && !completed"
              class="tw-flex tw-flex-col tw-gap-4"
            >
              <p class="tw-text-error tw-mb-0" v-if="error">
                {{ error }}
              </p>

              <button
                :disabled="loading"
                class="tw-btn tw-btn-block tw-shadow-lg tw-text-white tw-font-normal tw-flex tw-gap-2"
                :class="{
                  'tw-btn-primary': !loading,
                  'tw-btn-secondary': loading,
                }"
                @click="completePurchase"
              >
                <LoadingIcon
                  v-if="loading"
                  class="tw-w-4 tw-h-4 tw-animate-spin dark:tw-text-gray-200 tw-fill-white"
                />
                <span class="tw-text-white">{{
                  loading ? 'Completing the sale..' : 'Complete Purchase'
                }}</span>
              </button>
            </div>

            <div v-if="completed">
              <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                <button
                  v-if="paymentStore.persona?.customer?.userId"
                  class="tw-btn tw-btn-primary tw-btn-block tw-shadow-lg tw-text-white tw-font-normal"
                  @click="navigateToPersona"
                >
                  Customer Persona
                </button>
                <button
                  v-else
                  class="tw-btn tw-btn-primary tw-btn-block tw-shadow-lg tw-text-white tw-font-normal"
                  @click="navigateToHome"
                >
                  Complete Sale
                </button>

                <button
                  class="tw-btn tw-btn-primary tw-btn-block tw-shadow-lg tw-text-white tw-font-normal"
                  @click="print()"
                >
                  <PrintIcon class="tw-h-4 tw-w-4 tw-text-white" />Print Receipt
                </button>
              </div>
            </div>

            <div v-if="!shouldShowComplete">
              <div
                v-if="shouldShowAgreements"
                class="tw-w-full tw-grid tw-grid-flow-col tw-auto-cols-fr tw-gap-4"
              >
                <div tabindex="0" class="tw-collapse">
                  <div
                    class="tw-collapse-title tw-text-white tw-font-normal tw-shadow-lg tw-rounded-lg tw-bg-primary tw-text-center tw-p-3 tw-min-h-0 tw-flex tw-justify-center tw-items-center"
                  >
                    <p class="tw-mb-0">Use Agreement</p>
                  </div>
                  <div
                    class="tw-collapse-content tw-p-0 tw-flex tw-flex-col tw-gap-4 tw-pt-4 tw-pb-0"
                  >
                    <AgreementCard
                      v-for="agreement in agreements"
                      :key="agreement._id"
                      :agreement="agreement"
                    >
                      <template #content>
                        <b class="tw-ml-2" v-if="agreement.type === 'upgrades'">
                          {{ agreement.upgradeName }}
                          {{ agreement.left || 0 }} left
                        </b>

                        <p v-else class="tw-mt-1 tw-text-sm tw-opacity-70">
                          <b>{{ agreement.redeemed }}</b> Redeemed &#8226;
                          <b> {{ agreement.amount - agreement.redeemed }} </b>
                          Left
                        </p>
                      </template>

                      <template #footer>
                        <p class="tw-mt-1 tw-text-sm tw-font-bold">
                          Gentleman’s Agreement
                        </p>
                      </template>
                    </AgreementCard>

                    <AgreementCard
                      v-for="agreement in initiateAgreement"
                      :key="agreement._id"
                      :agreement="agreement"
                    >
                      <template #content>
                        <b class="tw-ml-2" v-if="agreement.type === 'upgrades'">
                          {{ agreement.upgradeName }}
                          {{ agreement.left || 0 }} left
                        </b>

                        <p v-else class="tw-mt-1 tw-text-sm tw-opacity-70">
                          <b>{{ agreement.redeemed }}</b> Redeemed &#8226;
                          <b> {{ agreement.amount - agreement.redeemed }} </b>
                          Left
                        </p>
                      </template>

                      <template #footer>
                        <p class="tw-mt-1 tw-text-sm tw-font-bold">
                          Initiate Agreement
                        </p>
                      </template>
                    </AgreementCard>

                    <AgreementCard
                      v-for="agreement in nobelAgreements"
                      :key="agreement._id"
                      :agreement="agreement"
                    >
                      <template #content>
                        <p class="tw-mt-1 tw-text-sm tw-opacity-70">
                          <b> {{ agreement.redeemed }}</b> redeemed &#8226;
                          Expiring at
                          <b>
                            {{
                              new Date(
                                agreement.expiryDate.seconds * 1000,
                              ).toDateString()
                            }}
                          </b>
                        </p>
                      </template>
                    </AgreementCard>

                    <AgreementCard
                      v-for="agreement in kingsAgreements"
                      :key="agreement._id"
                      :agreement="agreement"
                    >
                      <template #content>
                        <p class="tw-mt-1 tw-text-sm tw-opacity-70">
                          <b> {{ agreement.redeemed }}</b> redeemed &#8226;
                          Expiring at
                          <b>
                            {{
                              new Date(
                                agreement.expiryDate.seconds * 1000,
                              ).toDateString()
                            }}
                          </b>
                        </p>
                      </template>
                    </AgreementCard>

                    <AgreementCard
                      v-for="agreement in emperorAgreements"
                      :key="agreement._id"
                      :agreement="agreement"
                    >
                      <template #content>
                        <p class="tw-mt-1 tw-text-sm tw-opacity-70">
                          <b> {{ agreement.redeemed }}</b> redeemed &#8226;
                          Expiring at
                          <b>
                            {{
                              new Date(
                                agreement.expiryDate.seconds * 1000,
                              ).toDateString()
                            }}
                          </b>
                        </p>
                      </template>
                    </AgreementCard>

                    <AgreementCard
                      v-for="agreement in merchantAgreements"
                      :key="agreement._id"
                      :agreement="agreement"
                    >
                      <template #content>
                        <p class="tw-mt-1 tw-text-sm tw-opacity-70">
                          <b> {{ agreement.redeemed }}</b> Redeemed &#8226;
                          <b> {{ agreement.amount - agreement.redeemed }} </b>
                          Left
                        </p>
                      </template>
                    </AgreementCard>
                  </div>
                </div>
              </div>

              <LoyaltyPoint v-if="amountLeft > 0" @apply="applyLoyaltyPoints" />

              <div
                v-if="amountLeft > 0"
                class="tw-grid tw-grid-cols-2 tw-gap-4 md:tw-grid-cols-3"
              >
                <button
                  v-for="(method, index) in shownPaymentMethods"
                  :key="`${method.key}-${index}`"
                  class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
                  :class="{ 'tw-btn-success': method?.exclude }"
                  @click="addPayment(method.key, method?.exclude || false)"
                >
                  {{ method.name }}
                </button>

                <button
                  v-if="storeCredit"
                  key="store-credit"
                  class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
                  @click="addPayment(PaymentMethods.storeCredit, false)"
                >
                  <div>
                    Store Credit
                    <small>₫{{ formatNumber(storeCredit) }}</small>
                  </div>
                </button>
              </div>

              <div
                v-if="amountLeft < 0"
                class="tw-grid tw-grid-cols-1 tw-gap-4 tw-mt-4 md:tw-grid-cols-2"
              >
                <button
                  class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
                  @click="addPayment('Cash', false)"
                >
                  Return {{ formatNumber(-1 * partialAmount) }} vnd cash
                </button>
                <button
                  class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
                  @click="addPayment('Tip', false)"
                >
                  {{ formatNumber(-1 * partialAmount) }} Tip
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Print
      ref="print-bill"
      :order="saleStore.order"
      :payments="payments"
      :summary="{
        totalPayableAmount: saleStore.totalPayableAmount,
        subTotal: saleStore.subTotal,
        totalServiceChargePaying: saleStore.totalServiceChargePaying,
        totalVATPaying: saleStore.totalVATPaying,
        totalDiscount: saleStore.totalDiscount,
        totalSaleDiscount: saleStore.totalSaleDiscount,
      }"
      :applied-agreements="saleStore.appliedAgreements"
    />
  </div>
</template>

<script lang="ts" setup>
import database from '@/config/firebase/database'
import Print from '@/components/common/Print.vue'

import { getDoc, doc, onSnapshot } from 'firebase/firestore'
import { filter, isEmpty, map, reduce } from 'lodash'
import { ref as dbRef, onValue } from 'firebase/database'
import rtdb, { transform } from '@/config/firebase/realtime-database'
import { generateOrder } from '@/stores/sale/order'
import SaleItem from './components/SaleItem.vue'
import { STORE_CREDIT_AGREEMENT } from '@/utilities/constants'
import { LoadingIcon, PrintIcon } from '@/components/icons'
import AgreementCard from './components/AgreementCard.vue'
import PaymentCard from './components/PaymentCard.vue'
import Affiliate from './components/Affiliate.vue'
import AffiliateCodes from './components/AffiliateCodes.vue'
import LoyaltyPoint from './components/LoyaltyPoint.vue'
import Voucher from './components/Voucher.vue'
import { useSale } from '@/stores/sale/sale'
import { PaymentMethods, useRegister } from '@/stores/register'
import { NavbarTypes, useApp } from '@/stores/app'
import { useAgreement } from '@/stores/agreement'
import { useLoyalty } from '@/stores'
import { usePayment } from '@/stores/payment'
import { toast } from 'vue3-toastify'
import {
  ROYALTY_POINTS_GROOMING,
  ROYALTY_POINTS_NETWORKING,
  ROYALTY_POINTS_STYLING,
} from '@/utilities/constants'
import {
  ref,
  reactive,
  watch,
  computed,
  useTemplateRef,
  onMounted,
  onBeforeUnmount,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { formatNumber } from '@/utilities/common'
import debug from 'debug'
import type { SalePayment } from '@/stores/payment'
import { ProductTypes } from '@/stores/inventory'
import NumberInput from '@/components/common/NumberInput.vue'

const logger = debug('payment-component')

const route = useRoute()
const router = useRouter()

const paymentStore = usePayment()
const agreementStore = useAgreement()
const saleStore = useSale()
const registerStore = useRegister()
const appStore = useApp()
const loyalty = useLoyalty()

const loading = ref(false)
const completed = ref(false)
const printPending = ref(true)
const error = ref('')
const paid = ref(0)
const voucher = reactive({})
const partialAmount = ref(0)

const billRef = useTemplateRef('print-bill')

const localPayments = reactive<Array<SalePayment>>([])

const agreements = reactive<Array<any>>([])
const initiateAgreement = reactive<Array<any>>([])
const nobelAgreements = reactive<Array<any>>([])
const kingsAgreements = reactive<Array<any>>([])
const emperorAgreements = reactive<Array<any>>([])
const merchantAgreements = reactive<Array<any>>([])

let unsubscribe = reactive<{ callback: () => void }>({ callback: () => {} })

const orderRef = ref('')
const shouldShowPersona = ref(false)
const storeCredit = ref(0)

const payments = computed(() => {
  const paidByAgreement = [] as Array<any>
  if (saleStore.appliedAgreements.length) {
    paidByAgreement.push({
      amount: saleStore.agreementTotal,
      method: STORE_CREDIT_AGREEMENT,
      agreements: map(saleStore.appliedAgreements, a => a.agreement),
    })
  }

  return [...localPayments, ...paidByAgreement]
})

const remaining = computed(() => {
  return saleStore.totalPayableAmount - cleared.value
})

const agreementPaymentInfo = computed(() => {
  return reduce(
    saleStore.appliedAgreements,
    (info, applied) => {
      const _applied = applied.agreement
      switch (_applied.type) {
        case ProductTypes.KingsAgreement:
          info.push(`King's Agreement (${_applied.redeemed + 1} Redeemed)`)
          break
        case ProductTypes.EmperorsAgreement:
          info.push(`Emperor's Agreement (${_applied.redeemed + 1} Redeemed)`)
          break
        case ProductTypes.NobelAgreement:
          info.push(`Nobel's Agreement (${_applied.redeemed + 1} Redeemed)`)
          break
        case ProductTypes.MerchantsAgreement:
        case ProductTypes.GrandMerchantsAgreement:
          info.push(`Merchant's Agreement (${_applied.redeemed + 1} Redeemed)`)
          break
        default:
          info.push(`${_applied.serviceName}(${_applied.left - 1} left)`)
          break
      }

      return info
    },
    [] as Array<string>,
  )
})

const amountLeft = computed(() => {
  return saleStore.totalPayableAmount - cleared.value
})

const amountToReturn = computed(() => {
  return partialAmount.value - amountLeft.value
})

const helperAmounts = computed(() => {
  const nearest = [2000, 5000, 10000, 20000, 50000, 100000, 200000, 500000]

  return [
    amountLeft.value,
    ...nearest.map(n => {
      return Math.ceil(amountLeft.value / n) * n
    }),
  ].filter(
    (a, i, arr) =>
      a !== partialAmount.value && i === arr.findIndex(j => j === a),
  )
})

const shouldShowComplete = computed(() => {
  return (
    saleStore.totalPayableAmount === 0 ||
    saleStore.totalPayableAmount - cleared.value === 0
  )
})

const shouldShowAgreements = computed(() => {
  return (
    agreements.length ||
    initiateAgreement.length ||
    nobelAgreements.length ||
    kingsAgreements.length ||
    emperorAgreements.length ||
    merchantAgreements.length
  )
})

const cleared = computed(() => {
  return payments.value.reduce(
    (total, current) => (current.exclude ? total : total + current.amount),
    0,
  )
})

const shownPaymentMethods = computed(() => {
  return appStore.payments.filter(method => {
    if (!method.active) return false
    if (!method.locations) return true
    return method.locations.includes(registerStore.location)
  })
})

watch(
  () => saleStore.totalPayableAmount,
  amount => {
    partialAmount.value = amount - cleared.value
  },
)

watch(cleared, amount => {
  partialAmount.value = saleStore.totalPayableAmount - amount
})

const init = async () => {
  const { gentleman, initiate, nobel, king, emperor, merchant } =
    await agreementStore.fetchAgreements()
  agreements.splice(0, agreements.length, ...gentleman)
  initiateAgreement.splice(0, initiateAgreement.length, ...initiate)
  kingsAgreements.splice(0, kingsAgreements.length, ...king)
  nobelAgreements.splice(0, nobelAgreements.length, ...nobel)
  emperorAgreements.splice(0, emperorAgreements.length, ...emperor)
  merchantAgreements.splice(0, merchantAgreements.length, ...merchant)
}

const resetPaymentMethod = (): void => {
  saleStore.revertAllAppliedAgreements()

  partialAmount.value = saleStore.totalPayableAmount
  localPayments.splice(0, localPayments.length)
  paid.value = 0
}

const applyVoucher = async (voucher: any) => {
  const credit = parseInt(voucher.storeCredit)
  if (credit < partialAmount.value) {
    partialAmount.value = credit
  }
  addPayment('giftcheque', false)
}

const applyLoyaltyPoints = (points: any) => {
  const pointsMethod = [
    ROYALTY_POINTS_GROOMING,
    ROYALTY_POINTS_NETWORKING,
    ROYALTY_POINTS_STYLING,
  ]
  const next = filter(localPayments, p => !pointsMethod.includes(p.method))
  next.push(...points)
  localPayments.splice(0, localPayments.length, ...next)
  partialAmount.value = saleStore.totalPayableAmount - cleared.value
}

const addPayment = (method: string, excludeFromPayable: boolean) => {
  if (!partialAmount.value && !excludeFromPayable) return

  if (
    method === PaymentMethods.storeCredit &&
    partialAmount.value > storeCredit.value
  ) {
    partialAmount.value = storeCredit.value
  }

  localPayments.push({
    amount: partialAmount.value,
    exclude: excludeFromPayable,
    method,
  })

  partialAmount.value = saleStore.totalPayableAmount - cleared.value
}

const print = () => {
  printPending.value = false
  if (billRef.value) {
    billRef.value.print()
  }
}

const navigateToPersona = () => {
  router.push({
    name: 'persona',
    params: { orderId: orderRef.value },
  })
}

const navigateToHome = () => {
  router.replace({ name: 'home' })
}

const completePurchase = async () => {
  loading.value = true

  const personaParams = transform({
    orderId: saleStore.order.orderId,
    customer: { ...saleStore.customer },
    order: { ...saleStore.order },
    payments: payments.value,
  })

  orderRef.value = saleStore.order.orderId

  const data = {
    payments: payments.value,
    voucher: voucher,
  }

  let customer: Record<string, any> = {}

  if (personaParams?.customer?.userId) {
    const _customer = await getCustomerDoc(personaParams.customer.userId)
    if (_customer.exists()) {
      personaParams.customer = {
        ...personaParams.customer,
        ..._customer.data(),
        _id: _customer.id,
      }

      customer = {
        ..._customer.data(),
        id: _customer.id
      }
    }

    paymentStore.setPaymentPersona(personaParams)
    shouldShowPersona.value = true
  }

  try {
    if (billRef.value) billRef.value.lock()

    const _orderId = await saleStore.complete(data)
    completed.value = true

    if (billRef.value) billRef.value.setOrderId(_orderId || '')

    if (!isEmpty(customer) && _orderId) {
      loyalty
        .calculateLoyaltyPointsEarned(customer.id, _orderId, registerStore.location)
        .then(result => {
          if (billRef.value) {
            billRef.value.setLoyalty({
              points: result.points,
              membership: {
                ...result.membership,
                expiryDate: customer.membership.expiryDate
              }
            })
          }
        }).catch(e => {
          logger(e)
        }).finally(() => {
          loading.value = false
          print()
        })
    }
  } catch (e) {
    logger(e)
  }
}

const getCustomerDoc = async (id: string) => {
  return await getDoc(doc(database, `users/${id}`))
}

onMounted(() => {
  appStore.setNavbarOption(NavbarTypes.BackButtonOptionNavabar)
  saleStore.flushAppliedAgreements()

  const ordersRef = dbRef(
    rtdb,
    `${registerStore.location}/${route.params.orderId}`,
  )
  onValue(ordersRef, snapshot => {
    const order = snapshot.val()
    if (!order && !loading.value && !printPending.value) {
      toast.warning(
        'The order has been marked as complete or removed from active orders.',
      )

      router.push({ name: 'home' })

      return
    }

    saleStore.fromSale({ ...generateOrder(), ...order })
  })

  if (saleStore.customer.userId) {
    const userRef = doc(database, `users/${saleStore.customer.userId}`)
    const callback = onSnapshot(userRef, doc => {
      const {
        userLoyalty: { groomPoints: gP, stylePoints: sP, networkPoints: nP },
      } = saleStore.customer
      const {
        membership: {
          groomPoints = 0,
          stylePoints = 0,
          networkPoints = 0,
        } = {},
      } = {
        ...doc.data(),
      }

      if (gP !== groomPoints || sP !== stylePoints || nP !== networkPoints) {
        saleStore.assignCustomer({ _id: doc.id, ...doc.data() })
      }
    })

    unsubscribe = { callback }
  }

  init()
    .then(() => logger('Agreements hydration complete'))
    .catch(logger.log)

  paymentStore
    .fetchStoreCredit()
    .then(credit => (storeCredit.value = credit))
    .then(() => logger('Store Credit hydration complete'))
    .catch(logger.log)

  partialAmount.value = saleStore.totalPayableAmount
})

onBeforeUnmount(() => {
  unsubscribe.callback()
})
</script>
