import {
  Timestamp,
  type Transaction,
  arrayUnion,
  collection,
  doc,
  getDocs,
  increment,
  query,
  serverTimestamp,
  where,
} from 'firebase/firestore'
import database from '@/config/firebase/database'
import {
  each,
  filter,
  find,
  has,
  isArray,
  isEmpty,
  map,
  round,
  times,
} from 'lodash'
import {
  STORE_CREDIT_AGREEMENT,
  STORE_CREDIT_GIFTCHEQUE,
  STORE_CREDIT_LOCKERBOX,
  ROYALTY_POINTS_GROOMING,
  ROYALTY_POINTS_NETWORKING,
  ROYALTY_POINTS_STYLING,
  STORE_CREDIT,
} from '@/utilities/constants'
import { useAnalytics } from '../analytics'
import type { Customer, SaleProduct } from './types'
import { getTotalPayableAmount } from '@/utilities/sale'
import moment from 'moment'
import type { Promotion } from '@/stores/promotion'
import { useApp } from '../app'
import { PaymentMethods } from '../register'
import { ProductTypes } from '../inventory'
import debug from 'debug'

const logger = debug('sale')

type ArgsType = {
  transaction: Transaction
  _id: string
  orderId: string
  registerId: string
  registerName: string
  location: string
  customer: Customer
  order: any
  payments: any
  voucher: any
  saleDiscount: {
    type: string
    percentage: number
    amount: number
  }
}

export const createLedgerEntries = async ({
  payments,
  registerId,
  location,
  voucher,
  transaction,
  customer,
  _id,
  order,
  orderId,
  registerName,
}: ArgsType) => {
  if (!payments.length) {
    logger('No payment entries to process for ledger')
    return false
  }

  logger(`Creating ${payments.length} Ledger entries from sale`)

  const app = useApp()

  const excluded = map(
    filter(app.payments, p => p?.exclude),
    'key',
  )

  return await Promise.all(
    payments.map(async (payment: any) => {
      const ledgerRef = doc(
        collection(database, `locations/${location}/ledger`),
      )

      let credits = null
      if (payment.method === PaymentMethods.storeCredit) {
        credits = await updateStoreCredit({
          customer,
          payment,
          transaction,
          _id,
        })
      }

      const _ledger: any = {
        orderId: _id,
        order: orderId,
        amount: excluded.includes(payment.method)
          ? -payment.amount
          : payment.amount,
        date: serverTimestamp(),
        method: payment.method,
        registerId,
        staffId: order.staffId,
        staffName: order.staffName,
        status: 1,
        userId: customer.userId,
        userName: customer.userName,
        register: registerName,
      }

      if (credits && credits.length) {
        _ledger.credits = map(credits, c => c._id)
      }

      transaction.set(ledgerRef, _ledger)

      logger(`${ledgerRef.id} ledger has been created`)

      if (payment.method === 'giftcheque') {
        await updateGiftCheque({ payment, voucher, transaction, _id })
      }

      return { _id: ledgerRef.id, ..._ledger }
    }),
  )
}

export const updatePromotions = async ({ order, transaction }: ArgsType) => {
  const { promotion = [] } = order
  const _promotions = filter(promotion, (p: Promotion) =>
    has(p, 'redemptionLimit'),
  )

  logger(
    `${_promotions.length ? _promotions.length : 'No'} promotion needs to be updated`,
  )

  return await Promise.all(
    _promotions.map(async (promotion: Promotion) => {
      const update: any = {}
      if (promotion.redemptionLimit === 1) {
        update.active = false
      } else {
        update.redemptionCount = increment(1)

        if (
          promotion.redemptionLimit &&
          promotion.redemptionLimit - 1 === promotion.redemptionCount
        ) {
          update.active = false
        }
      }

      const ref = doc(database, 'promotions', promotion._id)
      transaction.update(ref, update)
    }),
  )
}

export const updateGiftCheque = async ({
  payment,
  voucher,
  transaction,
  _id,
}: Partial<ArgsType> & Pick<ArgsType, 'transaction'> & { payment: any }) => {
  if (isEmpty(payment) || isEmpty(voucher)) {
    logger('Giftcheque voucher or payment is empty')
    return true
  }

  logger(`Updating Giftcheque redeemption with voucher`)

  const updateTo = { ...voucher }
  updateTo.storeCredit = parseInt(updateTo.storeCredit) - payment.amount

  if (!isArray(updateTo.orders)) {
    updateTo.orders = []
  }

  updateTo.orders.push({
    date: Timestamp.fromDate(new Date()),
    id: _id,
    total: payment.amount,
  })

  if (!updateTo.storeCredit) {
    updateTo.active = false
  }

  const _updateTo = updateTo._id
  delete updateTo._id

  const ref = doc(database, 'giftcheques', _updateTo)
  transaction.update(ref, updateTo)
  logger(`Gift cheque ${ref.id} has been updated`)

  return { _id: _updateTo, ...updateTo }
}

const updateStoreCredit = async ({
  customer,
  payment,
  transaction,
  _id,
}: Partial<ArgsType> & Pick<ArgsType, 'transaction'> & { payment: any }) => {
  const q = query(
    collection(database, `storecredit`),
    where('active', '==', true),
    where('userId', '==', customer?.userId),
  )

  const snapshot = await getDocs(q)

  const credits: Array<any> = []

  snapshot.forEach(doc => {
    credits.push({
      _id: doc.id,
      ...doc.data(),
    })
  })

  logger(`Updating StoreCredit claim`)

  let amountToClaim = payment.amount
  const creditsToClaim = []

  const orderDetail = {
    date: Timestamp.fromDate(new Date()),
    id: _id,
    total: payment.amount,
  }

  for (let i = 0; i < credits.length && amountToClaim > 0; i++) {
    const _credit = credits[i]

    if (amountToClaim <= _credit.storeCredit) {
      creditsToClaim.push({
        _id: _credit._id,
        amount: amountToClaim,
        storeCredit: _credit.storeCredit - amountToClaim,
        orders:
          has(_credit, 'orders') && isArray(_credit.orders)
            ? [..._credit.orders, orderDetail]
            : [orderDetail],
        active: true,
      })
      break
    } else {
      creditsToClaim.push({
        _id: _credit._id,
        amount: _credit.storeCredit,
        storeCredit: 0,
        orders:
          has(_credit, 'orders') && isArray(_credit.orders)
            ? [..._credit.orders, orderDetail]
            : [orderDetail],
        active: false,
      })
      amountToClaim -= _credit.storeCredit
    }
  }

  each(creditsToClaim, _credit => {
    const ref = doc(database, 'storecredit', _credit._id)
    transaction.update(ref, {
      storeCredit: _credit.storeCredit,
      active: _credit.active,
      orders: _credit.orders,
    })
    logger(`Store credit ${ref.id} has been updated`)
  })

  return creditsToClaim
}

export const updateAgreements = async ({
  order,
  _id,
  payments,
  transaction,
}: Partial<ArgsType> & Pick<ArgsType, 'transaction'>) => {
  logger('Updating Agreement redeemptions')
  const agreementPay = find(payments, p => p.method === STORE_CREDIT_AGREEMENT)

  if (!agreementPay) return

  logger(`Updating Gentleman's Agreement redeemption from sale`)

  return await Promise.all(
    agreementPay.agreements.map(async (agreement: any) => {
      const ref = doc(database, 'agreements', agreement._id)

      const update: any = {
        left: increment(-1),
        redeemed: increment(1),
        storeCredit: increment(-agreement.pricePerService),
      }

      if (
        ![ProductTypes.KingsAgreement, ProductTypes.EmperorsAgreement].includes(
          agreement.type,
        ) &&
        agreement.left === 1
      ) {
        update.active = false
      }

      if (!isEmpty(order.eventItem)) {
        update.appointments = arrayUnion(order.eventItem)
      }
      update.orders = arrayUnion(_id)
      transaction.update(ref, update)
    }),
  )
}

/**
 *
 * @param param0 default arguments
 * @param {string} param1.location Location name
 * @returns {object} param1.register Register Document
 * @description General idea is to discount the product but number of
 * free products on purchase of several items together. For accounting,
 * the product price will be considered by pricePerService, which ignores
 * the number of free compensation count added in sale.
 */
export const createGentlemanAgreements = async ({
  location,
  registerId,
  registerName,
  order,
  transaction,
  customer,
  _id,
  orderId,
  saleDiscount,
}: ArgsType) => {
  const agreements = order.products.filter(
    (p: SaleProduct) =>
      p.type === ProductTypes.GentlemanAgreement ||
      p.type === ProductTypes.InitiateAgreement,
  )

  if (!agreements.length) return false

  logger(`Creating ${agreements.length} Gentleman's Agreement from sale`)

  const analytics = useAnalytics()
  analytics.assignTagToUser({
    user: customer,
    tags: ['agreement'],
    transaction,
  })

  return await Promise.all(
    agreements.map(async (agreement: any) => {
      const ledgerRef = doc(
        collection(database, `locations/${location}/ledger`),
      )
      const agreementRef = doc(collection(database, `agreements`))

      const _services = {
        id: agreement.service.id,
        name: agreement.service.name,
      }
      const quantity = agreement.agreementCount + agreement.agreementComp

      const total = getTotalPayableAmount(
        agreement,
        saleDiscount?.percentage || 0,
      )

      const expiry = moment().add(2, 'years').toDate()

      const _doc = {
        active: true,
        type:
          agreement.type === ProductTypes.GentlemanAgreement
            ? 'service'
            : ProductTypes.InitiateAgreement,
        amount: agreement.agreementCount + agreement.agreementComp,
        price: total,
        left: quantity,
        redeemed: 0,
        pricePerService: round(total / quantity),
        storeCredit: total,
        expiryDate: Timestamp.fromDate(expiry),
        services: [_services],
        serviceName: _services.name,
        createdAt: serverTimestamp(),
        purchasedAt: serverTimestamp(),
        orderId: _id,
        order: orderId,
        userMap: customer.userId
          ? {
              [customer.userId]: true,
            }
          : {},
        users: [
          {
            userId: customer.userId,
            userName: customer.userName,
          },
        ],
        staffId: agreement.salesPerson.staffId,
        staffName: agreement.salesPerson.staffName,
      }

      transaction.set(agreementRef, _doc)

      const _ledger = {
        orderId: _id,
        order: orderId,
        amount: -total,
        date: serverTimestamp(),
        method: STORE_CREDIT_AGREEMENT,
        registerId,
        register: registerName,
        status: 1,
        staffId: order.staffId,
        staffName: order.staffName,
        userId: customer.userId,
        userName: customer.userName,
      }

      transaction.set(ledgerRef, _ledger)

      logger(
        `${agreementRef.id} has been created with ledger entry ${ledgerRef.id}`,
      )

      return {
        document: {
          _id: agreementRef.id,
          ..._doc,
        },
        ledger: {
          _id: ledgerRef.id,
          ..._ledger,
        },
      }
    }),
  )
}

/**
 *
 * @description Method used to create king and emperor agreement
 */
export const createKingsAgreements = async ({
  location,
  registerId,
  registerName,
  order,
  transaction,
  customer,
  _id,
  orderId,
  saleDiscount,
}: ArgsType) => {
  const agreements = order.products.filter((p: SaleProduct) => {
    return (
      p.type === ProductTypes.KingsAgreement ||
      p.type === ProductTypes.NobelAgreement ||
      p.type === ProductTypes.EmperorsAgreement
    )
  })

  if (!agreements.length) return false

  const kings = filter(
    agreements,
    a => a.type === ProductTypes.KingsAgreement,
  ).length
  const emperor = filter(
    agreements,
    a => a.type === ProductTypes.EmperorsAgreement,
  ).length
  const nobel = filter(
    agreements,
    a => a.type === ProductTypes.NobelAgreement,
  ).length

  logger(
    `Creating ${kings} King's Agreement, ${emperor} Emperor's Agreement & ${nobel} Nobel's Agreement from sale`,
  )

  const tags = []
  if (kings) tags.push('kings agreement')
  if (emperor) tags.push('emperor agreement')
  if (nobel) tags.push('nobel agreement')

  const analytics = useAnalytics()
  analytics.assignTagToUser({ user: customer, tags, transaction })

  return await Promise.all(
    agreements.map(async (agreement: any) => {
      const agreementRef = doc(collection(database, `agreements`))
      const ledgerRef = doc(
        collection(database, `locations/${location}/ledger`),
      )

      const expiry = moment().add(agreement.months, 'months').toDate()

      const total = getTotalPayableAmount(
        agreement,
        saleDiscount?.percentage || 0,
      )

      const _doc = {
        active: true,
        type: agreement.type,
        price: total,
        months: agreement.months,
        pricePerMonth: round(total / agreement.months),
        storeCredit: total,
        redeemed: 0,
        pricePerService: 0,
        expiryDate: Timestamp.fromDate(expiry),
        orderId: _id,
        order: orderId,
        createdAt: serverTimestamp(),
        purchasedAt: serverTimestamp(),
        userMap: customer.userId
          ? {
              [customer.userId]: true,
            }
          : {},
        users: [
          {
            userId: customer.userId,
            userName: customer.userName,
          },
        ],
        staffId: agreement.salesPerson.staffId,
        staffName: agreement.salesPerson.staffName,
      }
      transaction.set(agreementRef, _doc)

      const _ledger = {
        orderId: _id,
        order: orderId,
        amount: -total,
        date: serverTimestamp(),
        method: STORE_CREDIT_AGREEMENT,
        registerId,
        register: registerName,
        status: 1,
        staffId: order.staffId,
        staffName: order.staffName,
        userId: customer.userId,
        userName: customer.userName,
      }
      transaction.set(ledgerRef, _ledger)

      logger(
        `Agreement ${agreementRef.id}  has been created with ledger entry ${ledgerRef.id}`,
      )

      return {
        document: {
          _id: agreementRef.id,
          ..._doc,
        },
        ledger: {
          _id: ledgerRef.id,
          ..._ledger,
        },
      }
    }),
  )
}

export const createGiftCheque = async ({
  registerId,
  registerName,
  order,
  location,
  transaction,
  customer,
  _id,
  orderId,
}: ArgsType) => {
  const cheques = order.products.filter(
    (p: SaleProduct) => p.type === ProductTypes.GiftCheque,
  )

  if (!cheques.length) return true

  logger(`Creating ${cheques.length} Giftcheque from sale`)

  return await Promise.all(
    cheques.map(async (cheque: any) => {
      const chequeRef = doc(collection(database, `giftcheques`))
      const ledgerRef = doc(
        collection(database, `locations/${location}/ledger`),
      )

      const expiry = moment().add(2, 'years').toDate()

      const _doc = {
        orderId: _id,
        order: orderId,
        active: true,
        value: cheque.price,
        code: cheque.code,
        storeCredit: cheque.price,
        expiryDate: Timestamp.fromDate(expiry),
        id: cheque.id,
        purchasedAt: serverTimestamp(),
        salesChannel: `POS - ${location}`,
        staffId: cheque.salesPerson.staffId,
        staffName: cheque.salesPerson.staffName,
        userId: customer.userId,
        userName: customer.userName,
      }
      transaction.set(chequeRef, _doc)

      const _ledger = {
        orderId: _id,
        order: orderId,
        amount: -cheque.price,
        date: serverTimestamp(),
        method: STORE_CREDIT_GIFTCHEQUE,
        registerId: registerId,
        register: registerName,
        status: 1,
        staffId: order.staffId,
        staffName: order.staffName,
        userId: customer.userId,
        userName: customer.userName,
      }

      transaction.set(ledgerRef, _ledger)

      logger(
        `Gift Cheque ${chequeRef.id} has been created with ledger entry ${ledgerRef.id}`,
      )

      return {
        document: {
          _id: chequeRef.id,
          ..._doc,
        },
        ledger: {
          _id: ledgerRef.id,
          ..._ledger,
        },
      }
    }),
  )
}

export const createStoreCredit = async ({
  registerId,
  registerName,
  order,
  location,
  transaction,
  customer,
  _id,
  orderId,
}: ArgsType) => {
  const credits = order.products.filter(
    (p: SaleProduct) => p.type === ProductTypes.StoreCredit,
  )

  if (!credits.length) return true

  logger(`Creating ${credits.length} StoreCredit from sale`)

  return await Promise.all(
    credits.map(async (credit: any) => {
      const creditRef = doc(collection(database, `storecredit`))
      const ledgerRef = doc(
        collection(database, `locations/${location}/ledger`),
      )

      const _doc = {
        orderId: _id,
        order: orderId,
        active: true,
        value: credit.price,
        storeCredit: credit.price,
        id: credit.id,
        purchasedAt: serverTimestamp(),
        salesChannel: `POS - ${location}`,
        staffId: credit.salesPerson.staffId,
        staffName: credit.salesPerson.staffName,
        userId: customer.userId,
        userName: customer.userName,
      }
      transaction.set(creditRef, _doc)

      const _ledger = {
        orderId: _id,
        order: orderId,
        amount: -credit.price,
        date: serverTimestamp(),
        method: STORE_CREDIT,
        registerId: registerId,
        register: registerName,
        status: 1,
        staffId: order.staffId,
        staffName: order.staffName,
        userId: customer.userId,
        userName: customer.userName,
      }

      transaction.set(ledgerRef, _ledger)

      logger(
        `Store credit ${creditRef.id} has been created with ledger entry ${ledgerRef.id}`,
      )

      return {
        document: {
          _id: creditRef.id,
          ..._doc,
        },
        ledger: {
          _id: ledgerRef.id,
          ..._ledger,
        },
      }
    }),
  )
}

export const createLockerBox = async ({
  registerId,
  registerName,
  order,
  location,
  transaction,
  customer,
  _id,
  orderId,
  saleDiscount,
}: ArgsType) => {
  const boxes = order.products.filter(
    (p: SaleProduct) => p.type === ProductTypes.LockerBox,
  )

  if (!boxes.length) return true

  logger(`Creating ${boxes.length} Lockerbox from sale`)

  const analytics = useAnalytics()
  analytics.assignTagToUser({
    user: customer,
    tags: ['locker box'],
    transaction,
  })

  return await Promise.all(
    boxes.map(async (box: any) => {
      const boxRef = doc(
        collection(database, `locations/${location}/lockerbox`),
      )
      const ledgerRef = doc(
        collection(database, `locations/${location}/ledger`),
      )

      const expiry = moment().add(box.months, 'months').toDate()

      const total = getTotalPayableAmount(box, saleDiscount?.percentage || 0)

      const _doc = {
        active: true,
        price: total,
        months: box.months,
        serviceName: `Lockerbox No. ${box.lockerNumber}`,
        pricePerMonth: round(total / box.months),
        storeCredit: total,
        redeemed: 0,
        freeGuests: box.freeGuests,
        number: box.lockerNumber,
        expiryDate: Timestamp.fromDate(expiry),
        purchasedAt: serverTimestamp(),
        orderId: _id,
        order: orderId,
        userId: customer.userId,
        userName: customer.userName,
        staffId: box.salesPerson.staffId,
        staffName: box.salesPerson.staffName,
      }
      transaction.set(boxRef, _doc)

      const _ledger = {
        orderId: _id,
        order: orderId,
        amount: -total,
        date: serverTimestamp(),
        method: STORE_CREDIT_LOCKERBOX,
        registerId,
        register: registerName,
        status: 1,
        staffId: order.staffId,
        staffName: order.staffName,
        userId: customer.userId,
        userName: customer.userName,
      }
      transaction.set(ledgerRef, _ledger)

      logger(
        `Lockerbox ${boxRef.id} has been created with ledger entry ${ledgerRef.id}`,
      )

      return {
        document: {
          _id: boxRef.id,
          ..._doc,
        },
        ledger: {
          _id: ledgerRef.id,
          ..._ledger,
        },
      }
    }),
  )
}

export const createTickets = async ({
  location,
  order,
  transaction,
  customer,
  _id,
  orderId,
  saleDiscount,
}: ArgsType) => {
  const tickets = order.products.filter(
    (p: SaleProduct) => p.type === ProductTypes.Ticket,
  )

  if (!tickets.length) return false

  logger(`Creating ${tickets.length} Tickets from sale`)

  const analytics = useAnalytics()
  analytics.assignTagToUser({
    user: customer,
    tags: ['agreement'],
    transaction,
  })

  const create = async (ticket: SaleProduct) => {
    const ticketRef = doc(
      collection(database, `locations/${location}/tickets/${ticket.id}/issued`),
    )

    const total = getTotalPayableAmount(ticket, saleDiscount?.percentage || 0)

    const _doc = {
      date: Timestamp.fromDate(moment().toDate()),
      orderId: _id,
      order: orderId,
      redeemed: false,
      staffId: ticket.salesPerson?.staffId,
      staffName: ticket.salesPerson?.staffName,
      userId: customer.userId,
      userName: customer.userName,
      price: total,
      purchasedAt: serverTimestamp(),
    }

    transaction.set(ticketRef, _doc)

    logger(`Ticket ${ticketRef.id} is sold to customer ${customer.userId}`)
  }

  return await Promise.all(
    tickets.map(async (ticket: any) => {
      await Promise.all(times(ticket.quantity, async () => create(ticket)))
      return ticket
    }),
  )
}

export const getRedeemedPoints = (payments: Array<any>) => {
  const redeemedPoints = {
    groom: 0,
    network: 0,
    style: 0,
  }

  const _grooming = find(payments, p => p.method === ROYALTY_POINTS_GROOMING)
  if (_grooming) redeemedPoints.groom = _grooming.amount

  const _networking = find(
    payments,
    p => p.method === ROYALTY_POINTS_NETWORKING,
  )
  if (_networking) redeemedPoints.network = _networking.amount

  const _style = find(payments, p => p.method === ROYALTY_POINTS_STYLING)
  if (_style) redeemedPoints.style = _style.amount

  return redeemedPoints
}
