<template>
  <div class="tw-grid tw-grid-cols-2 tw-gap-4 lg:tw-grid-cols-4">
    <div
      class="tw-card tw-shadow-md tw-cursor-pointer tw-border tw-border-gray-300 tw-transition tw-duration-100 hover:tw-border-primary"
    >
      <div
        class="tw-card-body tw-p-2 tw-flex tw-items-center tw-justify-center"
        @click="emit('close')"
      >
        <BackArrowIcon
          class="tw-text-primary hover:tw-text-secondary tw-w-8 tw-h-8"
        />
      </div>
    </div>
    <ProductCard
      v-for="product in items"
      :key="product.id"
      :product="product"
      @add="saveItem(product)"
    />
    <ProductVariants
      :visible="showVariants"
      :product="selectedProduct"
      @selected="saveItem"
    />
  </div>
</template>

<script lang="ts" setup>
import ProductVariants from '@/components/modal/ProductVariants.vue'
import {
  ProductTypes,
  useInventory,
  type BrandType,
  type InventoryProduct,
} from '@/stores/inventory'
import { useSale } from '@/stores/sale/sale'
import { assign } from 'lodash'
import { generateSaleItem } from '@/utilities/sale'
import BackArrowIcon from '@/components/icons/BackArrowIcon.vue'
import ProductCard from './components/ProductCard.vue'
import { orderBy } from 'lodash'
import { reactive, computed, ref } from 'vue'

const inventory = useInventory()
const sale = useSale()

const selectedProduct = reactive({} as InventoryProduct)
const showVariants = ref<boolean>(false)

const items = computed(() => {
  const brand = inventory.activeProductBrand as BrandType
  return orderBy(
    inventory.products.filter((product: InventoryProduct) => {
      if (brand.id === 1) return true
      return product.category.id == brand.id
    }),
    ['title'],
    ['asc'],
  )
})

const emit = defineEmits(['close'])

const saveItem = (product: InventoryProduct) => {
  if (product.type === ProductTypes.Product && product.variants.length) {
    assign(selectedProduct, product)
    showVariants.value = true
    return
  }

  showVariants.value = false

  const item = generateSaleItem({
    id: product.id,
    category: ProductTypes.Product,
    type: ProductTypes.Product,
    brand: product.category,
    name: product.title,
    price: product.price,
    originalPrice: product.price,
    storeCategory: product.storeCategory,
    storeBrand: product.storeBrand,
    VAT: product.VAT,
    serviceCharge: product.serviceCharge,
    brandName: product.data.brandName,
    cost: product.data.importPrice,
    points: product.data.points,
  })

  sale.add(item)
}
</script>
