<template>
  <div v-if="shouldRender">
    <div
      v-for="(code, index) in customer.affiliateCodes"
      :key="`affiliate-code-${index}`"
      className="tw-badge tw-rounded tw-bg-amber-500 tw-text-white tw-border-none tw-p-3"
    >
      {{ code }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSale } from '@/stores'
import { computed, ref } from 'vue'

const loading = ref(false)

const sale = useSale()
const customer = computed(() => sale.customer)
const shouldRender = computed(() => customer.value.affiliateCodes.length)
</script>
