import { createRouter, createWebHistory } from 'vue-router'
import { Auth } from './middlewares/auth'
import { RegisterSession } from './middlewares/register'

import Authentication from '@/views/auth/Login.vue'
import Home from '@/views/home/Home.vue'
import CreateRegister from '@/views/register/Create.vue'
import OpenRegister from '@/views/register/Open.vue'
import CloseRegister from '@/views/register/Close.vue'
import CashManagement from '@/views/register/CashManagement.vue'
import InitialRegister from '@/views/register/Initial.vue'
import Appointment from '@/views/home/Appointment.vue'
import Tables from '@/views/home/Tables.vue'
import CreateSale from '@/views/sale/CreateSale.vue'
import Sale from '@/views/sale/Sale.vue'
import Payment from '@/views/sale/Payment.vue'
import Persona from '@/views/sale/Persona.vue'
import PreparationQueue from '@/views/preparation-queue/PreparationQueue.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: Home,
      children: [
        {
          path: '',
          component: Tables,
          name: 'home',
          meta: {
            middleware: [Auth, RegisterSession],
          },
        },
        {
          path: 'appointment/:id',
          name: 'appointment.detail',
          component: Appointment,
          props: route => ({
            ...route.params,
          }),
          meta: {
            middleware: [Auth, RegisterSession],
          },
        },
      ],
      meta: {
        middleware: [Auth, RegisterSession],
      },
    },
    {
      path: '/register',
      component: CreateRegister,
      name: 'register',
      children: [
        {
          path: 'open',
          name: 'register.open',
          component: InitialRegister,
          meta: {
            middleware: [Auth],
          },
        },
        {
          path: 'create',
          name: 'register.create',
          component: OpenRegister,
          props: route => ({
            ...route.params,
          }),
          meta: {
            middleware: [Auth],
          },
        },
        {
          path: 'close',
          name: 'register.close',
          component: CloseRegister,
          props: route => ({
            ...route.params,
          }),
          meta: {
            middleware: [Auth, RegisterSession],
          },
        },
        {
          path: 'cash-management',
          name: 'register.cash-management',
          component: CashManagement,
          props: route => ({
            ...route.params,
          }),
          meta: {
            middleware: [Auth, RegisterSession],
          },
        },
      ],
      meta: {
        middleware: [Auth, RegisterSession],
      },
    },
    {
      path: '/sale',
      children: [
        {
          path: 'create',
          name: 'sale.create',
          component: CreateSale,
          meta: {
            middleware: [Auth, RegisterSession],
          },
        },
        {
          path: '/sale/:orderId',
          name: 'sale',
          component: Sale,
          meta: {
            middleware: [Auth, RegisterSession],
          },
        },
      ],
      meta: {
        middleware: [Auth, RegisterSession],
      },
    },
    {
      path: '/payment/:orderId',
      name: 'payment',
      component: Payment,
      meta: {
        middleware: [Auth, RegisterSession],
      },
    },
    {
      path: '/persona/:orderId',
      name: 'persona',
      component: Persona,
      meta: {
        middleware: [Auth, RegisterSession],
      },
      props: true,
    },
    {
      path: '/preparation-queue',
      name: 'preparation.queue',
      component: PreparationQueue,
      meta: {
        middleware: [Auth, RegisterSession],
      },
      props: true,
    },
    {
      path: '/auth/login',
      name: 'login',
      component: Authentication,
    },
  ],
})

router.beforeEach(async (to: any, from) => {
  const context = { to, from }

  if (to.meta.middleware) {
    const middlewares = to.meta.middleware

    for (const middleware of middlewares) {
      const route = await middleware(context)
      if (route) {
        return route
      }
    }
  }
})

export default router
