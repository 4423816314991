<template>
  <div>
    <div
      v-if="hasLoyaltyPoints"
      class="tw-border tw-p-2 tw-rounded-lg tw-mb-2 tw-cursor-pointer hover:tw-border-gray-400 hover:tw-bg-gray-50"
      @click="(isLoyaltyModelVisible = true)"
    >
      <div class="tw-text-center tw-text-gray-700 tw-font-bold">
        Loyalty Credits
      </div>
      <div class="tw-text-center tw-text-gray-600 tw-text-sm">
        {{ loyaltyPointsLabel }}
      </div>
    </div>

    <LoyaltyPoints
      v-model:visible="isLoyaltyModelVisible"
      @apply="points => $emit('apply', points)"
    />
  </div>
</template>

<script lang="ts" setup>
import LoyaltyPoints from '@/components/modal/LoyaltyPoints.vue'
import { useLoyalty } from '@/stores/sale/loyalty'
import { useSale } from '@/stores/sale/sale'
import { formatNumber } from '@/utilities/common'
import { ref, computed } from 'vue'

const loyalty = useLoyalty()
const sale = useSale()

const isLoyaltyModelVisible = ref(false)

const hasLoyaltyPoints = computed(() => loyalty.hasLoyaltyPoints)

const loyaltyPointsLabel = computed<string>(() => {
  const labels = []
  const userLoyalty = sale.customer.userLoyalty

  if (userLoyalty.groomPoints)
    labels.push(`Grooming Credit ${formatNumber(userLoyalty.groomPoints)}`)
  if (userLoyalty.networkPoints)
    labels.push(`Networking Credit ${formatNumber(userLoyalty.networkPoints)}`)
  if (userLoyalty.stylePoints)
    labels.push(`Styling Credit ${formatNumber(userLoyalty.stylePoints)}`)

  return labels.join(' | ')
})
</script>
