import { computed, type ComputedRef, useTemplateRef } from 'vue'
import { isEmpty, trim, has } from 'lodash'
import {
  calculateServiceCharge,
  calculateVAT,
  getOriginalTotal,
  getSubTotalAmount,
  getTotalPayableAmount,
} from '@/utilities/sale'
import { useSale } from '@/stores/sale/sale'
import { determinePoints } from '@/utilities/common'
import { ProductTypes } from '@/stores/inventory'
import type { AppliedPromotionType } from '@/stores/sale'

export function useComposableSaleItem(item: any, index: number) {
  const saleStore = useSale()
  const saleItemRef = useTemplateRef(`sale-item-${item.internalId}`)

  const promotion: ComputedRef<boolean | AppliedPromotionType> = computed(() =>
    isEmpty(item.promotion) ? false : item.promotion,
  )

  const originalTotal: ComputedRef<number> = computed(() =>
    getOriginalTotal(item),
  )

  const vat: ComputedRef<number> = computed(() => {
    if (item.type === ProductTypes.GentlemanAgreement) {
      return item.service ? calculateVAT(item.service) : 0
    }
    return calculateVAT(item)
  })

  const serviceCharge: ComputedRef<number> = computed(() => {
    if (item.type === ProductTypes.GentlemanAgreement) {
      return item.service ? calculateServiceCharge(item.service) : 0
    }
    return calculateServiceCharge(item)
  })

  const priceToConsider: ComputedRef<number> = computed(() => {
    if (item.type === ProductTypes.GentlemanAgreement) {
      return item.service?.price || 0
    }
    if (item.discount) {
      return item.priceAfterDiscount
    } else if (item.manualPrice) {
      return item.manualPrice
    } else {
      return item.price
    }
  })

  const price: ComputedRef<number> = computed(() => {
    switch (item.type) {
      case ProductTypes.GentlemanAgreement:
        return item.service?.price || 0
      default:
        return item.price
    }
  })

  const total = computed(() => getTotalPayableAmount(item))

  const subTotal: ComputedRef<number> = computed(() => getSubTotalAmount(item))

  const tax: ComputedRef<string> = computed(() => {
    if (!item) return ''

    const { VAT = {}, serviceCharge = {} } = item
    const labels = []

    if (has(VAT, 'amount')) labels.push(`VAT ${VAT.label}`)
    if (has(serviceCharge, 'amount'))
      labels.push(`Service Charge ${serviceCharge.label}`)

    if (!labels.length) return ''

    return trim(labels.join(' & '))
  })

  const update = (property: string, value: unknown, min?: unknown) => {
    if (!index && index != 0) {
      console.error(
        `Product mixin update called without item index in component`,
      )
    }

    const _value = !value && min ? min : value

    const update = { [property]: _value }
    saleStore.updateProperty(index, update)
  }

  const applyDiscount = (discount: number, recomputePoints = false) => {
    if (discount > 100 || discount < 0 || discount === item.discount) {
      return
    }

    const common: {
      manualPromotion: boolean
      manualPrice: number
      service?: object
      promotion: any
    } = {
      manualPromotion: true,
      manualPrice: 0,
      promotion: {},
    }

    if (!discount) {
      if (
        [
          ProductTypes.GentlemanAgreement,
          ProductTypes.InitiateAgreement,
        ].includes(item.type)
      ) {
        if (has(item, 'service') && !isEmpty(item.service)) {
          common.service = {
            ...item.service,
            discount: 0,
            manualPromotion: true,
            priceAfterDiscount: 0,
          }
        }
      }
      saleStore.updateProperty(index, {
        ...common,
        discount: 0,
        priceAfterDiscount: 0,
      })
      return
    }

    const { price } = item
    const priceAfterDiscount = price - (price / 100) * discount

    const points = recomputePoints
      ? determinePoints(priceAfterDiscount - item.cost)
      : item.points

    if (
      [
        ProductTypes.GentlemanAgreement,
        ProductTypes.InitiateAgreement,
      ].includes(item.type)
    ) {
      if (has(item, 'service') && !isEmpty(item.service)) {
        const { price } = item.service
        const priceAfterDiscount = price - (price / 100) * discount

        const points = recomputePoints
          ? determinePoints(priceAfterDiscount - item.service?.cost)
          : item.service?.points

        common.service = {
          ...item.service,
          discount: discount,
          manualPromotion: true,
          priceAfterDiscount,
          points,
        }
      }
    }

    saleStore.updateProperty(index, {
      ...common,
      discount: discount,
      priceAfterDiscount,
      points,
    })
  }

  const onPromotionRemove = () => {
    saleStore.removePromotionFromProduct(item)
  }

  const onStaffMemberChange = (staff: object) => {
    saleStore.updateProperty(index, { salesPerson: staff })
  }

  const scrollToSelf = () => {
    if (saleItemRef.value) {
      const el = saleItemRef.value as HTMLElement
      el.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }

  return {
    // computed
    promotion,
    originalTotal,
    vat,
    serviceCharge,
    priceToConsider,
    price,
    subTotal,
    total,
    tax,

    // callable
    update,
    applyDiscount,
    onPromotionRemove,
    onStaffMemberChange,
    scrollToSelf,
    updateProperty: (update: object) => saleStore.updateProperty(index, update),
  }
}
