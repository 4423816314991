<template>
  <div id="app">
    <div class="layout-full">
      <div id="nav" v-if="shouldDisplayNavigation">
        <Navigation ref="nagivation" :routes="routes">
          <multiple-option
            v-if="app.nav === NavbarTypes.MultipleOptionNavbar"
          />
          <back-button-nav
            v-if="app.nav === NavbarTypes.BackButtonOptionNavabar"
          />
        </Navigation>
      </div>

      <router-view></router-view>
    </div>
    <section id="outside-portal" />
  </div>
</template>

<script lang="ts" setup>
import { RouterView, useRoute } from 'vue-router'

import Navigation from '@/components/navigation/Navigation.vue'
import BackButtonNav from '@/components/navigation/BackButtonNav.vue'
import MultipleOption from '@/components/navigation/MultipleOptionNav.vue'
import { useAnalytics, useApp } from '@/stores'
import { computed } from 'vue'
import { NavbarTypes } from './stores/app'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const analytics = useAnalytics()
const app = useApp()

const route = useRoute()
const routes = [
  {
    title: 'Sales',
    to: '/',
    name: 'home',
    class: '',
  },
  {
    title: 'Cash Management',
    to: '/register/cash-management',
    name: 'register.cash-management',
    class: '',
  },
  {
    title: 'Close Register',
    to: '/register/close',
    name: 'register.close',
    class: '',
  },
  {
    title: 'Preparation Queue',
    to: '/preparation-queue',
    name: 'preparation.queue',
    class: '',
  },
]

const shouldDisplayNavigation = computed<boolean>(() => {
  return route.name !== 'login'
})
</script>
