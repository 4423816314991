<template>
  <div
    class="tw-font-bold tw-flex tw-flex-col tw-border tw-rounded-lg tw-shadow-sm tw-h-40 tw-cursor-pointer hover:tw-border-primary hover:tw-bg-primary hover:tw-bg-opacity-10 hover:tw-text-primary"
    v-bind="$attrs"
  >
    <div
      class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center tw-h-40"
    >
      <strong v-if="props.title">{{ props.title }}</strong>
      <slot></slot>
    </div>
    <Points v-if="props.points" :points="props.points" />
  </div>
</template>

<script lang="ts" setup>
import Points from '../../sale/components/Points.vue'
import { defineProps } from 'vue'

const props = defineProps({
  title: String,
  points: {
    type: Number,
    required: false,
  },
})
</script>
