import { collection, getDocs, query, where } from 'firebase/firestore'
import { defineStore } from 'pinia'
import database from '@/config/firebase/database'
import { filter, has, intersection, isEmpty, map, some } from 'lodash'
import { type Agreement } from './types'
import { reactive, computed } from 'vue'
import { useSale } from '@/stores/sale'
import { KingAgreementCategories, ProductTypes } from '../inventory'
import debug from 'debug'
const logger = debug('agreement')

export const useAgreement = defineStore('agreement', () => {
  const sale = useSale()

  const user = reactive({})

  const isAuthenticated = computed(() => {
    return !isEmpty(user)
  })

  const fetchAgreements = async () => {
    const customer = sale.customer
    const products = sale.products

    const agreements = {
      initiate: [],
      gentleman: [],
      nobel: [],
      king: [],
      emperor: [],
      merchant: [],
    } as {
      initiate: Array<Agreement>
      gentleman: Array<Agreement>
      nobel: Array<Agreement>
      king: Array<Agreement>
      emperor: Array<Agreement>
      merchant: Array<Agreement>
    }

    if (!customer.userId) {
      logger(
        'Could not load customer agreements, customer is not added in order',
      )
      return agreements
    }

    const agreementQuery = query(
      collection(database, `agreements`),
      where(`userMap.${customer.userId}`, '==', true),
    )
    const agreementSnapshot = await getDocs(agreementQuery)

    const serviceIds = map(
      filter(products, (p: any) => p.type === ProductTypes.Service),
      'id',
    )
    const upgradeIds = map(
      filter(products, (p: any) => p.type === ProductTypes.Upgrade),
      'id',
    )

    agreementSnapshot.forEach(agreement => {
      const _data: any = { _id: agreement.id, ...agreement.data() }

      const agreementServices = map(_data?.services, 'id')
      const agreementUpgrades = map(_data?.upgrades, 'id')

      switch (_data.type) {
        case ProductTypes.Service:
          if (!_data.amount) return
          if (has(_data, 'active') && !_data.active) return

          if (intersection(serviceIds, agreementServices).length) {
            agreements.gentleman.push({ ..._data, applicable: true })
          } else {
            agreements.gentleman.push({ ..._data, applicable: false })
            logger(
              `Gentleman's Agreement ${_data._id} is not applicable to current order`,
            )
          }
          return
        case ProductTypes.InitiateAgreement:
          if (!_data.amount) return
          if (has(_data, 'active') && !_data.active) return

          if (intersection(serviceIds, agreementServices).length) {
            agreements.initiate.push({ ..._data, applicable: true })
          } else {
            agreements.initiate.push({ ..._data, applicable: false })
            logger(
              `Initiate Agreement ${_data._id} is not applicable to current order`,
            )
          }
          return
        case ProductTypes.MerchantsAgreement:
        case ProductTypes.GrandMerchantsAgreement:
          if (new Date(_data.expiryDate.seconds * 1000).getTime() < Date.now())
            return
          if (_data.redeemed >= _data.amount) return
          agreements.merchant.push({ ..._data, applicable: true })
          return
        case ProductTypes.KingsAgreement:
          if (new Date(_data.expiryDate.seconds * 1000).getTime() < Date.now())
            return

          if (serviceIds.length) {
            const categories = map(
              filter(products, (p: any) => p.type === ProductTypes.Service),
              'serviceCategory',
            )
            if (
              some(categories, (c: string) =>
                KingAgreementCategories.includes(c),
              )
            ) {
              agreements.nobel.push({ ..._data, applicable: true })
            }
          } else {
            agreements.nobel.push({ ..._data, applicable: false })
            logger(
              `Nobel's Agreement ${_data._id} is not valid for current order`,
            )
          }
          return
        case ProductTypes.NobelAgreement:
          if (new Date(_data.expiryDate.seconds * 1000).getTime() < Date.now())
            return

          if (serviceIds.length) {
            const categories = map(
              filter(products, (p: any) => p.type === ProductTypes.Service),
              'serviceCategory',
            )
            if (
              some(categories, (c: string) =>
                KingAgreementCategories.includes(c),
              )
            ) {
              agreements.king.push({ ..._data, applicable: true })
            }
          } else {
            agreements.king.push({ ..._data, applicable: false })
            logger(
              `King's Agreement ${_data._id} is not valid for current order`,
            )
          }
          return
        case ProductTypes.EmperorsAgreement:
          if (new Date(_data.expiryDate.seconds * 1000).getTime() < Date.now())
            return
          if (!_data.active) return

          agreements.emperor.push({ ..._data, applicable: true })

          return
        case 'upgrades':
          if (has(_data, 'active') && !_data.active) return

          if (intersection(upgradeIds, agreementUpgrades).length) {
            agreements.gentleman.push({ ..._data, applicable: true })
          } else {
            agreements.gentleman.push({ ..._data, applicable: false })
            logger(
              `Upgrade Agreement ${_data._id} is not applicable to current order`,
            )
          }
          return
        default:
          return
      }
    })

    return agreements
  }

  return {
    user,
    isAuthenticated,
    fetchAgreements,
  }
})
