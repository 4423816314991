<template>
  <div
    :ref="`sale-item-${item.internalId}`"
    tabIndex="{0}"
    class="tw-collapse tw-rounded-lg tw-border-b tw-border-gray-300"
    :class="{
      'tw-collapse-open': visible,
      'tw-collapse-close': !visible,
      'tw-border tw-border-danger': !isEmpty(errors),
    }"
  >
    <div class="tw-collapse-title tw-py-2 tw-px-2">
      <ItemCardContent
        :item="item"
        :index="index"
        :details-visible="visible"
        @expand="onExpand"
        @assign="onStaffMemberChange"
      />
    </div>

    <div class="tw-collapse-content tw-flex tw-flex-col tw-gap-2">
      <div class="tw-flex tw-gap-2">
        <div role="group" class="tw-flex-1">
          <p class="tw-text-sm tw-mb-0">Months</p>
          <NumberInput
            min="0"
            :value="item.months"
            @input="value => update('months', value)"
            class="tw-input tw-input-sm tw-border tw-border-gray-300 tw-rounded-lg tw-w-full focus:tw-outline-none"
          />
        </div>
        <div role="group" class="tw-flex-1">
          <p class="tw-text-sm tw-mb-0">Discount (₫)</p>
          <NumberInput
            min="0"
            max="100"
            :value="item.discount"
            @input="value => applyDiscount(value)"
            class="tw-input tw-input-sm tw-border tw-border-gray-300 tw-rounded-lg tw-w-full focus:tw-outline-none"
          />
        </div>
      </div>

      <div v-if="item.promotion?._id" class="tw-flex tw-justify-between">
        <p class="tw-text-sm tw-mb-0">
          <b>Promotion:</b> {{ item.promotion.name }}
        </p>
        <TrashIcon
          class="tw-w-4 tw-h-4 tw-cursor-pointer hover:tw-opacity-80"
          @click="onPromotionRemove"
        />
      </div>

      <p class="tw-text-sm" v-if="tax">
        {{ tax }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { isEmpty } from 'lodash'
import ItemCardContent from './ItemCardContent.vue'
import { TrashIcon } from '@/components/icons'
import { reactive, ref, type PropType, defineProps } from 'vue'
import { useComposableSaleItem } from '@/composables/sale-item'
import type { KingsAgreementSaleProduct } from '@/stores/sale'
import NumberInput from '../common/NumberInput.vue'

const props = defineProps({
  item: {
    type: Object as PropType<KingsAgreementSaleProduct>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
})

const {
  tax,
  onPromotionRemove,
  applyDiscount,
  update,
  onStaffMemberChange,
  scrollToSelf,
} = useComposableSaleItem(props.item, props.index)

let errors = reactive<{ [property: string]: string }>({})
const visible = ref<boolean>(false)

const validator = () => {
  if (!props.item.months) {
    errors = {
      months: 'Number of months required',
    }
    return false
  }
  errors = {}
  return true
}

const onExpand = () => {
  visible.value = !visible.value

  if (visible.value) {
    scrollToSelf()
  }
}

defineExpose({
  validator,
})
</script>
