<template>
  <div>
    <div class="drink pt-3" v-if="shouldShowDrink">
      <div class="tw-flex tw-justify-between">
        <p class="tw-mb-0 tw-text-sm">Select drink</p>
        <p class="tw-mb-0 tw-text-sm">free</p>
      </div>

      <select
        class="tw-select tw-select-sm tw-flex-shrink tw-w-full tw-px-3 tw-font-normal tw-text-base tw-rounded-lg tw-border tw-border-gray-300 focus:tw-outline-none"
        :value="item?.complimentaryDrink?.id"
        @change="onDrinkSelected"
      >
        <option v-for="drink in drinks" :key="drink.value" :value="drink.value">
          {{ drink.text }}
        </option>
      </select>
    </div>

    <div v-if="shouldShowFood">
      <div class="tw-flex tw-justify-between">
        <p class="tw-mb-0 tw-text-sm">Select snack</p>
        <p class="tw-mb-0 tw-text-sm">free</p>
      </div>

      <select
        class="tw-select tw-select-sm tw-flex-shrink tw-w-full tw-px-3 tw-font-normal tw-text-base tw-rounded-lg tw-border tw-border-gray-300 focus:tw-outline-none"
        :value="item?.complimentaryFood?.id"
        @change="onFoodSelected"
      >
        <option
          v-for="food in foodOptions"
          :key="food.value"
          :value="food.value"
        >
          {{ food.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { map, orderBy, filter, startCase, find, isEmpty, has } from 'lodash'
import {
  ProductTypes,
  useInventory,
  type InventoryProduct,
} from '@/stores/inventory'
import { useRegister } from '@/stores/register'
import { reactive, computed, defineProps, onMounted } from 'vue'
import { useComposableSaleItem } from '@/composables/sale-item'
import type Product from '@/stores/inventory/product'

type OptionType = {
  text: string
  value: number | string
}

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  autoHide: Boolean,
})

const inventory = useInventory()
const register = useRegister()

const drinkOptions = reactive<OptionType[]>([])
const foodOptions = reactive<OptionType[]>([])
const upgrades = reactive<OptionType[]>([])
const complimentary = reactive<Product[]>([])

const { update } = useComposableSaleItem(props.item, props.index)

const shouldShowDrink = computed<boolean>(() => {
  return (
    props.item.complimentaries?.drink &&
    (props.autoHide
      ? !has(props.item, 'complimentaryDrink') ||
        isEmpty(props.item.complimentaryDrink)
      : true)
  )
})

const shouldShowFood = computed<boolean>(() => {
  return (
    props.item.complimentaries?.food &&
    (props.autoHide
      ? !has(props.item, 'complimentaryFood') ||
        isEmpty(props.item.complimentaryFood)
      : true)
  )
})

const drinks = computed(() => {
  if (props.item.type === ProductTypes.Upgrade) {
    return upgrades
  }

  return drinkOptions
})

const addComplimentary = (id: string, type: 'drink' | 'food') => {
  const property = type === 'drink' ? 'complimentaryDrink' : 'complimentaryFood'

  if (id === '1') {
    const label = startCase(type)
    update(property, {
      text: label,
      value: 1,
      id: 1,
    })

    return
  }

  const product: InventoryProduct =
    props.item.type === ProductTypes.Upgrade
      ? (find(inventory.complimentaryUpgrades, c => c.id === id) as Product)
      : (find(complimentary, c => c.id === id) as Product)

  if (product) {
    /**
     * @description Special case when upgrade has complimentary
     * item attached, update the parent product price as it relies
     * on the linked item.
     *
     */
    const item = {
      type: ProductTypes.FnB,
      quantity: 1,
      category: ProductTypes.FnB,
      brand: product.category,
      id: product.id,
      name: product.title,
      price: product.price,
      originalPrice: product.price,
      storeCategory: product.storeCategory,
      storeBrand: product.storeBrand,
      VAT: product.VAT,
      serviceCharge: product.serviceCharge,
      categoryName: product.data.CategoryName,
      cost: product.data.BOMPrice,
      salesPerson: props.item.salesPerson,
    }

    if (props.item.type === ProductTypes.Upgrade) {
      update('price', product.price)
    } else {
      item.type = ProductTypes.Complimentary
      item.category = ProductTypes.Complimentary
      item.categoryName =
        type === 'drink' ? 'Complimentary Drink' : 'Complimentary Food'
    }

    update(property, item)
  }
}

const onDrinkSelected = (event: Event) => {
  const id = (event.target as HTMLInputElement).value
  addComplimentary(id, 'drink')
}
const onFoodSelected = (event: Event) => {
  const id = (event.target as HTMLInputElement).value
  addComplimentary(id, 'food')
}

const getComplimentaryItems = () => {
  complimentary.splice(0, complimentary.length)

  const _foodOptions = [
    {
      text: 'No Snack',
      value: '1',
    },
    ...orderBy(
      map(
        filter(
          inventory.FoodComplimentary,
          f => f.location === register.location,
        ),
        c => {
          complimentary.push(c)
          return {
            text: c.title,
            value: c.id,
          }
        },
      ),
      ['text'],
      ['asc'],
    ),
  ]
  foodOptions.splice(0, foodOptions.length, ..._foodOptions)

  const _drinkOptions = [
    {
      text: 'No Drink',
      value: '1',
    },
    ...orderBy(
      map(
        filter(
          inventory.DrinkComplimentary,
          f => f.location === register.location,
        ),
        c => {
          complimentary.push(c)
          return {
            text: c.title,
            value: c.id,
          }
        },
      ),
      ['text'],
      ['asc'],
    ),
  ]
  drinkOptions.splice(0, drinkOptions.length, ..._drinkOptions)

  upgrades.splice(
    0,
    upgrades.length,
    ...map(inventory.complimentaryUpgrades, c => ({
      text: c.title,
      value: c.id,
    })),
  )
}

onMounted(() => {
  getComplimentaryItems()
})
</script>
