import type { FieldValue, Timestamp } from 'firebase/firestore'

export enum PaymentMethods {
  internalCashManagement = 'Internal Cash Management',
  storeCreditAgreement = 'Store Credit - Agreement',
  storeCreditGiftCheque = 'Store Credit - Gift Cheque',
  storeCreditLockerbox = 'Store Credit - Lockerbox',
  storeCredit = 'Store Credit',
}

export enum MovementType {
  add = 'add',
  remove = 'remove',
}

export type CashLedgerEntry = {
  amount: number
  date: Timestamp
  method: PaymentMethods
  reason: string
  registerId: string
  registerName: string
  staffId: string
  staffName: string
  type: MovementType
}

export type CreditCashLedgerEntry = CashLedgerEntry & {
  credit: number
}

export type DebitCashLedgerEntry = CashLedgerEntry & {
  debit: number
}

export type RegisterType = {
  _id: string
  closedAt: string | FieldValue
  closedByUserId: string
  closedByUserName: string
  closingNote: string
  code: number
  openStatus: boolean
  openedAt: string | Timestamp
  openedByUserId: string
  openedByUserName: string
  openingNote: string
  totalPayment: number
  totalDifference: number
  summary: object
  handover: boolean
}
