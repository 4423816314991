import { defineStore } from 'pinia'
import { compact, map, reduce } from 'lodash'
import { useRegister } from '@/stores/register'
import { collection, getDocs, query } from 'firebase/firestore'
import database from '@/config/firebase/database'
import { useSales } from '@/stores/sales'
import { useSale } from '@/stores/sale'
import { computed, reactive } from 'vue'

export type Table = {
  name: string
  reserved?: boolean
}

export type ExtendedTable = Table & { _id: string }

export type TablePayload = {
  tableId: string
  tableName: string
  customBill: string
}

export const useTables = defineStore('tables', () => {
  const sales = useSales()
  const sale = useSale()
  const register = useRegister()

  const tables = reactive<Array<ExtendedTable>>([])

  const unoccupied = computed<Array<ExtendedTable>>(() => {
    const occupied = compact(map(sales.orders, (o: any) => o.tableId))
    return tables.filter((t: any) => !occupied.includes(t._id))
  })

  const occupied = computed<Array<ExtendedTable>>(() => {
    const occupied = compact(map(sales.orders, (o: any) => o.tableId))
    return tables.filter(t => occupied.includes(t._id))
  })

  const occupiedTableIds = computed<Array<string>>(() => {
    return reduce(
      sales.orders as Array<any>,
      (list, order) => {
        if (order.tableId) {
          list.push(order.tableId)
        }
        return list
      },
      [] as Array<string>,
    )
  })

  const setTable = async (payload: TablePayload) => {
    const { tableId, tableName, customBill } = payload

    if (tableId && tableName) {
      sale.setTable(tableId, tableName)
    } else {
      sale.setCustomBill(customBill)
    }
  }

  const setTablesForCurrentLocation = async () => {
    const q = query(
      collection(database, `locations/${register.location}/tables`),
    )
    const snapshot = await getDocs(q)
    const _tables = [] as Array<ExtendedTable>
    snapshot.forEach(doc => {
      _tables.push({
        _id: doc.id,
        ...(doc.data() as Table),
      })
    })

    tables.splice(0, tables.length, ..._tables)
  }

  return {
    tables,
    unoccupied,
    occupied,
    occupiedTableIds,
    setTable,
    setTablesForCurrentLocation,
  }
})
