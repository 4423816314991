import { serverTimestamp } from 'firebase/firestore'
import type { ExtendedEvent } from '../events'
import type { Promotion } from '@/stores/promotion'
import { type SaleProductType, type SaleDiscount } from './types'

export const SaleStatus = {
  DISCARDED: 'discarded',
  OPEN: 'open',
  CLOSED: 'closed',
  PARKED: 'parked',
}

export const generateOrder = () => ({
  _id: '',
  orderId: '',
  orderNumber: '',
  active: false,
  status: '',
  tableId: '',
  tableName: '',
  registerId: '',
  openRegisterEntryId: '',
  department: '',
  customBill: '',
  salesCategory: '',
  isCustomBill: false,
  amountOfGuest: 0,
  generalSalesPerson: {
    staffName: '',
    profile: '',
  },
  products: [] as Array<SaleProductType>,
  orderNote: '',
  totalOrderValue: 0,
  totalReportingValue: 0,
  createdAt: serverTimestamp(),
  updatedAt: serverTimestamp(),
  closedAt: '',
  parkedSale: false,
  payment: {
    receivedCash: 0,
    paymentStatus: false,
  },
  staffId: '',
  staffName: '',
  eventItem: {} as ExtendedEvent,

  userTags: [] as Array<string>,
  userName: '',
  userId: '',
  userPhone: '',
  userEmail: '',
  userPersona: [] as Array<string>,
  affiliateCodes: [] as Array<string>,
  memberGroup: '',
  customerAlert: '',
  onAccount: false,
  userLoyalty: {
    groomPoints: 0,
    networkPoints: 0,
    stylePoints: 0,
  },

  redeemedPoints: {
    groom: 0,
    network: 0,
    style: 0,
  },

  promotionDiscount: 0,
  promotion: [] as Array<
    Promotion & { promotionDiscount: number; manual: boolean }
  >,
  manualPromotion: false,

  discount: {
    type: 'percentage',
    percentage: 0,
    amount: 0,
    promotion: null,
    manual: false,
  } as SaleDiscount,

  paid: false,
  isPromotionalSale: false,

  course: 0,
})
