<template>
  <svg
    v-bind="$attrs"
    fill="currentColor"
    viewBox="-3 0 28 28"
    @click="$emit('click', $event)"
  >
    <defs></defs>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      sketch:type="MSPage"
    >
      <g
        id="Icon-Set-Filled"
        sketch:type="MSLayerGroup"
        transform="translate(-419.000000, -571.000000)"
        fill="#000000"
      >
        <path
          d="M440.415,583.554 L421.418,571.311 C420.291,570.704 419,570.767 419,572.946 L419,597.054 C419,599.046 420.385,599.36 421.418,598.689 L440.415,586.446 C441.197,585.647 441.197,584.353 440.415,583.554"
          id="play"
          sketch:type="MSShapeGroup"
        ></path>
      </g>
    </g>
  </svg>
</template>
