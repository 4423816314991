import database from '@/config/firebase/database'
import { collection, query, onSnapshot } from 'firebase/firestore'
import { useRegister } from '@/stores'
import type { SaleCategory } from '@/stores/register'
import { setTimeoutLoop } from '@/utilities/common'
import debug from 'debug'

const logger = debug('sales-categories-plugin')

export const SalesCategories = {
  install() {
    logger('init')

    const fn = () => {
      logger('trying to register sales category plugin')
      const register = useRegister()

      if (!register.location) {
        return false
      }

      const _query = query(
        collection(database, `locations/${register.location}/salesCategories`),
      )

      onSnapshot(
        _query,
        snapshot => {
          const categories: Array<SaleCategory> = []
          snapshot.forEach(doc => {
            categories.push({ _id: doc.id, ...doc.data() } as SaleCategory)
          })
          logger(`hydrating ${categories.length} sales categories`)
          register.hydrateSaleCategories(categories)
        },
        error => {
          logger(`broken listener pipe`)
          console.error(error)
          setTimeoutLoop(fn, 1000)
        },
      )

      return true
    }

    setTimeoutLoop(fn, 5000)
  },
}
