import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
  serverTimestamp,
  addDoc,
  getDoc,
  doc,
  setDoc,
  Timestamp,
  deleteDoc,
  FieldValue,
} from 'firebase/firestore'
import { defineStore } from 'pinia'
import database from '@/config/firebase/database'
import { assign, each, map, reduce, uniq, upperFirst, filter } from 'lodash'
import { useSales } from '@/stores/sales'
import { useAuthentication } from '@/stores/authentication'
import { useStaff } from '@/stores/staff'
import { reactive, computed, ref } from 'vue'
import { patchTimeStamp } from '@/utilities/common'
import debug from 'debug'
import {
  PaymentMethods,
  type CashLedgerEntry,
  type CreditCashLedgerEntry,
  type DebitCashLedgerEntry,
  type RegisterType,
} from './types'

const logger = debug('register')

export type SaleCategory = {
  _id: string
  name: string
  isCustom?: boolean
}

export type CashEntryPayloadType =
  | (Omit<CashLedgerEntry, 'date'> & CreditCashLedgerEntry)
  | (Omit<CashLedgerEntry, 'date'> & DebitCashLedgerEntry)

export const registerSkeleton = (): RegisterType => {
  return {
    _id: '',
    closedAt: '' as string | FieldValue,
    closedByUserId: '',
    closedByUserName: '',
    closingNote: '',
    code: 0,
    openStatus: false,
    openedAt: '',
    openedByUserId: '',
    openedByUserName: '',
    openingNote: '',
    totalPayment: 0,
    totalDifference: 0,
    summary: {},
    handover: false,
  }
}

export const useRegister = defineStore('register', () => {
  const register = reactive<RegisterType>({ ...registerSkeleton() })
  const location = ref<string>('')
  const outlet = ref<string>('')
  const outlets = reactive<Array<unknown>>([])
  const registers = reactive<Array<RegisterType>>([])
  const cashMovements = reactive<
    Array<CreditCashLedgerEntry | DebitCashLedgerEntry>
  >([])
  const salesCategories = reactive<Array<SaleCategory>>([])
  const tables = reactive<Array<unknown>>([])

  const availableRegisters = computed(() => registers)

  const registerId = computed(() => register._id)

  const isOpen = computed(() => register.openStatus)

  const info = computed(() => ({ location, outlet }))

  const currentSequenceCode = computed(() => register.code)

  const initRegister = async (
    _location: string,
    _outlet: string,
    _outlets: Array<any>,
  ) => {
    location.value = _location
    outlet.value = _outlet
    outlets.splice(0, outlets.length, ..._outlets)

    return await open()
  }

  const getLatestRegisterCode = async () => {
    const q = query(
      collection(
        database,
        `locations/${location.value}/registers/${outlet.value}/sessions`,
      ),
      orderBy('code', 'desc'),
      limit(1),
    )

    const snapshot = await getDocs(q)

    if (snapshot.empty) {
      return 1
    }

    const lastRegister = snapshot.docs[0].data()
    return lastRegister.code + 1
  }

  const open = async () => {
    const q = query(
      collection(
        database,
        `locations/${location.value}/registers/${outlet.value}/sessions`,
      ),
      where('openStatus', '==', true),
      limit(1),
    )

    const registers = await getDocs(q)

    if (!registers.size) {
      return false
    }

    assign(register, {
      ...(registers.docs[0].data() as ReturnType<typeof registerSkeleton>),
      _id: registers.docs[0].id,
    })

    setTables(location.value)

    return true
  }

  const create = async (payload: {
    code?: string
    openingNote?: string
    cashManagement: Array<any>
  }) => {
    const staff = useStaff()
    const code = payload?.code ? payload.code : await getLatestRegisterCode()

    const openingData = {
      code,
      openingNote: payload.openingNote || '',
      openStatus: true,
      openedAt: serverTimestamp(),
      openedByUserId: staff.active._id,
      openedByUserName: staff.active.fullName,
    }

    const _register = {
      _id: '',
      closedAt: '',
      closedByUserId: '',
      closedByUserName: '',
      closingNote: '',
      ...openingData,
    }

    const ref = await addDoc(
      collection(
        database,
        `locations/${location.value}/registers/${outlet.value}/sessions`,
      ),
      _register,
    )

    const snapshot = await getDoc(ref)

    assign(register, {
      ...(snapshot.data() as ReturnType<typeof registerSkeleton>),
      _id: snapshot.id,
    })

    Promise.all(
      payload.cashManagement.map(
        async (data: any) =>
          await createCashEntry({
            ...data,
            registerId: snapshot.id,
          }),
      ),
    )

    window.dispatchEvent(new Event('register.opened'))
    setTables(location.value)
  }

  const setTables = async (location: string) => {
    const snapshot = await getDocs(
      query(collection(database, `locations/${location}/tables`)),
    )
    const _tables: Array<any> = []
    snapshot.forEach(doc => {
      _tables.push({
        _id: doc.id,
        ...doc.data(),
      })
    })
    tables.splice(0, tables.length, ..._tables)
  }

  const hydrateCashEntries = async () => {
    const q = query(
      collection(database, `locations/${location.value}/ledger`),
      where('method', '==', PaymentMethods.internalCashManagement),
      where('registerId', '==', registerId.value),
    )
    const snapshot = await getDocs(q)
    const entries: Array<any> = []
    snapshot.forEach(doc => entries.push({ ...doc.data(), _id: doc.id }))
    cashMovements.splice(0, cashMovements.length, ...entries)
  }

  const createCashEntry = async (payload: CashEntryPayloadType) => {
    const ledgerRef = doc(
      collection(database, `locations/${location.value}/ledger`),
    )

    const _ledger = {
      ...payload,
      registerName: outlet.value,
      method: PaymentMethods.internalCashManagement,
      date: serverTimestamp(),
    }

    setDoc(ledgerRef, _ledger).catch(console.error)

    cashMovements.push({ ..._ledger, date: Timestamp.now() })
  }

  const saveRegister = async () => {
    const docRef = doc(
      database,
      `locations/${location.value}/registers/${outlet.value}/sessions/${registerId.value}`,
    )

    const _register = patchTimeStamp({ ...register }, 'openedAt', 'closedAt')

    await setDoc(docRef, _register, { merge: true })

    logger(`${registerId.value} has been saved`)
  }

  const getOutletsFromLocation = async (location: string) => {
    const snapshot = await getDocs(
      query(collection(database, `locations/${location}/registers`)),
    )
    const outlets: Array<any> = []
    snapshot.forEach(doc => {
      outlets.push({
        text: upperFirst(doc.id),
        value: doc.id,
      })
    })

    return outlets
  }

  const activeRegistersInLocation = async (location: string) => {
    const openRegisters: Array<any> = []
    const locationOutlets: Array<any> = []

    const outlets = await getDocs(
      query(collection(database, `locations/${location}/registers`)),
    )

    outlets.forEach(doc => locationOutlets.push(doc.id))

    await Promise.allSettled(
      locationOutlets.map(async outlet => {
        const registers = await getDocs(
          query(
            collection(
              database,
              `locations/${location}/registers/${outlet}/sessions`,
            ),
            where('openStatus', '==', true),
          ),
        )

        if (!registers.empty) {
          registers.forEach(doc => {
            openRegisters.push({ ...doc.data(), _id: doc.id })
          })
        }
      }),
    )

    return openRegisters
  }

  /**
   * @description Register closure summary for Store Credit ledger entries
   * includes closure summary for agreement, gift-cheque and locker-box for
   * better reporting
   *
   * @param {Number} payload.difference
   * @param {string} payload.closingNote
   * @param {Array} payload.transactions
   */
  const storeCreditSummary = async () => {
    const entries = await getDocs(
      query(
        collection(database, `locations/${location.value}/ledger`),
        where('registerId', '==', `${registerId.value}`),
      ),
    )

    const summary = {
      storeCreditAgreements: {
        in: 0,
        out: 0,
        total: 0,
      },
      storeCreditLockerBox: {
        total: 0,
      },
      storeCreditGiftCheque: {
        total: 0,
      },
    }

    entries.forEach(doc => {
      const data = doc.data()
      switch (data.method) {
        case PaymentMethods.storeCreditAgreement:
          summary.storeCreditAgreements[data.amount < 0 ? 'in' : 'out'] +=
            data.amount
          summary.storeCreditAgreements.total += data.amount
          break
        case PaymentMethods.storeCreditGiftCheque:
          summary.storeCreditGiftCheque.total += data.amount
          break
        case PaymentMethods.storeCreditLockerbox:
          summary.storeCreditLockerBox.total += data.amount
          break
        // no default case is required
      }
    })

    return summary
  }

  /**
   * @description Register closure with handover support which allows closing
   * register if other outlet is open in same location. Prevent closing the
   * register if total difference is more then 100000 for staff.
   *
   * @param {Number} payload.difference
   * @param {string} payload.closingNote
   * @param {Array} payload.transactions
   */
  const close = async (payload: {
    difference: number
    closingNote: string
    closedByUserId: string
    closedByUserName: string
    transactions: Array<any>
  }) => {
    const sales = useSales()
    const authentication = useAuthentication()

    const {
      difference,
      closingNote,
      transactions,
      closedByUserId,
      closedByUserName,
    } = payload
    let handover = false

    const hasActiveOrders = filter(
      sales.orders,
      o => o.active && o.registerId === register._id,
    )

    if (hasActiveOrders.length) {
      throw Error(
        'This register can not be closed until all the sale is completed or parked!',
      )
    }

    if (
      difference > 100000 &&
      !authentication.user?.roles.includes('pos.admin')
    ) {
      throw Error(
        'Only admin can close register if difference is more than 1,00,000',
      )
    }

    const creditSummary = await storeCreditSummary()

    const summary: { [key: string]: any } = {
      payments: {},
      ...creditSummary,
    }

    const totalPayment = reduce(
      transactions,
      (_totalPayment, t) => _totalPayment + t.amount,
      0,
    )
    const totalDifference = reduce(
      transactions,
      (_totalDifference, t) => _totalDifference + (t.amount - t.cleared),
      0,
    )

    each(transactions, t => {
      summary.payments[t.method] = {
        counted: t.cleared,
        difference: t.amount - t.cleared,
        total: t.amount,
      }
    })

    const closure: Partial<typeof register> = {
      closedAt: serverTimestamp(),
      closedByUserId,
      closedByUserName,
      openStatus: false,
      closingNote,
      totalPayment,
      totalDifference,
      summary,
      handover,
    }

    assign(register, {
      ...register,
      ...closure,
    } as typeof register)

    await saveRegister()

    await cleanSaleCategories()
    assign(register, registerSkeleton())
  }

  const hydrateSaleCategories = async (categories: Array<SaleCategory>) => {
    salesCategories.splice(0, salesCategories.length, ...categories)
  }

  const cleanSaleCategories = async () => {
    try {
      const sales = useSales()
      const categories = uniq(map(sales.orders, 'salesCategory'))
      logger('categories for open orders', categories)

      const categoriesToClose = salesCategories.filter(
        c => c?.isCustom && !categories.includes(c._id),
      )
      logger('categories needs to be cleaned', categoriesToClose)
      await Promise.all(
        categoriesToClose.map(
          async c =>
            await deleteDoc(
              doc(
                collection(
                  database,
                  `locations/${location.value}/salesCategories`,
                ),
                c._id,
              ),
            ),
        ),
      )
    } catch (e) {
      logger(`could not clean up custom sales categories`, e)
    }
  }

  const createSalesCategory = (name: string) => {
    const _id = name.toLowerCase().replace(/ /g, '-')
    const category = { _id, name, isCustom: true }

    const categoryRef = doc(
      collection(database, `locations/${location.value}/salesCategories`),
      _id,
    )
    setDoc(categoryRef, { name, isCustom: true }).catch(console.error)

    salesCategories.push(category)
    return category
  }

  const reset = () => {
    location.value = ''
    outlet.value = ''
    assign(register, registerSkeleton())
    outlets.splice(0, outlets.length)
  }

  return {
    register,
    location,
    outlet,
    outlets,
    registers,
    cashMovements,
    salesCategories,
    tables,

    availableRegisters,
    registerId,
    isOpen,
    info,
    currentSequenceCode,
    initRegister,
    getLatestRegisterCode,
    open,
    create,
    hydrateCashEntries,
    createCashEntry,
    saveRegister,
    getOutletsFromLocation,
    activeRegistersInLocation,
    storeCreditSummary,
    close,
    hydrateSaleCategories,
    cleanSaleCategories,
    createSalesCategory,
    reset,
  }
})
