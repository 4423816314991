import database, { transform } from '@/config/firebase/realtime-database'
import { defineStore } from 'pinia'
import { useRegister } from './register'
import { ref, serverTimestamp, update } from 'firebase/database'
import { findIndex, has, some } from 'lodash'
import { SaleProductStatus, type SaleProduct } from './sale/types'
import { useSales } from './sales'
import { computed } from 'vue'
import debug from 'debug'
import { ProductTypes } from './inventory'
import type { OrderType } from './sales'

const logger = debug('queue')

export type QueueActionParams = {
  order: {
    orderId: number
    products: Array<SaleProduct>
    [key: string]: any
  }
  internalId: string
  staffId: string
  staffName: string
}

export const usePreparationQueue = defineStore('preparationQueue', () => {
  const register = useRegister()
  const sales = useSales()

  const queuedOrders = computed<Array<OrderType>>(() => {
    return sales.orders.filter(o => {
      return some(
        o.products,
        p =>
          (p.type === ProductTypes.FnB || p.type === ProductTypes.FnBVariant) &&
          has(p, 'orderTime'),
      )
    })
  })

  const start = async (info: QueueActionParams) => {
    const order = { ...info.order }

    const index = findIndex(
      order.products,
      p => p.internalId == info.internalId,
    )

    if (index !== -1) {
      const payload = {
        status: SaleProductStatus.preparing,
        preparationStart: serverTimestamp(),
        preparedById: info.staffId,
        preparedByName: info.staffName,
      }
      order.products[index] = { ...order.products[index], ...payload }

      await update(
        ref(database, `${register.location}/${order.orderId}`),
        transform(order),
      )
    } else {
      logger(`Queue:: Item ${info.internalId} not found to start preparation`)
    }
  }

  const finish = async (info: QueueActionParams) => {
    const order = { ...info.order }

    const index = findIndex(
      order.products,
      p => p.internalId == info.internalId,
    )

    if (index !== -1) {
      const payload = {
        status: SaleProductStatus.prepared,
        preparationFinish: serverTimestamp(),
      }
      order.products[index] = { ...order.products[index], ...payload }

      await update(
        ref(database, `${register.location}/${order.orderId}`),
        transform(order),
      )
    } else {
      logger(`Item ${info.internalId} not found to complete preparation`)
    }
  }

  return {
    queuedOrders,
    start,
    finish,
  }
})
