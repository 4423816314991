<template>
  <div class="md:tw-mx-12">
    <div class="tw-flex tw-mb-4">
      <div
        class="tw-flex tw-flex-1 tw-flex-col md:tw-flex-row tw-border tw-border-gray-300 tw-rounded-md tw-overflow-hidden tw-justify-content-between"
      >
        <div
          v-for="(tab, index) in Tabs"
          :key="`sales-tab-${index}`"
          class="hover:tw-text-white hover:tw-bg-gray-400 tw-transition-all tw-text-center tw-flex tw-flex-1 tw-items-center tw-justify-center tw-py-1 md:tw-py-0"
          :class="{ 'tw-text-white tw-bg-primary': activeTab === index }"
          @click="(activeTab = index)"
        >
          {{ tab.title }}
        </div>
      </div>
      <button
        class="tw-ml-2 tw-btn tw-text-white tw-bg-primary tw-rounded-md hover:tw-text-white hover:tw-bg-primary hover:tw-opacity-60"
        @click="openQuickSale"
      >
        Quick Sale
      </button>
    </div>

    <div
      v-if="showSkeleton"
      class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 xl:tw-grid-cols-4 gap-1"
    >
      <div
        v-for="n in 12"
        :key="n"
        class="shimmer tw-bg-gray-300 tw-h-44 tw-min-h-full tw-rounded-xl"
      ></div>
    </div>
    <div
      v-else
      class="tw-grid tw-grid-cols-1 tw-gap-2 md:tw-grid-cols-2 xl:tw-grid-cols-3"
      :title="ActiveTab?.title"
    >
      <table-card
        v-for="(item, index) in ActiveTab.data"
        :key="`table-${index}`"
        :item="item"
        :isEventOpen="isEventOpen"
        :id="item.orderId"
        @open-sale="openSale(item.orderId, item.status)"
      />
      <add-sale-card v-if="!ActiveTab?.hideAddAction" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import TableCard from '@/components/TableCard.vue'
import AddSaleCard from '@/components/AddSaleCard.vue'
import { useSales } from '@/stores/sales'
import { useRegister } from '@/stores/register'
import { NavbarTypes, useApp } from '@/stores/app'
import { useInventory } from '@/stores/inventory/inventory'
import { useSale } from '@/stores'
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import type { OrderType } from '@/stores/sales'

type TabType = {
  _id?: string
  title: string
  data: Array<OrderType>
  hideAddAction?: boolean
}

const router = useRouter()

const app = useApp()
const sales = useSales()
const sale = useSale()
const inventory = useInventory()
const register = useRegister()

const activeTab = ref(0)
const showSkeleton = ref(true)
const isEventOpen = ref(false)

onMounted(async () => {
  showSkeleton.value = true
  await inventory.hydrate()
  window.dispatchEvent(new Event('orders'))
  showSkeleton.value = false

  app.setNavbarOption(NavbarTypes.NoOptionNavbar)
})

const orders = computed(() => sales.orders)
const salesCategories = computed(() => register.salesCategories)

const ParkedSales = computed(() =>
  orders.value
    .filter((o: OrderType) => o.parkedSale === true)
    .sort(orderSortCallback),
)

const Tabs = computed<Array<TabType>>(() => {
  const _tabs = salesCategories.value.map(category => {
    return {
      _id: category._id,
      title: category.name,
      data: sales.orders
        .filter(
          (o: OrderType) => o.salesCategory === category._id && !o?.parkedSale,
        )
        .sort(orderSortCallback),
    }
  })
  return [
    {
      title: 'All',
      data: orders.value.filter((o: OrderType) => !o.parkedSale),
    },
    ..._tabs,
    {
      title: 'Parked',
      data: ParkedSales.value,
      hideAddAction: true,
    },
  ]
})

const ActiveTab = computed<TabType>(() => {
  return Tabs.value[activeTab.value]
})

const openSale = (orderId: string, status: string) => {
  if (status === 'cheque-confirmed') {
    router.push({
      name: 'Payment',
      params: { orderId },
    })
    return
  }
  router.push({ name: 'sale', params: { orderId } })
}

const openQuickSale = async () => {
  const payload = {
    tableId: '',
    tableName: '',
    orderNote: '',
    amountOfGuest: 1,
    customBill: 'Quick Sale',
    salesCategory: '',
    department: 'bar',
  }
  const order = await sale.open(payload)
  router.push({
    name: 'sale',
    params: { orderId: order.orderId },
  })
}

const orderSortCallback = (a: OrderType, b: OrderType) => {
  return a.status === 'cheque-confirmed' || a.updatedAt < b.updatedAt ? -1 : 1
}
</script>

<style lang="scss" scoped>
.main-screen {
  .appointment-btn {
    transform-origin: left;
    transform: translate(5%, 520%) rotate(270deg);
    border-radius: 0;
  }

  .event-cards {
    max-height: 72vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
