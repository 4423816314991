<template>
  <div class="print-bill" id="printSection" v-if="_orderId && _lock">
    <div class="logo">
      <img src="@/assets/logo.svg" height="100" alt="house-of-barbaard" />
    </div>
    <hr />
    <div class="address-time">
      <div class="address">
        House of Barbaard<br />
        {{ app.location?.addressFirst }}<br />
        {{ app.location?.addressSecond }}
      </div>
      <div class="time">
        {{ _orderId }}<br />
        {{ _printDate }}<br />
        {{ _order.generalSalesPerson.staffName }}
      </div>
    </div>
    <template v-if="_order.userName">
      <hr />
      <div class="member item-price-head">
        <div class="name item">{{ _order.userName }}</div>
        <div class="Membership-number price">
          <template v-if="_loyalty.membership && !isEmpty(_loyalty.membership)">
            {{ getTierLabel(_loyalty.membership.level) }}
          </template>
        </div>
      </div>
    </template>
    <hr v-if="!isPaymentProcessed || _order.userName" />
    <div class="cart">
      <div class="item-price-head">
        <div class="item">Item</div>
        <div class="price">Price</div>
      </div>

      <print-item
        v-for="(item, index) in _order.products"
        :key="item.id"
        :index="index"
        :item="item"
      />

      <div class="item-price" v-if="_order?.eventItem?.food">
        <div class="item">1 x {{ _order.eventItem.food }}</div>
        <div class="price">₫ 0</div>
      </div>
      <div class="item-price" v-if="_order?.eventItem?.drink">
        <div class="item">1 x {{ _order.eventItem.drink }}</div>
        <div class="price">₫ 0</div>
      </div>
    </div>
    <hr />
    <div class="item-price-foot">
      <div class="item">Subtotal</div>
      <div class="price">₫ {{ formatNumber(round(_summary.subTotal)) }}</div>
    </div>
    <div v-if="_summary.totalSaleDiscount" class="item-price-foot normal">
      <div class="item">
        {{ _order.discount.promotion?._id ? `Sale Promotion ${_order.discount.promotion.name}` : 'Sale Discount' }}
      </div>
      <div class="price">
        ₫ {{ formatNumber(round(_summary.totalSaleDiscount)) }}
      </div>
    </div>
    <hr />
    <div
      v-if="_summary.totalServiceChargePaying"
      class="item-price-foot normal"
    >
      <div class="item">Service Charge</div>
      <div class="price">
        ₫ {{ formatNumber(round(_summary.totalServiceChargePaying)) }}
      </div>
    </div>
    <div v-if="_summary.totalVATPaying" class="item-price-foot normal">
      <div class="item">VAT</div>
      <div class="price">
        ₫ {{ formatNumber(round(_summary.totalVATPaying)) }}
      </div>
    </div>
    <hr />
    <div class="item-price-foot major">
      <div class="item">Total</div>
      <div class="price">₫ {{ formatNumber(_summary.totalPayableAmount) }}</div>
    </div>
    <div v-if="isPaymentProcessed">
      <hr />
      <div
        v-for="(payment, index) in _payments"
        :key="index"
        class="d-flex justify-content-between align-items-start payment item-price-head"
      >
        <div class="item">
          {{ payment.method }}
          <div
            v-if="payment.method === STORE_CREDIT_AGREEMENT"
            class="text-muted"
          >
            {{
              payment.agreements?.length
                ? getAgreementPaymentLabel(payment.agreements)
                : ''
            }}
          </div>
        </div>
        <div class="normal-price">₫ {{ formatNumber(payment.amount) }}</div>
      </div>
    </div>
    <template v-if="_loyalty.membership && !isEmpty(_loyalty.membership)">
      <hr />
      <div class="item-price-foot" v-if="_loyalty.points">
        <div class="item">Loyalty Points Collected</div>
        <div class="price">+{{ _loyalty.points }}</div>
      </div>
      <div class="item-price-foot">
        <div class="item">Total Points</div>
        <div class="price">{{ formatNumber(_loyalty.membership.earningPoints) }}</div>
      </div>
      <div class="item-price-foot">
        <div class="item">Current Tier</div>
        <div class="price">{{ getTierLabel(_loyalty.membership.level) }}</div>
      </div>
      <div class="item-price-foot" v-if="_loyalty.membership.level !== Tier.black_member">
        <div class="item">Points till next tier</div>
        <div class="price">{{ getPointsToNextTier(_loyalty.membership.earningPoints) }}</div>
      </div>
      <div class="item-price-foot">
        <div class="item">Tier expiry date</div>
        <div class="price">{{ getTierExpiryDate(_loyalty.membership.expiryDate) }}</div>
      </div>
      <hr />
      <div class="item-price-foot">
        <div class="item">Groom Credit</div>
        <div class="price">₫ {{ formatNumber(_loyalty.membership.groomPoints) }}</div>
      </div>
      <div class="item-price-foot">
        <div class="item">Style Credit</div>
        <div class="price">₫ {{ formatNumber(_loyalty.membership.stylePoints) }}</div>
      </div>
      <div class="item-price-foot">
        <div class="item">Network Credit</div>
        <div class="price">₫ {{ formatNumber(_loyalty.membership.networkPoints) }}</div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import moment from 'moment'
import { Printd } from 'printd'
import PrintItem from './PrintItem.vue'
import { isEmpty, map, round } from 'lodash'
import { useSale } from '@/stores/sale/sale'
import { useApp } from '@/stores/app'
import { reactive, ref, type PropType, computed } from 'vue'
import { formatNumber } from '@/utilities/common'
import type { SalePayment } from '@/stores/payment'
import type { OrderType } from '@/stores/sales'
import { ProductTypes } from '@/stores/inventory'
import { cssText } from './PrintCSS'
import { Tier, Tiers } from '@/stores/authentication'
import { STORE_CREDIT_AGREEMENT } from '@/utilities/constants'

const sale = useSale()
const app = useApp()

const d = new Printd()

type SummaryParams = {
  totalPayableAmount: number
  subTotal: number
  totalServiceChargePaying: number
  totalVATPaying: number
  totalDiscount: number
  totalSaleDiscount: number,
}

type State = {
  order: OrderType
  appliedAgreements: object
  summary: SummaryParams
}

type LoyaltyData = {
  points: number,
  membership: Record<string, any>
}

const _orderId = ref<string>('')
const _state = reactive<State>({} as State)
const _loyalty = reactive<LoyaltyData>({} as LoyaltyData)
const _payments = reactive<Array<SalePayment>>([])
const _printDate = ref<string>('')
const _lock = ref<boolean>(false)

const props = defineProps({
  order: {
    type: Object as PropType<OrderType>,
    required: true,
  },
  summary: {
    type: Object as PropType<SummaryParams>,
    required: true,
  },
  appliedAgreements: Array<any>,
  payments: Array<SalePayment>,
})

const isPaymentProcessed = computed(() => _payments.length)

const _summary = computed<SummaryParams>(() => _state.summary)

const _order = computed<OrderType>(() => _state.order)

const getAgreementPaymentLabel = (agreements: any) => {
  const labels = map(agreements, a => {
    if ([
      ProductTypes.KingsAgreement,
      ProductTypes.EmperorsAgreement,
      ProductTypes.NobelAgreement
    ].includes(a.type)) {
      return `${moment(a.expiryDate.seconds * 1000).diff(
        moment(),
        'months',
      )} Months left` 
    } else if (a.type === 'service') {
      return `${a.left - 1} ${a.serviceName} left` 
    }
  })
  
  return labels.join('& ')
}

const getTierExpiryDate = (time: any) => {
  return moment(time.seconds * 1000).format('ll')
}

const getPointsToNextTier = (points: number) => {
  const sortedTiers = map(Tiers, 'points').sort((a, b) => a - b)
  for (const tier of sortedTiers) {
    if (points < tier) {
      return tier - points
    }
  }
  return 0
}

const getTierLabel = (tier: Tier) => {
  return Tiers[tier]?.label || 'Unknown'
}

const print = () => {
  sale.updateStatus('cheque-requested')
  _printDate.value = moment().format('HH:mm DD-MM-YYYY')

  setTimeout(() => {
    const el = document.getElementById('printSection')
    if (el) {
      d.print(el, [cssText])
    }
  }, 500)
}

const lock = () => {
  _state.order = {...props.order}
  _state.summary = { ...props.summary }
  _state.appliedAgreements = props.appliedAgreements ? [...props.appliedAgreements] : []
  _payments.splice(0, _payments.length, ...(props.payments ? props.payments : []))

  _lock.value = true
}

const setOrderId = (id: string) => {
  _orderId.value = id
}

const setLoyalty = (l: LoyaltyData) => {
  _loyalty.points = l.points
  _loyalty.membership = l.membership
}

defineExpose({
  print,
  lock,
  setOrderId,
  setLoyalty
})
</script>

<style lang="scss" scoped>
@page {
  size: auto;
  /* auto is the initial value */
  /* this affects the margin in the printer settings */
  margin: 0mm 0mm 0mm 0mm;
}

.print-bill {
  display: none;
}

@media print {
  body {
    zoom: 100%;
    margin: 0 auto;
  }

  .print-bill {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    height: auto;
    width: 95%;
    padding: 0px;
    font-size: 10pt;
  }
}

body {
  margin: 0;
  margin: 0 auto;
}

.print-bill {
  height: auto;
  width: 95%;
  padding: 0px;
  margin: 0 auto;
  font-size: 10pt;
}

.logo {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.logo > img {
  width: 50%;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: dotted 1px;
}

.address-time {
  display: flex;
  justify-content: space-between;
}

.address-time > .address {
  text-align: left;
}

.address-time > .time {
  text-align: right;
}

.barbaard-club {
  text-align: center;
}

.barbaard-club > .title {
  font-weight: bold;
  font-size: 10pt;
}

.barbaard-club > .description {
  margin-top: 18px;
  margin-bottom: 18px;
}

.item-price-head {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.item-price-head > .item {
  text-align: left;
}

.item-price-head > .item > .normal-item {
  font-weight: normal;
}

.item-price-head > .price {
  text-align: right;
}

.item-price-head > .normal-price {
  text-align: right;
  font-weight: normal;
}

.item-price {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.item-price > .item {
  text-align: left;
}

.item-price > .price {
  text-align: right;
}

.item-price-foot {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 9pt;
}

.item-price-foot.normal {
  font-weight: normal;
}

.item-price-foot > .item {
  text-align: left;
}

.item-price-foot > .price {
  text-align: right;
}

.payment {
  margin-bottom: 5px;
}

.download {
  text-align: center;
  font-weight: bold;
}

.barcode {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.barcode > img {
  width: 25%;
}

.store {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.store > .appstore {
  display: flex;
  justify-content: space-around;
  margin-top: 4px;
  width: 50%;
}

.store > .playstore {
  display: flex;
  justify-content: space-around;
  width: 50%;
}

.store > img {
  width: 80%;
}

text-normal {
  font-weight: normal;
}
</style>
