import { type Transaction, doc, updateDoc } from 'firebase/firestore'
import { defineStore } from 'pinia'
import database from '@/config/firebase/database'
import { uniq } from 'lodash'
import { type Customer } from '@/stores/sale'
import debug from 'debug'

const logger = debug('analytics')

export const useAnalytics = defineStore('analytics', () => {
  const assignTagToUser = async ({
    user,
    tags,
    transaction,
  }: {
    user: Customer
    tags: Array<string>
    transaction?: Transaction
  }) => {
    const ref = doc(database, `users/${user.userId}`)
    const update = {
      tags: uniq([...user.userTags, ...tags]),
    }
    logger(`Added ${tags.join(',')} (${tags.length}) to user ${user.userId}`)

    if (transaction) {
      transaction.update(ref, update)
    } else {
      await updateDoc(ref, update)
    }
  }

  return {
    assignTagToUser,
  }
})
