import { useAuthentication } from '@/stores/authentication'
import { onAuthStateChanged, type User } from 'firebase/auth'
import auth from '@/config/firebase/auth'

const getAuthStateSync = () => {
  return new Promise<User | null>((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      unsubscribe()
      resolve(user)
    })

    setTimeout(() => {
      reject(new Error('Timeout while obtaining authentication state'))
    }, 5000)
  })
}

export const Auth = async () => {
  let user = auth.currentUser
  const authentication = useAuthentication()

  if (!user) {
    try {
      user = await getAuthStateSync()
    } catch (_e) {
      authentication.reset()
      return { name: 'login' }
    }
  }

  if (user && !authentication.isAuthenticated) {
    /**
     * @description User authentication session is present, no
     * locally saved user data identified. Assign the user in
     * authentication store manually to restore the state.
     *
     */
    await authentication.setUserFromAuthentication(user)
  }

  if (!user && !authentication.isAuthenticated) {
    return { name: 'login' }
  }
}
