<template>
  <div class="tw-px-4 tw-border-b tw-border-gray-300">
    <div class="tw-flex tw-justify-between tw-py-3">
      <div>
        <h6 class="tw-mb-0" v-html="item.name"></h6>
        <p class="tw-mb-0 tw-text-gray-600">Quantity {{ item.quantity }}</p>
      </div>
      <div class="tw-ml-2">
        <h6>₫{{ formatNumber(total) }}</h6>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { SaleProductType } from '@/stores/sale'
import { formatNumber } from '@/utilities/common'
import { getTotalPayableAmount } from '@/utilities/sale';
import { computed, type PropType } from 'vue'
const props = defineProps({
  item: {
    type: Object as PropType<SaleProductType>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
})

const total = computed(() => getTotalPayableAmount(props.item))
</script>
