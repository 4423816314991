<template>
  <Modal
    :visible="visible"
    title="Choose The Variant"
    @close="() => emit('update:visible', false)"
  >
    <div class="tw-grid tw-grid-cols-6 tw-p-6 tw-gap-4 md:tw-grid-cols-4">
      <div
        v-for="(variant, index) in product.variants || []"
        :key="`product-variant-${index}`"
        class="tw-card tw-shadow-md tw-cursor-pointer tw-border tw-border-gray-300 tw-transition tw-duration-100 hover:tw-border-primary"
        @click="setVariant(variant)"
      >
        <figure class="tw-mb-0" v-if="variant.image">
          <img
            class="tw-w-full tw-object-cover tw-object-center tw-h-full"
            :src="variant.image"
            :alt="variant.id"
          />
        </figure>
        <div
          class="tw-h-64 tw-mb-0 tw-flex tw-items-center tw-justify-center"
          v-else
        >
          <BarbaardIcon class="tw-h-16 tw-w-16 tw-opacity-50" />
        </div>
        <div class="tw-card-body tw-p-2">
          <h6 class="tw-mb-0">{{ variant.title }}</h6>
          <small class="tw-mb-0" v-if="variant.price">
            Price: ₫ {{ formatNumber(variant.price) }}
          </small>
        </div>
        <Points v-if="variant.data.points" :points="variant.data.points" />
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import Points from '@/views/sale/components/Points.vue'
import Modal from '@/components/common/Modal.vue'
import Product from '@/stores/inventory/product'
import { formatNumber } from '@/utilities/common'
import BarbaardIcon from '../icons/BarbaardIcon.vue'

defineProps({
  visible: Boolean,
  product: {
    type: Product,
    required: true,
  },
})

const emit = defineEmits(['selected', 'update:visible'])

const setVariant = (variant: any) => {
  emit('selected', variant)
}
</script>

<style lang="scss" scoped>
.product-img {
  background-size: contain;
  height: 10rem;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
