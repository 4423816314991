<template>
  <Modal
    :visible="visible"
    max-width-class="tw-max-w-screen-sm"
    title="Add Discount"
    @close="() => emit('update:visible', false)"
  >
    <div class="tw-flex tw-flex-col tw-p-6 tw-gap-4">
      <div class="tw-w-full tw-flex">
        <input
          class="tw-input tw-w-full tw-px-3 tw-rounded-r-none tw-rounded-l-lg tw-border-r-0 tw-border-l tw-border-y tw-border-gray-300 focus:tw-outline-none"
          type="text"
          :value="amount ? amount : null"
          @input="onAmountInput"
          autofocus
          placeholder="0"
        />
        <div
          class="tw-font-bold tw-w-10 tw-bg-[#e9ecef] tw-text-[#495057] tw-px-3 tw-py-2 tw-rounded-r-lg tw-border tw-border-gray-300 tw-shrink-0 tw-flex tw-items-center"
        >
          {{ isCash ? '₫' : '%' }}
        </div>
      </div>

      <div class="tw-flex tw-flex-col">
        <p class="tw-mb-0">Discount by</p>

        <div class="tw-flex tw-gap-4">
          <label className="tw-label tw-cursor-pointer">
            <input
              v-model="isCash"
              type="radio"
              name="is-cash"
              className="tw-radio tw-border-gray-700 tw-mr-1"
              v-bind:value="false"
            />
            <span className="tw-label-text tw-text-gray-600">Percentage</span>
          </label>
          <label className="tw-label tw-cursor-pointer">
            <input
              v-model="isCash"
              type="radio"
              name="is-cash"
              className="tw-radio tw-border-gray-700 tw-mr-1"
              v-bind:value="true"
            />
            <span className="tw-label-text tw-text-gray-600">Amount</span>
          </label>
        </div>
      </div>

      <input-pad @store-value="setDiscountAmount" />

      <p
        class="tw-text-secondary tw-mb-0 tw-text-center tw-text-sm"
        v-if="discount.amount"
      >
        Customer is saving {{ formatNumber(discount.amount) }} ₫
        <span v-if="!isCash">( {{ discount.percentage }} %)</span> on the sale
      </p>

      <p class="tw-text-error tw-mb-0 tw-text-sm tw-text-center" v-if="error">
        {{ error }}
      </p>
    </div>

    <div class="tw-px-6 tw-pb-6 tw-flex tw-justify-end tw-gap-4">
      <button
        class="tw-btn tw-btn-secondary tw-text-white tw-font-normal"
        @click="onCancel"
      >
        Cancel
      </button>
      <button
        class="tw-btn tw-btn-primary tw-text-white tw-font-normal"
        @click="onSubmit"
      >
        Submit
      </button>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import InputPad from '@/components/common/Inputpad.vue'
import { formatNumber } from '@/utilities/common'
import Modal from '@/components/common/Modal.vue'
import { assign, round } from 'lodash'
import { useSale } from '@/stores/sale/sale'
import { ref, reactive, onMounted } from 'vue'

const discountSkeleton = {
  type: 'percentage',
  percentage: 0,
  amount: 0,
  manual: false,
  promotion: false,
}

const sale = useSale()

defineProps({
  visible: Boolean,
})

const emit = defineEmits(['update:visible'])

const error = ref('')
const isCash = ref(false)
const amount = ref(0)

const discount = reactive(discountSkeleton)

const onCancel = () => {
  sale.resetDiscount()
  emit('update:visible', false)
}

const setDiscountAmount = (value: any) => {
  const _val = parseInt(value)
  amount.value = _val ? _val : 0
}

const onSubmit = async () => {
  if (amount.value > 100 && !isCash.value) {
    error.value = 'Percentage should not be grater than to 100%'
    return
  } else {
    await sale.resetDiscount(true)
    const _discount = {
      amount: isCash.value
        ? amount.value
        : round((sale.total / 100) * amount.value),
      percentage: isCash.value
        ? (amount.value / sale.total) * 100
        : amount.value,
      type: isCash.value ? 'money' : 'percentage',
      manual: true,
      promotion: null,
    }

    assign(discount, _discount)
    sale.applyDiscount(_discount)
  }

  emit('update:visible', false)
}

const onAmountInput = (el: any) => {
  const value = +el.target.value

  if (value > 100 && !isCash.value) {
    error.value = 'Percentage should not be grater than to 100%'
  } else {
    error.value = ''
  }
  amount.value = value
}

onMounted(() => {
  const { discount: _discount } = sale.order
  assign(discount, _discount)
  isCash.value = _discount.type === 'money'
  amount.value =
    _discount.type === 'money' ? _discount.amount : _discount.percentage
})
</script>

<style></style>
