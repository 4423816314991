<template>
  <div>
    <div
      v-if="sale.order.parkedSale"
      class="tw-btn tw-btn-sm tw-flex tw-items-center tw-text-white hover:tw-opacity-80"
      @click="openTheParkedSale"
    >
      <LoadingIcon
        v-if="isParkSaleLoading"
        class="tw-w-4 tw-h-4 tw-animate-spin dark:tw-text-gray-200 tw-fill-white tw-mr-2"
      />
      <span>Open Sale</span>
    </div>
    <div
      v-else
      class="tw-btn tw-btn-sm tw-flex tw-items-center tw-text-white hover:tw-opacity-80"
      @click="parkedOpenSale"
    >
      <LoadingIcon
        v-if="isParkSaleLoading"
        class="tw-w-4 tw-h-4 tw-animate-spin dark:tw-text-gray-200 tw-fill-white tw-mr-2"
      />
      <span>Park Sale</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toast, type ToastOptions } from 'vue3-toastify'
import { useSale } from '@/stores/sale/sale'
import { LoadingIcon } from '@/components/icons'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const sale = useSale()

const isParkSaleLoading = ref<boolean>(false)
const isOpenParkLoading = ref<boolean>(false)

const parkedOpenSale = () => {
  isParkSaleLoading.value = true
  try {
    sale.park()
    isParkSaleLoading.value = false
    router.push({ name: 'home' })
  } catch (_e) {
    isParkSaleLoading.value = false

    toast('Failed to park the open Sale!', {
      type: toast.TYPE.ERROR,
      transition: toast.TRANSITIONS.SLIDE,
      theme: toast.THEME.LIGHT,
      position: toast.POSITION.BOTTOM_LEFT,
    } as ToastOptions)
  }
}

const openTheParkedSale = async () => {
  try {
    isOpenParkLoading.value = true
    await sale.openParked()
    router.push({ name: 'home' })
  } catch (e) {
    console.error(e)
    isOpenParkLoading.value = false
    toast('Failed to open the parked Sale!', {
      type: toast.TYPE.ERROR,
      transition: toast.TRANSITIONS.SLIDE,
      theme: toast.THEME.LIGHT,
      position: toast.POSITION.BOTTOM_LEFT,
    } as ToastOptions)
  }
}
</script>
