<template>
  <Modal
    max-width-class="tw-max-w-screen-sm"
    :visible="visible"
    title="Verify Security Pin"
    @close="() => emit('update:visible', false)"
  >
    <div class="tw-p-8">
      <div class="tw-flex tw-gap-4 tw-justify-center" ref="inputWrapper">
        <input
          type="password"
          v-for="index in length"
          :key="index"
          maxlength="1"
          pattern="[0-9]*"
          @keyup="onKey"
          :class="inputClass"
        />
      </div>

      <p class="tw-text-center tw-text-red-600 tw-my-2" v-if="error">
        {{ error }}
      </p>

      <div class="tw-flex tw-justify-center">
        <button
          :disabled="value.length < length"
          class="tw-mt-5 tw-mx-20 tw-btn tw-w-80 tw-btn-primary tw-text-white tw-font-normal"
          @click="verify"
        >
          <LoadingIcon
            v-if="loading"
            class="tw-w-5 tw-h-5 tw-animate-spin dark:tw-text-gray-400 tw-fill-white"
          />
          <template v-else> Verify </template>
        </button>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { LoadingIcon } from '@/components/icons'
import Modal from '@/components/common/Modal.vue'
import { useAuthentication } from '@/stores'
import { ref, computed, watch, useTemplateRef } from 'vue'

const authentication = useAuthentication()

const props = defineProps({
  visible: Boolean,
  length: {
    type: Number,
    default: 4,
  },
  staffId: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['verified', 'reset', 'update:visible'])

const value = ref('')
const inputWrapper = useTemplateRef('inputWrapper')
const loading = ref(false)
const error = ref('')

const inputClass = computed(() => {
  const _class = error.value ? 'tw-border-red-300' : 'tw-border-gray-300'
  return `tw-input tw-text-center tw-text-xl tw-font-bold tw-w-16 tw-h-16 tw-border ${_class} focus:tw-border-primary`
})

const onKey = (e: KeyboardEvent) => {
  const el = e.target as HTMLInputElement
  if (e.key === 'Backspace' || e.key === 'Delete') {
    e.preventDefault()
    if (el.previousElementSibling) {
      ;(el.previousElementSibling as HTMLInputElement).value = ''
      ;(el.previousElementSibling as HTMLInputElement).focus()
    } else {
      el.value = ''
    }
  } else if (el.value && el.nextElementSibling) {
    ;(el.nextElementSibling as HTMLInputElement).focus()
  }

  if (inputWrapper.value) {
    const code = (
      Array.from(inputWrapper.value.children) as Array<HTMLInputElement>
    )
      .map(e => e.value)
      .join('')
    value.value = code

    if (code.length === props.length) {
      verify()
    }
  }
}

const verify = async () => {
  loading.value = true
  error.value = ''

  const verified = await authentication.verifyPin(props.staffId, value.value)
  loading.value = false

  if (verified) {
    emit('verified')
    emit('update:visible', false)
  } else {
    error.value = 'Wrong security pin, try again'
    reset()
  }
}

const reset = () => {
  value.value = ''
  //TODO: Not able find from where this $refs comming
  if (inputWrapper.value) {
    Array.from(inputWrapper.value.children).forEach((e, index) => {
      if (index === 0) {
        ;(e as HTMLInputElement).focus()
      }
      ;(e as HTMLInputElement).value = ''
    })
  }
}

watch(
  () => props.staffId,
  () => {
    emit('reset')
    reset()
  },
)

watch(
  () => props.visible,
  visible => {
    if (visible) (inputWrapper.value?.children[0] as HTMLInputElement)?.focus()
  },
)
</script>
