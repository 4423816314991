<template>
  <svg
    v-bind="$attrs"
    viewBox="0 0 24 24"
    fill="currentColor"
    @click="$emit('click')"
  >
    <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
  </svg>
</template>
