export enum ProductStatus {
  inactive = 'inactive',
  active = 'active',
}

export type BrandType = {
  id: number
  title: string
}

export enum ComplimentaryTypes {
  Food = 'food',
  Drink = 'drink',
}

// TODO move all ProductTypes reference to this enun
export enum ProductTypes {
  Product = 'product',
  ProductVariant = 'productVariant',
  GentlemanAgreement = 'services',
  InitiateAgreement = 'initiate',
  KingsAgreement = 'king',
  NobelAgreement = 'nobel',
  EmperorsAgreement = 'emperor',
  Service = 'service',
  Upgrade = 'upgrade',
  GiftCheque = 'giftcheque',
  LockerBox = 'lockerbox',
  FnB = 'fnb',
  FnBVariant = 'fnbVariant',
  Complimentary = 'complimentary',
  Ticket = 'ticket',
  StoreCredit = 'storeCredit',
  MerchantsAgreement = 'merchant',
  GrandMerchantsAgreement = 'grand-merchant',
}

export const KingAgreementCategories = ['Head & Hair', 'Beard & Shaving']
