import { defineStore } from 'pinia'
import { find, findIndex, has, map, reduce, round, uniq, values } from 'lodash'
import { useApp, type PaymentMethod } from './app'
import { PaymentMethods, useRegister } from './register'
import database from '@/config/firebase/database'
import {
  collection,
  getDocs,
  query,
  where,
  type Timestamp,
  doc,
  updateDoc,
} from 'firebase/firestore'
import { computed, reactive } from 'vue'
import debug from 'debug'

const logger = debug('transactions')

export type LedgerTransaction = {
  _id: string
  amount: number
  date: Timestamp
  method: string
  order: string
  orderId: string
  register: string
  registerId: string
  staffId: string
  staffName: string
  status: number
  userId: string
  userName: string
}

export type SummaryTransaction = {
  transactions: Array<LedgerTransaction>
  cleared: number
  amount: number
  method: string
  movement?: Array<LedgerTransaction>
  movementTotal: number
  exclude: boolean
}

export const useTransactions = defineStore('transactions', () => {
  const register = useRegister()

  const transactions = reactive<Array<LedgerTransaction>>([])

  const cashMovements = computed(() => {
    return transactions.filter(
      t => t.method === PaymentMethods.internalCashManagement,
    )
  })

  const usedMethods = computed(() => {
    return uniq(map(transactions, t => t.method))
  })

  const summary = computed<Array<SummaryTransaction>>(() => {
    const app = useApp()
    const transaction: Record<string, SummaryTransaction> = {}

    transactions.forEach(t => {
      // Exclude it as it's being added in Cash method
      if (t.method === PaymentMethods.internalCashManagement) {
        return
      }

      if (has(transaction, t.method)) {
        transaction[t.method].amount += t.amount
        transaction[t.method].transactions.push(t)
      } else {
        const _method =
          find(app.payments, i => i.key === t.method) ?? ({} as PaymentMethod)
        const { exclude = false } = _method
        transaction[t.method] = {
          transactions: [t],
          cleared: 0,
          amount: t.amount,
          method: t.method,
          movementTotal: 0,
          exclude,
        }
      }
    })

    const total = reduce(
      cashMovements.value,
      (sum, t) => sum + t.amount || 0,
      0,
    )

    if (!has(transaction, 'Cash')) {
      transaction.Cash = {
        transactions: [],
        cleared: 0,
        amount: 0,
        movementTotal: 0,
        method: 'Cash',
        exclude: false,
      }
    }

    transaction.Cash.amount += total
    transaction.Cash.movement = cashMovements.value
    transaction.Cash.movementTotal = total

    return map(values(transaction), t => ({
      ...t,
      amount: round(t.amount, -3),
    }))
  })

  const fetchLedgerEntries = async () => {
    const entries = await getDocs(
      query(
        collection(database, `locations/${register.location}/ledger`),
        where('registerId', '==', `${register.registerId}`),
        where('method', 'not-in', [
          PaymentMethods.storeCreditAgreement,
          PaymentMethods.storeCreditGiftCheque,
          PaymentMethods.storeCreditLockerbox,
          PaymentMethods.storeCredit,
        ]),
      ),
    )

    const _transactions: Array<LedgerTransaction> = []
    entries.forEach(doc => {
      _transactions.push({ _id: doc.id, ...doc.data() } as LedgerTransaction)
    })

    transactions.splice(0, transactions.length, ..._transactions)
  }

  const updateTransactionMethod = async ({
    _id,
    method,
  }: {
    _id: string
    method: string
  }) => {
    const ref = doc(database, `locations/${register.location}/ledger/${_id}`)

    await updateDoc(ref, { method })

    const index = findIndex(transactions, t => t._id === _id)

    if (index === -1) {
      logger(`could not update ${_id} ledger entry`)
      return
    }

    const t = { ...transactions[index] }
    transactions.splice(index, 1, { ...t, method })
  }

  return {
    transactions,
    cashMovements,
    usedMethods,
    summary,
    fetchLedgerEntries,
    updateTransactionMethod,
  }
})
