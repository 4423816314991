import type { Timestamp } from 'firebase/firestore'

export type TraitType = {
  key: string
  name: string
  highlight?: boolean
}

export enum Tier {
  silver_member = 'silver_member',
  gold_member = 'gold_member',
  black_member = 'black_member',
}

export const Tiers: Record<
  Tier,
  {
    label: string
    points: number
  }
> = {
  silver_member: {
    label: 'Silver Member',
    points: 60000,
  },
  gold_member: {
    label: 'Gold Member',
    points: 130000,
  },
  black_member: {
    label: 'Black Member',
    points: 210000,
  },
}

export const PersonaTraits: Array<TraitType> = [
  {
    key: 'tourist',
    name: 'Tourist',
  },
  {
    key: 'businessman',
    name: 'Businessman',
  },
  {
    key: 'rich_kid',
    name: 'Rich Kid',
  },
  {
    key: 'metrosexual_man',
    name: 'Metrosexual man',
  },
  {
    key: 'westernised_vietnamese',
    name: 'Westernised Vietnamese',
  },
  {
    key: 'expat',
    name: 'Expat',
  },
  {
    key: 'ultra_rich',
    name: 'Ulta rich',
  },
  {
    key: 'student',
    name: 'Student',
  },
  {
    key: 'vip',
    name: 'VIP',
    highlight: true,
  },
  {
    key: 'svip',
    name: 'SVIP',
    highlight: true,
  },
]

export enum LoyaltyMemberGroup {
  Gold = 'Gold Member',
  Black = 'Black Member',
  BlackDiamond = 'Black Diamond Member',
  ClubMember = 'Club Member',
  SilverMember = 'Silver Member',
}

export enum Tag {
  New = 'new', // - set 'new' tag in users with customers with one appointment
  ExAgreement = 'ex-agreement', // - Add tag 'ex-agreement' for users that have an active = false agreement linked
  Agreement = 'agreement',
  Barbershop = 'barbershop', // - Add 'barbershop' tag for people that have at least one completed appointment
  Returning = 'returning',
  App = 'app',
  ExKingsAgreement = 'ex-kings-agreement',
  KingsAgreement = 'kings-agreement',
  ExLockerBox = 'ex locker box',
  LockerBox = 'locker box',
  CustomerLost = 'customer lost',
  NotifyGuido = 'notify guido',
  Bar = 'bar',
  Duplicate = 'duplicate',
}

export enum Persona {
  tourist = 'tourist',
  vip = 'vip',
  svip = 'svip',
}

export type Referral = {
  id?: string
  userName?: string
  date?: Timestamp
  status?: string
  email?: string
}

export enum SendingBlueEventType {
  Sent = 'request',
  Click = 'click',
  Deferred = 'deferred',
  Delivered = 'delivered',
  SoftBounce = 'soft_bounce',
  HardBounce = 'hard_bounce',
  Complaint = 'complaint',
  UniqueOpened = 'unique_opened', // first opening
  Opened = 'opened',
  InvalidEmail = 'invalid_email',
  Blocked = 'blocked',
  Error = 'error',
  Unsubscribed = 'unsubscribed',
  ProxyOpen = 'proxy_open',
}

export type User = {
  _id: string
  firstName: string
  lastName: string
  fullName: string
  email?: string
  phone?: string
  notifySMS?: boolean
  birthday?: Timestamp
  nationality?: string
  company?: string
  preferences?: {
    locale?: string
  }
  barbershop?: {
    firstAppointment?: Timestamp
    lastAppointment?: Timestamp
    lastBarber?: string
    totalAppointments?: number
    expressBeardTrim?: boolean
    expressHaircut?: boolean
    appointmentsLate?: number
    appointmentsOnTime?: number
    totalNoShows?: number
    averageDaysPerAppointment?: number
  }

  bar?: {
    firstReservation?: Timestamp
    lastReservation?: Timestamp
    totalReservations?: number
    averageDaysPerReservation?: number
  }

  referredById?: string
  referredByName?: string
  refferedRedeemed?: boolean
  referrals?: Referral[]

  location?: string
  connectaId?: string
  sendinblueId?: number
  trengoProfileId?: number
  trengoPhoneContactId?: number
  // booklyId?: number;
  wpId?: number
  createdAt?: Timestamp
  customerAlert?: string
  membershipNumber?: string
  vendID?: string
  comments?: string
  // groups?: string[];
  kiotvietID?: string
  tags?: Tag[]
  persona?: Persona[]

  loyalty?: {
    totalPoints: number
    actualPoints: number
    memberGroup: LoyaltyMemberGroup
    memberGroupId: string
    memberGroupPoints: number
    nextMemberGroupPoints: number
    nextMemberGroupId: string
    nextMemberGroup: string
    loyaltyApp: string
    loyaltyAppActivation?: Timestamp
    lastLoyaltyTransaction?: Timestamp
  }

  notifications?: {
    emailError?: boolean
    emailErrorType?: 'event'
    emailErrorReason?: SendingBlueEventType
    emailNotificationSubscribed?: boolean
  }

  booklyUserId?: number
}

export type StaffUser = User & {
  image: string
  roles: Array<string>
  locations: Array<string>
  active: boolean
  pinCode: string
}
